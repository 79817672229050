import { EditOutlined, EyeOutlined } from '@ant-design/icons'
import { Button, Flex, Tag, Typography } from 'antd'
import type { ColumnType } from 'antd/es/table'
import moment from 'moment'
import { Text } from 'native-base'
import { useState } from 'react'

import { PaginatedTable } from '#admin/components/widgets/PaginatedTable'
import { SearchButton } from '#admin/components/widgets/SearchButton'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import { useSearchProduct } from '#graphql/codegen'
import { navigate } from '#navigator/helpers'
import type { SearchProductItems } from '#types/product'

import { PaymentModal } from './PaymentModal'

export const Payment = () => {
  const [search, setsearch] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const { openModal } = useOverlay()
  const [products, refetchProduct] = useSearchProduct({
    variables: {
      filter: search ? { name_iLike: `%${search}%` } : {},
      order: ['createdAt_desc'],
      page: { limit: pageSize, offset: (currentPage - 1) * pageSize },
    },
  })

  const handleAddEditRecord = (record?: SearchProductItems) => {
    openModal(PaymentModal, {
      containerProps: {
        closeOnOverlayClick: false,
      },
      onSuccess: () => refetchProduct({ requestPolicy: 'network-only' }),
      payment: record,
    })
  }

  const handleViewDetail = (recordId: string) => {
    navigate('Admin', {
      screen: 'AdminPaymentDetail',
      params: { id: recordId },
    })
  }

  const renderPrice = (_: unknown, record: SearchProductItems) => {
    if (!record.price) {
      return null
    }
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: record.price.currency,
      currencyDisplay: 'narrowSymbol',
    })
    return (
      <Tag
        color='blue'
        style={tw.style('uppercase text-sm p-1', { lineHeight: 1 })}
      >
        {formatter.format(record.price.unitAmount)}
      </Tag>
    )
  }

  const columns: ColumnType<SearchProductItems>[] = [
    {
      title: 'Name',
      render: (_, { name }) => <Typography>{name}</Typography>,
    },
    {
      title: 'Price',
      render: renderPrice,
    },
    {
      title: 'Description',
      render: (_, record) => <Typography>{record.description}</Typography>,
    },
    {
      title: 'Status',
      render: (_, { active }) => (
        <Typography
          style={tw.style(
            'font-medium',
            active ? 'text-green-500' : 'text-red-500',
          )}
        >
          {active ? 'Active' : 'Inactive'}
        </Typography>
      ),
    },
    {
      title: 'Created at',
      render: (_, record) => (
        <Typography>
          {moment(record.createdAt).format('YYYY-MM-DD, hh:mm A')}
        </Typography>
      ),
    },
    {
      width: 110,
      render: (_, record) => (
        <Flex gap={4}>
          <Button
            icon={<EyeOutlined />}
            onClick={() => handleViewDetail(record.id)}
            className='p-0 bg-transparent'
          />
          <Button
            icon={<EditOutlined />}
            onClick={() => handleAddEditRecord(record)}
            className='p-0 bg-transparent'
          />
        </Flex>
      ),
    },
  ]

  return (
    <Flex style={tw`flex-1 flex-col p-4 h-full`}>
      <Text style={tw`py-6 text-6`}>Payment Management</Text>
      <Flex style={tw`flex-col`}>
        <SearchButton
          title='Add'
          handleAddData={() => handleAddEditRecord()}
          keyword={(k: string) => {
            setsearch(k)
            setCurrentPage(1)
          }}
        />
        <PaginatedTable<SearchProductItems>
          dataSource={products.data?.searchProduct ?? []}
          columns={columns}
          loading={products.fetching}
          total={products.data?.count ?? 0}
          currentPage={currentPage}
          pageSize={pageSize}
          setCurrentPage={setCurrentPage}
          setPageSize={setPageSize}
          scrollHeight={'69vh'}
          scrollWidth={1100}
        />
      </Flex>
    </Flex>
  )
}
