import type { FC } from 'react'
import { Pressable, TouchableOpacity, View } from 'react-native'
import type { SvgProps } from 'react-native-svg'

import { Avatar } from '#components/base/Avatar'
import { ButtonIcon } from '#components/base/ButtonIcon/ButtonIcon'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import type { SearchAiTutorItem } from '#types/aiTutor'

import { IconComponent, MoreInfo } from './MoreInfo'

type TeacherItemProps = {
  teacher: SearchAiTutorItem
  onSelect: (id: string) => void
  onPressSelect: (id: string) => void
  isSelected: boolean
  onPressSpeak: (url: string) => void
}

export const TeacherItem: FC<TeacherItemProps> = ({
  teacher,
  onSelect,
  isSelected,
  onPressSelect,
  onPressSpeak,
}) => {
  const { openActionsheet } = useOverlay()
  const Icon: FC<SvgProps> = IconComponent[teacher.languageAI]

  const onPressMoreInfo = () => {
    openActionsheet(MoreInfo, {
      data: teacher,
      onPress: () => {
        onPressSelect(teacher.id)
      },
    })
  }

  const onPressVolume = () => {
    onPressSpeak(teacher.media?.url || '')
  }

  return (
    <Pressable
      onPress={() => onSelect(teacher.id)}
      style={tw.style(
        'bg-white p-3 rounded-lg shadow flex flex-row items-center border-2 gap-x-3',
        isSelected ? 'border-primary-400' : 'border-transparent',
      )}
    >
      <Avatar
        rounded={'xl'}
        _image={{ rounded: 'xl' }}
        source={{ uri: teacher.thumbnail?.url || '' }}
        name={teacher.name}
        size='xl'
      />
      <View style={tw`flex-1 flex-col`}>
        <View style={tw`flex-row items-center border-b border-separation pb-2`}>
          <View style={tw`flex-1 mr-3`}>
            <Text specialType='Headline4'>{teacher.name}</Text>
          </View>
          <ButtonIcon
            tone='four'
            bg={tw.color('transparent')}
            padding={1.5}
            size={16}
            icon={{
              name: 'VolumeHigh',
              type: 'SAX',
              color: tw.color('primary-400'),
              variant: 'Bold',
            }}
            onPress={onPressVolume}
          />
        </View>
        <View style={tw`flex-row items-center mt-2 mb-3`}>
          {teacher.languageAI && <Icon height={24} width={24} />}
          <View style={tw.style('flex-1', teacher.languageAI && 'ml-2')}>
            <Text specialType='paragraph1'>{teacher.languageAI}</Text>
          </View>
        </View>
        <TouchableOpacity
          style={tw`flex-row items-center`}
          onPress={onPressMoreInfo}
        >
          <View style={tw`flex-1 mr-2`}>
            <Text specialType='paragraph2' color={tw.color('primary-400')}>
              More info
            </Text>
          </View>
          <SystemIcon type='SAX' name='ArrowRight2' size={20} />
        </TouchableOpacity>
      </View>
    </Pressable>
  )
}
