import moment from 'moment'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useWindowDimensions, View } from 'react-native'
import { Calendar } from 'react-native-calendars'

import { Image } from '#components/base/Image'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { Header } from '#components/widgets/Header'
import { gql } from '#graphql/urql'

import { Skeleton } from './Skeleton'

type StreakProps = {
  closeModal: () => void
  userId: string
}

export const Streak: FC<StreakProps> = ({ closeModal, userId }) => {
  const { height, width } = useWindowDimensions()
  const month = moment().add(1, 'months')
  const today = moment().format('YYYY-MM-DD')
  const [currentDate, setCurrentDate] = useState<string>(today)

  const [loading, setLoading] = useState<boolean>(true)
  const [slogan, setSlogan] = useState<string>('')
  const [media, setMedia] = useState<string>('')
  const [data, setData] = useState<string[]>([])
  const [streakData, setStreakData] = useState<string[]>([])
  const [count, setCount] = useState<number>(0)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const resp = await gql.searchStreakInUser(
      { filter: { userId } },
      { requestPolicy: 'network-only' },
    )
    const streakInUser = resp.data?.searchStreakInUser || []
    if (streakInUser.length > 0) {
      const streakD = streakInUser[0]
      const attendance = streakD.dateStreak?.attendance || []
      setData(attendance)
      getStreakData(moment().format(), attendance)
      const newDate = moment().format('YYYY-MM-DD')
      const yesterday = moment(newDate).subtract(1, 'days').format()
      const lastDate = streakD.lastDate
      const c = moment(lastDate).isSame(moment(newDate).format(), 'day')
        ? streakD.countStreak
        : !lastDate || !moment(lastDate).isSame(yesterday, 'day')
          ? 0
          : streakD.countStreak
      setCount(c)
      const respMilestone = await gql.searchStreak({
        filter: { numberStreak: c },
      })
      const milestoneD = respMilestone.data?.searchStreak || []
      const sloganD =
        milestoneD.length > 0 ? milestoneD[0].infor?.content || [] : []
      if (sloganD.length > 0) {
        const newSlogan = sloganD[Math.floor(Math.random() * sloganD.length)]
        setSlogan(newSlogan)
      } else {
        const respDaily = await gql.searchStreakDaily()
        const dailyD = respDaily.data?.searchStreakDaily || []
        const newSlogan =
          dailyD.length > 0
            ? dailyD[Math.floor(Math.random() * dailyD.length)].name
            : 'Great job!'
        setSlogan(newSlogan)
      }
      const newMedia =
        milestoneD.length > 0 ? milestoneD[0].media?.url || '' : ''
      setMedia(newMedia)
    }

    setLoading(false)
  }

  const getStreakData = async (date: string, d: string[]) => {
    const currentD = moment(date)
    const startOfMonth = currentD.clone().startOf('month')
    const endOfMonth = currentD.clone().endOf('month')
    const momentDates = d.map(i => moment(i))
    const filteredDates = momentDates.filter(i =>
      i.isBetween(startOfMonth, endOfMonth, 'day', '[]'),
    )
    const filteredDateStrings = filteredDates.map(i =>
      moment(i, 'YYYY-MM-DD').format('YYYY-MM-DD'),
    )
    setStreakData(filteredDateStrings)
  }
  const onPressArrowLeft = (subtractMonth: () => void) => {
    subtractMonth()
    const prevMonth = moment(currentDate, 'YYYY-MM-DD')
      .subtract(1, 'months')
      .format('YYYY-MM-DD')
    setCurrentDate(moment(prevMonth).format('YYYY-MM-DD'))
    getStreakData(moment(prevMonth).format('YYYY-MM-DD'), data)
  }
  const onPressArrowRight = (addMonth: () => void) => {
    const nextMonth = moment(currentDate, 'YYYY-MM-DD')
      .add(1, 'months')
      .format('YYYY-MM-DD')
    if (moment(nextMonth).isBefore(month, 'month')) {
      addMonth()
      setCurrentDate(moment(nextMonth, 'YYYY-MM-DD').format('YYYY-MM-DD'))
      getStreakData(moment(nextMonth, 'YYYY-MM-DD').format('YYYY-MM-DD'), data)
    }
  }

  return (
    <View
      style={tw.style('flex-1 flex-col bg-background-light-1', {
        width,
        height,
      })}
    >
      <Header onPressBack={closeModal} title='Streak' iconType='close' />

      {loading ? (
        <View
          style={tw`flex-col items-center bg-background-light-white m-4 p-6 rounded-2xl `}
        >
          <Skeleton.Image />
          <Skeleton.Title />
          <Skeleton.Subtitle />
        </View>
      ) : (
        <View
          style={tw`flex-col items-center bg-background-light-white m-4 p-6 rounded-2xl gap-3`}
        >
          {media ? (
            <Image
              source={{ uri: media }}
              resizeMode='contain'
              style={tw.style('w-22 h-22')}
            />
          ) : (
            <SystemIcon type='SVG' name='streak-icon' size={88} />
          )}
          <Text
            specialType='Headline3'
            textAlign='center'
            color={tw.color('primary-400')}
          >
            {`${count}  DAY STREAK`}
          </Text>
          <Text specialType='Title' textAlign='center'>
            {slogan}
          </Text>
        </View>
      )}

      <View style={tw`flex-1 mx-4 gap-4`}>
        <Text specialType='Headline2'>Calendar</Text>
        <Calendar
          current={today}
          markedDates={streakData.reduce((acc, date) => {
            acc[date] = { selected: true }
            return acc
          }, {})}
          onPressArrowLeft={onPressArrowLeft}
          onPressArrowRight={onPressArrowRight}
          renderArrow={direction =>
            direction === 'left' ? (
              <View style={tw.style('mr-5')}>
                <SystemIcon
                  type='SAX'
                  name='ArrowLeft2'
                  size={24}
                  color={tw.color('primary-400')}
                />
              </View>
            ) : moment(currentDate)
                .add(1, 'months')
                .isBefore(month, 'month') ? (
              <View style={tw.style('ml-5')}>
                <SystemIcon
                  type='SAX'
                  name='ArrowRight2'
                  size={24}
                  color={tw.color('primary-400')}
                />
              </View>
            ) : (
              <View style={tw.style('w-6')} />
            )
          }
          theme={{
            selectedDayBackgroundColor: tw.color('primary-400'),
            selectedDayTextColor: '#ffffff',
            todayTextColor: tw.color('primary-400'),
            arrowColor: tw.color('primary-400'),
            textMonthFontWeight: 'bold',
            textDayFontSize: 16,
            textMonthFontSize: 20,
            textDayHeaderFontSize: 14,
          }}
          style={tw`bg-background-light-white rounded-2xl p-4`}
        />
      </View>
    </View>
  )
}
