import type { FC } from 'react'
import { TouchableOpacity, useWindowDimensions } from 'react-native'

import { Image } from '#components/base/Image'
import { tw } from '#components/utils/tw'
import type { AnswerItemProps } from '#types/games'

export const SelectImageAnswer: FC<AnswerItemProps> = ({
  item,
  onPressAnswer,
  hint,
  index,
}) => {
  const { height, width } = useWindowDimensions()

  const maxWidth = (width - 48) * 0.5
  const maxHeight = height * 0.25
  return (
    <TouchableOpacity
      onPress={onPressAnswer}
      disabled={hint.length > 0 ? hint.includes(index) : false}
      style={tw.style(
        'rounded-2xl border-2  border-neutral-200 overflow-hidden',
        {
          width: maxWidth,
          height: maxHeight,
        },
      )}
    >
      {!hint.includes(index) && (
        <Image
          source={{ uri: item.image?.url || '' }}
          style={tw.style('rounded-2xl', {
            width: maxWidth,
            height: maxHeight,
          })}
        />
      )}
    </TouchableOpacity>
  )
}
