import { useState } from 'react'
import { View } from 'react-native'

import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { audioManager } from '#components/utils/audio/audioManager'
import { tw } from '#components/utils/tw'
import { ProgressCircle } from '#screens/Review/ProgressCircle'
import { S } from '#store'
import type { ResourceInWord } from '#types/resourceInWord'
import type { SearchTranslateItem } from '#types/translate'
import type { SearchWordInUser } from '#types/wordInUser'

type ListItemProps = {
  item: SearchWordInUser & {
    translate: SearchTranslateItem[]
    media: ResourceInWord[]
  }
  index: number
  total: number
  isTrue: boolean
  onPlay: () => void
}

export const Item = ({ item, index, total, isTrue, onPlay }: ListItemProps) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false)

  const handleTTS = async () => {
    onPlay()
    setIsPlaying(true)
    audioManager.stop()
    const audio = item.media.find(media => media.type === 'audio_game')
    if (!!audio && !!audio.media?.url) {
      await audioManager.add(audio.media.url)
      await audioManager.play(S.shared.currentUser?.speed || 1)
      const duration = audioManager.getDuration() || 0
      setTimeout(
        () => {
          setIsPlaying(false)
        },
        (await duration) * 1000,
      )
    } else {
      setIsPlaying(false)
    }
  }

  return (
    <View
      style={tw.style(
        'flex-row bg-background-light-white p-4.5 gap-2',
        index === total - 1 ? 'rounded-b-5' : '',
      )}
    >
      <SystemIcon
        color={isPlaying && isTrue ? tw.color('primary-400') : tw.color('icon')}
        variant={isPlaying && isTrue ? 'Bold' : 'Linear'}
        type='SAX'
        name='VolumeHigh'
        onPress={handleTTS}
      />
      <View style={tw.style('flex-1')}>
        <Text specialType='Title'>{item?.word?.vocabAndPhrase || ''}</Text>
        <Text specialType='Note'>
          {item.translate.length > 0 ? item.translate[0].text : ''}
        </Text>
      </View>
      <ProgressCircle progress={item.times - 1} />
    </View>
  )
}
