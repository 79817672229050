import { DeleteOutlined } from '@ant-design/icons'
import { Avatar, Button, Flex, Input } from 'antd'
import { useEffect, useState } from 'react'

import { Upload } from '#admin/components/widgets/Upload'
import { getInitials } from '#components/utils/getInitials'
import { toastError } from '#components/utils/Toast'
import { tw } from '#components/utils/tw'
import { useCurrentAuthSession } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import { S } from '#store'
import type { LanguageType } from '#types/language'
import type { CurrentUser } from '#types/user'

import { Action } from './Action'
import { Dropdown } from './Dropdowns'

export const GeneralTab = () => {
  const account = useCurrentAuthSession({ variables: {} })
  const currentAccount = account[0].data?.currentAuthSession

  const [avatar, setAvatar] = useState<{ id: string; url: string }>()
  const [languageLearn, setLanguageLearn] = useState<LanguageType>(
    currentAccount?.user?.languageLearn as LanguageType,
  )
  const [name, setName] = useState(currentAccount?.user?.name)
  const [nativeLanguage, setNativeLanguage] = useState<LanguageType>(
    currentAccount?.user?.nativeLanguage as LanguageType,
  )
  const [isEditName, setIsEditName] = useState(false)
  const [isEditLanguageLearn, setIsEditLanguageLearn] = useState(false)
  const [isEditNativeLanguage, setIsEditNativeLanguage] = useState(false)

  useEffect(() => {
    if (currentAccount) {
      setAvatar({
        url: currentAccount?.user?.thumbnail?.url ?? '',
        id: currentAccount?.user?.thumbnailId ?? '',
      })
      setName(currentAccount?.user?.name ?? '')
      setLanguageLearn(
        (currentAccount?.user?.languageLearn ?? 'English') as LanguageType,
      )
      setNativeLanguage(
        (currentAccount?.user?.nativeLanguage ?? 'English') as LanguageType,
      )
    }
  }, [currentAccount])

  useEffect(() => {
    handleEditData()
  }, [avatar?.url])

  const handleEditData = async () => {
    const res = await gql.updateUser({
      data: {
        email: currentAccount?.user?.email,
        languageLearn,
        name,
        nativeLanguage,
        thumbnailId: avatar?.id,
      },
    })
    const newUser: CurrentUser = S.shared.currentUser
      ? {
          ...S.shared.currentUser,
          ...res.data?.updateUser,
        }
      : null
    S.shared.currentUser = newUser
    if (res.error) {
      toastError({
        message: res.error.message,
      })
    }
  }

  return (
    <Flex style={tw`p-4 px-[15%] flex-col items-center text-[16px] w-full`}>
      <Flex style={tw`flex justify-between items-center w-full py-4`}>
        <Avatar
          style={tw.style('w-26 h-26 border-2', {
            fontSize: '45px',
          })}
          src={avatar?.url}
        >
          {getInitials(name ?? '')}
        </Avatar>
        <Flex gap={8}>
          <Button onClick={() => setAvatar({ url: '', id: '' })}>
            <DeleteOutlined style={tw`text-red-500`} />
          </Button>
          <Upload
            resourceType='avatar'
            url={avatar?.url}
            type='avatar'
            display='image'
            onChange={data => {
              setAvatar({
                url: data.url,
                id: data.id,
              })
            }}
            onDeleted={() => setAvatar({ url: '', id: '' })}
            isHiddenImage
          />
        </Flex>
      </Flex>
      <hr style={tw`w-full`} />
      <Flex style={tw`flex justify-between w-full items-center`}>
        <Flex style={tw`flex-col w-[50%] p-4`} gap={8}>
          <div>Name </div>
          {!isEditName ? (
            <div style={tw`text-gray-500`}>{name}</div>
          ) : (
            <Input value={name} onChange={e => setName(e.target.value)} />
          )}
        </Flex>
        <Action
          isEdit={isEditName}
          onClickEdit={isEdit => setIsEditName(isEdit)}
          onClickSubmit={() => {
            handleEditData()
            setIsEditName(false)
          }}
        />
      </Flex>
      <hr style={tw`w-full`} />
      <Flex style={tw`flex-col w-full p-4`} gap={8}>
        <div>Email </div>
        <div style={tw`text-gray-500`}>{currentAccount?.user?.email}</div>
      </Flex>
      <hr style={tw`w-full`} />
      <Flex style={tw`flex justify-between w-full items-center`}>
        <Dropdown
          title='I want to learn'
          language={languageLearn}
          isEdit={isEditLanguageLearn}
          onChange={e => setLanguageLearn(e)}
        />
        <Action
          isEdit={isEditLanguageLearn}
          onClickEdit={isEdit => setIsEditLanguageLearn(isEdit)}
          onClickSubmit={() => {
            handleEditData()
            setIsEditLanguageLearn(false)
          }}
        />
      </Flex>
      <hr style={tw`w-full`} />
      <Flex style={tw`flex justify-between w-full items-center`}>
        <Dropdown
          title='I speak'
          language={nativeLanguage}
          isEdit={isEditNativeLanguage}
          onChange={e => setNativeLanguage(e)}
        />
        <Action
          isEdit={isEditNativeLanguage}
          onClickEdit={isEdit => setIsEditNativeLanguage(isEdit)}
          onClickSubmit={() => {
            handleEditData()
            setIsEditNativeLanguage(false)
          }}
        />
      </Flex>
    </Flex>
  )
}
