import type { FC } from 'react'
import { useEffect, useRef, useState } from 'react'

import { Text } from './Text'

type CountdownProps = {
  initialSeconds: number
  onEnd?: () => void
}
const useCountdown = ({ initialSeconds, onEnd }: CountdownProps) => {
  const [seconds, setSeconds] = useState(initialSeconds)
  const [isActive] = useState(true)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (isActive && seconds > 0) {
      intervalRef.current = setInterval(() => {
        setSeconds(prev => prev - 1)
      }, 1000)
    } else if (seconds === 0) {
      onEnd?.()
      clearInterval(intervalRef.current as NodeJS.Timeout)
    }
    return () => clearInterval(intervalRef.current as NodeJS.Timeout)
  }, [isActive, seconds])

  return { seconds, isActive }
}
const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`
}
export const Countdown: FC<CountdownProps> = ({ initialSeconds, onEnd }) => {
  const { seconds } = useCountdown({
    initialSeconds,
    onEnd,
  })
  return <Text specialType='Note2'>{formatTime(seconds)}</Text>
}
