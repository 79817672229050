import type { TouchableOpacityProps } from 'react-native'
import { TouchableOpacity, View } from 'react-native'
import type { Style } from 'twrnc'

import type { IconPropsWithType } from '#components/base/SystemIcon'
import { SystemIcon } from '#components/base/SystemIcon'
import { tw } from '#components/utils/tw'

type Type = 'small' | 'medium' | 'large'
type Tone = 'primary' | 'secondary' | 'third' | 'secondary2' | 'four'

export type ButtonProps = Omit<TouchableOpacityProps, 'style'> & {
  size?: Type | number
  tone?: Tone
  style?: Style
  shadow?: boolean
  padding?: Type | number
  icon: IconPropsWithType
  bg?: string
}

const buttonStyle: {
  type: { [key in Type]: string }
  tone: { [key in Tone]: string }
} = {
  type: {
    large: 'p-5',
    medium: 'p-3',
    small: 'p-1.5',
  },
  tone: {
    primary: 'bg-transparent',
    secondary: 'bg-background-light-1',
    third: 'bg-transparent',
    secondary2: 'bg-secondary2-400',
    four: 'bg-background-light-white',
  },
}

const iconStyle: {
  type: { [key in Type]: number }
  tone: { [key in Tone]: string }
} = {
  type: {
    large: 24,
    medium: 20,
    small: 16,
  },
  tone: {
    primary: 'text-text-4',
    secondary: 'text-primary-500',
    third: 'text-text-4',
    secondary2: 'text-secondary2-500',
    four: 'primary-400',
  },
}

const gradientStyle: { [key in Tone]: object } = {
  primary: {
    background:
      'linear-gradient(180deg, rgba(26, 184, 229, 1) 0%, rgba(59, 137, 235, 1) 100%)',
  },
  secondary: {
    background:
      'linear-gradient(135deg, rgba(255, 255, 255, 0.30) 15%, rgba(255, 255, 255, 0.25) 85%)',
    backdropFilter: 'blur(5px)',
  },
  third: {
    boxShadow: '0px 2px 5px rgba(57, 139, 234, 0.12)',
  },
  secondary2: {
    background: tw.color('secondary1-500'),
  },
  four: {
    // background: tw.color(''),
  },
}

const shadowClasses = 'shadow-lg elevation-1'

export const ButtonIcon = ({
  tone = 'primary',
  size = 'medium',
  disabled = false,
  onPress,
  style,
  padding = 'medium',
  shadow = false,
  icon,
  bg,
  ...props
}: ButtonProps) => (
  <TouchableOpacity
    {...props}
    style={
      tone === 'four'
        ? tw`border-2 rounded-full border-neutral-200 items-center justify-center`
        : tw``
    }
    onPress={onPress}
    disabled={disabled}
  >
    <div
      style={{
        ...tw.style(
          'self-start rounded-full',
          typeof padding === 'number'
            ? `p-${padding}`
            : buttonStyle.type[padding],
          buttonStyle.tone[tone],
          shadow ? shadowClasses : '',
        ),
        ...gradientStyle[tone],
        ...style,
        ...(bg ? { background: bg } : {}),
        ...(disabled
          ? { background: tw.color('neutral-200'), borderWidth: 0 }
          : {}),
      }}
    >
      <View style={tw`flex justify-center items-center`}>
        <SystemIcon
          {...icon}
          color={icon.color || tw.color(iconStyle.tone[tone])}
          size={typeof size === 'number' ? size : iconStyle.type[size]}
        />
      </View>
    </div>
  </TouchableOpacity>
)
