import type { FC } from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { TouchableOpacity } from 'react-native'

import type { IconSvgProps } from '#components/base/IconSvg'
import { SystemIcon } from '#components/base/SystemIcon'
import { tw } from '#components/utils/tw'
import { goBack, popToTop } from '#navigator/helpers'

export type BackButtonProps = {
  onPressBack?: () => void
  iconSvg?: IconSvgProps
  iconType?: 'arrow' | 'close'
  buttonStyle?: StyleProp<ViewStyle>
  dark?: boolean
  isPopToTop?: boolean
}

export const BackButton: FC<BackButtonProps> = ({
  onPressBack,
  iconType,
  buttonStyle,
  dark,
  isPopToTop: isBackToTop,
}) => {
  const handlePress = () => {
    if (onPressBack) {
      onPressBack()
      return
    }
    if (isBackToTop) {
      popToTop()
    } else {
      goBack()
    }
  }

  return (
    <TouchableOpacity
      onPress={handlePress}
      style={[tw`w-12 h-12 justify-center items-center`, buttonStyle]}
    >
      {iconType === 'close' ? (
        <SystemIcon
          type='SVG'
          color={dark ? tw.color('icon') : 'white'}
          name='x'
        />
      ) : (
        <SystemIcon
          type='SAX'
          color={dark ? tw.color('icon') : 'white'}
          name='ArrowLeft2'
        />
      )}
    </TouchableOpacity>
  )
}
