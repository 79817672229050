import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { View } from 'react-native'

import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { S } from '#store'
import type { GameQuestionProps } from '#types/games'

export const TextQuestion: FC<GameQuestionProps> = observer(({ question }) => {
  const native = S.shared.currentUser?.nativeLanguage
  const learn = S.shared.currentUser?.languageLearn
  return (
    <View
      style={tw.style(
        'flex-1 justify-center items-center bg-primary-400 mx-4 rounded-2xl p-4',
      )}
    >
      <Text
        textAlign='center'
        color={tw.color('text-4')}
        specialType='Headline3'
      >
        {question.type === 'fillTheBlank'
          ? question.text.replaceAll('...', '___')
          : question.type === 'rearrangement' &&
              native !== learn &&
              question.translation
            ? question.translation
            : question.text}
      </Text>
    </View>
  )
})
