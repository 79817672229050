import type React from 'react'
import { Text, TouchableOpacity, View } from 'react-native'

import { tw } from '#components/utils/tw'

type Pr = {
  closeModal: () => void
}

export const CheckPermissionModal: React.FC<Pr> = ({ closeModal }) => (
  <View style={tw`flex-1 justify-center items-center px-4`}>
    <View style={tw`bg-white p-4 items-center rounded-lg`}>
      <Text>
        {
          'Microphone Access Denied. This app needs access to your microphone to record audio. Please grant permission in your browser settings.'
        }
      </Text>
      <TouchableOpacity
        onPress={() => closeModal()}
        style={tw` items-center justify-center w-20 h-7.5 mt-2 border border-primary-400 rounded-xl`}
      >
        <Text style={tw.style('text-primary-400')}>OK</Text>
      </TouchableOpacity>
    </View>
  </View>
)
