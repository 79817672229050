import { forwardRef, useImperativeHandle } from 'react'
import { View } from 'react-native'

import { Avatar } from '#components/base/Avatar'
// import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import type { AudioPropsRef } from '#components/utils/audio/type'
import { useAudioManager } from '#components/utils/audio/useAudioManager'
import { tw } from '#components/utils/tw'
import { S } from '#store'
import type { GameQuestionProps } from '#types/games'

export const CompleteTheDialogueQuestion = forwardRef<
  AudioPropsRef,
  GameQuestionProps
>(({ question, topic }, ref) => {
  const { play, stop, isPlay } = useAudioManager({
    url: question.media?.url || '',
  })

  useImperativeHandle(ref, () => ({ stop: stopAudio, play: playAudio }), [
    isPlay,
    question.media,
  ])

  const playAudio = () => {
    play(1)
  }

  const stopAudio = () => {
    stop()
  }

  const left = question.index === 0
  return (
    <View style={tw.style('flex-col px-9 py-8 gap-7')}>
      <View style={tw`flex-row items-center gap-4 `}>
        <Avatar
          size={44}
          source={{
            uri: left
              ? topic?.media?.url || ''
              : S.shared.currentUser?.thumbnail?.url || '',
          }}
          name={left ? topic?.name || '' : S.shared.currentUser?.name || ''}
        />
        <View
          style={tw.style(
            'flex-row rounded-r-2xl rounded-b-2xl border px-4 py-3 gap-3 border-neutral-200',
            left ? 'flex-1' : 'min-w-20',
          )}
        >
          {/* {left && (
            <SystemIcon
              color={tw.color('primary-400')}
              size={32}
              type='SAX'
              name='VolumeHigh'
              variant='Bold'
              onPress={() => play(1)}
            />
          )} */}
          <View style={tw.style('flex-1', !left ? 'items-center' : '')}>
            <Text specialType='Headline4'>{left ? question.text : '...'}</Text>
          </View>
        </View>
      </View>
      <View style={tw`flex-row items-center gap-4 justify-end`}>
        <View
          style={tw.style(
            'flex-row rounded-l-2xl rounded-b-2xl border px-4 py-3 gap-3 border-neutral-200',
            !left ? 'flex-1' : 'min-w-20',
          )}
        >
          {/* {!left && (
            <SystemIcon
              color={tw.color('primary-400')}
              size={32}
              type='SAX'
              name='VolumeHigh'
              variant='Bold'
              onPress={() => play(1)}
            />
          )} */}
          <View style={tw.style('flex-1', left ? 'items-center' : '')}>
            <Text specialType='Headline4'>{!left ? question.text : '...'}</Text>
          </View>
        </View>
        <Avatar
          size={44}
          source={{
            uri: !left
              ? topic?.media?.url || ''
              : S.shared.currentUser?.thumbnail?.url,
          }}
          name={!left ? topic?.name || '' : S.shared.currentUser?.name}
        />
      </View>
    </View>
  )
})
