import { useEffect } from 'react'
import { Platform, TouchableOpacity, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { Avatar } from '#components/base/Avatar'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import { logCustomEvent } from '#config/firebaseConfig'
import { useSearchAiTutor } from '#graphql/codegen'
import { navigate, replace } from '#navigator/helpers'
import { S } from '#store'
import type { UserTasks } from '#types/chat'

import { ActionSheetChatSetting } from './ActionSheetChatSetting'
import { ActionSheetTask } from './ActionSheetTask'

type Props = {
  tasks: UserTasks
  topicId: string
  topicName: string
}

export const Header = ({ tasks, topicId, topicName }: Props) => {
  const { top } = useSafeAreaInsets()
  const { openActionsheet } = useOverlay()

  const [aiTutorData] = useSearchAiTutor({
    variables: { filter: { id: S.shared.currentUser?.aiTutorId } },
  })

  const aiTutor = aiTutorData.data?.searchAiTutor[0]

  useEffect(() => {
    logCustomEvent('chat_session', {
      topic_id: topicId,
      topic: topicName,
      tutor_name: aiTutor?.name ?? '',
      tutor_id: S.shared.currentUser?.aiTutorId,
    })
  }, [])

  return (
    <View
      style={tw.style(
        'flex-row justify-between h-15 bg-white mb-1 shadow',
        Platform.OS === 'ios' && { paddingTop: top, height: 76 + top },
      )}
    >
      <View style={tw`flex-row items-center h-full gap-x-1`}>
        <TouchableOpacity
          style={tw`h-full w-11 justify-center items-center`}
          onPress={() => replace('Home', { screen: 'Topics' })}
        >
          <SystemIcon type='SAX' name='ArrowLeft2' />
        </TouchableOpacity>
        <View style={tw`flex-1 flex-row items-center`}>
          <Avatar
            style={tw`w-11 h-11`}
            name={aiTutor?.name ?? 'Cody'}
            source={{ uri: aiTutor?.thumbnail?.url }}
          />
          <View style={tw`ml-2`}>
            <Text specialType='Headline4'>{aiTutor?.name ?? 'Cody'}</Text>
          </View>
        </View>
      </View>
      <View style={tw`flex-row items-center h-full`}>
        <TouchableOpacity
          style={tw`h-full w-11 justify-center items-center`}
          onPress={() => openActionsheet(ActionSheetTask, { data: tasks })}
        >
          <SystemIcon type='SAX' name='TaskSquare' />
        </TouchableOpacity>
        <TouchableOpacity
          style={tw`h-full w-11 justify-center items-center`}
          onPress={() =>
            navigate('App', { screen: 'SuggestedSpeak', params: { topicId } })
          }
        >
          <SystemIcon type='SAX' name='MagicStar' />
        </TouchableOpacity>
        <TouchableOpacity
          style={tw`h-full w-11 justify-center items-center`}
          onPress={() => openActionsheet(ActionSheetChatSetting)}
        >
          <SystemIcon type='SAX' name='Setting2' />
        </TouchableOpacity>
      </View>
    </View>
  )
}
