import { View } from 'react-native'

import { Expandable } from '#components/base/Expandable'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import type { TasksItem } from '#graphql/codegen'

type Props = {
  index: number
  task: TasksItem
}

export const TaskListItem = ({ index, task }: Props) => {
  const isParentTask = !!task.subTask?.length

  const renderCompletedCheck = (isCompleted: boolean) => (
    <View style={tw`mt-px`}>
      {isCompleted && (
        <SystemIcon
          type='SAX'
          name='TickCircle'
          variant='Bold'
          color={tw.color('green-500')}
        />
      )}
      {!isCompleted && (
        <View style={tw`w-6 h-6 mt-px`}>
          <View style={tw`flex flex-1 m-0.5 rounded-full bg-gray-300`} />
        </View>
      )}
    </View>
  )

  const renderSubTask = (subTask: Omit<TasksItem, 'subTask'>) => (
    <View key={subTask.id} style={tw`flex flex-row justify-between gap-x-4`}>
      <View style={tw`ml-4.5 flex-1 flex-row gap-x-1.5 items-baseline`}>
        <View
          style={tw.style('w-1.5 h-1.5 rounded-full bg-gray-500', {
            transform: [{ translateY: -1 }],
          })}
        />
        <Text specialType='paragraph1' style={tw`text-[17px] leading-normal`}>
          {subTask.name}
        </Text>
      </View>
      {renderCompletedCheck(!!subTask.isPassed)}
    </View>
  )

  return (
    <Expandable
      defaultExpand
      animateOnMount={false}
      style={tw`flex-col bg-white p-4 rounded-2xl border border-gray-100 shadow gap-y-1`}
    >
      <Expandable.Trigger style={tw`flex flex-row justify-between gap-x-4`}>
        <View style={tw`flex-1 flex-row gap-x-1.5 items-baseline`}>
          <Text specialType='paragraph1' style={tw`text-[17px] leading-normal`}>
            {index + 1}.
          </Text>
          <Text specialType='paragraph1' style={tw`text-[17px] leading-normal`}>
            {task.name}
          </Text>
        </View>
        {isParentTask ? (
          <Expandable.Arrow style={tw`flex-shrink-0 mr-px mt-0.5`} />
        ) : (
          renderCompletedCheck(!!task.isPassed)
        )}
      </Expandable.Trigger>
      {isParentTask && (
        <Expandable.Content style={tw`flex-col gap-y-0.5`}>
          {task.subTask?.map(subTask => renderSubTask(subTask!))}
        </Expandable.Content>
      )}
    </Expandable>
  )
}
