import { useIsFocused } from '@react-navigation/native'
import { useEffect, useState } from 'react'

import { useAppState } from '#components/utils/appState'
import {
  generateRandomAnswerPositions,
  generateRandomAnswers,
} from '#components/utils/random'
import { toastError } from '#components/utils/Toast'
import { generateGames } from '#components/widgets/Games/generate/games'
import { gql } from '#graphql/urql'
import { replace } from '#navigator/helpers'
import type {
  AnswerGame,
  GameContainer,
  GameType,
  QuestionGame,
} from '#types/games'
import { answersGame, GameTimes } from '#types/games'
import type { LanguageType, Level } from '#types/language'
import type { SearchTopicItem } from '#types/topic'
import type { SearchWordInUser } from '#types/wordInUser'

type RandomGame = {
  wordsData: GameContainer[]
  currentIndex: number
  total: number
  question: QuestionGame | null
  answers: AnswerGame[]
  wordInUser: GameContainer | null
  loading: boolean
  onChangeWordsData: (data: Partial<GameContainer>) => void
  onChangeIndex: () => void
  correctAnswerIndex: number[]
  topicData: SearchTopicItem | undefined
}

export type QuestionData = {
  wordId: string
  type: GameType
}

type Props = {
  topicId: string
  userId: string
  language: LanguageType
  isPhrase: boolean
  isCompleted?: boolean
  isPlayAgain?: boolean
}

export const useRandomGame = ({
  topicId,
  userId,
  language,
  isPhrase,
  isCompleted,
  isPlayAgain,
}: Props): RandomGame => {
  const [wordsData, setWordsData] = useState<GameContainer[]>([])
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [totalCounts, setTotalCounts] = useState<number>(10)
  const [answers, setAnswers] = useState<AnswerGame[]>([])
  const [question, setQuestion] = useState<QuestionGame | null>(null)
  const [wordInUser, setWordInUser] = useState<GameContainer | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [correctAnswerIndex] = useState<number[]>(
    generateRandomAnswerPositions(totalCounts),
  )
  const [topicData, setTopicData] = useState<SearchTopicItem | undefined>(
    undefined,
  )

  const isFocus = useIsFocused()
  const appState = useAppState()

  useEffect(() => {
    if (isFocus && appState === 'active') {
      setLoading(true)
      setCurrentIndex(0)
      fetchWords()
    }
  }, [isFocus, appState])

  const fetchWords = async () => {
    const resp = await gql.searchTopic({
      filter: { id: topicId },
    })

    if (!resp.data?.searchTopic || !resp.data?.searchTopic?.length) {
      toastError({ message: 'Lesson not found.' })
      replace('Home', { screen: 'Topics' })
      return
    }
    const topic = resp.data.searchTopic[0]
    setTopicData(topic)
    const data = await generateGames({
      topicId,
      isPhrase,
      userId,
      level: topic.level as Level,
      language,
      isCompleted,
      isPlayAgain,
    })
    setTotalCounts(data.length)

    if (data.length > 0) {
      setWordsData(data)
      const w: GameContainer = data[0]
      setWordInUser(w)
      const g = isCompleted
        ? getRandomDifficulty() === 'easy'
          ? w.easy
          : w.hard
        : w.times < GameTimes.TimeHard
          ? w.easy
          : w.hard
      setQuestion(g.question)
      const correctAnswers = g.answers.filter(item => item.isCorrect)
      const incorrectAnswers = g.answers.filter(item => !item.isCorrect)
      const a = answersGame.includes(g.question.type || '')
        ? g.answers
        : generateRandomAnswers({
            correctAnswers,
            incorrectAnswers,
            correctAnswerIndex: correctAnswerIndex[0],
          })
      setAnswers(a)
    } else {
      toastError({
        message: 'The data for the game section is currently empty',
      })
      replace('Home', { screen: 'Topics' })
    }
    setLoading(false)
  }

  const onChangeWordsData = (data: Partial<SearchWordInUser>) => {
    setWordsData(currentState =>
      currentState.map(item =>
        item.id === data.id
          ? { ...item, times: data.times ?? item.times }
          : item,
      ),
    )
  }

  const onChangeIndex = () => {
    if (currentIndex < totalCounts - 1) {
      const index = currentIndex + 1
      const w = wordsData[index]
      const g = isCompleted
        ? getRandomDifficulty() === 'easy'
          ? w.easy
          : w.hard
        : w.times < GameTimes.TimeHard
          ? w.easy
          : w.hard
      setQuestion(g.question)
      const correctAnswers = g.answers.filter(item => item.isCorrect)
      const incorrectAnswers = g.answers.filter(item => !item.isCorrect)
      const a = answersGame.includes(g.question.type || '')
        ? g.answers
        : generateRandomAnswers({
            correctAnswers,
            incorrectAnswers,
            correctAnswerIndex: correctAnswerIndex[index],
          })
      setAnswers(a)
      setWordInUser(w)
      setCurrentIndex(currentState => currentState + 1)
    } else {
      if (isCompleted) {
        replace('Home', { screen: 'Topics' })
      } else {
        replace('App', { screen: 'Review', params: { topicId, isPhrase } })
      }
    }
  }

  return {
    correctAnswerIndex,
    wordsData,
    currentIndex,
    total: totalCounts,
    question,
    answers,
    wordInUser,
    loading,
    onChangeIndex,
    onChangeWordsData,
    topicData,
  }
}

const getRandomDifficulty = () => {
  const difficulties = ['easy', 'hard']
  const randomIndex = Math.floor(Math.random() * difficulties.length)
  return difficulties[randomIndex]
}
