import type { FC, ReactNode } from 'react'
import type { ViewStyle } from 'react-native'
import { Platform, View } from 'react-native'

import { Loading } from '#components/base/Loading'
import { ScrollView } from '#components/base/ScrollView'
import type { SpecialType, TextProps } from '#components/base/Text'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

type ActionSheetProps = {
  isLoading?: boolean
  header?: string
  containerStyle?: ViewStyle
  children: ReactNode
  headerStyle?: ViewStyle
  specialType?: SpecialType
  textAlign?: TextProps['textAlign']
  renderFooter?: () => ReactNode
}

export const ActionSheet: FC<ActionSheetProps> = ({
  isLoading,
  header,
  containerStyle,
  children,
  headerStyle,
  specialType = 'Headline4',
  textAlign = 'center',
  renderFooter,
}) => {
  if (isLoading) {
    return <Loading isLoading />
  }

  return (
    <View
      style={[
        tw`w-full flex`,
        containerStyle,
        Platform.OS === 'web' ? tw`flex-1` : null,
      ]}
    >
      <ScrollView style={tw.style('flex flex-1')}>
        {header && (
          <View style={[tw`w-full p-4`, headerStyle]}>
            <Text textAlign={textAlign} specialType={specialType}>
              {header}
            </Text>
          </View>
        )}
        {children}
        {renderFooter && <View style={tw`w-full`}>{renderFooter()}</View>}
      </ScrollView>
    </View>
  )
}
