import { autorun } from 'mobx'
import type { PropsWithChildren } from 'react'
import { useEffect } from 'react'

import type { DarkMode } from '#components/dark/DarkModeProvider'
import { DarkModeSelect } from '#components/dark/DarkModeSelect'
import { useSetDarkMode } from '#components/dark/hooks'
import { S } from '#store'

export const PersistedDarkModeProvider = ({ children }: PropsWithChildren) => {
  const setDarkMode = useSetDarkMode()
  useEffect(
    () =>
      autorun(() => {
        if (S.shared.darkMode) {
          setDarkMode(S.shared.darkMode)
        }
      }),
    [],
  )
  return children
}

const onDarkModeChange = (mode: DarkMode) => {
  S.shared.darkMode = mode
}
export const PersistedDarkModeSelect = () => (
  <DarkModeSelect onChange={onDarkModeChange} />
)
