import { Input } from 'antd'
import { memo, useEffect } from 'react'

import { useDebounceValue } from '../utils/useDebounceValue'

type Prop = {
  keyword: (key: string) => void
}

export const SearchBar = memo(
  ({ keyword }: Prop) => {
    const [searchKeyword, setSearchKeyword, debouncedKeyword] =
      useDebounceValue<string>('')

    useEffect(() => {
      keyword(debouncedKeyword)
    }, [debouncedKeyword, keyword])

    return (
      <Input
        placeholder='Search'
        onChange={e => {
          setSearchKeyword(e.target.value)
        }}
        value={searchKeyword}
        style={{ marginBottom: '1rem' }}
      />
    )
  },
  () => true,
)
