import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import type {
  NativeSyntheticEvent,
  TextInputFocusEventData,
  TextInputProps,
} from 'react-native'
import { Platform, TextInput, View } from 'react-native'
import type { Style } from 'twrnc'

import { useDarkColor } from '#components/dark/hooks'
import { tw } from '#components/utils/tw'

export type InputProps = Omit<TextInputProps, 'style' | 'icon'> & {
  _invalid?: boolean
  containerStyle?: Style
  style?: Style
}

export const FormInputPayment = forwardRef<TextInput, InputProps>(
  (
    {
      _invalid,
      containerStyle,
      onFocus,
      onBlur,
      onChangeText,
      style,
      editable = true,
      value = '',
      ...props
    },
    ref,
  ) => {
    const inputRef = useRef<TextInput>(null)
    const [text, setText] = useState(value)
    const dark = useDarkColor()

    useEffect(() => {
      if (text !== value) {
        setText(value)
      }
    }, [value])

    useImperativeHandle(ref, () => inputRef.current!, [])

    const handleFocus = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      onFocus?.(e)
    }

    const handleBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      onBlur?.(e)
    }

    const handleChangeText = (newValue: string) => {
      setText(newValue)
      onChangeText?.(newValue)
    }

    return (
      <View
        style={tw.style(
          'w-full flex-row border rounded-full mb-4 py-4 px-6 gap-x-1 border-neutral-200',
          Platform.OS === 'web' && { cursor: 'text' },
          containerStyle,
        )}
        onPointerDown={() => setTimeout(() => inputRef.current?.focus(), 10)}
      >
        <TextInput
          style={tw.style(
            `flex-1 p-0 text-sm text-[${dark('text-black', 'text-1')}] `,
            Platform.OS === 'web' && { outline: 'none' },
            {
              fontFamily: 'Nunito-Regular',
              fontSize: 16,
            },
            style,
          )}
          placeholderTextColor={'#87919A'}
          {...props}
          ref={inputRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChangeText={(t: string) => {
            handleChangeText(t)
          }}
          editable={editable}
          value={text}
        />
      </View>
    )
  },
)
