import { Button, Flex } from 'antd'
import type { FC } from 'react'

type Props = {
  isEdit: boolean
  onClickEdit: (isEdit: boolean) => void
  onClickSubmit: () => void
}

export const Action: FC<Props> = ({ isEdit, onClickEdit, onClickSubmit }) =>
  !isEdit ? (
    <Button onClick={() => onClickEdit(true)}>Edit</Button>
  ) : (
    <Flex gap={8}>
      <Button onClick={() => onClickEdit(false)}>Cancel</Button>
      <Button type='primary' onClick={onClickSubmit}>
        Save
      </Button>
    </Flex>
  )
