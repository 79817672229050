import type { ReactNode } from 'react'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import type {
  NativeSyntheticEvent,
  TextInputFocusEventData,
  TextInputProps,
} from 'react-native'
import { Platform, TextInput, View } from 'react-native'
import type { Style } from 'twrnc'

import { ButtonIcon } from '#components/base/ButtonIcon/ButtonIcon'
import { useDarkColor } from '#components/dark/hooks'
import { tw } from '#components/utils/tw'

export type InputProps = Omit<TextInputProps, 'style' | 'icon'> & {
  _invalid?: boolean
  containerStyle?: Style
  style?: Style
  prefix?: ReactNode
  suffix?: ReactNode
}

export const Input = forwardRef<TextInput, InputProps>(
  (
    {
      _invalid,
      containerStyle,
      onFocus,
      onBlur,
      onChangeText,
      style,
      prefix,
      suffix,
      editable = true,
      value = '',
      ...props
    },
    ref,
  ) => {
    const inputRef = useRef<TextInput>(null)
    const [focus, setFocus] = useState(false)
    const [text, setText] = useState(value)
    const bg = editable ? 'bg-white' : 'bg-neutral-75'
    const dark = useDarkColor()

    useEffect(() => {
      if (text !== value) {
        setText(value)
      }
    }, [value])

    useImperativeHandle(ref, () => inputRef.current!, [])

    const handleFocus = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      setFocus(true)
      onFocus?.(e)
    }

    const handleBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      setFocus(false)
      onBlur?.(e)
    }

    const handleChangeText = (newValue: string) => {
      setText(newValue)
      onChangeText?.(newValue)
    }

    const clearTextInput = () => {
      setText('')
      onChangeText?.('')
    }

    const borderColor = dark(
      editable
        ? _invalid
          ? 'red-500'
          : focus
            ? 'primary-400'
            : 'neutral-75'
        : 'neutral-75',
      editable
        ? _invalid
          ? 'red-500'
          : focus
            ? 'primary-400'
            : 'neutral-75'
        : 'neutral-75',
    )

    return (
      <View
        style={tw.style(
          `w-full flex-row p-4 border rounded-full gap-x-1 ${bg} border-[${borderColor}] `,
          Platform.OS === 'web' && { cursor: 'text' },
          containerStyle,
        )}
        onPointerDown={() => setTimeout(() => inputRef.current?.focus(), 10)}
      >
        {prefix}
        <TextInput
          style={tw.style(
            `flex-1 p-0 text-sm text-[${dark('tex-2', 'text-1')}] `,
            Platform.OS === 'web' && { outline: 'none' },
            {
              fontFamily: 'Nunito-Regular',
              fontSize: 16,
            },
            style,
          )}
          placeholderTextColor={dark('gray-500', 'text-2')}
          {...props}
          ref={inputRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChangeText={handleChangeText}
          editable={editable}
          value={text}
        />
        {suffix
          ? suffix
          : text &&
            editable && (
              <ButtonIcon
                icon={{
                  name: 'CloseCircle',
                  type: 'SAX',
                }}
                shadow={false}
                onPress={clearTextInput}
                style={tw`p-0`}
                tone='secondary'
                size={24}
              />
            )}
      </View>
    )
  },
)
