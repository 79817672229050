export const rnServerOrigin =
  process.env.NODE_ENV === 'production'
    ? 'https://cody.nongdan.dev'
    : 'http://localhost:4000'
export const rnFrontEndOrigin =
  process.env.NODE_ENV === 'production'
    ? 'https://cody.nongdan.dev'
    : 'http://localhost:3000'

export const rnReachabilityUrl = `${rnServerOrigin}/api/204`
export const rnFetchTimeout = 5000

export const rnSqliteDbName = (userId: string) => `mychatapp_${userId}`
export const rnPaginationSize = 20

export const DEBOUNCE_TIME = 350

export const firebaseConfig = {
  apiKey: 'AIzaSyB37JJvhdv3l3GQS1FYY43bvWk3MSvU7rA',
  authDomain: 'aia-app-d9e49.firebaseapp.com',
  projectId: 'aia-app-d9e49',
  storageBucket: 'aia-app-d9e49.firebasestorage.app',
  messagingSenderId: '283605109549',
  appId: '1:283605109549:web:be3a9754a92216fd8261a4',
  measurementId: 'G-440ND743G4',
}

export const PUBLISHABLE_KEY_STRIPE =
  'pk_test_51PGFbq04W1KfufOnSmfIaLymFKOb2iyuzR6zSLPyOqeSPrSxRPN0Wiy5d7crouYe00KkmR4byvolasr7QRzmS3Mi00tb78IBt5'
