import { CloseOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Flex, Image, Radio, Typography } from 'antd'

import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import type { AnswerInQuestion } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import type { InputQuestion } from '#types/listenGame'

import { AnswerModal } from './AnswerModal'

type Props = {
  index: number
  item: AnswerInQuestion
  question: InputQuestion
  handleUpdate: (answer: AnswerInQuestion, index: number) => void
  handleDelete: (index: number) => void
  onChangeRadio: (answer: AnswerInQuestion, index: number) => void
  loading: boolean
  hideActionDelete?: boolean
}

export const AnswerItem = ({
  index,
  item,
  question,
  handleUpdate,
  handleDelete,
  onChangeRadio,
  loading,
  hideActionDelete,
}: Props) => {
  const { openModal } = useOverlay()

  const handleRemove = () => {
    if (item.answerId) {
      openModal(ModalLayout, {
        onConfirm: async () => {
          const res = await gql.deleteAnswer({ id: item.answerId ?? '' })
          if (res.data) {
            toastSuccess({
              message: 'Answer deleted successfully',
            })
            handleDelete(index)
          } else {
            toastError({
              message: 'Delete answer failed',
            })
          }
        },
        title: 'Confirm Delete',
        content: 'Are you sure you want to delete this answer?',
        successMessage: 'Answer deleted successfully',
        errorMessage: 'Failed to delete answer',
        containerProps: {
          closeOnOverlayClick: false,
        },
      })
    } else {
      handleDelete(index)
    }
  }

  const handleEdit = () => {
    openModal(AnswerModal, {
      question,
      item,
      onChangeSuccess: i => {
        handleUpdate({ ...item, ...i }, index)
      },
    })
  }

  const onChangeRaioItem = async () => {
    if (!item.isTrue) {
      onChangeRadio(item, index)
    }
  }

  return (
    <Flex
      gap={8}
      style={tw.style(
        'flex-1 items-center bg-white p-3 rounded-xl',
        item.isTrue && 'bg-[#CCECFA]',
      )}
    >
      <Radio
        onChange={onChangeRaioItem}
        checked={item.isTrue}
        disabled={loading}
      />
      <Flex vertical style={tw`flex-1`}>
        {question.type !== 'selectImage' ? (
          <Typography>{item.answer?.text || `Answer ${index + 1}`}</Typography>
        ) : (
          <Image
            width={60}
            height={60}
            src={item?.thumbnail?.url || ''}
            style={tw`border-2 border-gray-400 rounded-xl`}
          />
        )}
      </Flex>
      {question.type !== 'yesNo' && (
        <Flex vertical={false} gap={8} style={tw`items-center w-20`}>
          <Button onClick={handleEdit} icon={<EditOutlined />} type='default' />
          {!hideActionDelete && (
            <Button
              onClick={handleRemove}
              icon={<CloseOutlined />}
              type='default'
              danger
            />
          )}
        </Flex>
      )}
    </Flex>
  )
}
