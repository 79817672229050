import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import { useEffect, useState } from 'react'
import { View } from 'react-native'

import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

type Props = {
  onFillCardFinish: (e: boolean) => void
}

export const CardPaymentInput = (props: Props) => {
  const { onFillCardFinish } = props
  const [cardComplete, setCardComplete] = useState<boolean>(false)
  const [expiryComplete, setExpiryComplete] = useState<boolean>(false)
  const [cvcComplete, setCvcComplete] = useState<boolean>(false)

  useEffect(() => {
    if (cardComplete && expiryComplete && cvcComplete) {
      onFillCardFinish(true)
    }
  }, [cardComplete, expiryComplete, cvcComplete])

  return (
    <>
      <View style={tw.style('pb-3')}>
        <Text specialType='Title' color={tw.color('text-1')}>
          Card information
        </Text>
      </View>
      <View
        style={tw.style(
          'relative border border-gray-300 rounded-full mb-4 py-4 px-6',
        )}
      >
        <CardNumberElement
          onChange={e => {
            setCardComplete(e.complete)
          }}
          options={{
            placeholder: 'Card Number',
            style: {
              base: {
                fontSize: '17px',
                color: 'black',
                letterSpacing: '0.05em',
                fontFamily: 'Nunito, sans-serif',
                '::placeholder': {
                  color: '#87919A',
                },
              },
              invalid: { color: tw.color('error-200') },
            },
          }}
        />
        <View style={tw.style('absolute right-6 top-1/4')}>
          <SystemIcon type='SVG' name='card' />
        </View>
      </View>
      <View style={tw.style('flex-row gap-2')}>
        <View
          style={tw.style(
            'border border-gray-300 rounded-full mb-4 py-4 px-6 flex-1',
          )}
        >
          <CardExpiryElement
            onChange={e => {
              setExpiryComplete(e.complete)
            }}
            options={{
              style: {
                base: {
                  fontSize: '17px',
                  color: 'black',
                  letterSpacing: '0.05em',
                  fontFamily: 'Nunito, sans-serif',
                  '::placeholder': {
                    color: '#87919A',
                  },
                },
                invalid: { color: tw.color('error-200') },
              },
            }}
          />
        </View>
        <View
          style={tw.style(
            'border border-gray-300 rounded-full mb-4 py-4 px-6 flex-1',
          )}
        >
          <CardCvcElement
            onChange={e => {
              setCvcComplete(e.complete)
            }}
            options={{
              style: {
                base: {
                  fontSize: '17px',
                  color: 'black',
                  letterSpacing: '0.05em',
                  fontFamily: 'Nunito, sans-serif',
                  '::placeholder': {
                    color: '#87919A',
                  },
                },
                invalid: { color: tw.color('error-200') },
              },
            }}
          />
        </View>
      </View>
    </>
  )
}
