import { Button, Flex, Spin, Typography } from 'antd'
import type { ReactNode } from 'react'

import { AnswerHeader } from '#admin/components/widgets/Answer/AnswerHeader'
import { tw } from '#components/utils/tw'

type Props = {
  disabled?: boolean
  handleAdd: () => void
  children?: ReactNode
  hideButton?: boolean
  loading?: boolean
  isHiddenAudio?: boolean
  isHiddenHeader?: boolean
}

export const AnswersList = ({
  disabled,
  handleAdd,
  hideButton,
  loading,
  isHiddenAudio,
  isHiddenHeader,
  children,
}: Props) => (
  <Flex
    vertical
    gap={8}
    style={tw`w-full bg-gray-50 mt-4 p-4 border-2 rounded-xl`}
  >
    <Flex style={tw`flex items-center justify-end mb-2`}>
      {!hideButton && (
        <Button onClick={handleAdd} disabled={disabled}>
          Add Answer
        </Button>
      )}
    </Flex>
    {!isHiddenHeader ? (
      <AnswerHeader isHiddenAudio={isHiddenAudio} />
    ) : (
      <Flex style={tw`flex-1`}>
        <Typography.Title level={5} style={tw`m-0`}>
          Answer
        </Typography.Title>
      </Flex>
    )}
    <Flex vertical gap={8} style={tw`relative`}>
      {children}
      {loading && (
        <Flex
          vertical
          style={tw`absolute top-0 left-0 right-0 bottom-0 bg-gray-200 opacity-20 flex-1 items-center justify-center`}
        >
          <Spin />
        </Flex>
      )}
    </Flex>
  </Flex>
)
