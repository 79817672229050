import { forwardRef, useState } from 'react'
import type { TextInput } from 'react-native'
import { View } from 'react-native'

import { SystemIcon } from '#components/base/SystemIcon'
import { tw } from '#components/utils/tw'

import type { InputProps } from './Input'
import { Input } from './Input'

export type InputPasswordProps = Omit<InputProps, 'secureTextEntry' | 'suffix'>

export const InputPassword = forwardRef<TextInput, InputPasswordProps>(
  (props: InputPasswordProps, ref) => {
    const [show, setShow] = useState(false)
    const [hasText, setHasText] = useState(false)

    const handleTextChange = (text: string) => {
      setHasText(text.length > 0)
      if (props.onChangeText) {
        props.onChangeText(text)
      }
    }

    return (
      <Input
        ref={ref}
        {...props}
        secureTextEntry={!show}
        onChangeText={handleTextChange}
        suffix={
          hasText ? (
            <SystemIcon
              type='SAX'
              name={show ? 'Eye' : 'EyeSlash'}
              onPress={() => setShow(prev => !prev)}
            />
          ) : (
            <View style={tw.style('h-6')} />
          )
        }
      />
    )
  },
)
