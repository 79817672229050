import { useMemo } from 'react'
import { Platform, useWindowDimensions } from 'react-native'

type DeviceType = 'mobile' | 'tablet' | 'web' | 'desktop'

const iPhoneModels = {
  iPhoneX: { width: 375, height: 812 },
  iPhoneXSMax: { width: 414, height: 896 },
  iPhone12: { width: 390, height: 844 },
  iPhone12ProMax: { width: 428, height: 926 },
  iPhone14Pro: { width: 393, height: 852 },
  iPhone14ProMax: { width: 430, height: 932 },
}

const checkIsiPhoneXSeries = (width: number, height: number): Boolean =>
  Object.values(iPhoneModels).some(
    model =>
      Platform.OS === 'ios' &&
      !Platform.isPad &&
      !Platform.isTV &&
      ((model.width === width && model.height === height) ||
        (model.width === height && model.height === width)),
  )

const getDeviceType = (width: number): DeviceType => {
  if (width >= 1024) {
    return 'web'
  } else if (width >= 768) {
    return 'tablet'
  } else {
    return 'mobile'
  }
}

export const useDeviceDimensions = () => {
  const { width, height } = useWindowDimensions()
  const isWeb = useMemo(() => Platform.OS === 'web', [])
  const isiOS = useMemo(() => Platform.OS === 'ios', [])
  const isIphoneX = useMemo(
    () => checkIsiPhoneXSeries(width, height),
    [height, width],
  )
  const isAndroid = useMemo(() => Platform.OS === 'android', [])
  const isLandscape = useMemo(() => width > height, [width, height])
  const deviceType = useMemo(() => getDeviceType(width), [width])

  return {
    width,
    height,
    deviceType,
    isWeb,
    isiOS,
    isIphoneX,
    isAndroid,
    isLandscape,
  }
}
