import { useState } from 'react'
import type { GestureResponderEvent, TouchableOpacityProps } from 'react-native'
import { TouchableOpacity, View } from 'react-native'
import type { Style } from 'twrnc'

import type { UPromise } from '##/shared/ts'
import { Spinner } from '#components/base/Spinner'
import type { IconPropsWithType } from '#components/base/SystemIcon'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

type Size = 'small' | 'medium' | 'large' | 'plain' | 'link'
type Tone = 'primary' | 'secondary' | 'plain' | 'link' | 'danger' | 'success'
type Shape = 'square' | 'rounded' | 'pill'
type FontWeight =
  | 'normal'
  | 'medium'
  | 'semibold'
  | 'bold'
  | 'extrabold'
  | 'black'
export type ButtonProps = Omit<TouchableOpacityProps, 'style'> & {
  size?: Size
  tone?: Tone
  shape?: Shape
  loading?: boolean
  fontWeight?: FontWeight
  style?: Style
  rounded?: boolean
  icon?: IconPropsWithType
  iconPosition?: 'left' | 'right'
  titleColor?: string
}

const buttonClasses = () => ({
  base: 'flex-row justify-center items-center',
  size: {
    large: 'w-full h-14',
    medium: 'w-75 h-12',
    small: 'w-25.75 h-8',
    plain: 'w-auto min-h-8 self-start',
    link: 'w-auto min-h-8 self-start',
  },
  tone: {
    primary: {
      background:
        'linear-gradient(180deg, rgba(26, 184, 229, 1) 0%, rgba(59, 137, 235, 1) 100%)',
    },
    secondary: { background: tw.color('neutral-75') ?? 'transparent' },
    plain: { background: 'transparent' },
    link: { background: 'transparent' },
    danger: { background: tw.color('red-500') ?? 'red' },
    success: { background: tw.color('green-500') ?? 'green' },
  },
  shape: {
    square: 'rounded-none',
    rounded: 'rounded-md',
    pill: 'rounded-full',
  },
})

const textClasses = () => ({
  base: 'font-normal',
  size: {
    large: 'text-base',
    medium: 'text-base',
    small: 'text-base',
    plain: 'text-xs',
    link: 'text-xs',
  },
  tone: {
    primary: { color: tw.color('text-4') ?? 'black' },
    secondary: { color: tw.color('primary-400') ?? 'black' },
    plain: { color: tw.color('text-1') ?? 'black' },
    link: {
      color: tw.color('primary-400') ?? 'black',
      textDecorationLine: 'underline',
    },
    danger: { color: tw.color('text-4') ?? 'black' },
    success: { color: tw.color('text-4') ?? 'black' },
  },
  fontWeight: {
    normal: { fontWeight: 400 },
    medium: { fontWeight: 500 },
    semibold: { fontWeight: 600 },
    bold: { fontWeight: 700 },
    extrabold: { fontWeight: 800 },
    black: { fontWeight: 900 },
  },
})

const iconClasses = {
  size: {
    small: 'text-md',
    medium: 'text-lg',
  },
  tone: {
    primary: 'text-text-4',
    secondary: 'text-text-1',
    plain: 'text-text-1',
    link: 'text-text-1 underline font-bold',
    danger: 'text-text-4',
    success: 'text-text-4',
  },
}

export const Button = ({
  tone = 'primary',
  size = 'large',
  shape = 'pill',
  disabled,
  onPress,
  loading = false,
  fontWeight = 'bold',
  style,
  children,
  icon,
  iconPosition,
  titleColor,
  ...props
}: ButtonProps) => {
  const buttonStyle = buttonClasses()
  const textStyle = textClasses()
  const [loadingControlled, setLoadingControlled] = useState(false)
  loading = loadingControlled || loading

  const onPressControlled = async (e: GestureResponderEvent) => {
    const p = onPress?.(e) as UPromise
    const isPromise = p instanceof Promise
    const isPromiseLike = typeof p?.catch === 'function'
    if (!isPromise && !isPromiseLike) {
      return p
    }
    setLoadingControlled(true)
    const r = await p.catch(err => {
      setLoadingControlled(false)
      throw err
    })
    setLoadingControlled(false)
    return r
  }

  return (
    <TouchableOpacity
      {...props}
      activeOpacity={0.6}
      onPress={onPressControlled}
      disabled={disabled || loading}
    >
      <div
        style={tw.style(
          'flex flex-row justify-center items-center',
          buttonStyle.base,
          buttonStyle.shape[shape],
          buttonStyle.size[size],
          buttonStyle.tone[tone],
          disabled ? { background: tw.color('neutral-200') ?? '' } : {},
          loading && tone === 'primary'
            ? { background: tw.color('primary-200') ?? '' }
            : {},
          style,
        )}
      >
        {loading && (
          <View style={tw`mr-2`}>
            <Spinner
              style={tw.style(iconClasses.size[size], textStyle.tone[tone])}
            />
          </View>
        )}
        <View style={tw.style('flex-row justify-center items-center')}>
          {typeof children === 'string' ? (
            <View style={tw`flex-row items-center gap-x-1`}>
              {iconPosition === 'left' && icon && (
                <SystemIcon
                  {...icon}
                  color={
                    (icon?.color || titleColor || tw.color('text-4')) ?? 'black'
                  }
                />
              )}
              <Text
                specialType='Button'
                style={tw.style(
                  textStyle.base,
                  textStyle.size[size],
                  textStyle.tone[tone],
                  textStyle.fontWeight[fontWeight],
                  titleColor ? { color: titleColor } : {},
                  disabled ? { color: tw.color('text-3') ?? 'gray' } : {},
                )}
              >
                {children}
              </Text>
              {iconPosition === 'right' && icon && (
                <SystemIcon
                  {...icon}
                  color={
                    (icon?.color || titleColor || tw.color('text-4')) ?? 'black'
                  }
                />
              )}
            </View>
          ) : (
            children
          )}
        </View>
      </div>
    </TouchableOpacity>
  )
}
