import { View } from 'react-native'

import { Skeleton as BaseSkeleton } from '#components/base/Skeleton'
import { tw } from '#components/utils/tw'

const Thumbnail = () => (
  <BaseSkeleton
    style={tw.style('mb-4 flex-1 rounded-2xl', { aspectRatio: 16 / 10 })}
  />
)

const Paragraph = () => (
  <View style={tw`flex flex-1 flex-col gap-y-1`}>
    <BaseSkeleton style={tw`w-full h-6`} />
    <BaseSkeleton style={tw`w-full h-6`} />
    <BaseSkeleton style={tw`w-full h-6`} />
    <BaseSkeleton style={tw`w-3/4 h-6`} />
  </View>
)

const RolePlay = () =>
  Array.from({ length: 2 }).map((_, idx) => (
    <BaseSkeleton key={idx} style={tw`flex flex-1 rounded-2xl h-[211.5px]`} />
  ))

const TaskList = () =>
  Array.from({ length: 3 }).map((_, idx) => (
    <BaseSkeleton key={idx} style={tw`h-[58px] rounded-2xl`} />
  ))

const Button = () =>
  Array.from({ length: 2 }).map((_, idx) => (
    <BaseSkeleton key={idx} style={tw`h-14 rounded-full`} />
  ))

export const Skeleton = {
  Thumbnail,
  Paragraph,
  RolePlay,
  TaskList,
  Button,
}
