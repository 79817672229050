import { ImageBackground } from 'react-native'

import LaunchScreenBg from '#assets/images/launch_screen_bg.png'

import { LinearGradientColor } from '#components/base/LinearGradientColor/LinearGradientColor'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

export const LaunchScreen = () => (
  <LinearGradientColor>
    <ImageBackground
      source={{ uri: LaunchScreenBg }}
      resizeMode='cover'
      style={tw`flex-1 w-full h-full justify-center items-center`}
    >
      <Text specialType='Headline1' style={tw`text-white font-black text-5xl`}>
        CODY
      </Text>
    </ImageBackground>
  </LinearGradientColor>
)
