import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { View } from 'react-native'

import { images } from '#assets'
import { FlatList } from '#components/base/FlatList'
import { Gif } from '#components/base/Gif/Gif'
import { audioManager } from '#components/utils/audio/audioManager'
import { useAudioManager } from '#components/utils/audio/useAudioManager'
import { isValidAudioUrl } from '#components/utils/checkMedia'
import { tw } from '#components/utils/tw'
import type { WordOrPhraseItem } from '#types/word'

import { Empty } from './Empty'
import { fetchDataWordOrPhrase } from './fetchDataWordOrPhrase'
import { Item } from './Item'

type Props = { topicId: string; isPhrase: boolean; tabIndex: number }

export const WordOrPhrase: FC<Props> = ({ topicId, isPhrase, tabIndex }) => {
  const [data, setData] = useState<WordOrPhraseItem[]>([])
  const [refreshing, setRefreshing] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [audioUrl, setAudioUrl] = useState<string>('')
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [playingMessageId, setPlayingMessageId] = useState<string | null>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalItems, setTotalItems] = useState<number>(0)

  const PAGE_SIZE = 15

  const { play, stop } = useAudioManager({
    url: audioUrl,
  })

  useEffect(() => {
    stop()
    setIsPlaying(false)
  }, [tabIndex])

  useEffect(() => {
    if (audioUrl) {
      play(1)
      setIsPlaying(true)
    }
  }, [audioUrl])

  useEffect(() => {
    let intervalId: number

    const checkPlaybackEnd = async () => {
      const currentPosition = await audioManager.getCurrentPosition()
      const duration = await audioManager.getDuration()

      if (currentPosition >= duration && isPlaying) {
        setIsPlaying(false)
        setPlayingMessageId(null)
        clearInterval(intervalId)
      }
    }

    if (isPlaying) {
      intervalId = setInterval(checkPlaybackEnd, 1000) as unknown as number
    }

    return () => clearInterval(intervalId)
  }, [isPlaying])

  useEffect(() => {
    fetchData(1)
  }, [topicId, isPhrase])

  const fetchData = async (page: number) => {
    if (page === 1) {
      setLoading(true)
    }
    setRefreshing(true)

    const { items, total } = await fetchDataWordOrPhrase(
      topicId,
      isPhrase,
      page,
      PAGE_SIZE,
    )
    setData(page === 1 ? items : [...data, ...items])
    setTotalItems(total)
    setCurrentPage(page)
    setRefreshing(false)
    setLoading(false)
  }

  const onRefresh = () => {
    if (!refreshing) {
      fetchData(1)
    }
  }

  const onEndReached = () => {
    if (data.length < totalItems && !refreshing) {
      fetchData(currentPage + 1)
    }
  }

  const onPressAudio = async (item: WordOrPhraseItem) => {
    try {
      if (audioUrl) {
        stop()
        setIsPlaying(false)
        setPlayingMessageId(null)
      }
      const audios = item.media.filter(i => isValidAudioUrl(i.media?.url || ''))
      const url = audios.length > 0 ? audios[0].media?.url || '' : ''

      if (url !== audioUrl) {
        setAudioUrl(url)
        setPlayingMessageId(item.id)
      } else if (isPlaying) {
        stop()
        setIsPlaying(false)
        setPlayingMessageId(null)
      } else {
        setIsPlaying(true)
        setPlayingMessageId(item.id)
        play(1)
      }
    } catch (error) {
      console.error('Error playing audio:', error)
      setIsPlaying(false)
    }
  }

  const renderItem = ({ item }: { item: WordOrPhraseItem; index: number }) => (
    <Item
      item={item}
      onPress={onPressAudio}
      isPlaying={isPlaying}
      isItemPlaying={playingMessageId === item.id}
    />
  )

  const renderItemSeparator = () => (
    <View style={tw`bg-neutral-200 h-0.25 w-full my-2`} />
  )

  if (loading) {
    return (
      <View style={tw`flex-1 items-center justify-center`}>
        <Gif source={images.loading} width={50} height={50} />
      </View>
    )
  }

  const renderListEmpty = () => <Empty loading={loading} />

  return (
    <View style={tw`flex-1 flex-col bg-background-light-1`}>
      <FlatList
        data={data}
        renderItem={renderItem}
        ItemSeparatorComponent={renderItemSeparator}
        refreshing={refreshing}
        onRefresh={onRefresh}
        onEndReached={onEndReached}
        ListEmptyComponent={renderListEmpty}
        onEndReachedThreshold={0.5}
        contentContainerStyle={tw`m-4 p-4 bg-background-light-white rounded-2xl`}
      />
    </View>
  )
}
