import type React from 'react'
import { View } from 'react-native'

import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

type Props = {
  title: string
  count: number
  total: number
}

export const TitleGame: React.FC<Props> = ({ title, count, total }) => (
  <View
    style={tw`flex-col items-center justify-center p-4 border-b border-line-1 mb-4`}
  >
    <Text specialType='Note' textAlign='center'>
      {`Question: ${count || 0}/${total || 0}`}
    </Text>
    <Text specialType='Headline4' textAlign='center'>
      {title}
    </Text>
  </View>
)
