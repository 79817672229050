import type React from 'react'
import { TouchableOpacity, View } from 'react-native'

import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

import { getCurrencySymbol } from './utils/getCurrencySymbols'

type RadioButtonProps = {
  title: string
  description: string
  price: number
  discount: number
  selected: boolean
  currency: string
  onSelect: () => void
}

export const PaymentRadioButton: React.FC<RadioButtonProps> = ({
  title,
  description,
  price,
  selected,
  discount,
  currency,
  onSelect,
}) => (
  <TouchableOpacity
    onPress={onSelect}
    style={tw.style(
      `flex-col p-6 ${selected ? 'border-primary-400 border-[3px]' : ''} rounded-3xl gap-1 bg-background-light-white`,
    )}
  >
    <View style={tw`mr-5`}>
      {discount > 0 && (
        <View
          style={tw.style(
            'bg-primary-400 px-8 py-2 mb-1 self-start rounded-2xl bottom-1',
          )}
        >
          <Text color={tw.color('text-white')} specialType='Note'>
            {discount}% OFF
          </Text>
        </View>
      )}
    </View>
    <View style={tw.style('flex-row')}>
      <View style={tw.style('flex-1')}>
        <View style={tw`flex-row items-center`}>
          {/* <Text specialType='Title'>{title}</Text> */}
          <Text
            specialType='Headline4'
            color={tw.color(selected ? 'primary-400' : 'text-1')}
          >
            {title} - {getCurrencySymbol(currency)}
            {price}
          </Text>
        </View>

        <Text specialType='Note' color={tw.color('text-2')} style={tw`pt-1`}>
          {description}
        </Text>
      </View>

      <View
        style={tw.style(
          'w-8 h-8 rounded-full bottom-1',
          selected ? 'bg-neutral-75' : 'bg-white border border-neutral-300',
        )}
      >
        {selected && (
          <SystemIcon
            buttonProps={{
              style: {
                bottom: 3,
              },
            }}
            name='TickCircle'
            type='SAX'
            color={tw.color('primary-400')}
            size={40}
            variant='Bold'
          />
        )}
      </View>
    </View>
  </TouchableOpacity>
)
