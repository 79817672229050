import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'
import axios from 'axios'

import { rnServerOrigin } from '#config'

export type IConfig = {
  baseURL?: AxiosRequestConfig['baseURL']
  endPoint: string // AxiosRequestConfig['url'];
  data?: AxiosRequestConfig['data']
  params?: AxiosRequestConfig['params']
  headers?: AxiosRequestConfig['headers']
}

const basicHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'access-control-allow-credentials': true,
}

export const instance = axios.create({
  baseURL: rnServerOrigin,
  timeout: 10000,
  headers: basicHeaders,
})

instance.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    config.headers['Access-Control-Allow-Origin'] = '*'
    config.headers['access-control-allow-credentials'] = true
    return config
  },
  (error: AxiosError): Promise<AxiosError> => Promise.reject(error),
)

instance.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => response,
  (error: AxiosError): Promise<AxiosError> => Promise.reject(error),
)

export const setBaseURL = (baseURL: string) => {
  if (baseURL) {
    instance.defaults.baseURL = baseURL
  }
}

export const setToken = (token: string) => {
  if (!token) {
    instance.defaults.headers.common.Authorization = ''
  } else {
    instance.defaults.headers.common.Authorization = `Bearer ${token}`
  }
}

export const get = async ({
  baseURL,
  endPoint,
  params,
  headers,
}: Pick<IConfig, 'baseURL' | 'endPoint' | 'params' | 'headers'>) => {
  try {
    const response = await instance.get(endPoint, { baseURL, params, headers })
    return response.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const post = async ({
  baseURL,
  endPoint,
  data,
  headers,
}: Pick<IConfig, 'baseURL' | 'endPoint' | 'data' | 'headers'>) => {
  try {
    const response = await instance.post(endPoint, data, { baseURL, headers })
    return response.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const put = async ({
  baseURL,
  endPoint,
  data,
  headers,
}: Pick<IConfig, 'baseURL' | 'endPoint' | 'data' | 'headers'>) => {
  try {
    const response = await instance.put(endPoint, data, { baseURL, headers })
    return response.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteAxios = async ({
  baseURL,
  endPoint,
  headers,
}: Pick<IConfig, 'baseURL' | 'endPoint' | 'headers'>) => {
  try {
    const response = await instance.delete(endPoint, { baseURL, headers })
    return response.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const postForm = async ({
  baseURL,
  endPoint,
  data,
  headers,
}: Pick<IConfig, 'baseURL' | 'endPoint' | 'data' | 'headers'>) => {
  try {
    const response = await instance.postForm(endPoint, data, {
      baseURL,
      headers,
    })
    return response.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const putForm = async ({
  baseURL,
  endPoint,
  data,
  headers,
}: Pick<IConfig, 'baseURL' | 'endPoint' | 'data' | 'headers'>) => {
  try {
    const response = await instance.putForm(endPoint, data, {
      baseURL,
      headers,
    })
    return response.data
  } catch (error) {
    return Promise.reject(error)
  }
}
