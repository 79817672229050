import { observer } from 'mobx-react-lite'
import { Fragment } from 'react'

import { ScrollView } from '#components/base/ScrollView'
import { tw } from '#components/utils/tw'
import { Header } from '#components/widgets/Header'

import { SectionLearning } from './SectionLearning'
import { SectionOther } from './SectionOther'
import { SectionOverview } from './SectionOverview'
import { SectionProfile } from './SectionProfile'

export const Profile = observer(() => (
  <Fragment>
    <Header isBack title='Profile' />
    <ScrollView contentContainerStyle={tw`flex-grow`} style={tw`px-4 py-6`}>
      <SectionProfile />
      <SectionOverview />
      <SectionLearning />
      <SectionOther />
    </ScrollView>
  </Fragment>
))
