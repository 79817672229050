import { Flex, Input, Select, Typography } from 'antd'

import { Upload } from '#admin/components/widgets/Upload'
import { tw } from '#components/utils/tw'
import type {
  InputQuestion,
  ListenGameType,
  ListenMedia,
} from '#types/listenGame'
import { listenGameQuestion } from '#types/listenGame'
import type { ResponseFile } from '#types/media'

type Props = {
  question: InputQuestion
  onChangeData: (data: Partial<InputQuestion>) => void
  mediaQuestions: ListenMedia
  onChangeMedia: (data: ListenMedia) => void
}
export const QuestionGame = ({
  question,
  onChangeData,
  mediaQuestions,
  onChangeMedia,
}: Props) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeData({ text: e.target.value })
  }

  const onURLChange = (data: ResponseFile) => {
    // onChangeData({ mediaId: data.id })
    onChangeMedia({ url: data.url, id: data.id, name: data.name })
  }

  const handleSelectChange = (value: ListenGameType) => {
    onChangeData({ type: value })
  }

  return (
    <Flex vertical style={tw`bg-gray-50 p-4 rounded-xl`}>
      <Flex gap={8} vertical style={tw`w-full`}>
        <Typography style={tw`text-4`}>Upload:</Typography>
        <Upload
          resourceType='audio'
          type='audio'
          display='audio'
          onChange={onURLChange}
          url={mediaQuestions.url}
          name={mediaQuestions.name}
          onDeleted={() => onChangeData({ mediaId: '' })}
        />
      </Flex>
      <Flex gap={8} vertical style={tw`w-full mt-4`}>
        <Typography style={tw`text-4`}>Question:</Typography>
        <Input
          defaultValue={question.text ?? ''}
          value={question.text ?? ''}
          onChange={handleInputChange}
          placeholder='Enter your question'
          style={tw`w-full`}
        />
      </Flex>
      <Flex gap={8} vertical style={tw`w-full mt-4`}>
        <Typography style={tw`text-4`}>Select type game:</Typography>
        <Select
          value={question.type}
          onChange={handleSelectChange}
          placeholder='Select an option'
          defaultValue={question.type}
          style={tw`w-full`}
          disabled={!!question.id}
        >
          {listenGameQuestion.map(option => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Flex>
    </Flex>
  )
}
