import type { Analytics } from 'firebase/analytics'
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from 'firebase/analytics'
import { initializeApp } from 'firebase/app'

import { firebaseConfig } from '#config'
import type { CurrentUser } from '#types/user'

const app = initializeApp(firebaseConfig)

// let analytics: Analytics | undefined = undefined
// if (typeof window !== 'undefined') {
//   analytics = getAnalytics(app)
// }
const analytics: Analytics = getAnalytics(app)

export { analytics, app }

let userId: string = ''

export const logCustomEvent = (
  eventName: string,
  eventParams: { [key: string]: any },
) => {
  if (!analytics) {
    return
  }
  logEvent(analytics, eventName, { user: userId, ...eventParams })
}

export const setUserForAnalytics = (data: CurrentUser) => {
  if (!data?.id) {
    userId = ''
    return
  }
  userId = data.id
  setUserId(analytics, data.id)
  setUserProperties(analytics, {
    name: data?.name || '',
    username: data?.email || '',
  })
}
