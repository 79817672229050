import { ulid } from 'ulidx'

import { isValidAudioUrl, isValidImageUrl } from '#components/utils/checkMedia'
import type {
  AnswerGame,
  GamesProps,
  GenerateHardGameProps,
  QuestionGame,
} from '#types/games'
import { HardGame } from '#types/games'

import { getItemInList } from './getItemInList'

export const generateFillTheBlank = async ({
  item,
  data,
}: GenerateHardGameProps): Promise<GamesProps> => {
  const audios = item.media.filter(i => isValidAudioUrl(i.media?.url || ''))
  const questionData: QuestionGame = {
    text: data?.question || item.text,
    media: null,
    type: HardGame.fillTheBlank,
    translation: '',
    index: 0,
  }
  const images = item.media.filter(i => isValidImageUrl(i.media?.url || ''))
  const answers: AnswerGame[] = !data?.answers.length
    ? []
    : data?.answers.map(i => ({
        text: i.text || '',
        isCorrect: i.isCorrect,
        audio: getItemInList(audios)?.media || null,
        image: getItemInList(images)?.media || null,
        translation: '',
        type: HardGame.fillTheBlank,
        id: ulid(),
        answer: item.text || '',
      }))
  return { question: questionData, answers }
}
