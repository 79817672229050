import { TouchableOpacity, View } from 'react-native'

import { IconSax } from '#components/base/IconSax'
import { Spain } from '#components/base/IconSvg/Spain'
import {
  A1,
  A2,
  B1,
  B2,
  English,
  OwnLevel,
  PlacementTest,
} from '#components/base/IconSvg/SVG'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import type { LanguageType, Level } from '#types/language'

const IconComponent = {
  English,
  Spain,
  A1,
  A2,
  B1,
  B2,
  OwnLevel,
  PlacementTest,
}

export type ItemLanguageType = {
  id: string
  value: LanguageType | Level | 'OwnLevel' | 'PlacementTest'
  label: string
  description?: string
  icon: keyof typeof IconComponent
}

type ItemLanguageProps = {
  item: ItemLanguageType
  isSelected?: boolean
  onPress?: (item: ItemLanguageType) => void
}

export const ItemLanguage: React.FC<ItemLanguageProps> = ({
  item,
  isSelected,
  onPress,
}) => {
  const Icon = IconComponent[item.icon]
  return (
    <TouchableOpacity
      style={tw.style(
        'flex-row items-center px-4 py-2 rounded-3xl border',
        isSelected
          ? 'bg-background-light-2 border-primary-400'
          : 'bg-background-light-white border-neutral-200',
      )}
      onPress={() => onPress?.(item)}
    >
      <View style={tw.style('py-2 px-1 items-center justify-center')}>
        <View
          style={tw.style(
            'items-center justify-center overflow-hidden',
            ['English', 'Spanish'].includes(item.value)
              ? 'rounded'
              : 'rounded-2xl',
          )}
        >
          <Icon />
        </View>
      </View>
      <View style={tw.style('ml-3 flex-1')}>
        <Text specialType='Title'>{item.label}</Text>
        {item.description && <Text specialType='Note'>{item.description}</Text>}
      </View>
      {isSelected && (
        <IconSax
          name='TickCircle'
          color={tw.color('primary-400')}
          variant='Bold'
        />
      )}
    </TouchableOpacity>
  )
}
