import { Flex, Menu } from 'antd'
import type { MenuItemType } from 'antd/es/menu/interface'
import { Text } from 'native-base'
import { useState } from 'react'

import { tw } from '#components/utils/tw'

import { GeneralTab } from './GeneralTab'
import { ImageContentTab } from './ImageContentTab'

export const Settings = () => {
  const [selectedKey, setSelectedKey] = useState('General')

  const items: MenuItemType[] = [
    {
      label: 'General',
      key: 'General',
      onClick: () => {
        setSelectedKey('General')
      },
    },
    {
      label: 'Image & content',
      key: 'Image & content',
      onClick: () => {
        setSelectedKey('Image & content')
      },
    },
  ]

  return (
    <Flex style={tw`flex-1 flex-col h-full overflow-y-hidden bg-white`}>
      <Text style={tw`py-6 pl-4 text-6`}>Settings</Text>
      <hr style={tw`w-full`} />
      <Flex style={tw`h-full -mt-2`}>
        <Flex style={tw`p-4 flex-col text-[16px] w-[35%]`} gap={8}>
          <Menu
            mode='inline'
            items={items}
            selectedKeys={[selectedKey]}
            style={{
              flex: 1,
              overflowY: 'auto',
              border: 'none',
              height: 'calc(100% - 90px)',
              background: 'none',
              fontSize: '16px',
            }}
          />
        </Flex>
        <div style={tw`w-[2px] h-full bg-gray-300`}></div>
        <div style={tw`w-full`}>
          {selectedKey === 'General' ? <GeneralTab /> : <ImageContentTab />}
        </div>
      </Flex>
    </Flex>
  )
}
