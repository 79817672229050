export const CURRENCIES = [
  'USD',
  'AUD',
  'CHF',
  'CNY',
  'DKK',
  'GBP',
  'HKD',
  'KRW',
  'NZD',
  'PLN',
  'TRY',
] as const

export const CURRENCY_OPTIONS = CURRENCIES.map(c => ({ label: c, value: c }))
