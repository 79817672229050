import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useWindowDimensions, View } from 'react-native'

import { FlatList } from '#components/base/FlatList'
import { tw } from '#components/utils/tw'
import { Header } from '#components/widgets/Header'
import { useSearchStreakInUser, useSearchStreaks } from '#graphql/codegen'
import { S } from '#store'
import type { SearchStreakItem } from '#types/streak'

import { StreakItem } from './StreakItem'

export const StreakRecord: FC = observer(() => {
  const { height, width } = useWindowDimensions()

  const [longestStreak, setLongestStreak] = useState<number>(0)

  const [s] = useSearchStreaks({
    variables: { order: ['numberStreak_asc'] },
  })

  const [{ data: streakData }] = useSearchStreakInUser({
    variables: { filter: { userId: S.shared.currentUser?.id } },
  })
  const streakInUser = streakData?.searchStreakInUser || []
  useEffect(() => {
    if (streakInUser) {
      setLongestStreak(streakInUser[0].highestStreak || 0)
    }
  }, [streakInUser])
  const streak = s.data?.searchStreaks || []

  const renderItem = ({ item }: { item: SearchStreakItem }) => (
    <StreakItem item={item} dayStreak={longestStreak} />
  )

  const renderItemSeparator = () => (
    <View style={tw`flex-col items-center justify-center my-2`}>
      <View style={tw`h-px bg-neutral-200 w-full`} />
    </View>
  )

  return (
    <View
      style={tw.style('flex-1 flex-col bg-background-light-1', {
        width,
        height,
      })}
    >
      <Header title='Streak record' />
      <FlatList
        data={streak}
        renderItem={renderItem}
        ItemSeparatorComponent={renderItemSeparator}
        contentContainerStyle={tw`bg-background-light-white rounded-3xl p-4 m-4`}
      />
    </View>
  )
})
