import { observer } from 'mobx-react-lite'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import { Image } from '#components/base/Image'
import { LinearGradientColor } from '#components/base/LinearGradientColor/LinearGradientColor'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { toastWarning } from '#components/utils/Toast'
import { tw } from '#components/utils/tw'
import type { SearchTopicItem } from '#types/topic'

type Props = {
  topic: SearchTopicItem
}

export const InactiveTopicItem = observer(({ topic }: Props) => {
  const handlePress = () => {
    toastWarning({ message: 'Complete the prior topic to unlock' })
  }

  return (
    <TouchableOpacity
      style={tw`rounded-3xl overflow-hidden`}
      onPress={handlePress}
      activeOpacity={0.6}
    >
      <Image source={{ uri: topic.media?.url }} style={tw.style('h-36')} />
      <View style={tw.style(StyleSheet.absoluteFillObject)}>
        <LinearGradientColor
          colorX='rgba(0, 0, 0, 0.9)'
          colorY='rgba(0, 0, 0, 0.6)'
          degree={90}
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
        >
          <View style={tw`flex-1 p-4 flex-row justify-between items-center`}>
            <Text specialType='Headline3' style={tw`text-gray-400`}>
              {topic.name}
            </Text>
            <View style={tw`p-3 rounded-full bg-gray-400/50`}>
              <SystemIcon
                name={'Key'}
                type={'SAX'}
                size={24}
                color={tw.color('neutral-75')}
              />
            </View>
          </View>
        </LinearGradientColor>
      </View>
    </TouchableOpacity>
  )
})
