import type { FC } from 'react'
import { View } from 'react-native'

import { Button } from '#components/base/Button/Button'
import { NumberSign1, NumberSign2 } from '#components/base/IconSvg/SVG'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { navigate } from '#navigator/helpers'

type LevelUpNowProps = {}

export const LevelUpNow: FC<LevelUpNowProps> = () => {
  const onPressStartNow = () => {
    navigate('Home', { screen: 'Topics' })
  }

  return (
    <View
      style={tw.style(
        'bg-primary-400 rounded-2xl overflow-hidden h-21.5 relative',
      )}
    >
      <View style={tw.style('absolute z-1', { top: -30, right: -5 })}>
        <NumberSign2 />
      </View>
      <View style={tw.style('absolute z-2', { left: -10, bottom: -20 })}>
        <NumberSign1 />
      </View>
      <View style={tw.style('absolute top-0 left-0 right-0 bottom-0 z-3')}>
        <View style={tw.style('flex-row items-center flex-1 px-4 py-3.5')}>
          <View style={tw`flex-1 flex-col mr-3`}>
            <Text specialType='Title' color={tw.color('text-4')}>
              Level Up Now!
            </Text>
            <Text specialType='Note' color={tw.color('text-4')}>
              Master new lessons and improve your skills every day
            </Text>
          </View>
          <Button
            tone='secondary'
            size='medium'
            style={tw`w-25`}
            onPress={onPressStartNow}
          >
            Start now
          </Button>
        </View>
      </View>
    </View>
  )
}
