import type { FC } from 'react'
import { View } from 'react-native'

import { tw } from '#components/utils/tw'

type ListFooterProps = {}

export const ListFooter: FC<ListFooterProps> = () => (
  <View style={tw`flex-col`} />
)
