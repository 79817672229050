import type { FC } from 'react'
import { forwardRef, useEffect, useState } from 'react'
import type { TextInput as RNTextInput, TextInputProps } from 'react-native'
import { ActivityIndicator, Platform, View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'

import { Button } from '#components/base/Button/Button'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { gql } from '#graphql/urql'

interface InputProps extends TextInputProps {
  topicInUserId: string
  onSend: (content: string) => void
}

export const SuggestMessage: FC<InputProps> = forwardRef<
  RNTextInput,
  InputProps
>(({ onSend, topicInUserId }, ref) => {
  useEffect(() => {
    getSuggestion()
  }, [])
  const [suggestionList, setSuggestionList] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const handleSuggestSend = () => {
    if (suggestionList[0].trim()) {
      onSend(suggestionList[0].trim())
    }
  }

  const getSuggestion = async () => {
    try {
      setSuggestionList([])
      setLoading(true)
      const res = await gql.suggestMessage(
        { topicInUserId },
        { requestPolicy: 'network-only' },
      )
      if (res.data) {
        let dataStr = res.data.suggestMessage || '[]'
        if (!dataStr.startsWith('[')) {
          dataStr = `["${dataStr}"]`
        }
        setSuggestionList(JSON.parse(dataStr))
      }
    } catch (error) {
      console.error('Error fetching suggestion:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <View style={tw.style('items-center justify-center px-4 pb-2')}>
      <View
        style={tw.style(
          'bg-background-light-2 px-4 py-2.5 w-full  rounded-2xl border border-white flex-col justify-between  mt-4',
          Platform.OS !== 'web' && 'h-30',
        )}
      >
        <View style={tw`flex-1 justify-center `}>
          {loading ? (
            <ActivityIndicator
              style={tw`self-center`}
              size='large'
              color={tw.color('primary-400')}
            />
          ) : (
            <Text specialType='paragraph1'>{suggestionList[0]}</Text>
          )}
        </View>

        <View style={tw`flex-row justify-between items-center w-full mt-2`}>
          <TouchableOpacity
            disabled={!(suggestionList?.length > 0)}
            onPress={getSuggestion}
          >
            <SystemIcon
              color={tw.color(loading ? 'bg-neutral-400' : 'bg-black')}
              type='SVG'
              name='arrows-clockwise'
            />
          </TouchableOpacity>

          <Button
            disabled={!(suggestionList?.length > 0)}
            tone='secondary'
            size='small'
            onPress={handleSuggestSend}
          >
            Send text
          </Button>
        </View>
      </View>
    </View>
  )
})
