import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Flex, Typography } from 'antd'
import type { ColumnType } from 'antd/es/table'
import { Text } from 'native-base'
import { useState } from 'react'

import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { PaginatedTable } from '#admin/components/widgets/PaginatedTable'
import { SearchButton } from '#admin/components/widgets/SearchButton'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import { useCountCategory, useSearchCategory } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import type { SearchCategoryItem } from '#types/category'

import { TagModal } from './TagModal'

export const Tags = () => {
  const [searchKeyword, setSearchKeyword] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const { openModal } = useOverlay()

  const dateType: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  const [countTags, refetchCount] = useCountCategory({
    variables: {
      filter: searchKeyword
        ? {
            tagName_iLike: `%${searchKeyword}%`,
          }
        : {},
    },
  })

  const [tags, refetchTags] = useSearchCategory({
    variables: {
      filter: searchKeyword
        ? {
            tagName_iLike: `%${searchKeyword}%`,
          }
        : {},
      order: ['tagName_asc'],
      page: { limit: pageSize, offset: (currentPage - 1) * pageSize },
    },
  })

  const searchTags = tags.data?.searchCategory ?? []

  const onModalOk = () => {
    refetchTags({ requestPolicy: 'network-only' })
    refetchCount({ requestPolicy: 'network-only' })
  }

  const handleEditData = (tag: SearchCategoryItem) => {
    openModal(TagModal, {
      onOk: onModalOk,
      isUpdate: false,
      containerProps: {
        closeOnOverlayClick: false,
      },
      selectedTag: tag,
    })
  }

  const handleAddData = () => {
    openModal(TagModal, {
      onOk: onModalOk,
      isUpdate: false,
      containerProps: {
        closeOnOverlayClick: false,
      },
      languageTopic: '',
    })
  }

  const reloadDelete = () => {
    refetchTags({ requestPolicy: 'network-only' })
    refetchCount({ requestPolicy: 'network-only' })

    if (countTags.data?.countCategory !== undefined) {
      const remainingItemsCount = countTags.data.countCategory - 1
      let newPageCount = Math.ceil(remainingItemsCount / pageSize)
      if (newPageCount < 1) {
        newPageCount = 1
      }
      if (currentPage > newPageCount) {
        setCurrentPage(newPageCount)
      }
    }
  }

  const actionsColumn = {
    title: 'Actions',
    key: 'actions',
    width: 100,
    render: (_, record) => (
      <Flex gap={4} style={tw`flex justify-center`}>
        <Button
          icon={<EditOutlined />}
          onClick={e => {
            e.stopPropagation()
            handleEditData(record)
          }}
          className='p-0 bg-transparent'
        />
        <Button
          icon={<DeleteOutlined />}
          onClick={async e => {
            e.stopPropagation()
            openModal(ModalLayout, {
              onConfirm: async () => {
                await gql.deleteCategory({ id: record.id })
                reloadDelete()
              },
              title: 'Confirm delete',
              content: 'Are you sure you want to delete this tag?',
              successMessage: 'Tag deleted successfully',
              errorMessage: 'Delete tag failed',
              containerProps: {
                closeOnOverlayClick: false,
              },
            })
          }}
          className='p-0 bg-transparent'
          danger={true}
        />
      </Flex>
    ),
  }

  const columns: ColumnType<SearchCategoryItem>[] = [
    {
      title: 'Tag name',
      dataIndex: 'wordAndPhrases',
      key: 'text',
      render: (_, record) => <Typography>{record.tagName}</Typography>,
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => (
        <Typography>
          {record.createdAt.toLocaleString('en-US', dateType)?.slice(0, 10)}
        </Typography>
      ),
    },
    actionsColumn,
  ]

  return (
    <Flex style={tw`flex-1 flex-col p-4 h-full overflow-y-hidden`}>
      <Text style={tw`py-6 text-6`}>Tag</Text>
      <SearchButton
        title='Add'
        handleAddData={handleAddData}
        keyword={(k: string) => {
          setSearchKeyword(k)
          setCurrentPage(1)
        }}
      />

      <PaginatedTable<SearchCategoryItem>
        dataSource={searchTags}
        columns={columns}
        loading={tags.fetching}
        total={
          countTags.data?.countCategory ? countTags.data?.countCategory : 0
        }
        currentPage={currentPage}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        scrollHeight={'60vh'}
      />
    </Flex>
  )
}
