import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { StyleSheet, View } from 'react-native'

import { Button } from '#components/base/Button/Button'
import { PointReview } from '#components/base/IconSvg/SVG'
import { Image } from '#components/base/Image'
import { LinearGradientColor } from '#components/base/LinearGradientColor/LinearGradientColor'
import type { IconPropsWithType } from '#components/base/SystemIcon'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import { logCustomEvent } from '#config/firebaseConfig'
import { S } from '#store'
import type { SearchTopicItem } from '#types/topic'

import { ActionSheetTopic } from '../ActionSheetTopic'

type Props = {
  topic: SearchTopicItem
}

export const ActiveTopicItem = observer(({ topic }: Props) => {
  const { openActionsheet } = useOverlay()

  const { percentGame, percentListening, percentSpeaking } =
    S.lessonPercentage.getLessonPercentageById(topic.id)

  useEffect(() => {
    S.shared.currentTopicId = topic.id
    S.lessonPercentage.setLessonPercentageById({
      topicId: topic.id,
      userId: S.shared.currentUser?.id || '',
      language: S.shared.currentUser?.languageLearn,
    })
  }, [])

  const handleStartLesson = async () => {
    logCustomEvent('lessons_selected', { id: topic.id, name: topic.name })
    openActionsheet(ActionSheetTopic, {
      topicId: topic.id,
      isCompleted: false,
      lastDate: S.shared.currentUser?.lastUsedDate || '',
    })
  }

  const renderLearnStats = ({
    point,
    iconName,
    colors,
  }: {
    point: number
    iconName: Extract<IconPropsWithType, { type: 'SAX' }>['name']
    colors: [string, string]
  }) => (
    <View style={tw`relative`}>
      <PointReview
        point={point}
        light={tw.color(colors[0])}
        dark={tw.color(colors[1])}
      />
      <View style={tw`absolute inset-0 justify-center items-center`}>
        <SystemIcon
          color={tw.color('text-4')}
          type='SAX'
          variant='Bold'
          size={24}
          name={iconName}
        />
      </View>
      {point === 100 && (
        <View
          style={tw`absolute bottom-0 right-0 bg-secondary1-400 rounded-full`}
        >
          <SystemIcon
            color={tw.color('text-4')}
            type='SAX'
            size={16}
            name='TickCircle'
          />
        </View>
      )}
    </View>
  )

  return (
    <View style={tw`relative rounded-3xl overflow-hidden`}>
      <Image source={{ uri: topic.media?.url }} style={tw.style('h-96')} />
      <View style={tw.style(StyleSheet.absoluteFillObject)}>
        <LinearGradientColor
          colorX='rgba(0, 0, 0, 0.32)'
          colorY='rgba(0, 0, 0, 0.8)'
        >
          <View style={tw`flex-1 flex-col h-full p-4 justify-end`}>
            <Text
              numberOfLines={2}
              specialType='Headline1'
              style={tw.style('text-gray-50 mb-1')}
            >
              {topic.name}
            </Text>
            <Text
              numberOfLines={2}
              specialType='paragraph2'
              style={tw`text-gray-200 mb-5`}
            >
              {topic.description}
            </Text>
            <Button activeOpacity={0.6} onPress={handleStartLesson}>
              Start lesson
            </Button>
          </View>
        </LinearGradientColor>
        <View
          style={tw`absolute top-0 right-0 w-15 h-28 rounded-tr-3xl rounded-bl-3xl overflow-hidden`}
        >
          <LinearGradientColor
            colorX='rgba(255, 255, 255, 0.3)'
            colorY='rgba(255, 255, 255, 0.12)'
            degree={90}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
          >
            <View
              style={tw`absolute top-0 right-0 w-full h-full items-center gap-y-2 pt-2`}
            >
              {renderLearnStats({
                point: percentGame + percentListening,
                iconName: 'Teacher',
                colors: ['primary-200', 'primary-400'],
              })}
              {renderLearnStats({
                point: percentSpeaking,
                iconName: 'Microphone2',
                colors: ['secondary1-75', 'secondary1-400'],
              })}
            </View>
          </LinearGradientColor>
        </View>
      </View>
    </View>
  )
})
