import AntDesign from 'react-native-vector-icons/AntDesign'
import EvilIcons from 'react-native-vector-icons/EvilIcons'
import Feather from 'react-native-vector-icons/Feather'
import type { IconProps as RNVIconProps } from 'react-native-vector-icons/Icon'
import Ionicons from 'react-native-vector-icons/Ionicons'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import MaterialIcons from 'react-native-vector-icons/MaterialIcons'
import type { Style } from 'twrnc'

import { useDarkColor } from '#components/dark/hooks'
import { tw } from '#components/utils/tw'

const providers = {
  Ionicons,
  Feather,
  MaterialCommunityIcons,
  MaterialIcons,
  AntDesign,
  EvilIcons,
}

export type IconProps = RNVIconProps & {
  provider: keyof typeof providers
}

export const Icon = ({ provider, style, ...props }: IconProps) => {
  const dark = useDarkColor()
  const Component = providers[provider]

  return (
    <Component
      {...props}
      style={tw.style(
        `text-base text-[${dark('body-dark', 'body-light')}]`,
        style as Style,
      )}
    />
  )
}
