import { View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'

import { Spinner } from '#components/base/Spinner'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { Divider } from '#components/widgets/Divider'
import { navigate } from '#navigator/helpers'

import { useLogout } from './onLogoutPress'

type To = 'edit-profile' | 'private-policy'

export const SectionOther = () => {
  const [loading, onLogout] = useLogout()

  const handleNavigate = (to: To) => () => {
    if (to === 'edit-profile') {
      return navigate('App', {
        screen: 'EditProfile',
      })
    }
    return navigate('App', {
      screen: 'PrivacyPolicy',
    })
  }

  return (
    <View style={tw`mt-8 flex-col gap-3`}>
      <Text specialType='Headline4'>Other</Text>
      <View style={tw`rounded-2xl bg-white py-2`}>
        <TouchableOpacity
          activeOpacity={0.6}
          style={tw`flex-row items-center px-4 h-14 gap-2.5`}
          onPress={handleNavigate('edit-profile')}
        >
          <SystemIcon type='SAX' name='Edit' />
          <Text
            specialType='paragraph1'
            style={tw`flex-1 text-lg leading-none`}
          >
            Edit profile
          </Text>
          <SystemIcon
            name='ArrowRight2'
            type='SAX'
            color={tw.color('neutral-300')}
          />
        </TouchableOpacity>
        <Divider style={tw`my-1.5 bg-neutral-50`} />
        <TouchableOpacity
          activeOpacity={0.6}
          style={tw`flex-row items-center px-4 h-14 gap-2.5`}
          onPress={handleNavigate('private-policy')}
        >
          <SystemIcon type='SAX' name='ShieldTick' />
          <Text
            specialType='paragraph1'
            style={tw`flex-1 text-lg leading-none`}
          >
            Private policy
          </Text>
          <SystemIcon
            name='ArrowRight2'
            type='SAX'
            color={tw.color('neutral-300')}
          />
        </TouchableOpacity>
        <Divider style={tw`my-1.5 bg-neutral-50`} />
        <TouchableOpacity
          activeOpacity={0.6}
          style={tw`flex-row items-center px-4 h-14 gap-2.5`}
        >
          <SystemIcon type='SAX' name='MessageQuestion' />
          <Text
            specialType='paragraph1'
            style={tw`flex-1 text-lg leading-none`}
          >
            Help
          </Text>
          <SystemIcon
            name='ArrowRight2'
            type='SAX'
            color={tw.color('neutral-300')}
          />
        </TouchableOpacity>
        <Divider style={tw`my-1.5 bg-neutral-50`} />
        <TouchableOpacity
          activeOpacity={0.6}
          style={tw`flex-row items-center px-4 h-14 gap-2.5`}
          onPress={onLogout}
          disabled={loading}
        >
          <SystemIcon type='SAX' name='Logout' color={tw.color('red-500')} />
          <Text
            specialType='paragraph1'
            style={tw`text-red-500 flex-1 text-lg leading-none`}
          >
            Sign out
          </Text>
          {loading && (
            <View style={tw`mr-1`}>
              <Spinner style={tw`text-red-500 text-lg`} />
            </View>
          )}
        </TouchableOpacity>
      </View>
    </View>
  )
}
