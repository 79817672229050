import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Flex, Image } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { PaginatedTable } from '#admin/components/widgets/PaginatedTable'
import { SearchButton } from '#admin/components/widgets/SearchButton'
import { AITutorModal } from '#admin/screens/AITutor/AITutorModal'
import { Text } from '#components/base/Text'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import { useCountAiTutor, useSearchAiTutor } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import type { SearchAiTutorItem } from '#types/aiTutor'

import { VoiceAi } from './VoiceAi'

export const AITutor = observer(() => {
  const [searchKeyword, setSearchKeyword] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState(10)
  const { openModal } = useOverlay()

  const [d, refetch] = useSearchAiTutor({
    variables: {
      filter: searchKeyword
        ? {
            name_iLike: `%${searchKeyword}%`,
          }
        : {},
      order: ['name_asc'],
      page: { limit: pageSize, offset: (currentPage - 1) * pageSize },
    },
  })

  const [c, refetchCount] = useCountAiTutor({
    variables: {
      filter: searchKeyword ? { name_iLike: `%${searchKeyword}%` } : {},
    },
  })

  const handleAddData = () => {
    openModal(AITutorModal, {
      containerProps: {
        closeOnOverlayClick: false,
      },
      onComplete: reload(),
    })
  }

  const reload = () => {
    refetch({ requestPolicy: 'network-only' })
    refetchCount({ requestPolicy: 'network-only' })

    if (c.data?.countAiTutor !== undefined) {
      const remainingItemsCount = c.data.countAiTutor - 1
      let newPageCount = Math.ceil(remainingItemsCount / pageSize)
      if (newPageCount < 1) {
        newPageCount = 1
      }
      if (currentPage > newPageCount) {
        setCurrentPage(newPageCount)
      }
    }
  }
  const actionsColumn = {
    title: 'Actions',
    key: 'actions',
    width: 100,
    render: (text, record) => (
      <Flex style={tw`justify-between`}>
        <Button
          icon={<EditOutlined />}
          onClick={e => {
            e.stopPropagation()
            openModal(AITutorModal, {
              selectedAi: record,
              containerProps: {
                closeOnOverlayClick: false,
              },
            })
          }}
          className='p-0 bg-transparent'
        />
        <Button
          icon={<DeleteOutlined />}
          onClick={e => {
            e.stopPropagation()
            openModal(ModalLayout, {
              onConfirm: async () => {
                const res = await gql.deleteAiTutor({ id: record.id })
                if (res.data) {
                  reload()
                  toastSuccess({ message: 'Delete AI Tutor Success' })
                } else {
                  toastError({ message: 'Delete AI Tutor failed' })
                }
              },
              selectedAi: record,
              title: 'Confirm delete',
              content: 'Are you sure you want to delete this AI Tutor?',
              containerProps: {
                closeOnOverlayClick: false,
              },
            })
          }}
          className='p-0 bg-transparent'
          danger={true}
        />
      </Flex>
    ),
  }
  const searchAiTutor = d.data?.searchAiTutor ?? []
  const columns: ColumnsType<SearchAiTutorItem> = [
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      key: 'avatar',
      width: 100,
      render: (_, record) => (
        <Flex
          onClick={e => {
            e?.stopPropagation()
          }}
        >
          <Image
            src={record?.thumbnail?.url}
            style={tw.style('w-12 h-12 border-2 border-gray-300', {
              cursor: 'pointer',
            })}
            onClick={e => {
              e?.stopPropagation()
            }}
          />
        </Flex>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 'auto',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 'auto',
      render: (_, record) => (
        <div dangerouslySetInnerHTML={{ __html: record.description }} />
      ),
    },
    {
      title: 'Language',
      dataIndex: 'languageAI',
      key: 'languageAI',
      width: 'auto',
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      width: 100,
    },
    {
      title: 'Voice',
      dataIndex: 'voice',
      key: 'voice',
      width: 100,
      render: (_, record) => <VoiceAi voiceFile={record.media?.url ?? ''} />,
    },
    actionsColumn,
  ]

  return (
    <Flex style={tw`flex-1 flex-col p-4 h-full overflow-y-hidden`}>
      <Text style={tw`py-6 text-6`}>AI tutor</Text>
      <SearchButton
        title='Add'
        handleAddData={handleAddData}
        keyword={(k: string) => {
          setSearchKeyword(k)
          setCurrentPage(1)
        }}
      />
      <PaginatedTable<SearchAiTutorItem>
        dataSource={searchAiTutor}
        columns={columns}
        loading={d.fetching}
        total={c.data?.countAiTutor ?? 0}
        currentPage={currentPage}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        scrollHeight={'60vh'}
      />
    </Flex>
  )
})
