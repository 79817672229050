import type { FC, ReactNode } from 'react'
import { View } from 'react-native'
import { Circle, Svg } from 'react-native-svg'

import { SystemIcon } from '#components/base/SystemIcon'
import { tw } from '#components/utils/tw'

type Props = {
  progressPercent: number
  size?: number
  strokeWidth?: number
  pgColor?: string
  bgColor?: string
  children?: ReactNode
  backgroundColor?: string
}

export const CircularProgress: FC<Props> = ({
  size = 50,
  strokeWidth = 4,
  progressPercent,
  children,
  pgColor = 'yellow',
  bgColor = 'blue',
  backgroundColor,
}) => {
  const radius = (size - strokeWidth) / 2
  const circum = radius * 2 * Math.PI
  const svgProgress = 100 - progressPercent

  return (
    <View style={tw.style('m-2.5  rounded-full', { backgroundColor })}>
      <Svg width={size} height={size}>
        {/* Background Circle */}
        <Circle
          stroke={bgColor ? bgColor : '#f2f2f2'}
          fill='none'
          cx={size / 2}
          cy={size / 2}
          r={radius}
          {...{ strokeWidth }}
        />

        {/* Progress Circle */}
        <Circle
          stroke={pgColor ? pgColor : '#3b5998'}
          fill='none'
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeDasharray={`${circum} ${circum}`}
          strokeDashoffset={radius * Math.PI * 2 * (svgProgress / 100)}
          strokeLinecap='round'
          transform={`rotate(-90, ${size / 2}, ${size / 2})`}
          {...{ strokeWidth }}
        />
      </Svg>
      <View
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {children}
      </View>
      {progressPercent === 100 && (
        <View
          style={tw`absolute right-0 bottom-0 justify-center items-center bg-secondary1-400 w-5 h-5 rounded-full`}
        >
          <SystemIcon type='SAX' name='TickCircle' size={24} color='white' />
        </View>
      )}
    </View>
  )
}
