import { MotiView } from 'moti'
import type { Dispatch, ReactNode } from 'react'
import { createContext, useContext, useReducer, useRef } from 'react'
import type {
  GestureResponderEvent,
  PressableProps,
  ViewProps,
} from 'react-native'
import { Pressable, View } from 'react-native'
import type { Style } from 'twrnc'

import { tw } from '#components/utils/tw'

import type { IconPropsWithType } from './SystemIcon'
import { SystemIcon } from './SystemIcon'

type State = {
  expanded: boolean
  contentHeight: number
}

type Context = State & {
  duration: number
  dispatch: Dispatch<Partial<State>>
  onExpandChange?: (expand: boolean) => void
  onDidAnimate: () => void
}

const ExpandableCtx = createContext<Context | undefined>(undefined)

const useExpandableContext = () => {
  const ctx = useContext(ExpandableCtx)
  if (!ctx) {
    throw new Error('Expandable: invalid context call')
  }
  return ctx
}

// ---------------------------------------------------- //

type ExpandableProps = Omit<ViewProps, 'style'> & {
  style?: Style
  defaultExpand?: boolean
  duration?: number
  animateOnMount?: boolean
  children: ReactNode
  onExpandChange?: (expand: boolean) => void
}

const Expandable = ({
  style,
  defaultExpand = false,
  duration = 200,
  animateOnMount,
  onExpandChange,
  children,
  ...props
}: ExpandableProps) => {
  const [state, dispatch] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    { expanded: defaultExpand, contentHeight: 0 },
  )

  // first mount - 0
  // first animate (height is 0) - 1
  // second animate (height is contentHeight) - 2
  const animateCount = useRef(0)

  const onDidAnimate = () => {
    animateCount.current += 1
  }

  const ctx: Context = {
    ...state,
    dispatch,
    onExpandChange,
    onDidAnimate,
    duration:
      animateOnMount === false && animateCount.current < 2 ? 0 : duration,
  }

  return (
    <ExpandableCtx.Provider value={ctx}>
      <View {...props} style={tw.style(style)}>
        {children}
      </View>
    </ExpandableCtx.Provider>
  )
}

// ---------------------------------------------------- //

const Trigger = ({ style, children, ...props }: PressableProps) => {
  const { expanded, dispatch, onExpandChange } = useExpandableContext()

  const handleToggle = (e: GestureResponderEvent) => {
    const newVal = !expanded
    dispatch({ expanded: newVal })
    onExpandChange?.(newVal)
    props.onPress?.(e)
  }

  return (
    <Pressable
      {...props}
      style={tw.style(style as Style)}
      onPress={handleToggle}
    >
      {children}
    </Pressable>
  )
}

// ---------------------------------------------------- //

type ContentProps = Omit<ViewProps, 'style'> & {
  containerStyle?: Style
  style?: Style
  children: ReactNode
}

const Content = ({ containerStyle, style, children }: ContentProps) => {
  const { expanded, contentHeight, dispatch, duration, onDidAnimate } =
    useExpandableContext()

  return (
    <MotiView
      style={tw.style('relative w-full overflow-hidden', containerStyle)}
      animate={{ height: contentHeight * +expanded }}
      transition={{ type: 'timing', duration }}
      onDidAnimate={onDidAnimate}
    >
      <View
        style={tw.style('w-full absolute', style)}
        onLayout={e => dispatch({ contentHeight: e.nativeEvent.layout.height })}
      >
        {children}
      </View>
    </MotiView>
  )
}

// ---------------------------------------------------- //

type ArrowProps = Omit<ViewProps, 'style' | 'children'> & {
  style?: Style
  icon?: Extract<IconPropsWithType, { type: 'SAX' }>
}

const Arrow = ({ style, icon, ...props }: ArrowProps) => {
  const { expanded, duration } = useExpandableContext()

  return (
    <MotiView
      {...props}
      style={tw.style('self-start', style)}
      animate={{ rotate: 90 * +expanded + 'deg' }}
      transition={{ type: 'timing', duration }}
    >
      <SystemIcon
        type='SAX'
        name='ArrowRight2'
        size={22}
        variant='Bold'
        color={tw.color('gray-600')}
        {...icon}
      />
    </MotiView>
  )
}

Expandable.Trigger = Trigger
Expandable.Content = Content
Expandable.Arrow = Arrow
export { Expandable, type ExpandableProps }
