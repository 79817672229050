import type React from 'react'
import { View } from 'react-native'
import type { Style } from 'twrnc'

import type { IconSaxName } from '#components/base/IconSax'
import { IconSax } from '#components/base/IconSax'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { useDeviceDimensions } from '#components/utils/useDeviceDimensions'

export type MessageProps = {
  type: 'success' | 'error' | 'warning'
  message?: string
  containerStyle?: Style
  iconStyle?: Style
  contentStyle?: Style
  messageStyle?: Style
}

export const ToastMessage: React.FC<MessageProps> = ({
  type,
  message,
  containerStyle,
  iconStyle,
  contentStyle,
  messageStyle,
}) => {
  const { width } = useDeviceDimensions()
  const typeStyle = {
    success: 'border-success-300 ',
    error: 'border-error-300 ',
    warning: 'border-alert-300',
  }
  const iconComponent: Record<MessageProps['type'], IconSaxName> = {
    success: 'TickCircle',
    error: 'Forbidden2',
    warning: 'Warning2',
  }
  const color: Record<MessageProps['type'], string> = {
    success: tw.color('success-300') ?? '#34C759',
    error: tw.color('error-300') || '#FF3B30',

    warning: tw.color('warning-300') || 'FFCC00',
  }
  const backgroundColor: Record<MessageProps['type'], string> = {
    success: tw.color('secondary1-50') ?? '#EAFEF7',
    error: tw.color('error-50') ?? '#FFEBEA',
    warning: tw.color('warning-50') ?? '#FFFAE6',
  }
  const shadowColor: Record<MessageProps['type'], any> = {
    success: tw.color('secondary1-50'),
    error: tw.color('error-50'),
    warning: tw.color('warning-50'),
  }

  return (
    <View
      style={tw.style(
        `p-4 my:4 bg-background-light-1 rounded-2 flex-row max-w-192 w-[${width - 72}px] shadow-2xl`,
        {
          backgroundColor: backgroundColor[type],
          shadowColor: shadowColor[type],
        },
        typeStyle[type],
        containerStyle,
      )}
    >
      <View style={tw.style(iconStyle)}>
        <IconSax
          name={iconComponent[type]}
          color={color[type]}
          variant='Bold'
        />
      </View>
      <View style={tw.style('flex-1 flex-col ml-4', contentStyle)}>
        {message && (
          <Text
            // style={tw.style('text-text-1 ', { fontWeight: 500 }, messageStyle)}
            numberOfLines={2}
            specialType='Title'
            color={tw.color('text-1')}
          >
            {message}
          </Text>
        )}
      </View>
    </View>
  )
}
