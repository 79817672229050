import { Fragment, useState } from 'react'
import { ScrollView, View } from 'react-native'

import { Button } from '#components/base/Button/Button'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { InputPassword } from '#components/form/InputPassword'
import { toastError, toastSuccess } from '#components/utils/Toast'
import { tw } from '#components/utils/tw'
import { Header } from '#components/widgets/Header'
import { WarningPassword } from '#components/widgets/WarningPassword'
import { useResolveForgotPassword } from '#graphql/codegen'
import type { AuthStackScreenProps } from '#navigator/types'
import { onAppInit } from '#screens/App/onAppInit'

export const NewPassword = ({ route }: AuthStackScreenProps<'NewPassword'>) => {
  const [password, setPassword] = useState('')
  const [isValidPassword, setIsValidPassword] = useState(false)

  const { id, secret } = route.params
  const [forgotPasswordResult, forgotPassword] = useResolveForgotPassword()

  const handleConfirm = async () => {
    if (isValidPassword) {
      try {
        const { data, error } = await forgotPassword({
          data: { secret, id },
          newPassword: password,
        })

        if (!data || !!error) {
          throw new Error('Unable to change password, please try again!')
        }

        await onAppInit({ currentAuthSession: data.resolveForgotPassword })
        toastSuccess({ message: 'You can now login with your new password' })
      } catch (e: any) {
        toastError({
          message: e || 'Something went wrong, please try again later',
        })
      }
    }
  }

  return (
    <Fragment>
      <Header isBack />
      <ScrollView contentContainerStyle={tw`px-4 pb-4`}>
        <View style={tw`flex-col items-center py-8 gap-2`}>
          <Text textAlign='center' specialType='Headline2'>
            New password
          </Text>
          <Text
            textAlign='center'
            specialType='Title'
            color={tw.color('text-2')}
          >
            Create a new password that is safe and easy to remember
          </Text>
        </View>
        <View style={tw`flex-col gap-4 mb-12`}>
          <InputPassword
            autoFocus
            onSubmitEditing={handleConfirm}
            onChangeText={setPassword}
            placeholder='Enter your password'
            prefix={
              <View style={tw.style('pr-2')}>
                <SystemIcon type='SAX' name='Lock1' />
              </View>
            }
          />
          <Button
            loading={forgotPasswordResult.fetching}
            disabled={!isValidPassword}
            onPress={handleConfirm}
          >
            Continue
          </Button>
        </View>
        <WarningPassword
          password={password}
          onAllConditionsMet={setIsValidPassword}
        />
      </ScrollView>
    </Fragment>
  )
}
