import { ApplePay, CardPay, GooglePay } from '#components/base/IconSvg/SVG'

export const IconComponent = {
  ApplePay,
  CardPay,
  GooglePay,
}
export type SvgIconName = 'ApplePay' | 'CardPay' | 'GooglePay'
export const paymentMethods = [
  { id: 'applePay', svg: 'ApplePay' as SvgIconName },
  { id: 'card', svg: 'CardPay' as SvgIconName },
  { id: 'googlePay', svg: 'GooglePay' as SvgIconName },
]
