import type { FC } from 'react'
import { Fragment } from 'react'
import { View } from 'react-native'

import { images } from '#assets'
import { Gif } from '#components/base/Gif/Gif'
import { EmptyCompletedLesson } from '#components/base/IconSvg/SVG'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

type EmptyProps = {
  loading?: boolean
}

export const Empty: FC<EmptyProps> = ({ loading }) => (
  <View style={tw.style('flex-1 flex-col items-center justify-center p-4')}>
    {loading ? (
      <View style={tw`flex-1 flex-col items-center justify-center`}>
        <Gif source={images.loading} width={40} height={40} />
      </View>
    ) : (
      <Fragment>
        <EmptyCompletedLesson />
        <View style={tw`mt-3 gap-1`}>
          <Text
            specialType='Title'
            color={tw.color('primary-400')}
            textAlign='center'
          >
            Words awaiting you!
          </Text>
          <Text
            specialType='paragraph2'
            color={tw.color('text-2')}
            textAlign='center'
          >
            {'Let’s get started and build your \nvocabulary together'}
          </Text>
        </View>
      </Fragment>
    )}
  </View>
)
