import { observer } from 'mobx-react-lite'
import type React from 'react'
import { Platform, View } from 'react-native'

import { Button } from '#components/base/Button/Button'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { ActionSheet } from '#components/widgets/ActionSheet'

type Pr = {
  closeActionsheet: () => void
  title?: string
  content?: string
  handleStop?: () => void
  handleContinue?: () => void
  LableButtonContinue?: string
  lablebuttonStop?: string
}

export const LevelNotificationModal: React.FC<Pr> = observer(
  ({
    closeActionsheet,
    title,
    content,
    LableButtonContinue,
    lablebuttonStop,
    handleStop,
    handleContinue,
  }) => (
    <ActionSheet
      containerStyle={tw.style(
        'bg-background-light-1',
        Platform.OS === 'web' ? 'flex-1' : 'h-85',
      )}
      isLoading={false}
    >
      <View style={tw.style('pt-6 pb-2.5 items-center')}>
        <SystemIcon name='warning' type='SVG' size={74} />
      </View>
      <View
        style={tw.style('pb-5 w-full flex items-center justify-center px-7.5')}
      >
        <Text specialType='Headline3' textAlign='center'>
          {title}
        </Text>
        <Text specialType='paragraph1' textAlign='center'>
          {content}
        </Text>
      </View>
      <View style={tw`flex-col flex-1 w-full`}>
        <Button
          onPress={handleStop}
          tone='secondary'
          size='large'
          style={tw`w-full mb-4 bg-warning-400`}
          titleColor={tw.color('text-4')}
        >
          <Text color={tw.color('text-4')} specialType='Button'>
            {lablebuttonStop}
          </Text>
        </Button>
        <View style={tw.style('border border-warning-400 rounded-full')}>
          <Button
            onPress={handleContinue}
            size='large'
            style={tw.style('w-full bg-background-light-white')}
            tone='plain'
          >
            <Text color={tw.color('warning-400')} specialType='Button'>
              {LableButtonContinue}
            </Text>
          </Button>
        </View>
      </View>
    </ActionSheet>
  ),
)
