import moment from 'moment'

import type {
  DirectionMilestonesType,
  TimeMilestonesValue,
} from '#types/wordInUser'
import {
  DirectionMilestones,
  MILESTONES,
  TimeMilestones,
} from '#types/wordInUser'

export const getNextDateFromMilestones = ({
  currentMilestone,
  nextMilestone,
  isDays = true,
}: {
  currentMilestone: TimeMilestonesValue
  nextMilestone: TimeMilestonesValue
  isDays?: boolean
}): Date => {
  const date = moment()
  const daysToAdd =
    currentMilestone <= nextMilestone
      ? nextMilestone - currentMilestone
      : nextMilestone
  if (daysToAdd === 0) {
    return date.add(isDays ? 1 : 15, isDays ? 'hours' : 'minutes').toDate()
  }
  const nextDate = date.add(
    isDays ? daysToAdd : 15,
    isDays ? 'days' : 'minutes',
  )
  return nextDate.toDate()
}

export const getNextOrPreviousMilestone = (
  currentMilestone: TimeMilestonesValue,
  direction: DirectionMilestonesType,
): TimeMilestonesValue => {
  if (
    currentMilestone === TimeMilestones.Zero &&
    direction === DirectionMilestones.Previous
  ) {
    return currentMilestone
  }

  if (
    currentMilestone === TimeMilestones.Thirty &&
    direction === DirectionMilestones.Next
  ) {
    return currentMilestone
  }

  const currentIndex = MILESTONES.indexOf(currentMilestone)

  if (currentIndex === -1) {
    return currentMilestone
  }

  if (direction === DirectionMilestones.Next) {
    const nextIndex = currentIndex + 1
    return MILESTONES[nextIndex] || currentMilestone
  }

  if (direction === DirectionMilestones.Previous) {
    const previousIndex = currentIndex - 1
    return MILESTONES[previousIndex] || currentMilestone
  }

  return currentMilestone
}
