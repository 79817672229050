import type { ImageSourcePropType } from 'react-native'
import { Image } from 'react-native'

import { tw } from '#components/utils/tw'

type Props = {
  source: ImageSourcePropType
  width: number
  height: number
}

export const Gif: React.FC<Props> = ({ source, width, height }) => (
  <Image
    source={source}
    resizeMode='cover'
    style={tw.style(`w-${width} h-${height}`)}
  />
)
