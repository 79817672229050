import { ulid } from 'ulidx'

import { isValidAudioUrl, isValidImageUrl } from '#components/utils/checkMedia'
import type {
  AnswerGame,
  GamesProps,
  GenerateHardGameProps,
  QuestionGame,
} from '#types/games'
import { HardGame } from '#types/games'

import { getItemInList } from './getItemInList'

export const generateCompleteTheDialogue = async ({
  item,
  data,
}: GenerateHardGameProps): Promise<GamesProps> => {
  const audios = item.media.filter(i => isValidAudioUrl(i.media?.url || ''))

  const question: QuestionGame = {
    text: data?.question || '',
    media: null,
    type: HardGame.completeTheDialogue,
    translation: '',
    index: data?.index || 0,
  }
  const images = item.media.filter(i => isValidImageUrl(i.media?.url || ''))
  const answers: AnswerGame[] = (data?.answers || []).map(i => ({
    text: i.text || '',
    isCorrect: i.isCorrect,
    audio: getItemInList(audios)?.media || null,
    image: getItemInList(images)?.media || null,
    translation: '',
    type: HardGame.completeTheDialogue,
    id: ulid(),
    answer: item.text || '',
  }))
  return { question, answers }
}
