import { Fragment } from 'react/jsx-runtime'
import type { Style } from 'twrnc'

import type { SkeletonProps } from '#components/base/Skeleton'
import { Skeleton as BaseSkeleton } from '#components/base/Skeleton'
import { tw } from '#components/utils/tw'

const TopicItem = ({ style, ...props }: SkeletonProps) => (
  <BaseSkeleton {...props} style={tw.style('h-36 rounded-3xl', style)} />
)

const Topics = ({
  firstItemStyle,
  itemStyle,
}: {
  firstItemStyle?: Style
  itemStyle?: Style
}) => (
  <Fragment>
    <BaseSkeleton style={tw`w-[45%] h-10 rounded-2xl mb-4`} />
    {Array.from({ length: 3 }).map((_, idx) => (
      <TopicItem
        key={idx}
        style={tw.style(
          idx < 3 && 'mb-3',
          itemStyle,
          idx === 0 && firstItemStyle,
        )}
      />
    ))}
  </Fragment>
)

export const Skeleton = {
  Topics,
  TopicItem,
}
