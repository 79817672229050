import { useOverlay } from '#components/overlay/hooks'

import { ActionSheetStripe } from './ActionSheetStripe'
import { PayementPackage } from './component/PayementPackage'

export const Payment = () => {
  const { openActionsheet } = useOverlay()

  return (
    <PayementPackage
      onPress={s => {
        if (!s || !s?.price?.unitAmount) {
          return
        }
        openActionsheet(ActionSheetStripe, {
          selectedPlan: s,
        })
      }}
    />
  )
}
