import 'react-native-reanimated'

import { configure } from 'mobx'
import { NativeBaseProvider } from 'native-base'
import { GestureHandlerRootView } from 'react-native-gesture-handler'

import { DarkModeProvider } from '#components/dark/DarkModeProvider'
import { OverlayProvider } from '#components/overlay/OverlayProvider'
import { combineProviders } from '#components/utils/combineProviders'
import { UrqlProvider } from '#graphql/urql'

import { AppNavigatorContainer } from '../../navigator/NavigatorContainer'
import { onAppInit } from './onAppInit'
import { PersistedDarkModeProvider } from './PersistedDarkModeProvider'

configure({
  enforceActions: 'never',
})
onAppInit()

export const App = () => {
  const Combined = combineProviders(
    UrqlProvider,
    DarkModeProvider,
    PersistedDarkModeProvider,
    NativeBaseProvider,
    OverlayProvider,
  )
  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <Combined>
        <AppNavigatorContainer />
      </Combined>
    </GestureHandlerRootView>
  )
}
