import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import { Animated, TouchableOpacity, View } from 'react-native'

import { tw } from '#components/utils/tw'
import { TalkBubble } from '#components/widgets/ChatBubble'
import { S } from '#store'

export const TipsChat = observer(() => {
  const opacityValue = useRef<Animated.Value>(new Animated.Value(0)).current
  const opacityTextValue = useRef<Animated.Value>(new Animated.Value(0)).current

  useEffect(() => {
    fadeIn()
  }, [])

  const fadeIn = () => {
    Animated.parallel([
      Animated.timing(opacityValue, {
        toValue: 0.8,
        duration: 500,
        useNativeDriver: true,
      }),
      Animated.timing(opacityTextValue, {
        toValue: 1,
        duration: 500,
        useNativeDriver: true,
      }),
    ]).start()
  }

  const fadeOut = () => {
    Animated.parallel([
      Animated.timing(opacityValue, {
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }),
      Animated.timing(opacityTextValue, {
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }),
    ]).start(() => {
      S.shared.isTipsTopicSpeak = true
    })
  }

  return (
    <View style={tw`absolute top-0 left-0 right-0 bottom-0`}>
      <TouchableOpacity onPress={fadeOut} style={tw`flex-1`}>
        <Animated.View style={[tw`flex-1 flex-col`]} />
        <Animated.View style={[tw`absolute top-0 left-0 right-0 bottom-0`]}>
          <View style={tw.style('w-3/5 mt-17 place-items-end self-end mr-7.5')}>
            <TalkBubble
              position='top'
              text='Click to view tasks that nees to be completed'
              backgroundColor={tw.color('primary-400')}
              textColor={tw.color('text-4')}
            />
          </View>
        </Animated.View>
      </TouchableOpacity>
    </View>
  )
})
