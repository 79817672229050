import { Fragment } from 'react/jsx-runtime'
import { View } from 'react-native'

import { Skeleton as BaseSkeleton } from '#components/base/Skeleton'
import { tw } from '#components/utils/tw'

const colorConfig = {
  startColor: tw.color('gray-300/70'),
  endColor: tw.color('gray-300/20'),
}

const Button = () => (
  <BaseSkeleton {...colorConfig} style={tw`h-14 rounded-full`} />
)

const SlideItem = ({
  containerWidth,
  width,
  height,
}: {
  containerWidth: number
  width: number
  height: number
}) => (
  <View
    style={tw.style(
      'flex justify-center items-center overflow-hidden rounded-2xl',
      { width: containerWidth },
    )}
  >
    <BaseSkeleton
      {...colorConfig}
      style={tw.style('rounded-2xl', { width, height })}
    />
    <View style={tw`flex flex-1 flex-col gap-y-3 w-full px-6 mt-4`}>
      <BaseSkeleton {...colorConfig} style={tw`rounded-2xl flex h-10 w-3/4`} />
      <View style={tw.style('flex flex-col gap-y-1.5 w-full')}>
        <BaseSkeleton {...colorConfig} style={tw`rounded-2xl h-5`} />
        <BaseSkeleton {...colorConfig} style={tw`rounded-2xl h-5`} />
        <BaseSkeleton {...colorConfig} style={tw`rounded-2xl h-5 w-10/12`} />
      </View>
    </View>
  </View>
)

const Dot = () => (
  <Fragment>
    <BaseSkeleton {...colorConfig} style={tw`w-3 h-2 rounded-md`} />
    <BaseSkeleton {...colorConfig} style={tw`w-3 h-2 rounded-md`} />
    <BaseSkeleton {...colorConfig} style={tw`w-3 h-2 rounded-md`} />
  </Fragment>
)

export const Skeleton = {
  Button,
  SlideItem,
  Dot,
}
