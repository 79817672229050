import { isNumber } from 'lodash'
import type { ISliderProps } from 'native-base'
import { Slider } from 'native-base'
import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'
import { View } from 'react-native'
import type { Style } from 'twrnc'

import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

// TODO (if needed)
// - make MAKER_SIZE dynamic
// - allow changing slider size, right now it's 'lg' and cannot be changed
// - allow render custom markers and labels
// - more checks on setValue inside useEffect to prevent weird behaviours

type Step = {
  label: ReactNode
  value: number
}

type StepSliderProps<T extends Step> = ISliderProps & {
  steps: T[]
  containerStyle?: Style
}

const MARKER_SIZE = 16

const calcStepValue = (steps: Step[]) => {
  const stepNums = steps.slice(1).map((s, idx) => s.value - steps[idx].value)
  return stepNums.every(n => n === stepNums[0]) ? stepNums[0] : -1
}

export const StepSlider = <T extends Step>({
  steps,
  step,
  containerStyle,
  value: propValue,
  defaultValue,
  ...props
}: StepSliderProps<T>) => {
  if (steps.length < 2) {
    throw new Error('StepSlider requires at least two steps.')
  }

  const minValue = steps.at(0)!.value
  const maxValue = steps.at(-1)!.value
  const stepValue = step ?? calcStepValue(steps)

  const [trackWidth, setTrackWidth] = useState(0)
  const [thumbWidth, setThumbWidth] = useState(0)
  const [value, setValue] = useState(defaultValue ?? propValue ?? minValue)

  const halfThumbWidth = thumbWidth / 2

  useEffect(() => {
    if (isNumber(propValue)) {
      setValue(Math.min(Math.max(propValue, minValue), maxValue))
    }
  }, [propValue])

  const calcMarkerPosition = (index: number) => {
    const totalMarker = steps.length
    const space = (trackWidth - MARKER_SIZE * totalMarker) / (totalMarker - 1)

    let offset = 0
    if (index < Math.floor(totalMarker / 2)) {
      offset = -halfThumbWidth
    } else if (index > Math.floor(totalMarker / 2)) {
      offset = halfThumbWidth
    }

    return index * (MARKER_SIZE + space) + offset
  }

  const handleChange = (v: number) => {
    setValue(v)
    props.onChange?.(v)
  }

  return (
    <View style={tw.style(`mx-[${halfThumbWidth}px]`, containerStyle)}>
      <Slider
        {...props}
        value={value}
        minValue={minValue}
        maxValue={maxValue}
        step={stepValue}
        size='lg'
        onChange={handleChange}
      >
        <Slider.Track onLayout={e => setTrackWidth(e.nativeEvent.layout.width)}>
          <Slider.FilledTrack backgroundColor={tw.color('primary-400')} />
        </Slider.Track>
        <Slider.Thumb
          style={tw.style('overflow-hidden', { outline: 'none' })}
          backgroundColor={tw.color('primary-400')}
          onLayout={e => setThumbWidth(e.nativeEvent.layout.width)}
        />
        {steps.map((s, idx) => (
          <View
            key={s.value}
            pointerEvents='none'
            style={tw.style(
              'absolute bg-white border-[3px] rounded-full',
              { width: MARKER_SIZE, height: MARKER_SIZE },
              { left: calcMarkerPosition(idx) },
              value >= s.value ? 'border-primary-400' : 'border-neutral-300',
            )}
          />
        ))}
      </Slider>
      <View
        style={tw`-mx-[${halfThumbWidth}px] flex-row justify-between mt-1.5`}
      >
        {steps.map(s => (
          <Text
            key={s.value}
            specialType='Note'
            color={tw.color(value === s.value ? 'primary-400' : 'neutral-400')}
          >
            {s.label}
          </Text>
        ))}
      </View>
    </View>
  )
}
