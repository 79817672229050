import type { FC } from 'react'
import { useEffect } from 'react'
import { Platform, View } from 'react-native'
import { Gesture, GestureDetector } from 'react-native-gesture-handler'
import Animated, {
  interpolate,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated'

import { Image } from '#components/base/Image'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { useDimensions } from '#components/utils/useDimensions'
import type { GameQuestionProps } from '#types/games'

export const SameOrDifferentQuestion: FC<GameQuestionProps> = ({
  question,
  onSwipe,
  onEndSwipe,
  currentIndex,
}) => {
  const { swipeThreshold, maxWidth, screenWidth, height } = useDimensions({
    padding: 16,
  })

  const maxHeight = height * (height < 670 ? 0.45 : 0.55)

  const translateX = useSharedValue(0)
  const cardOpacity = useSharedValue(1)

  useEffect(() => {
    translateX.value = withSpring(0)
    cardOpacity.value = withSpring(1)
  }, [currentIndex])

  const panGesture = Gesture.Pan()
    .onUpdate(event => {
      if (Platform.OS !== 'web') {
        ;('worklet')
      }
      translateX.value = event.translationX
      if (translateX.value < -10) {
        if (onSwipe) {
          runOnJS(onSwipe)('left')
        }
      } else if (translateX.value > 10) {
        if (onSwipe) {
          runOnJS(onSwipe)('right')
        }
      } else {
        if (onSwipe) {
          runOnJS(onSwipe)?.('none')
        }
      }
    })
    .onEnd(() => {
      if (Platform.OS !== 'web') {
        ;('worklet')
      }
      if (translateX.value < -swipeThreshold) {
        // Card swiped left
        cardOpacity.value = withSpring(0, {}, () => {
          if (onEndSwipe) {
            runOnJS(onEndSwipe)('left')
          }
        })
      } else if (translateX.value > swipeThreshold) {
        // Card swiped right
        cardOpacity.value = withSpring(0, {}, () => {
          if (onEndSwipe) {
            runOnJS(onEndSwipe)('right')
          }
        })
      } else {
        translateX.value = withSpring(0)
        cardOpacity.value = withSpring(1)
        if (onSwipe) {
          runOnJS(onSwipe)?.('none')
        }
      }
    })

  const animatedStyle = useAnimatedStyle(
    () => ({
      transform: [
        { translateX: translateX.value },
        Platform.OS === 'web'
          ? {
              rotate: `${interpolate(
                translateX.value,
                [-screenWidth / 2, 0, screenWidth / 2],
                [-50, 0, 50],
              )}deg`,
            }
          : {
              rotateZ: `${interpolate(
                translateX.value,
                [-screenWidth / 2, 0, screenWidth / 2],
                [-50, 0, 50],
              )}deg`,
            },
      ],
      opacity: cardOpacity.value,
    }),
    [translateX, cardOpacity],
  )

  return (
    <GestureDetector gesture={panGesture}>
      <Animated.View
        style={[
          tw.style(
            'justify-center items-center bg-white rounded-3xl p-3 shadow-3xl shadow-md shadow-primary-400',
          ),
          { width: screenWidth },
          animatedStyle,
        ]}
      >
        <View style={tw.style('flex-1 flex-col items-center')}>
          <View
            style={tw.style(
              'justify-center items-center overflow-hidden rounded-2xl',
              {
                width: maxWidth,
              },
            )}
          >
            <Image
              source={{ uri: question.media?.url || '' }}
              style={tw.style(
                'rounded-2xl',
                `w-[${maxWidth}px] h-[${maxHeight}px]`,
              )}
            />
          </View>
          <View style={tw`flex-col items-center justify-center mx-4 mt-4`}>
            <Text specialType='Headline2' textAlign='center' numberOfLines={4}>
              {question.text}
            </Text>
          </View>
        </View>
      </Animated.View>
    </GestureDetector>
  )
}
