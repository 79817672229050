import { Button, Flex, Form, Input, Select, Spin } from 'antd'
import Typography from 'antd/es/typography/Typography'
import { Modal } from 'native-base'
import type { FC } from 'react'
import { useEffect, useState } from 'react'

import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { useDebounceValue } from '#admin/components/utils/useDebounceValue'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { Upload } from '#admin/components/widgets/Upload'
import { tw } from '#components/utils/tw'
import type {
  Language,
  LevelType,
  TopicInCategory,
  TopicStatusType,
} from '#graphql/codegen'
import { useSearchCategory } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import type { LanguageType } from '#types/language'
import type { ResponseFile } from '#types/media'
import type { SearchTopicItem } from '#types/topic'

const { Option } = Select

type Prop = {
  selectedTopic?: SearchTopicItem
  topicInCate?: TopicInCategory[]
  closeModal: () => void
  onOk: () => void
}

const { TextArea } = Input

export const TopicModal: FC<Prop> = ({
  selectedTopic,
  closeModal,
  onOk,
  topicInCate,
}) => {
  const l: LevelType = (selectedTopic?.level as LevelType) || 'A1'
  const [topic, setTopic] = useState(selectedTopic?.name || '')
  const [description, setDescription] = useState(
    selectedTopic?.description || '',
  )
  const [level, setLevel] = useState<LevelType>(l)
  const [languageTopic, setLanguageTopic] = useState<Language>(
    (selectedTopic?.languageTopic as Language) || 'English',
  )
  const [topicStatus, setTopicStatus] = useState<TopicStatusType>(
    (selectedTopic?.status as TopicStatusType) || 'Pending',
  )
  const [file, setFile] = useState<{ url: string; mediaId: string }>({
    url: selectedTopic?.media?.url ?? '',
    mediaId: selectedTopic?.mediaId ?? '',
  })
  // const [avatar, setAvatar] = useState<{ url: string; avatarId: string }>({
  //   url: selectedTopic?.character?.url ?? '',
  //   avatarId: selectedTopic?.characterId ?? '',
  // })

  const [tags, setTags] = useState<{ label: string; value: string }[]>(
    selectedTopic && topicInCate
      ? topicInCate.map(e => ({
          label: e.category?.tagName ?? '',
          value: e.category?.id ?? '',
        }))
      : [],
  )

  const [createTag, setCreateTag] = useState('')
  const [loading, setLoading] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const optionsLanguage: { label: string; value: LanguageType }[] = [
    { label: 'English', value: 'English' },
    { label: 'Spanish', value: 'Spanish' },
  ]

  const optionsStatus: { label: string; value: TopicStatusType }[] = [
    { label: 'Pending', value: 'Pending' },
    { label: 'Active', value: 'Active' },
  ]

  const [searchTag, setSearchTag] = useState('')

  const [, setInputTag, debouncedKeyword] = useDebounceValue<string>('', 1000)

  const [category, refetchCategory] = useSearchCategory({
    variables: {
      filter: searchTag
        ? { tagName_iLike: `%${searchTag}%`, tagName_notLike: '' }
        : { tagName_notLike: '' },
      order: ['tagName_asc'],
      page: { limit: 10, offset: 0 },
    },
  })

  const [options, setOptions] = useState(
    category?.data?.searchCategory.map(e => ({
      label: e.tagName,
      value: e.id,
    })),
  )

  useEffect(() => {
    setOptions(
      category.data?.searchCategory.map(e => ({
        label: e.tagName,
        value: e.id,
      })),
    )
  }, [JSON.stringify(category)])

  useEffect(() => {
    setSearchTag(debouncedKeyword)
  }, [debouncedKeyword, setSearchTag])

  const debounceFetcher = (value: string) => {
    setInputTag(value)
  }

  useEffect(() => {
    setTopic(selectedTopic?.name || '')
    setDescription(selectedTopic?.description || '')
    setLevel(l)
    if (selectedTopic?.media?.url) {
      setFile({
        url: selectedTopic?.media?.url,
        mediaId: selectedTopic?.mediaId,
      })
    }
  }, [selectedTopic])

  useEffect(() => {
    setSearchTag('')
  }, [tags])

  const handleModalOk = async () => {
    try {
      if (isLoading) {
        return
      }
      setIsLoading(true)
      let res
      if (selectedTopic) {
        res = await gql.updateTopic({
          id: selectedTopic.id,
          data: {
            name: topic,
            description,
            level,
            mediaId: file.mediaId,
            characterId: '', // avatar.avatarId,
            languageTopic,
            status: topicStatus,
          },
        })
        topicInCate?.map(async e => {
          const temp = tags?.find(tag => tag.value === e.category?.id)
          if (!temp) {
            await gql.deleteTopicInCategory({
              id: e.id,
            })
          }
        })
        tags.map(async tag => {
          const temp = topicInCate?.find(e => e.category?.id === tag.value)
          if (!temp) {
            await gql.createTopicInCategory({
              data: {
                topicId: selectedTopic.id,
                categoryId: tag.value,
              },
            })
          }
        })
      } else {
        res = await gql.createTopic({
          data: {
            name: topic,
            description,
            level,
            mediaId: file.mediaId,
            characterId: '', // avatar.avatarId,
            languageTopic,
          },
        })
        tags.map(async tag => {
          await gql.createTopicInCategory({
            data: {
              topicId: res.data?.createTopic.id,
              categoryId: tag.value,
            },
          })
        })
      }
      if (res.error || !res.data) {
        toastError({ title: 'Error', message: res.error?.message })
        setIsLoading(false)
        return
      }
      toastSuccess({ message: 'Topic handled successfully' })
      onOk()
      setTopic('')
      setDescription('')
      setLevel('A1')
      closeModal()
      setIsLoading(false)
    } catch (error) {
      toastError({ message: 'Failed to handle topic' })
      setIsLoading(false)
    }
  }

  const handleModalCancel = () => {
    setTopic('')
    setDescription('')
    setLevel('A1')
    closeModal()
  }

  // const handleKeyPress = (event: KeyboardEvent) => {
  //   if (event.key === 'Enter' && topic.trim() !== '') {
  //     handleModalOk()
  //   }
  // }

  const onURLChange = (data: ResponseFile) => {
    setFile({
      url: data.url,
      mediaId: data.id,
    })
  }

  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = async () => {
    setLoading(true)
    const data = await gql.createCategory({
      data: {
        tagName: createTag,
      },
    })
    refetchCategory({ requestPolicy: 'network-only' })
    setTags(prev => [
      ...prev,
      {
        label: data.data?.createCategory?.tagName ?? '',
        value: data.data?.createCategory?.id ?? '',
      },
    ])
    setCreateTag('')
    setLoading(false)
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <ModalLayout
      title={selectedTopic ? 'Edit topic' : 'Add new topic'}
      closeModal={handleModalCancel}
      onConfirm={handleModalOk}
      disableOk={!topic || loading || isLoading}
      autoClose={false}
      isEnterEvent={false}
    >
      <Form layout='vertical' style={tw`w-full pt-2`}>
        <Form.Item label='Topic'>
          <Input
            value={topic}
            onChange={e => setTopic(e.target.value)}
            className='w-full'
          />
        </Form.Item>
        <Form.Item label='Tags'>
          <Flex gap={8}>
            <Select
              value={tags}
              mode='multiple'
              options={options}
              onSearch={debounceFetcher}
              optionFilterProp='label'
              showSearch
              notFoundContent={category.fetching ? <Spin size='small' /> : null}
              labelInValue
              onChange={e => setTags(e)}
              className='w-full'
            />
            <Button type='primary' onClick={showModal}>
              Create tag
            </Button>
            <Modal
              isOpen={isModalOpen}
              onClose={handleCancel}
              style={tw`flex px-6 bg-white w-[20%] h-[20%] top-[40%] left-[40%] rounded-lg`}
            >
              <div style={tw`w-full`}>
                <Typography style={tw`mb-4 text-[16px] font-[500]`}>
                  Create tag
                </Typography>
                <Input
                  placeholder='Please enter item'
                  value={createTag}
                  onChange={e => setCreateTag(e.target.value)}
                  style={tw`mb-4`}
                />
                <Flex gap={8} style={tw`flex justify-end`}>
                  <Button type='default' onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    type='primary'
                    onClick={handleOk}
                    disabled={!createTag}
                    loading={loading}
                  >
                    Create tag
                  </Button>
                </Flex>
              </div>
            </Modal>
          </Flex>
        </Form.Item>
        <Form.Item label='Description'>
          <Flex gap={8}>
            <TextArea
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </Flex>
        </Form.Item>
        <Form.Item label='Level'>
          <Select value={level} onChange={value => setLevel(value)}>
            <Option value='A1'>A1</Option>
            <Option value='A2'>A2</Option>
            <Option value='B1'>B1</Option>
            <Option value='B2'>B2</Option>
          </Select>
        </Form.Item>
        <Form.Item label='I want to learn'>
          <Select
            value={languageTopic}
            onChange={value => setLanguageTopic(value)}
            options={optionsLanguage}
          />
        </Form.Item>
        {selectedTopic && (
          <Form.Item label='Status'>
            <Select
              value={topicStatus}
              onChange={value => setTopicStatus(value)}
              options={optionsStatus}
            />
          </Form.Item>
        )}
        <Form.Item style={tw`text-center w-full`}>
          <Flex gap={32} style={tw`flex items-center justify-center`}>
            {/* <Upload
              resourceType='image_game'
              type='avatar'
              display='pictureCard'
              url={avatar.url}
              onChange={(data: ResponseFile) => {
                setAvatar({ url: data.url, avatarId: data.id })
              }}
              onDeleted={() => setAvatar({ url: '', avatarId: '' })}
              setLoadingUpload={setLoading}
            /> */}
            <Upload
              resourceType='image_game'
              type='image'
              display='pictureCard'
              url={file.url}
              onChange={onURLChange}
              onDeleted={() => setFile({ url: '', mediaId: '' })}
              setLoadingUpload={setLoading}
            />
          </Flex>
        </Form.Item>
      </Form>
    </ModalLayout>
  )
}
