import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Flex } from 'antd'

import { tw } from '#components/utils/tw'

import { Label } from './Label'

type Prop = {
  loading: boolean
  title: string
  required?: boolean
  onClick?: () => void
}
export const ButtonUpLoad = ({ loading, title, required, onClick }: Prop) => (
  <Button
    style={tw`flex-col w-30 h-30 items-center justify-center`}
    type='dashed'
    onClick={() => {
      if (onClick) {
        onClick()
      }
    }}
  >
    <Flex vertical style={tw`mb-1`}>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
    </Flex>
    <Label label={title} required={required} />
  </Button>
)
