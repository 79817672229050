import { useIsFocused } from '@react-navigation/native'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Platform, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { Button } from '#components/base/Button/Button'
import { FlatList } from '#components/base/FlatList'
import { Loading } from '#components/base/Loading'
import { tw } from '#components/utils/tw'
import { Divider } from '#components/widgets/Divider'
import { Header } from '#components/widgets/Header'
import { useSearchTranslate, useSearchWordInUser } from '#graphql/codegen'
import { navigate, replace } from '#navigator/helpers'
import type { AppStackScreenProps } from '#navigator/types'
import { S } from '#store'
import { PercentageTopic } from '#types/games'
import type { SearchWordInUser } from '#types/wordInUser'

import { Confetti } from './Confetti'
import { ListHeader } from './ListHeader'
import { ListItem } from './ListItem'

export const Review = observer(({ route }: AppStackScreenProps<'Review'>) => {
  const { topicId, isPhrase } = route?.params
  const insets = useSafeAreaInsets()

  const isFocus = useIsFocused()

  const {
    percentGame,
    countPhrase,
    countPhraseUnknown,
    countVocabulary,
    countVocabularyUnKnown,
    fetching,
  } = S.lessonPercentage.getLessonPercentageById(topicId)

  const [d] = useSearchWordInUser({
    variables: {
      filter: {
        topicId,
        userId: S.shared.currentUser?.id,
        word_some: { isVocabulary: !isPhrase },
      },
    },
    requestPolicy: 'network-only',
  })

  const [translate] = useSearchTranslate({
    variables: {
      filter: {
        suportLanguage: S.shared.currentUser?.nativeLanguage,
        Word_some: { isVocabulary: !isPhrase, topicId },
      },
    },
    requestPolicy: 'network-only',
  })
  console.log('Duy-translate', translate)

  useEffect(() => {
    if (isFocus) {
      S.lessonPercentage.setLessonPercentageById({
        topicId,
        userId: S.shared.currentUser?.id || '',
        language: S.shared.currentUser?.languageLearn,
      })
    }
  }, [isFocus])

  const fetchWords = (isCheckPhrase: boolean) => {
    if (isCheckPhrase) {
      const vocabulary = countVocabularyUnKnown === countVocabulary
      navigate('App', {
        screen: 'Games',
        params: { topicId, isPhrase: vocabulary },
      })
    } else {
      const phrase = countPhraseUnknown === countPhrase
      navigate('App', {
        screen: 'Games',
        params: { topicId, isPhrase: !phrase },
      })
    }
  }

  const handleContinue = () => {
    S.shared.setWrongNext(0)
    if (percentGame / PercentageTopic.Game > 0.8) {
      navigate('App', { screen: 'ListenGame', params: { topicId } })
    } else {
      fetchWords(isPhrase)
    }
  }

  const renderListHeader = () => (
    <ListHeader
      isPhrase={isPhrase}
      countPhrase={countPhrase}
      countVocabulary={countVocabulary}
      countPhraseUnknown={countPhraseUnknown}
      countVocabularyUnKnown={countVocabularyUnKnown}
    />
  )

  const renderItem = ({
    item,
    index,
  }: {
    item: SearchWordInUser
    index: number
  }) => (
    <ListItem
      item={item}
      index={index}
      total={d.data?.searchWordInUser.length ?? 0}
      translate={
        translate.data?.searchTranslate
          ? translate.data?.searchTranslate.filter(
              i => i.wQAId === item?.wordId,
            )
          : []
      }
    />
  )

  const renderItemSeparator = () => (
    <Divider style={tw`h-px bg-neutral-200 mx-4.5`} />
  )

  if (d.fetching || fetching) {
    return <Loading isLoading />
  }

  return (
    <View
      style={tw.style(
        'flex-1 flex-col',
        Platform.OS === 'ios' && { paddingBottom: insets.bottom },
      )}
    >
      <Header
        isDark
        onPressBack={() => {
          replace('Home', { screen: 'Topics' })
        }}
      />
      <View style={tw`flex-1 flex-col px-4 pb-4 overflow-hidden`}>
        {percentGame > PercentageTopic.Game && (
          <View style={{ zIndex: 3, pointerEvents: 'none' }}>
            <Confetti />
          </View>
        )}
        <View style={tw.style('flex-1 flex-col gap-4')}>
          <FlatList
            style={[tw`rounded-3xl flex-1`]}
            contentContainerStyle={tw`flex-grow`}
            data={d?.data?.searchWordInUser ?? []}
            renderItem={renderItem}
            keyExtractor={item => item.id}
            refreshing={d.fetching}
            ListHeaderComponent={renderListHeader}
            ItemSeparatorComponent={renderItemSeparator}
          />

          <Button onPress={handleContinue}>Continue</Button>
        </View>
      </View>
    </View>
  )
})
