import { ScrollView, View } from 'react-native'

import { Button } from '#components/base/Button/Button'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { TaskListItem } from '#screens/ScenarioSpeak/TaskListItem'
import type { UserTaskItem } from '#types/chat'

type Props = {
  closeActionsheet: () => void
  data: UserTaskItem[]
}

export const ActionSheetTask = ({ closeActionsheet, data }: Props) => (
  <View style={tw`w-full`}>
    <Text specialType='Headline4' textAlign='center' style={tw`p-4`}>
      Task List
    </Text>
    <ScrollView
      contentContainerStyle={tw`flex-col gap-y-1.5`}
      showsHorizontalScrollIndicator={false}
      style={tw`px-2 max-h-96 pb-4`}
    >
      {data.map((task, index) => (
        <TaskListItem key={task.id} index={index} task={task} />
      ))}
    </ScrollView>
    <View style={tw`p-2`}>
      <Button onPress={closeActionsheet}>Got it</Button>
    </View>
  </View>
)
