import {
  CommonActions,
  createNavigationContainerRef,
  StackActions,
} from '@react-navigation/native'

import type { RootStackParamList } from './types'

type K = keyof RootStackParamList
type P<T extends K> = RootStackParamList[T] extends undefined
  ? [name: T]
  : [name: T, params: RootStackParamList[T]]

const ref = createNavigationContainerRef<RootStackParamList>()
export const rootNavigationRef = ref

let resolvePromise: Function
const promise = new Promise(r => {
  resolvePromise = r
})
const now = Date.now()
const id = setInterval(() => {
  if (ref.isReady() || Date.now() - now >= 3000) {
    clearInterval(id)
    resolvePromise()
  }
}, 100)

export const initRouteStack = async <T extends K>(...[name, params]: P<T>) => {
  await promise
  if (!ref.isReady()) {
    return
  }
  ref.dispatch(
    CommonActions.reset({
      index: 0,
      routes: [{ name, params }],
    }),
  )
}

export const navigate = async <T extends K>(...[name, params]: P<T>) => {
  await promise
  if (!ref.isReady()) {
    return
  }
  ref.navigate<any>(name, params)
}

export const replace = async <T extends K>(...[name, params]: P<T>) => {
  await promise
  if (!ref.isReady()) {
    return
  }
  ref.dispatch(StackActions.replace(name, params))
}

export const goBack = async () => {
  await promise
  if (!ref.isReady() || !ref.canGoBack()) {
    return
  }
  // TODO add name params and check then navigate?
  ref.goBack()
}

export const popToTop = async () => {
  await promise
  if (!ref.isReady() || !ref.canGoBack()) {
    return
  }
  ref.dispatch(StackActions.popToTop())
}

export const pop = async (num: number) => {
  await promise
  if (!ref.isReady() || !ref.canGoBack()) {
    return
  }
  ref.dispatch(StackActions.pop(num))
}
