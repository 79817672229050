import type { FC } from 'react'
import { View } from 'react-native'

import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { ProgressCircle } from '#screens/Review/ProgressCircle'
import type { SearchTranslateItem } from '#types/translate'
import type { SearchWordInUser, TimeMilestonesValue } from '#types/wordInUser'
import { TimeMilestones } from '#types/wordInUser'

type ListItemProps = {
  item: SearchWordInUser & {
    translate: SearchTranslateItem[]
  }
  index: number
  total: number
}

export const Item: FC<ListItemProps> = ({ item, index, total }) => (
  <View
    style={tw.style(
      'flex-row bg-background-light-white p-4.5',
      index === 0 ? 'rounded-t-5' : '',
      index === total - 1 ? 'rounded-b-5' : '',
    )}
  >
    <View style={tw.style('flex-1')}>
      <Text specialType='Title'>{item?.word?.vocabAndPhrase || ''}</Text>
      <Text specialType='paragraph2'>
        {item.translate.length > 0 ? item.translate[0].text : ''}
      </Text>
    </View>
    <ProgressCircle
      progress={item.isCompleted ? 6 : progress[item.milestone] - 1}
    />
  </View>
)

const progress: { [key in TimeMilestonesValue]: number } = {
  [TimeMilestones.Zero]: 1,
  [TimeMilestones.One]: 2,
  [TimeMilestones.Three]: 3,
  [TimeMilestones.Seven]: 4,
  [TimeMilestones.Fourteen]: 5,
  [TimeMilestones.Thirty]: 6,
}
