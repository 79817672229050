import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Fragment } from 'react/jsx-runtime'
import { TouchableOpacity, View } from 'react-native'

import { images } from '#assets'
import { Image } from '#components/base/Image'
import { Skeleton } from '#components/base/Skeleton'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import {
  useSearchStreakInUser,
  useSearchTopicInUser,
  useSearchWordInUser,
} from '#graphql/codegen'
import { navigate } from '#navigator/helpers'
import { S } from '#store'

export const SectionOverview = observer(() => {
  const [containerWidth, setContainerWidth] = useState(0)

  const [streakData] = useSearchStreakInUser({
    variables: { filter: { userId: S.shared.currentUser?.id } },
  })

  const [wordInUserData] = useSearchWordInUser({
    variables: { filter: { times: 7, userId: S.shared.currentUser?.id } },
  })

  const [topicInUserData] = useSearchTopicInUser({
    variables: {
      filter: { percent: 100, userLearnId: S.shared.currentUser?.id },
    },
  })

  const streak = streakData.data?.searchStreakInUser || []
  const wordInUser = wordInUserData.data?.searchWordInUser || []
  const topicInUser = topicInUserData.data?.searchTopicInUser || []

  const stats = [
    {
      label: 'Day streak',
      icon: 'streak',
      color: 'primary-300',
      loading: streakData.fetching,
      count: streak[0]?.countStreak ?? 0,
    },
    {
      label: 'Completed lessons',
      icon: 'lesson',
      color: 'primary-400',
      loading: topicInUserData.fetching,
      count: topicInUser.length,
    },
    {
      label: 'Learned words',
      icon: 'word',
      color: 'secondary2-500',
      loading: wordInUserData.fetching,
      count: wordInUser.filter(item => item.word?.isVocabulary).length,
    },
    {
      label: 'Learned phrases',
      icon: 'phrase',
      color: 'warning-500',
      loading: wordInUserData.fetching,
      count: wordInUser.filter(item => !item.word?.isVocabulary).length,
    },
  ] as const

  const renderStat = (stat: (typeof stats)[number]) => (
    <View
      key={stat.label}
      style={tw.style(
        'rounded-2xl gap-1',
        !stat.loading && 'bg-white px-4 py-6',
        { width: containerWidth / 2 - 6, height: 106 },
      )}
    >
      {stat.loading ? (
        <Skeleton style={tw`rounded-2xl w-full h-full`} />
      ) : (
        <Fragment>
          <View style={tw`flex-row gap-2.5 items-center`}>
            <SystemIcon
              name={stat.icon}
              type='SVG'
              color={tw.color(stat.color)}
              size={24}
            />
            <Text specialType='Headline3' color={tw.color(stat.color)}>
              {stat.count}
            </Text>
          </View>
          <Text specialType='paragraph2' style={tw`mt-1`}>
            {stat.label}
          </Text>
        </Fragment>
      )}
    </View>
  )

  return (
    <View
      style={tw`mt-8 flex-col gap-3`}
      onLayout={e => setContainerWidth(e.nativeEvent.layout.width)}
    >
      <Text specialType='Headline4'>Overview</Text>
      <View style={tw`flex-row flex-wrap gap-3`}>{stats.map(renderStat)}</View>
      <View style={tw`w-full flex-col gap-3`}>
        <TouchableOpacity
          activeOpacity={0.6}
          style={tw`flex-1 flex-row items-center w-full bg-white rounded-2xl px-4 py-3 gap-2.5`}
          onPress={() => navigate('App', { screen: 'StreakRecord' })}
        >
          <View
            style={tw`rounded-full bg-background-light-2 p-3 w-14 h-14 justify-center items-center`}
          >
            <Image
              source={images.image_streak}
              style={tw`w-7 h-7`}
              resizeMode='contain'
            />
          </View>
          <Text specialType='paragraph1' style={tw`flex-1 text-lg`}>
            Streak record
          </Text>
          <SystemIcon
            name='ArrowRight2'
            type='SAX'
            color={tw.color('neutral-300')}
          />
        </TouchableOpacity>
        <TouchableOpacity
          activeOpacity={0.6}
          style={tw`flex-1 flex-row items-center w-full bg-white rounded-2xl px-4 py-3 gap-2.5`}
          onPress={() =>
            navigate('App', {
              screen: 'SuggestedSpeak',
              params: { topicId: '' },
            })
          }
        >
          <View
            style={tw`rounded-full bg-background-light-2 p-3 w-14 h-14 justify-center items-center`}
          >
            <SystemIcon
              name='BrifecaseTick'
              type='SAX'
              size={26}
              variant='Bold'
              color={tw.color('secondary2-400')}
            />
          </View>
          <Text specialType='paragraph1' style={tw`flex-1 text-lg`}>
            Mastered words & phrases
          </Text>
          <SystemIcon
            name='ArrowRight2'
            type='SAX'
            color={tw.color('neutral-300')}
          />
        </TouchableOpacity>
      </View>
    </View>
  )
})
