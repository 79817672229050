import { useEffect, useRef } from 'react'
import { Animated, View } from 'react-native'

import { SystemIcon } from '#components/base/SystemIcon'
import { tw } from '#components/utils/tw'

export const BreathingButton = () => {
  const outerScaleAnim = useRef(new Animated.Value(1)).current
  const outerOpacityAnim = useRef(new Animated.Value(0.3)).current

  const innerScaleAnim = useRef(new Animated.Value(1)).current
  const innerOpacityAnim = useRef(new Animated.Value(0.3)).current

  useEffect(() => {
    const startOuterAnimation = () => {
      Animated.loop(
        Animated.sequence([
          Animated.timing(outerScaleAnim, {
            toValue: 1.2,
            duration: 500,
            useNativeDriver: true,
          }),
          Animated.timing(outerOpacityAnim, {
            toValue: 0,
            duration: 250,
            useNativeDriver: true,
          }),
          Animated.timing(outerScaleAnim, {
            toValue: 1,
            duration: 0,
            useNativeDriver: true,
          }),
          Animated.timing(outerOpacityAnim, {
            toValue: 0.4,
            duration: 0,
            useNativeDriver: true,
          }),
          Animated.delay(750),
        ]),
      ).start()
    }

    const startInnerAnimation = () => {
      Animated.loop(
        Animated.sequence([
          Animated.timing(innerScaleAnim, {
            toValue: 1.2,
            duration: 1000,
            useNativeDriver: true,
          }),
          Animated.timing(innerOpacityAnim, {
            toValue: 0,
            duration: 500,
            useNativeDriver: true,
          }),
        ]),
      ).start()
    }

    startOuterAnimation()
    startInnerAnimation()
  }, [outerScaleAnim, outerOpacityAnim, innerScaleAnim, innerOpacityAnim])

  return (
    <View style={tw.style('items-center justify-center flex-1')}>
      <Animated.View
        style={{
          transform: [{ scale: outerScaleAnim }],
          opacity: outerOpacityAnim,
          position: 'absolute',
          borderColor: tw.color('primary-400'),
          borderWidth: 12,
          borderRadius: 100,
          width: 130,
          height: 80,
        }}
      />

      <Animated.View
        style={{
          transform: [{ scale: innerScaleAnim }],
          opacity: innerOpacityAnim,
          position: 'absolute',
          borderColor: tw.color('primary-400'),
          borderWidth: 12,
          borderRadius: 100,
          width: 130,
          height: 80,
        }}
      />

      {/* Button chính */}

      <View
        style={tw.style(
          'py-5 px-12 bg-primary-400 rounded-full overflow-hidden',
        )}
      >
        <SystemIcon type='SAX' size={40} name='ArrowUp' color={'white'} />
      </View>
    </View>
  )
}
