import { Fragment } from 'react'
import { StyleSheet } from 'react-native'
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated'

import { tw } from '#components/utils/tw'

import { Actionsheet, ActionsheetContent } from './Actionsheet'
import type { OverlayContext } from './OverlayProvider'

type ActionsheetRootProps = {
  ctx: OverlayContext
}
export const ActionsheetRoot = ({
  ctx,
  ctx: { openings, closeActionsheet, androidFixActionsheet },
}: ActionsheetRootProps) => {
  const actionsheets = openings.filter(o => o.type === 'actionsheet')

  return (
    <Fragment>
      {/* fix bug android actionsheet */}
      {/* https://github.com/GeekyAnts/NativeBase/issues/4886 */}
      {!!androidFixActionsheet && (
        <Animated.View
          entering={FadeIn}
          exiting={FadeOut}
          style={tw.style('bg-black/50', StyleSheet.absoluteFillObject)}
        />
      )}
      {actionsheets.map((a, i) => {
        const close = () => {
          closeActionsheet(a.id)
          return a.containerProps?.onClose?.()
        }
        return (
          <Actionsheet
            key={a.id}
            isOpen={i === actionsheets.length - 1}
            {...a.containerProps}
            onClose={close}
            ctx={ctx}
          >
            <ActionsheetContent>
              <a.component
                {...a.props}
                actionsheetId={a.id}
                closeActionsheet={close}
              />
            </ActionsheetContent>
          </Actionsheet>
        )
      })}
    </Fragment>
  )
}
