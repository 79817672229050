import { View } from 'react-native'

import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

export const CannotHear = () => (
  <View
    style={tw.style(
      'px-4 py-3 mx-4 max-w-[80%] mt-2',
      'self-end border-dashed border-2 rounded-l-3xl rounded-t-3xl border-gray-400',
    )}
  >
    <Text specialType='paragraph1' color={tw.color('text-gray-400')}>
      Sorry, we can't hear you. Please try again.
    </Text>
  </View>
)
