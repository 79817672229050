import { useState } from 'react'

import { toastError } from '#admin/components/utils/Toast'
import { AnswersList } from '#admin/components/widgets/Answer/AnswersList'
import { useOverlay } from '#components/overlay/hooks'
import type { AnswerInQuestion } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import type { InputQuestion } from '#types/listenGame'

import { AnswerItem } from './AnswerItem'
import { AnswerModal } from './AnswerModal'

type Props = {
  disabled?: boolean
  question: InputQuestion
  answers: AnswerInQuestion[]
  handleAddAnswers: () => void
  handleAddAnswer: (answer: AnswerInQuestion) => void
  onChangeAnswer: (answer: AnswerInQuestion, index: number) => void
  handleDeleteAnswer: (index: number) => void
  onChangeCorrectAnswer: (index: number) => void
}

export const AnswersGame = ({
  question,
  answers,
  handleAddAnswers,
  handleAddAnswer,
  onChangeAnswer,
  handleDeleteAnswer,
  onChangeCorrectAnswer,
  disabled,
}: Props) => {
  const { openModal } = useOverlay()

  const [loading, setLoading] = useState<boolean>(false)

  const addAnswer = () => {
    openModal(AnswerModal, {
      question,
      onChangeSuccess: handleAddAnswer,
    })
  }

  const updateAnswer = async (item: AnswerInQuestion, index: number) => {
    try {
      setLoading(true)
      const resp = await gql.updateAnswerInQuestion({
        id: item.id,
        data: { isTrue: true, questionId: item.questionId },
      })
      if (resp.error) {
        toastError({ message: 'Change answer correct failed' })
        return
      }
      onChangeCorrectAnswer(index)
    } catch (error) {
      toastError({ message: 'Change answer correct failed' })
    } finally {
      setLoading(false)
    }
  }

  const onChangeRadio = async (item: AnswerInQuestion, index: number) => {
    if (question.id) {
      updateAnswer(item, index)
    } else {
      onChangeCorrectAnswer(index)
    }
  }

  return (
    <AnswersList
      handleAdd={
        question.type === 'yesNo' && !answers.length
          ? handleAddAnswers
          : addAnswer
      }
      hideButton={question.type === 'yesNo' && answers.length > 0}
      isHiddenAudio
      loading={disabled || loading}
    >
      {answers.map((answer, index) => (
        <AnswerItem
          key={`${index}-${answer.id}`}
          index={index}
          item={answer}
          question={question}
          handleDelete={handleDeleteAnswer}
          handleUpdate={onChangeAnswer}
          onChangeRadio={onChangeRadio}
          loading={loading}
          hideActionDelete={answers.length < 3}
        />
      ))}
    </AnswersList>
  )
}
