import moment from 'moment'
import type { FC } from 'react'
import React, { useState } from 'react'
import { TouchableOpacity, View } from 'react-native'

import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { gql } from '#graphql/urql'
import type { MessageItem } from '#types/message'

import { CannotHear } from './CannotHear'
import { LoadingDots } from './LoadingDots'

type MessageProps = {
  item: MessageItem
  isLoading: boolean
  onTTSPress: (item: MessageItem) => void
  isPlaying: boolean
  isItemPlaying?: boolean
}

type MessageType = 'USER' | 'SYSTEM'

export const Message: FC<MessageProps> = React.memo(
  ({ item, isLoading, onTTSPress, isPlaying, isItemPlaying }) => {
    const [isTranslate, setIsTranslate] = useState<boolean>(false)
    const [translateContent, setTranslateContent] = useState<string>('')
    const type: MessageType = item.sentByUser ? 'USER' : 'SYSTEM'
    const message = item.content
    const canHear = item.canHear ?? true
    const date = moment(item.createdAt).format()
    const previousMessageType = item.previousMessage?.sentByUser
      ? 'USER'
      : 'SYSTEM'
    const previousMessageDate = item.previousMessage
      ? moment(item.previousMessage?.createdAt).format()
      : null
    const isSameDate = moment(date).isSame(previousMessageDate, 'day')

    const translateMessage = async (content: string) => {
      try {
        const res = await gql.translateMessage(
          { content },
          { requestPolicy: 'network-only' },
        )
        if (res.data?.translateMessage) {
          setTranslateContent(res.data?.translateMessage)
        }
      } catch (error) {
        console.error('Error fetching translation:', error)
      }
    }

    const handleTranslatePress = () => {
      if (type === 'SYSTEM') {
        if (!isTranslate) {
          translateMessage(message)
        }
        setIsTranslate(!isTranslate)
      }
    }

    const renderFixedTextWithHighlights = () => {
      if (!item.fixedText) {
        return null
      }

      const regex = /<mark>(.*?)<\/mark>/g
      const parts = item.fixedText.split(regex)

      return (
        <View
          style={tw`flex rounded-2xl mx-4 mt-3 bg-secondary1-50 self-end justify-end items-end border border-secondary1-500 max-w-[80%]`}
        >
          <View style={tw`px-4 py-2.5`}>
            <Text specialType='paragraph2'>
              {parts.map((part, index) => {
                const isHighlighted = index % 2 === 1
                return (
                  <Text
                    key={index}
                    specialType={isHighlighted ? 'paragraph2M' : 'paragraph2'}
                    color={
                      isHighlighted
                        ? tw.color('secondary1-500')
                        : tw.color('text-1')
                    }
                  >
                    {part}
                  </Text>
                )
              })}
            </Text>
          </View>
        </View>
      )
    }

    return (
      <View key={item.id}>
        {!item.taskPassedString ? (
          !isLoading ? (
            <>
              {!isSameDate && (
                <View style={tw`px-4 pt-3 justify-center items-center`}>
                  <Text
                    specialType='Note'
                    color={tw.color('text-2')}
                    textAlign='center'
                  >
                    {moment().isSame(date, 'year')
                      ? moment(date).format('dddd, Do MMMM')
                      : moment(date).format('Do MMMM, YYYY')}
                  </Text>
                </View>
              )}
              <View style={tw.style('flex-col')}>
                {canHear && (
                  <View
                    style={tw.style(
                      'px-4 py-3 mx-4 max-w-[80%]',
                      type === 'USER'
                        ? 'bg-primary-400 self-end rounded-l-3xl rounded-t-3xl'
                        : 'bg-white self-start rounded-r-3xl rounded-b-3xl',
                      previousMessageType === type ? 'mt-2' : 'mt-3',
                    )}
                  >
                    <Text
                      id={`selectable-text-${item.id}`}
                      specialType='paragraph1'
                      color={tw.color(type === 'USER' ? 'text-4' : 'text-1')}
                    >
                      {message}
                    </Text>

                    {type === 'SYSTEM' &&
                      isTranslate &&
                      translateContent !== '' && (
                        <View
                          style={tw`flex-col border-t border-neutral-200 mt-2 pt-2`}
                        >
                          <Text
                            specialType='paragraph1'
                            color={tw.color('text-2')}
                          >
                            {translateContent}
                          </Text>
                        </View>
                      )}

                    <View
                      style={tw.style(
                        'flex-row items-center mt-1',
                        type === 'SYSTEM' ? 'justify-between' : 'justify-end',
                      )}
                    >
                      {type === 'USER' && (
                        <Text
                          color={tw.color('text-4')}
                          specialType='smalltext'
                        >
                          {moment(date).format('LT')}
                        </Text>
                      )}
                      <View style={tw`flex-row items-center`}>
                        {type === 'SYSTEM' && (
                          <>
                            <TouchableOpacity
                              style={tw`self-center p-1.5`}
                              onPress={() => onTTSPress(item)}
                            >
                              <SystemIcon
                                type='SAX'
                                name='VolumeHigh'
                                color={
                                  isPlaying && isItemPlaying
                                    ? tw.color('primary-400')
                                    : tw.color('icon')
                                }
                                variant={
                                  isPlaying && isItemPlaying ? 'Bold' : 'Linear'
                                }
                              />
                            </TouchableOpacity>
                            <TouchableOpacity
                              style={tw`self-center p-1.5`}
                              onPress={handleTranslatePress}
                            >
                              <SystemIcon
                                type='SAX'
                                name='Translate'
                                color={
                                  isTranslate
                                    ? tw.color('primary-400')
                                    : tw.color('icon')
                                }
                              />
                            </TouchableOpacity>
                          </>
                        )}
                      </View>
                      {type === 'SYSTEM' && (
                        <Text
                          color={tw.color('text-2')}
                          specialType='smalltext'
                        >
                          {moment(date).format('LT')}
                        </Text>
                      )}
                    </View>
                  </View>
                )}

                {!canHear && <CannotHear />}

                {renderFixedTextWithHighlights()}
              </View>
            </>
          ) : (
            <View
              style={tw.style(
                'px-4 py-3 mx-4 max-w-[80%]',
                type === 'USER'
                  ? 'bg-primary-400 self-end rounded-l-3xl rounded-t-3xl'
                  : 'bg-white self-start rounded-r-3xl rounded-b-3xl',
                previousMessageType === type ? 'mt-2' : 'mt-3',
              )}
            >
              <LoadingDots />
            </View>
          )
        ) : (
          <View style={tw.style('flex-row items-center justify-center my-4')}>
            <View style={tw.style('h-0.5 w-1/3 bg-gray-300')} />

            <View style={tw.style('flex-row items-center mx-2')}>
              <Text style={tw.style('text-2xl')}>🎉</Text>
              <Text
                specialType='paragraph2'
                color={tw.color('text-2')}
                style={tw.style('flex-shrink max-w-xs')}
              >
                {item.taskPassedString}
              </Text>
            </View>

            <View style={tw.style('h-0.5 w-1/3 bg-gray-300')} />
          </View>
        )}
      </View>
    )
  },
)
