import { toastError } from '#admin/components/utils/Toast'
import type { UploadFileProps } from '#types/media'

export const checkImage = (file: UploadFileProps): boolean => {
  const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg']
  const isImage = !!(
    file?.originFileObj?.type &&
    allowedTypes.includes(file?.originFileObj?.type)
  )
  if (!isImage) {
    toastError({ title: 'Error', message: 'You can only upload JPG/PNG file!' })
  }
  return isImage
}
