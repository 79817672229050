import type { FC } from 'react'
import { View } from 'react-native'

import { Button } from '#components/base/Button/Button'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { Header } from '#components/widgets/Header'

type Props = { onPress: () => void }

export const StartReview: FC<Props> = ({ onPress }) => (
  <View style={tw`flex-1 flex-col`}>
    <Header />
    <View style={tw`flex-1 flex-col justify-center items-center p-4`}>
      <View
        style={tw`flex-col justify-center items-center px-4 py-6 bg-background-light-white w-full rounded-2xl`}
      >
        <SystemIcon type='SVG' name='vocab-phrase-review' size={280} />
        <View style={tw`mt-6 mb-7`}>
          <Text
            specialType='Headline2'
            color={tw.color('primary-400')}
            textAlign='center'
          >
            {'Let’s review what \nyou learned'}
          </Text>
        </View>
        <Text
          specialType='paragraph1'
          color={tw.color('text-2')}
          textAlign='center'
        >
          {'Before we begin, let’s do a quick \nreview of previous lessons'}
        </Text>
      </View>
    </View>
    <View style={tw`p-4`}>
      <Button onPress={onPress}>Start review</Button>
    </View>
  </View>
)
