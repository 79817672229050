import NetInfo, { useNetInfo } from '@react-native-community/netinfo'
import { AppState } from 'react-native'

import { fetchWithTimeout } from '##/shared/fetchWithTimeout'
import type { UPromise } from '##/shared/ts'
import { rnReachabilityUrl } from '#config'

NetInfo.configure({
  reachabilityUrl: rnReachabilityUrl,
  reachabilityShouldRun: () => AppState.currentState === 'active',
  useNativeReachability: false,
  shouldFetchWiFiSSID: false,
})

const fetchReachable = () =>
  fetchWithTimeout(rnReachabilityUrl, { method: 'HEAD' })
    .then(r => r.status === 204)
    .catch(() => false)

let promise: UPromise
export const initNetInfo = () => {
  if (!promise) {
    promise = Promise.all([NetInfo.fetch(), fetchReachable()])
  }
  return promise
}
export const useInternetConnected = () => {
  const s = useNetInfo()
  return s.isConnected === null || s.isConnected
}
export const useInternetReachable = () => {
  const s = useNetInfo()
  return s.isInternetReachable === null || s.isInternetReachable
}
