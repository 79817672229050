import type { ForwardedRef } from 'react'
import { forwardRef, useImperativeHandle, useState } from 'react'

import { InputPayment } from './InputPayment'

export const CardInforInput = forwardRef(
  (
    _,
    ref: ForwardedRef<{
      getName: () => string
      setName: (n: string) => void
    }>,
  ) => {
    const [name, setName] = useState('')

    useImperativeHandle(ref, () => ({
      getName: () => name,
      setName: n => setName(n),
    }))

    return (
      <InputPayment
        value={name}
        name='Name of card'
        onChangeText={t => {
          setName(t)
        }}
        placeholder='Name on card'
      />
    )
  },
)
