import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { View } from 'react-native'

import { Avatar } from '#components/base/Avatar'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { S } from '#store'
import { getLevelDescription } from '#types/levelTest'

export const SectionProfile = observer(() => {
  const [textWidth, setTextWidth] = useState(0)

  const { color, label } = getLevelDescription(
    S.shared.currentUser?.level?.current,
  )

  return (
    <View style={tw`relative flex-col items-center justify-center`}>
      <View style={tw`rounded-full border-[3px] border-${color}`}>
        <View style={tw`bg-white p-1 rounded-full`}>
          <Avatar
            name={S.shared.currentUser?.name}
            style={tw`w-25 h-25`}
            _text={{ fontSize: 32 }}
            source={{ uri: S.shared.currentUser?.thumbnail?.url }}
          />
        </View>
        <View
          onLayout={e => setTextWidth(e.nativeEvent.layout.width)}
          style={tw.style(
            'absolute -bottom-1.5 px-2 py-1 rounded-full left-1/2',
            {
              opacity: !textWidth ? 0 : 1,
              transform: [{ translateX: -(textWidth / 2) }],
              backgroundColor: tw.color(color),
            },
          )}
        >
          <Text
            numberOfLines={1}
            specialType='smalltext'
            style={tw`text-white`}
          >
            {label}
          </Text>
        </View>
      </View>
      <View style={tw`mt-3 flex-col gap-0.5 justify-center items-center`}>
        <Text specialType='Headline4'>{S.shared.currentUser?.name}</Text>
        <Text specialType='Note' style={tw`text-gray-500`}>
          {S.shared.currentUser?.email}
        </Text>
      </View>
    </View>
  )
})
