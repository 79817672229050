import { Modal } from 'native-base'

import type { OverlayContext } from './OverlayProvider'

export type ModalRootProps = {
  ctx: OverlayContext
}
export const ModalRoot = ({
  ctx: { openings, closeModal },
}: ModalRootProps) => {
  const modals = openings.filter(o => o.type === 'modal')

  return (
    <>
      {modals.map((m, i) => {
        const close = () => {
          closeModal(m.id)
          return m.containerProps?.onClose?.()
        }
        return (
          <Modal
            key={m.id}
            isOpen={i === modals.length - 1}
            {...m.containerProps}
            onClose={close}
          >
            <m.component {...m.props} modalId={m.id} closeModal={close} />
          </Modal>
        )
      })}
    </>
  )
}
