import type { FC } from 'react'
import { View } from 'react-native'

import { ScrollView } from '#components/base/ScrollView'
import { tw } from '#components/utils/tw'
import { Header } from '#components/widgets/Header'

type Props = {
  children: React.ReactNode
  isHeader?: boolean
}

export const BackgroundAuth: FC<Props> = ({ children, isHeader }) => (
  <View style={tw`bg-background-light-1 flex-1 flex-col`}>
    {isHeader && <Header />}
    <ScrollView contentContainerStyle={tw`px-4 pb-4`}>{children}</ScrollView>
  </View>
)
