import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Flex, Typography } from 'antd'
import type { ColumnType } from 'antd/es/table'
import { useEffect, useState } from 'react'

import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { PaginatedTable } from '#admin/components/widgets/PaginatedTable'
import { SearchButton } from '#admin/components/widgets/SearchButton'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import { gql } from '#graphql/urql'
import type { TaskItem } from '#types/chat'
import type { SearchTopicItem } from '#types/topic'

import { InfoTopic } from './InfoTopic'
import { TaskSpeaking } from './TaskSpeaking'

type Props = { id: string }

export const Speaking = ({ id }: Props) => {
  const { openModal } = useOverlay()

  const [topic, setTopic] = useState<SearchTopicItem | null>(null)
  const [fetching, setFetching] = useState<boolean>(true)
  const [data, setData] = useState<TaskItem[]>([])
  const [pageSize, setPageSize] = useState<number>(10)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchKeyword, setSearchKeyword] = useState<string>('')

  useEffect(() => {
    fetchData()
  }, [id])

  const fetchData = async () => {
    setFetching(true)
    const resp = await gql.searchTopic({ filter: { id } })
    if (resp.data?.searchTopic && resp.data?.searchTopic.length > 0) {
      const t = resp.data?.searchTopic[0]
      setTopic(t)
      setData(t.task?.data || [])
    }
    setFetching(false)
  }

  const onChangeDataTask = (tasks: TaskItem[]) => {
    setData(tasks)
  }

  const handleAdd = () => {
    openModal(TaskSpeaking, {
      onChangeData: onChangeDataTask,
      topic,
      data,
      containerProps: { closeOnOverlayClick: false },
    })
  }

  const handleEditData = (task: TaskItem) => {
    openModal(TaskSpeaking, {
      onChangeData: onChangeDataTask,
      topic,
      data,
      item: task,
      containerProps: { closeOnOverlayClick: false },
    })
  }

  const onChangeData = (params: Partial<SearchTopicItem>) => {
    setTopic(pre => (pre?.id ? { ...pre, ...params } : pre))
  }

  const handleEditInfo = () => {
    if (topic?.id) {
      openModal(InfoTopic, {
        onChangeData,
        topic,
        containerProps: { closeOnOverlayClick: false },
      })
    }
  }

  const onDelete = async (task: TaskItem) => {
    const params = { task: { data: data.filter(i => i.id !== task.id) } }
    const resp = await gql.updateTopic({
      id,
      data: params,
    })
    if (resp.error || !resp.data) {
      toastError({ title: 'Error', message: resp.error?.message })
      return
    }
    toastSuccess({ message: 'Successfully deleted task speaking' })
    setData(s => s.filter(i => i.id !== task.id))
  }

  const actionsColumn = {
    title: 'Actions',
    key: 'actions',
    width: 100,
    render: (text, record) => (
      <Flex style={tw`justify-between`}>
        <Button
          icon={<EditOutlined />}
          onClick={e => {
            e.stopPropagation()
            handleEditData(record)
          }}
          className='p-0 bg-transparent'
        />
        <Button
          icon={<DeleteOutlined />}
          onClick={e => {
            e.stopPropagation()
            openModal(ModalLayout, {
              onConfirm: async () => onDelete(record),
              selectedAi: record,
              title: 'Confirm delete',
              content: 'Are you sure you want to delete this tasks?',
              containerProps: {
                closeOnOverlayClick: false,
              },
            })
          }}
          className='p-0 bg-transparent'
          danger={true}
        />
      </Flex>
    ),
  }

  const columns: ColumnType<TaskItem>[] = [
    {
      title: 'Task',
      dataIndex: 'text',
      key: 'text',
      render: (_, record) => <Typography>{record.name}</Typography>,
    },
    {
      title: 'Sub task',
      dataIndex: 'text',
      key: 'text',
      render: (_, record) => (
        <Flex vertical gap={4}>
          {record.subTask.map(sub => (
            <Typography>{sub.name}</Typography>
          ))}
        </Flex>
      ),
    },
    actionsColumn,
  ]

  return (
    <div style={{ overflowY: 'scroll', height: '72vh' }}>
      <Flex style={tw.style('flex-1 flex-col px-4 pb-4 h-full')} gap={16}>
        {topic && (
          <Flex vertical style={tw`w-full bg-white rounded-2xl p-4`}>
            <Flex style={tw`items-center`}>
              <Typography.Title level={5} style={tw`m-0 flex-1`}>
                Info
              </Typography.Title>
              <Button
                icon={<EditOutlined />}
                onClick={handleEditInfo}
                className='p-0 bg-transparent'
              />
            </Flex>
            <Flex vertical style={tw`w-full`} gap={8}>
              <Flex gap={8}>
                <Typography>Scenario:</Typography>
                <Typography style={tw`font-bold flex-1`}>
                  {topic.scenario}
                </Typography>
              </Flex>
              <Flex style={tw`items-center`}>
                <Flex style={tw`w-1/2`} gap={8}>
                  <Typography>AI tutor role:</Typography>
                  <Typography style={tw`font-bold flex-1`}>
                    {topic.aiTutorRole}
                  </Typography>
                </Flex>
                <Flex style={tw`w-1/2`} gap={8}>
                  <Typography>User role:</Typography>
                  <Typography style={tw`font-bold flex-1`}>
                    {topic.userRole}
                  </Typography>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        )}
        <Flex vertical style={tw`w-full bg-white rounded-2xl p-4`}>
          <SearchButton
            title='Add'
            handleAddData={handleAdd}
            keyword={(k: string) => {
              setSearchKeyword(k)
            }}
          />
          <PaginatedTable<TaskItem>
            dataSource={
              !searchKeyword
                ? data
                : data.filter(item =>
                    item.name
                      .toLowerCase()
                      .includes(searchKeyword.toLowerCase()),
                  )
            }
            columns={columns}
            loading={fetching}
            total={
              !searchKeyword
                ? data.length
                : data.filter(item =>
                    item.name
                      .toLowerCase()
                      .includes(searchKeyword.toLowerCase()),
                  ).length
            }
            currentPage={currentPage}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
          />
        </Flex>
      </Flex>
    </div>
  )
}
