import { useContext } from 'react'
import { useColorScheme } from 'react-native'

import { tw } from '#components/utils/tw'

import { DarkModeContext, SetDarkModeContext } from './DarkModeProvider'

export const useIsDark = () => {
  let colorScheme = useColorScheme() || 'light'
  const appColorScheme = useContext(DarkModeContext)
  if (appColorScheme !== 'device') {
    colorScheme = appColorScheme
  }
  return colorScheme === 'dark'
}
export const useSetDarkMode = () => useContext(SetDarkModeContext)

export const useDarkBg = () => {
  const isDark = useIsDark()
  return `bg-${isDark ? 'background-dark-1' : 'background-light-1'}`
}
export const useDarkText = () => {
  const isDark = useIsDark()
  return `text-body-${isDark ? 'dark' : 'light'}`
}

export const useDarkColor = () => {
  const isDark = useIsDark()
  return (...args: [dark: string, light: string]) =>
    tw.color(isDark ? args[0] : args[1]) as string
}
export const useDarkBgColor = () => useDarkColor()('gray-900', 'white')
