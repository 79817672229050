import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import { Animated, TouchableOpacity, View } from 'react-native'

import { IconSax } from '#components/base/IconSax'
import { IconSvg } from '#components/base/IconSvg'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { S } from '#store'

export const Tips = observer(() => {
  const opacityValue = useRef<Animated.Value>(new Animated.Value(0)).current

  useEffect(() => {
    fadeIn()
  }, [])

  const fadeIn = () => {
    Animated.timing(opacityValue, {
      toValue: 0.8,
      duration: 500,
      useNativeDriver: true,
    }).start()
  }

  const fadeOut = () => {
    Animated.timing(opacityValue, {
      toValue: 0,
      duration: 500,
      useNativeDriver: true,
    }).start(() => {
      S.shared.isTipsLearned = true
    })
  }

  return (
    <View style={tw`absolute top-0 left-0 right-0 bottom-0`}>
      <TouchableOpacity onPress={fadeOut} style={tw`flex-1`}>
        <Animated.View
          style={[
            tw`flex-1 flex-col bg-black items-center justify-center gap-14`,
            { opacity: opacityValue },
          ]}
        >
          <View style={tw`items-center justify-center w-44`}>
            <View style={tw`items-center justify-center mb-4`}>
              <IconSax name='ArrowRight' color={tw.color('text-4')} size={51} />
              <IconSvg name='hand-swipe' color={tw.color('text-4')} size={70} />
            </View>
            <Text
              textAlign='center'
              color={tw.color('text-4')}
              specialType='Title'
            >
              Swipe right to learn new word
            </Text>
          </View>
          <View style={tw`items-center justify-center w-44`}>
            <View style={tw`items-center justify-center mb-4`}>
              <IconSax name='ArrowLeft' color={tw.color('text-4')} size={51} />
              <IconSvg name='hand-swipe' color={tw.color('text-4')} size={70} />
            </View>
            <Text
              textAlign='center'
              color={tw.color('text-4')}
              specialType='Title'
            >
              Swipe left to skip known words
            </Text>
          </View>
          <View style={tw`items-center justify-center w-44 pt-21`}>
            <Text
              textAlign='center'
              color={tw.color('text-4')}
              specialType='Note'
            >
              Tap anywhere to close
            </Text>
          </View>
        </Animated.View>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={fadeOut}
        style={tw`absolute top-0 left-0 right-0 bottom-0 flex-1`}
      />
    </View>
  )
})
