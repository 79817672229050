import type { IActionsheetProps, IModalProps } from 'native-base'
import type { ComponentProps, ElementType, PropsWithChildren } from 'react'
import { createContext, useState } from 'react'
import { Platform } from 'react-native'
import { ulid } from 'ulidx'

import type { RequiredKeys } from '##/shared/ts'

import { ActionsheetRoot } from './ActionsheetRoot'
import { ModalRoot } from './ModalRoot'

export type OverlayData<T extends ElementType = any> = {
  type: 'modal' | 'actionsheet'
  id: string
  component: T
  props: ComponentProps<T>
  containerProps?: any
}

export type OverlayContext = {
  openings: OverlayData[]

  openModal: <
    T extends ElementType,
    P = Omit<ComponentProps<T>, 'modalId' | 'closeModal'> & {
      containerProps?: IModalProps
    },
  >(
    component: T,
    ...props: RequiredKeys<P> extends never ? [P?] : [P]
  ) => string

  closeModal: (id: string) => void
  closeAllModals: () => void

  openActionsheet: <
    T extends ElementType,
    P = Omit<ComponentProps<T>, 'actionsheetId' | 'closeActionsheet'> & {
      containerProps?: IActionsheetProps
    },
  >(
    component: T,
    ...props: RequiredKeys<P> extends never ? [P?] : [P]
  ) => string

  closeActionsheet: (id: string) => void
  closeAllActionsheets: () => void

  androidFixActionsheetOpen: () => void
  androidFixActionsheetClose: () => void
  androidFixActionsheet: number
}

export const OverlayContext = createContext<OverlayContext | undefined>(
  undefined,
)

export const OverlayProvider = ({ children }: PropsWithChildren) => {
  const [openings, setOpenings] = useState<OverlayData[]>([])
  const openModal = (
    type: OverlayData['type'],
    component: ElementType,
    props: any = {},
  ) => {
    const { containerProps, ...componentProps } = props
    const m = {
      id: ulid(),
      type,
      component,
      props: componentProps,
      containerProps,
    }
    setOpenings(o => [...o, m])
    return m.id
  }
  const closeModal = (id: string) => {
    setOpenings(o => o.filter(m => m.id !== id))
  }
  const closeAllModals = () => {
    setOpenings(o => o.filter(m => m.type !== 'modal'))
  }
  const closeAllActionsheets = () => {
    setOpenings(o => o.filter(m => m.type !== 'actionsheet'))
  }

  // fix bug android actionsheet
  // https://github.com/GeekyAnts/NativeBase/issues/4886
  const [androidFixActionsheet, setAndroidFixActionsheet] = useState(0)
  const androidFixActionsheetOpen = () => {
    if (Platform.OS !== 'android') {
      return
    }
    setAndroidFixActionsheet(n => n + 1)
  }
  const androidFixActionsheetClose = () => {
    if (Platform.OS !== 'android') {
      return
    }
    setAndroidFixActionsheet(n => n - 1)
  }

  const ctx: OverlayContext = {
    openings,
    openModal: (c, ...[p]) => openModal('modal', c, p),
    closeModal,
    closeAllModals,
    openActionsheet: (c, ...[p]) => openModal('actionsheet', c, p),
    closeActionsheet: closeModal,
    closeAllActionsheets,
    androidFixActionsheet,
    androidFixActionsheetOpen,
    androidFixActionsheetClose,
  }

  return (
    <OverlayContext.Provider value={ctx}>
      {children}
      <ModalRoot ctx={ctx} />
      <ActionsheetRoot ctx={ctx} />
    </OverlayContext.Provider>
  )
}
