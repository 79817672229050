import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Flex, Image } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useState } from 'react'

import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { PaginatedTable } from '#admin/components/widgets/PaginatedTable'
import { SearchButton } from '#admin/components/widgets/SearchButton'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import { useCountSplash, useSearchSplash } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import type { SearchSplashItem } from '#types/splash'

import { SplashModal } from './SplashModal'

export const ImageContentTab = () => {
  const { openModal } = useOverlay()

  const [searchKeyword, setSearchKeyword] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState(10)

  const [splash, refetch] = useSearchSplash({
    variables: {
      filter: searchKeyword
        ? {
            OR: [
              {
                title_iLike: `%${searchKeyword}%`,
              },
              { subTitle_iLike: `%${searchKeyword}%` },
            ],
          }
        : {},
      order: ['createdAt_asc'],
      page: { limit: pageSize, offset: (currentPage - 1) * pageSize },
    },
  })

  const [count, refetchCount] = useCountSplash({
    variables: {
      filter: searchKeyword
        ? {
            OR: [
              {
                title_iLike: `%${searchKeyword}%`,
              },
              { subTitle_iLike: `%${searchKeyword}%` },
            ],
          }
        : {},
    },
  })

  const reload = () => {
    refetch({ requestPolicy: 'network-only' })
    refetchCount({ requestPolicy: 'network-only' })

    if (count.data?.countSplash) {
      const remainingItemsCount = count.data.countSplash - 1
      let newPageCount = Math.ceil(remainingItemsCount / pageSize)
      if (newPageCount < 1) {
        newPageCount = 1
      }
      if (currentPage > newPageCount) {
        setCurrentPage(newPageCount)
      }
    }
  }

  const handleAdd = () => {
    openModal(SplashModal, {
      containerProps: {
        closeOnOverlayClick: false,
      },
      onComplete: reload(),
      reload,
    })
  }

  const actionsColumn = {
    title: 'Actions',
    key: 'actions',
    width: 100,
    render: (text, record) => (
      <Flex style={tw`justify-between`}>
        <Button
          icon={<EditOutlined />}
          onClick={e => {
            e.stopPropagation()
            openModal(SplashModal, {
              item: record,
              containerProps: {
                closeOnOverlayClick: false,
              },
              reload,
            })
          }}
          className='p-0 bg-transparent'
        />
        {(count.data?.countSplash || 0) > 1 && (
          <Button
            icon={<DeleteOutlined />}
            onClick={e => {
              e.stopPropagation()
              openModal(ModalLayout, {
                onConfirm: async () => {
                  const res = await gql.deleteSplash({ id: record.id })
                  if (res.data) {
                    reload()
                    toastSuccess({ message: 'Delete successfully' })
                  } else {
                    toastError({ message: 'Delete failed' })
                  }
                },
                title: 'Confirm delete',
                content: 'Are you sure you want to delete?',
                containerProps: {
                  closeOnOverlayClick: false,
                },
              })
            }}
            className='p-0 bg-transparent'
            danger={true}
          />
        )}
      </Flex>
    ),
  }

  const columns: ColumnsType<SearchSplashItem> = [
    {
      title: 'Avatar',
      dataIndex: 'Avatar',
      width: 100,
      key: 'Avatar',
      render: (_, record) =>
        record?.thumbnail?.url ? (
          <Flex
            onClick={e => {
              e?.stopPropagation()
            }}
          >
            <Image
              src={record?.thumbnail?.url}
              style={tw.style('w-12 h-12 border-2 border-gray-300', {
                cursor: 'pointer',
              })}
              onClick={e => {
                e?.stopPropagation()
              }}
            />
          </Flex>
        ) : null,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Sub title',
      dataIndex: 'subTitle',
      key: 'subTitle',
    },
    actionsColumn,
  ]

  return (
    <Flex style={tw`p-4 px-[10%] flex-col text-[16px] w-full`}>
      <SearchButton
        title='Add'
        handleAddData={handleAdd}
        keyword={(k: string) => {
          setSearchKeyword(k)
          setCurrentPage(1)
        }}
      />
      <PaginatedTable<SearchSplashItem>
        dataSource={splash.data?.searchSplash || []}
        columns={columns}
        loading={splash.fetching}
        total={count.data?.countSplash || 0}
        currentPage={currentPage}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        scrollHeight={'60vh'}
      />
    </Flex>
  )
}
