import { useIsFocused } from '@react-navigation/native'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { TouchableOpacity, useWindowDimensions, View } from 'react-native'

import { images } from '#assets'
import { Button } from '#components/base/Button/Button'
import { Gif } from '#components/base/Gif/Gif'
import { LinearGradientColor } from '#components/base/LinearGradientColor/LinearGradientColor'
import { ScrollView } from '#components/base/ScrollView'
import { Text } from '#components/base/Text'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import { Header } from '#components/widgets/Header'
import { navigate, replace } from '#navigator/helpers'
import type { AppStackScreenProps } from '#navigator/types'
import { S } from '#store'
import { PercentageTopic } from '#types/games'

import { PlayMiniGame } from './PlayMiniGame'

export const ReviewListening = observer(
  ({ route }: AppStackScreenProps<'ReviewListening'>) => {
    const topicId = route.params.topicId
    const { height, width } = useWindowDimensions()
    const { openActionsheet } = useOverlay()
    const isFocus = useIsFocused()
    const { percentListening } =
      S.lessonPercentage.getLessonPercentageById(topicId)

    useEffect(() => {
      if (isFocus) {
        S.lessonPercentage.setLessonPercentageById({
          topicId,
          userId: S.shared.currentUser?.id || '',
          language: S.shared.currentUser?.languageLearn,
        })
      }
    }, [isFocus])

    const handlePlayMiniGame = async () => {
      openActionsheet(PlayMiniGame, {
        topicId,
      })
    }

    const onPressSpeak = () => {
      navigate('App', {
        screen: 'ScenarioSpeak',
        params: { topicId },
      })
    }

    const handlePress = () => {
      if (percentListening >= PercentageTopic.Listen) {
        handlePlayMiniGame()
      } else {
        navigate('App', {
          screen: 'ListenGame',
          params: { topicId },
        })
      }
    }

    return (
      <View style={tw`flex-1 flex-col bg-background-light-white`}>
        <ScrollView>
          <View
            style={tw.style('flex-col overflow-hidden', {
              height: height * 0.35 + 100,
            })}
          >
            <View
              style={tw.style(
                'flex-col overflow-hidden justify-end items-center',
                {
                  height: height * 0.35,
                },
              )}
            >
              <View
                style={tw.style('rounded-full overflow-hidden', {
                  height: width * 1.5,
                  width: width * 1.5,
                })}
              >
                <LinearGradientColor colorX='#3B89EB' colorY='#9F7BEA' />
              </View>
            </View>
            <View
              style={tw.style(
                'items-center justify-end absolute bottom-0 left-0 right-0 ',
              )}
            >
              <View style={tw.style('', { left: -20, bottom: -100 })}>
                <Gif
                  source={images.congratulationListen}
                  width={170}
                  height={170}
                />
              </View>
            </View>
          </View>
          <View style={tw`flex-col gap-2 mt-8`}>
            <Text specialType='Headline1' textAlign='center'>
              Great work !!!
            </Text>
            <Text specialType='Headline4' textAlign='center'>
              {percentListening >= PercentageTopic.Listen
                ? "Let's practice speaking. \nAre you Ready?"
                : "You're making progress! Let's try \nthe listening task again"}
            </Text>
            <View style={tw`mt-20 px-4 mb-4`}>
              {percentListening >= PercentageTopic.Listen && (
                <Button
                  style={tw`mb-5.5`}
                  tone='primary'
                  onPress={onPressSpeak}
                >
                  <Text style={tw`text-white`} specialType='Title'>
                    Speak with Tutor
                  </Text>
                </Button>
              )}
              <TouchableOpacity
                onPress={handlePress}
                style={tw`w-full justify-center items-center rounded-3xl border border-primary-400 h-14`}
              >
                <Text specialType='Title' color={tw.color('primary-400')}>
                  More Practice
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
        <View style={tw`absolute top-0 left-0 right-0`}>
          <Header
            onPressBack={() => {
              replace('Home', { screen: 'Topics' })
            }}
            isDark={false}
          />
        </View>
      </View>
    )
  },
)
