import React from 'react'
import type { AppStateStatus } from 'react-native'
import { AppState } from 'react-native'

export const useAppState = () => {
  const currentState = AppState.currentState
  const [appState, setAppState] = React.useState<AppStateStatus>(currentState)

  React.useEffect(() => {
    const subscription = AppState.addEventListener('change', onChange)

    return () => {
      subscription.remove()
    }
  }, [])

  const onChange = (newState: AppStateStatus) => {
    setAppState(newState)
  }

  return appState
}
