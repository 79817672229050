import { useEffect, useState } from 'react'
import { Animated, Dimensions, Easing, View } from 'react-native'

import { tw } from '#components/utils/tw'

export const Confetti = () => {
  const { width: screenWidth, height: screenHeight } = Dimensions.get('screen')

  const [confetti] = useState(
    Array.from({ length: 100 }).map(() => ({
      x: new Animated.Value(Math.random() < 0.5 ? -20 : screenWidth),
      y: new Animated.Value(screenHeight),
      opacity: new Animated.Value(1),
      scale: new Animated.Value(1),
      rotate: new Animated.Value(0),
      width: new Animated.Value(Math.random() * 10),
      height: new Animated.Value(Math.random() * 10),
      finalX: Math.random() * screenWidth,
      finalY: Math.random() * screenHeight,
      finalRotate: Math.random() * 360,
    })),
  )

  useEffect(() => {
    confetti.forEach(c => {
      Animated.sequence([
        Animated.parallel([
          Animated.timing(c.scale, {
            toValue: Math.random() * 1.5 + 0.5,
            duration: 500,
            useNativeDriver: true,
          }),
          Animated.timing(c.opacity, {
            toValue: 1,
            duration: 500,
            useNativeDriver: true,
          }),
          Animated.timing(c.x, {
            toValue: Math.max(0, Math.min(screenWidth, c.finalX)),
            duration: 500,
            easing: Easing.out(Easing.cubic),
            useNativeDriver: true,
          }),
          Animated.timing(c.y, {
            toValue: Math.max(0, Math.min(screenHeight, c.finalY)),
            duration: 500,
            easing: Easing.out(Easing.cubic),
            useNativeDriver: true,
          }),
          Animated.timing(c.rotate, {
            toValue: c.finalRotate,
            duration: 500,
            easing: Easing.out(Easing.cubic),
            useNativeDriver: true,
          }),
          Animated.timing(c.width, {
            toValue: Math.random() * 10,
            duration: 500,
            easing: Easing.out(Easing.cubic),
            useNativeDriver: false,
          }),
          Animated.timing(c.height, {
            toValue: Math.random() * 10,
            duration: 500,
            easing: Easing.out(Easing.cubic),
            useNativeDriver: false,
          }),
        ]),
        Animated.parallel([
          Animated.timing(c.x, {
            toValue: Math.max(
              0,
              Math.min(screenWidth, c.finalX + (Math.random() - 0.5) * 1000),
            ),
            duration: 2000 + Math.random() * 2000,
            easing: Easing.inOut(Easing.quad),
            useNativeDriver: true,
          }),
          Animated.timing(c.y, {
            toValue: screenHeight,
            duration: 2000 + Math.random() * 2000,
            easing: Easing.inOut(Easing.quad),
            useNativeDriver: true,
          }),
          Animated.timing(c.opacity, {
            toValue: 0,
            duration: 2000 + Math.random() * 1000,
            useNativeDriver: true,
          }),
          Animated.timing(c.width, {
            toValue: Math.random() * 10,
            duration: 2000 + Math.random() * 1000,
            easing: Easing.inOut(Easing.quad),
            useNativeDriver: false,
          }),
          Animated.timing(c.height, {
            toValue: Math.random() * 10,
            duration: 2000 + Math.random() * 1000,
            easing: Easing.inOut(Easing.quad),
            useNativeDriver: false,
          }),
        ]),
      ]).start()
    })
  }, [confetti])

  return (
    <View
      style={tw.style('absolute top-0 left-0', {
        width: screenWidth,
        height: screenHeight,
        overflow: 'hidden',
      })}
    >
      {confetti.map((c, i) => (
        <Animated.View
          key={i}
          style={[
            {
              position: 'absolute',
              opacity: c.opacity,
              transform: [
                { translateX: c.x },
                { translateY: c.y },
                { scale: c.scale },
                {
                  rotate: c.rotate.interpolate({
                    inputRange: [0, 360],
                    outputRange: ['0deg', '360deg'],
                  }),
                },
              ],
              width: c.width,
              height: c.height,
              backgroundColor: randomColor(),
            },
          ]}
        />
      ))}
    </View>
  )
}

const randomColor = () => {
  const colors = [
    '#FF6347',
    '#FF4500',
    '#FFD700',
    '#ADFF2F',
    '#00FF7F',
    '#1E90FF',
    '#9932CC',
    '#FF1493',
  ]
  return colors[Math.floor(Math.random() * colors.length)]
}
