import { observer } from 'mobx-react-lite'
import { Fragment, useEffect, useReducer } from 'react'
import { ScrollView, View } from 'react-native'

import { FlatList } from '#components/base/FlatList'
import { EmptyCompletedLesson } from '#components/base/IconSvg/SVG'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { gql } from '#graphql/urql'
import { S } from '#store'
import { getLevelDescription } from '#types/levelTest'
import type { SearchTopicItem } from '#types/topic'

import { Skeleton } from '../Skeleton'
import { CompletedItem } from './CompletedItem'

type State = {
  loading: boolean
  topics: { [key: string]: { level: string; topics: SearchTopicItem[] } }
  currentPage: number
  total: number
}

const initState: State = {
  loading: true,
  topics: {},
  currentPage: 1,
  total: 0,
}

const PAGE_SIZE = 10

export const CompletedTab = observer(() => {
  const [state, dispatch] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState,
  )

  useEffect(() => {
    handleGetCompletedTopics(1)
  }, [])

  const handleGetCompletedTopics = async (page: number) => {
    if (!S.shared.currentUser) {
      return
    }

    try {
      dispatch({ loading: true })
      const user = S.shared.currentUser

      const response = await gql.searchTopics({
        filter: {
          status: 'Active',
          OR: [
            { topicInUser_some: { percent: 100, userLearnId: user.id } },
            { level_lt: user.level.current },
          ],
        },
        order: ['level_asc', 'ordinalNumber_asc'],
        page: { limit: PAGE_SIZE, offset: (page - 1) * PAGE_SIZE },
      })

      const { searchTopics = [], count = 0 } = response.data || {}
      dispatch({
        loading: false,
        topics: mergeTopicsByLevel(searchTopics),
        total: count,
        currentPage: page,
      })
    } catch (e) {
      dispatch({ loading: false })
    }
  }

  const mergeTopicsByLevel = (topics: SearchTopicItem[]) => {
    const output = { ...state.topics }
    topics.forEach(topic => {
      output[topic.level] = {
        level: output[topic.level]?.level || topic.level,
        topics: [...(output[topic.level]?.topics || []), topic],
      }
    })
    return output
  }

  const handleRefresh = () => {
    if (!state.loading) {
      dispatch(initState)
      handleGetCompletedTopics(1)
    }
  }

  const handleLoadMore = () => {
    if (!state.loading) {
      let loaded = 0
      Object.values(state.topics).forEach(v => {
        loaded += v.topics.length
      })
      if (loaded < state.total) {
        handleGetCompletedTopics(state.currentPage + 1)
      }
    }
  }

  const renderListEmpty = () => (
    <View style={tw`flex-1 flex-col items-center justify-center p-4`}>
      <View style={tw`flex-col items-center justify-center`}>
        <EmptyCompletedLesson />
        <View style={tw`mt-5 flex-col gap-y-2`}>
          <Text
            specialType='Headline4'
            color={tw.color('primary-400')}
            textAlign='center'
          >
            Feels empty here
          </Text>
          <Text
            specialType='paragraph2'
            style={tw`leading-snug text-base`}
            color={tw.color('text-2')}
            textAlign='center'
          >
            Start learning, and you will see it fill up with your achievements.
          </Text>
        </View>
      </View>
    </View>
  )

  const renderTopics = (data: State['topics'][number], idx: number) => {
    const { label, color } = getLevelDescription(data.level)
    return (
      <Fragment>
        <View
          style={tw.style(
            'px-4 py-2 bg-white rounded-2xl gap-2 flex-row items-center self-start mb-4',
            idx > 0 && 'mt-8',
          )}
        >
          <SystemIcon type='SAX' name='Notepad2' color={tw.color(color)} />
          <Text specialType='paragraph2'>{label}</Text>
        </View>
        <View style={tw`flex-col gap-y-3`}>
          {data.topics.map(topic => (
            <CompletedItem key={topic.id} topic={topic} />
          ))}
          {state.loading && Object.values(state.topics).length === idx + 1 && (
            <Skeleton.TopicItem style={tw`h-56`} />
          )}
        </View>
      </Fragment>
    )
  }

  if (state.loading && !Object.keys(state.topics).length) {
    return (
      <ScrollView
        contentContainerStyle={tw`grow p-4`}
        showsVerticalScrollIndicator={false}
      >
        <Skeleton.Topics itemStyle={tw`h-56`} />
      </ScrollView>
    )
  }

  return (
    <FlatList
      data={Object.values(state.topics)}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={tw`grow p-4`}
      keyExtractor={item => item.level}
      renderItem={({ item, index }) => renderTopics(item, index)}
      refreshing={state.loading}
      onRefresh={handleRefresh}
      onEndReached={handleLoadMore}
      onEndReachedThreshold={0.3}
      ListEmptyComponent={renderListEmpty}
    />
  )
})
