import type { FC } from 'react'
import type { TouchableOpacityProps } from 'react-native'

import type { IconSaxProps } from './IconSax'
import { IconSax } from './IconSax'
import type { IconSvgComponentProps } from './IconSvg'
import { IconSvg } from './IconSvg'

export type IconType = 'SVG' | 'SAX'

type Prop = {
  size?: number
  color?: string
  onPress?: () => void
  buttonProps?: TouchableOpacityProps
}

export type IconPropsWithType =
  | ({ type: 'SVG'; name: IconSvgComponentProps } & Prop)
  | ({
      type: 'SAX'
      name: IconSaxProps['name']
      variant?: IconSaxProps['variant']
    } & Prop)

export const SystemIcon: FC<IconPropsWithType> = props => {
  const { type } = props
  if (type === 'SAX') {
    const { ...iconProps } = props
    return <IconSax {...iconProps} />
  }
  if (type === 'SVG') {
    const { ...iconProps } = props
    return <IconSvg {...iconProps} />
  }
  return null
}
