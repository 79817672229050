import type { FC } from 'react'
import { View } from 'react-native'

import { images } from '#assets'
import { Image } from '#components/base/Image'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

type ListHeaderProps = {
  total: number
  mastered: number
}

export const ListHeader: FC<ListHeaderProps> = ({ total, mastered }) => (
  <View style={tw`mb-4`}>
    <View style={tw`flex-col justify-center items-center mb-4`}>
      <Text specialType='Headline1' color={tw.color('success-400')}>
        Great job!
      </Text>
      <Text style={tw`mb-6`} specialType='paragraph1'>
        You've completed your review session
      </Text>
      <Image
        source={images.congratulation}
        resizeMode='cover'
        style={tw.style('w-40 h-40')}
      />
    </View>
    <View style={tw`w-full flex-row justify-between gap-x-8`}>
      <View
        style={tw`flex-1 bg-white rounded-2 flex-col overflow-hidden border border-success-400  ml-10`}
      >
        <View style={tw`flex-1 bg-success-400 justify-center py-3`}>
          <Text
            textAlign='center'
            specialType='paragraph1'
            color={tw.color('text-4')}
          >
            Reviewing
          </Text>
        </View>
        <View style={tw`flex-1 justify-center py-3`}>
          <Text
            specialType='Headline4'
            textAlign='center'
            color={tw.color('text-1')}
          >
            {total - mastered}
          </Text>
        </View>
      </View>
      <View
        style={tw`flex-1 bg-white rounded-2 flex-col overflow-hidden border border-secondary2-400  mr-10`}
      >
        <View style={tw`flex-1 bg-secondary2-400 justify-center py-3`}>
          <Text
            textAlign='center'
            specialType='paragraph1'
            color={tw.color('text-4')}
          >
            Mastered
          </Text>
        </View>
        <View style={tw`flex-1 justify-center py-3`}>
          <Text
            specialType='Headline4'
            textAlign='center'
            color={tw.color('text-1')}
          >
            {mastered}
          </Text>
        </View>
      </View>
    </View>
  </View>
)
