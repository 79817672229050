import { gql } from '#graphql/urql'
import type { GenerateProps, QuestionGame, WordItem } from '#types/games'
import { phraseGameLabel, wordGameLabel } from '#types/games'
import type { LevelTestContainer, LevelTestItem } from '#types/levelTest'
import type { QuesTionType } from '#types/question'

import { gameFunc } from './gameFunc'
import { getItemInList } from './getItemInList'

type GenerateGames = {
  item: LevelTestItem
}

export const fetchGameLevelTest = async ({
  item,
}: GenerateGames): Promise<LevelTestContainer> => {
  const respWord = await gql.searchWord(
    {
      filter: {
        topic_some: {
          level: item.level,
          languageTopic: item.language,
          status: 'Active',
        },
        isVocabulary: item.type === 'word',
      },
      page: { limit: 1, offset: item.position },
    },
    { requestPolicy: 'network-only' },
  )
  const wordData = respWord.data?.searchWord || []

  const randomType = getItemInList(
    item.type === 'phrase' ? phraseGameLabel : wordGameLabel,
  )
  if (!wordData.length || !randomType) {
    const question: QuestionGame = {
      index: 0,
      media: null,
      text: '',
      translation: '',
      type: null,
    }
    return { ...item, question, answers: [] }
  }
  const word = wordData[0]

  const respTopics = await gql.searchTopic({
    filter: { id: word.topicId },
  })
  const topicData = respTopics.data?.searchTopic || []
  const topic = topicData.length > 0 ? topicData[0] : undefined

  const respWords = await gql.searchWord(
    {
      filter: {
        topicId: word.topicId,
        isVocabulary: word.isVocabulary,
      },
    },
    { requestPolicy: 'network-only' },
  )
  const wordsId = (respWords.data?.searchWord || []).map(w => w.id)

  const resourceWord = await gql.searchResourceInWord(
    {
      filter: { wordId_in: wordsId },
    },
    { requestPolicy: 'network-only' },
  )
  const media = resourceWord.data?.searchResourceInWord || []

  const respTranslate = await gql.searchTranslate(
    {
      filter: { wQAId_in: wordsId },
    },
    { requestPolicy: 'network-only' },
  )
  const translate = respTranslate.data?.searchTranslate || []

  const wordsData: WordItem[] = (respWords.data?.searchWord || []).map(w => {
    const m = media.filter(i => i.wordId === w.id)
    const t = translate.filter(i => i.wQAId === w.id)
    return { ...w, media: m, translation: t }
  })

  const type: QuesTionType = randomType.value
  const params: GenerateProps = {
    item: {
      media: media.filter(i => i.wordId === word.id),
      text: word.vocabAndPhrase,
      wordId: word.id,
      translation: translate.filter(i => i.wQAId === word.id),
    },
    data: wordsData,
    language: item.language,
    isPhrase: item.type === 'phrase',
  }

  const resultGame = await gameFunc[type](params)

  return {
    ...item,
    topic,
    question: resultGame.question,
    answers: resultGame.answers,
    translation: translate.filter(i => i.wQAId === word.id),
  }
}
