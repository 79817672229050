import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { useEffect, useRef } from 'react'

import { useOverlay } from '#components/overlay/hooks'
import { GameContainer } from '#components/widgets/Games/GameContainer'
import { LevelNotificationModal } from '#components/widgets/LevelNotificationModal'
import { logCustomEvent } from '#config/firebaseConfig'
import { gql } from '#graphql/urql'
import { replace } from '#navigator/helpers'
import type { AppStackScreenProps } from '#navigator/types'
import { S } from '#store'
import { GameTimes } from '#types/games'
import type { LanguageType, Level } from '#types/language'
import { LanguageLabel, LevelDowngrade } from '#types/language'
import { TimeMilestones } from '#types/wordInUser'

import { useRandomGame } from './useRandomGame'

export const Games = observer(({ route }: AppStackScreenProps<'Games'>) => {
  const { isPhrase, topicId, isCompleted, isPlayAgain } = route.params

  const modalId = useRef<string>('')
  const countAnswer = useRef<number>(0)
  const startTime = useRef<number>(0)
  const totalTime = useRef<number>(0)
  const { openActionsheet, closeActionsheet } = useOverlay()

  const {
    answers,
    currentIndex,
    onChangeWordsData,
    question,
    total,
    wordInUser,
    loading,
    onChangeIndex,
    topicData,
  } = useRandomGame({
    topicId,
    userId: S.shared.currentUser?.id || '',
    language:
      (S.shared.currentUser?.languageLearn as LanguageType) ||
      LanguageLabel.English,
    isPhrase,
    isCompleted,
    isPlayAgain,
  })

  useEffect(() => {
    if (!loading) {
      if (currentIndex === 0) {
        totalTime.current = Date.now()
      }
      startTime.current = Date.now()
    }
  }, [loading, currentIndex])

  const updateUser = async (times: number) => {
    try {
      await gql.updateWordInUser({
        id: wordInUser?.id ?? '',
        data:
          times < GameTimes.TimeMax
            ? { times }
            : {
                times, // lastDay: moment().toDate(),
                nextDay: moment().toDate(),
                milestone: TimeMilestones.Zero,
              },
      })
      if (times === GameTimes.TimeMax) {
        logCustomEvent(
          wordInUser?.word?.isVocabulary
            ? 'vocabulary_learned'
            : 'phrase_learned',
          {
            id: wordInUser?.wordId || '',
            name: wordInUser?.word?.vocabAndPhrase || '',
          },
        )
      }
    } catch (error) {
      console.error('Failed to update word in user:', error)
    }
  }

  const onChangeAnswer = async (isTrue: boolean) => {
    countAnswer.current = isTrue ? countAnswer.current + 1 : countAnswer.current
    S.shared.checkWrongNext(isTrue)
    const newTimes = isTrue
      ? (wordInUser?.times || GameTimes.TimeMin) + 1
      : (wordInUser?.times || GameTimes.TimeMin) >= GameTimes.TimeHard
        ? GameTimes.TimeReset
        : GameTimes.TimeMin

    onChangeWordsData({ ...wordInUser, times: newTimes })
    const endTime = Date.now()
    const timer = (endTime - startTime.current) / 60000 // convert ms to m
    logCustomEvent(
      wordInUser?.word?.isVocabulary
        ? 'game_vocabulary_result'
        : 'game_phrase_result',
      {
        question: question?.text || '',
        answer_results: isTrue,
        word_id: wordInUser?.wordId || '',
        game_type: question?.type || '',
        text: wordInUser?.word?.vocabAndPhrase || '',
        topic_id: topicId,
        answer_time_per_minute: timer,
      },
    )
    logCustomEvent(
      wordInUser?.word?.isVocabulary
        ? 'game_vocabulary_type_result'
        : 'game_phrase_type_result',
      {
        question: question?.text || '',
        answer_results: isTrue,
        word_id: wordInUser?.wordId || '',
        game_type: question?.type || '',
        text: wordInUser?.word?.vocabAndPhrase || '',
        topic_id: topicId,
        answer_time_per_minute: timer,
      },
    )
    if (currentIndex === total - 1) {
      const time = (endTime - totalTime.current) / 60000 // convert ms to m
      logCustomEvent('game_session_time', {
        topic_id: topicId,
        count_answer: countAnswer.current,
        total_time_per_section: time,
      })
      if (countAnswer.current === total) {
        logCustomEvent('session_game_result', {
          topic_id: topicId,
          count_answer: countAnswer.current,
          total_time_per_section: time,
        })
      }
    }
    if (!isCompleted) {
      await updateUser(newTimes)
    }
  }

  const handleStop = async () => {
    const lv = S.shared.currentUser?.level.current ?? ''
    const newLevel = LevelDowngrade[lv]
    updateLevel(newLevel, newLevel !== 'A1')
    if (S.shared.currentUser) {
      S.shared.currentUser.level = { isShow: true, current: newLevel }
    }
    closeActionsheet(modalId.current)
    await replace('Home', { screen: 'Topics' })
    logCustomEvent('change_level', {
      current: lv,
      next: newLevel,
      reason: 'When answering incorrectly 5 times',
    })
  }

  const handleContinue = async () => {
    closeActionsheet(modalId.current)
    const lv = S.shared.currentUser?.level.current ?? ''
    await updateLevel(lv, false)
    if (S.shared.currentUser) {
      S.shared.currentUser.level.isShow = false
    }
  }

  const updateLevel = async (current: Level, isShow: boolean) => {
    await gql.updateUser({
      data: { level: { isShow, current } },
    })
  }

  const showSuggestDownGrade = () => {
    if (
      S.shared.wrongNext >= 5 &&
      S.shared.currentUser?.level.isShow === true &&
      S.shared.currentUser.level !== 'A1' &&
      !isCompleted
    ) {
      modalId.current = openActionsheet(LevelNotificationModal, {
        title: 'Is the lessons too hard?',
        content: 'We can jump to another lessons that’s closer to your level',
        LableButtonContinue: 'Continue',
        lablebuttonStop: 'Try easier lesson',
        handleStop,
        handleContinue,
        closeActionsheet,
      })
    }
  }

  return (
    <GameContainer
      currentIndex={currentIndex}
      total={total}
      question={question}
      answers={answers}
      loading={loading}
      onChangeIndex={onChangeIndex}
      onChangeAnswer={onChangeAnswer}
      showSuggestDownGrade={showSuggestDownGrade}
      type='Game'
      topic={topicData}
    />
  )
})
