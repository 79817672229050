import type { ISelectProps } from 'native-base'
import { Select as NbSelect } from 'native-base'
import { forwardRef, useContext, useState } from 'react'
import { Platform } from 'react-native'
import type { Style } from 'twrnc'

import { Icon } from '#components/base/Icon'
import { useDarkBg, useDarkColor } from '#components/dark/hooks'
import { OverlayContext } from '#components/overlay/OverlayProvider'
import { tw } from '#components/utils/tw'

export type SelectProps = ISelectProps & {
  _invalid?: boolean
  onBlur?: () => void
}

export const Select = forwardRef<any, SelectProps>(
  ({ _invalid, onOpen, onClose, onValueChange, onBlur, style, ...p }, ref) => {
    const [focus, setFocus] = useState(false)
    const bg = useDarkBg()
    const dark = useDarkColor()
    const ctx = useContext(OverlayContext)

    let _backdrop = undefined
    if (Platform.OS === 'android') {
      _backdrop = {
        ...p._actionSheet?._backdrop,
        style: {
          backgroundColor: 'transparent',
          ...p._actionSheet?._backdrop?.style,
        },
      }
    }

    const borderColor = dark(
      _invalid ? 'red-500' : focus ? 'primary-500' : 'gray-600',
      _invalid ? 'red-500' : focus ? 'primary-500' : 'gray-200',
    )

    return (
      <NbSelect
        // @ts-ignore
        ref={ref}
        borderColor={borderColor}
        backgroundColor={dark('gray-900', 'white')}
        placeholderTextColor={dark('gray-500', 'gray-400')}
        style={tw.style(
          `p-4 text-sm text-[${dark('body-dark', 'body-light')}]`,
          Platform.OS === 'ios' && 'pb-4',
          style as Style,
        )}
        dropdownIcon={
          <Icon
            provider='Feather'
            name='chevron-down'
            style={tw`text-2xl pr-2.5`}
          />
        }
        onOpen={e => {
          setFocus(true)
          ctx?.androidFixActionsheetOpen()
          return onOpen?.(e)
        }}
        onClose={e => {
          setFocus(false)
          onBlur?.()
          ctx?.androidFixActionsheetClose()
          return onClose?.(e)
        }}
        onValueChange={v => {
          setFocus(false)
          onBlur?.()
          ctx?.androidFixActionsheetClose()
          return onValueChange?.(v)
        }}
        _actionSheet={{
          _backdrop,
        }}
        _actionSheetContent={{
          style: tw`${bg}`,
        }}
        _item={{
          style: tw`${bg}`,
          _pressed: {
            style: tw`rounded-md bg-[${dark('gray-800', 'gray-100')}]`,
          },
          _text: {
            style: tw`text-[${dark('body-dark', 'body-light')}]`,
          },
        }}
        _selectedItem={{
          style: tw`relative rounded-md bg-[${dark('gray-800', 'gray-100')}]`,
          endIcon: (
            <Icon
              provider='Feather'
              name='check'
              style={tw`absolute text-xl right-0`}
            />
          ),
        }}
        {...p}
      />
    )
  },
)

export const SelectItem = NbSelect.Item
