import { useState } from 'react'

import { useDebounce } from './useDebounce'

export const useDebounceValue = <T>(
  value?: any,
  delay?: number,
): [T, any, T] => {
  const [state, setState] = useState<T>(value)
  const debouncedValue = useDebounce<T>(state, delay)
  return [state, setState, debouncedValue]
}
