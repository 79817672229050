import { Typography } from 'antd'
import type { FC } from 'react'

type ILabelProps = {
  label: string
  required?: boolean
}

export const Label: FC<ILabelProps> = ({ label, required }) => (
  <Typography style={{ whiteSpace: 'normal', display: 'inline' }}>
    {label}
    {required && (
      <Typography style={{ color: 'red', display: 'inline' }}>*</Typography>
    )}
  </Typography>
)
