import { observer } from 'mobx-react-lite'
import moment from 'moment'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useWindowDimensions, View } from 'react-native'

import { images } from '#assets'
import { Button } from '#components/base/Button/Button'
import { Image } from '#components/base/Image'
import { ScrollView } from '#components/base/ScrollView'
import { Text } from '#components/base/Text'
import { Radio } from '#components/form/Radio'
import { tw } from '#components/utils/tw'
import { gql } from '#graphql/urql'
import { replace } from '#navigator/helpers'
import type { AppStackScreenProps } from '#navigator/types'
import { S } from '#store'

type Props = AppStackScreenProps<'StreakDaily'>

export const StreakDaily: FC<Props> = observer(({ navigation, route }) => {
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  const { width } = useWindowDimensions()
  const [, setLoading] = useState<boolean>(true)
  const [slogan, setSlogan] = useState<string>('Great job!')
  const [media, setMedia] = useState<string>('')
  const [count, setCount] = useState<number>(0)
  const [streakIndicators, setStreakIndicators] = useState<boolean[]>(
    daysOfWeek.map(() => false),
  )

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const resp = await gql.searchStreakInUser(
      { filter: { userId: S.shared.currentUser?.id || '' } },
      { requestPolicy: 'network-only' },
    )
    const streakInUser = resp.data?.searchStreakInUser || []
    if (streakInUser.length > 0) {
      const streakD = streakInUser[0]
      const newDate = moment().format('YYYY-MM-DD')
      const lastDate = streakD.lastDate
      const yesterday = moment(newDate).subtract(1, 'days').format()
      const countStreak =
        !lastDate || !moment(lastDate, 'YYYY-MM-DD').isSame(yesterday, 'day')
          ? 1
          : streakD.countStreak + 1
      setCount(countStreak)
      const attendance = streakD.dateStreak?.attendance || []
      const newAttendance = [...attendance, newDate]
      await gql.updateStreakInUser({
        id: streakD.id,
        data: {
          countStreak,
          lastDate: newDate,
          dateStreak: { attendance: newAttendance },
          highestStreak:
            countStreak > streakD.highestStreak
              ? countStreak
              : streakD.highestStreak,
        },
      })
      const respMilestone = await gql.searchStreak({
        filter: { numberStreak: countStreak },
      })
      const milestoneD = respMilestone.data?.searchStreak || []
      const sloganD =
        milestoneD.length > 0 ? milestoneD[0].infor?.content || [] : []
      if (sloganD.length > 0) {
        const newSlogan = sloganD[Math.floor(Math.random() * sloganD.length)]
        setSlogan(newSlogan)
      } else {
        const respDaily = await gql.searchStreakDaily()
        const dailyD = respDaily.data?.searchStreakDaily || []
        const newSlogan =
          dailyD.length > 0
            ? dailyD[Math.floor(Math.random() * dailyD.length)].name
            : 'Great job!'
        setSlogan(newSlogan)
      }
      const newMedia =
        milestoneD.length > 0 ? milestoneD[0].media?.url || '' : ''

      setMedia(newMedia)
      await getStreakData(newAttendance)
    }
    setLoading(false)
  }

  const getStreakData = async (d: string[]) => {
    moment.updateLocale('en', { week: { dow: 0 } })
    const currentDate = moment()
    const startOfWeek = currentDate.clone().startOf('weeks')
    const endOfWeek = currentDate.clone().endOf('weeks')
    const momentDates = d.map(i => moment(i))
    const filteredDates = momentDates.filter(i =>
      i.isBetween(startOfWeek, endOfWeek, 'day', '[]'),
    )
    const filteredDateStrings = filteredDates.map(i =>
      moment(i, 'YYYY-MM-DD').format('YYYY-MM-DD'),
    )
    const startDate = moment().startOf('week')
    const daysWeek: string[] = []

    for (let i = 0; i < 7; i++) {
      daysWeek.push(startDate.clone().add(i, 'days').format('YYYY-MM-DD'))
    }

    const streakData = daysWeek.map(i => filteredDateStrings.includes(i))
    setStreakIndicators(streakData)
  }

  const handleContinuePress = () => {
    if (route.params.isGoBack) {
      navigation.goBack()
    } else {
      replace('App', {
        screen: 'ReviewListening',
        params: { topicId: S.shared.currentTopicId },
      })
    }
  }

  return (
    <View
      style={tw`flex-1 bg-background-light-white justify-between w-full px-4 py-4`}
    >
      <View style={tw`flex-1 items-center justify-center`}>
        <Image
          source={media ? { uri: media } : images.image_streak}
          resizeMode='contain'
          style={tw.style('w-32 h-32 ')}
        />
        <View style={tw`mt-10 mb-3`}>
          <Text specialType='Headline3' textAlign='center'>
            {`${count}  DAY STREAK`}
          </Text>
        </View>
        <Text textAlign='center' specialType='Title'>
          {slogan}
        </Text>
        <View style={tw.style('h-18 mt-10.5', { width })}>
          <ScrollView
            horizontal
            contentContainerStyle={tw.style(
              'w-full px-6 justify-center items-center',
            )}
          >
            <View style={tw.style('flex-row justify-center items-center')}>
              {daysOfWeek.map((day, index) => (
                <View
                  key={index}
                  style={tw.style(
                    'items-center gap-y-0.5',
                    index < daysOfWeek.length - 1 && 'mr-5',
                  )}
                >
                  <Radio
                    variant='Bold'
                    isChecked={streakIndicators[index]}
                    size={32}
                  />
                  <View style={tw`mt-1`}>
                    <Text specialType='paragraph2' color={tw.color('text-2')}>
                      {day}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </ScrollView>
        </View>
      </View>
      <View style={tw`w-full`}>
        <Button onPress={handleContinuePress} size='large'>
          Continue
        </Button>
      </View>
    </View>
  )
})
