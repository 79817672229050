import { isValidAudioUrl, isValidImageUrl } from '#components/utils/checkMedia'
import type {
  AnswerGame,
  GamesProps,
  GenerateProps,
  QuestionGame,
} from '#types/games'
import { EasyGame, LabelGame } from '#types/games'

import { getItemInList } from './getItemInList'

export const generateSameOrDifferent = async ({
  item,
  data,
}: GenerateProps): Promise<GamesProps> => {
  const words = data.filter(i => i.id !== item.wordId)
  const choices = [LabelGame.LabelDifferent, LabelGame.LabelSame]
  let correctAnswers =
    words.length > 0 ? Math.floor(Math.random() * choices.length) : 1
  const images = item.media.filter(i => isValidImageUrl(i.media?.url || ''))
  let mediaQuestion = getItemInList(images)?.media || null
  if (
    choices[correctAnswers] === LabelGame.LabelDifferent &&
    words.length > 0
  ) {
    const randomWord = Math.floor(Math.random() * words.length)
    const word = words[randomWord]
    const resourceImage = word.media.filter(i =>
      isValidImageUrl(i.media?.url || ''),
    )
    const image = getItemInList(resourceImage)?.media || null
    mediaQuestion = image ? image : mediaQuestion
    correctAnswers = image ? 0 : 1
  } else {
    correctAnswers = 1
  }
  const questionData: QuestionGame = {
    text: item.text,
    media: mediaQuestion,
    type: EasyGame.sameOrDifferent,
    translation: '',
    index: 0,
  }
  const audios = item.media.filter(i => isValidAudioUrl(i.media?.url || ''))
  const answerData = {
    audio: getItemInList(audios)?.media || null,
    image: getItemInList(images)?.media || null,
    translation: '',
    text: item.text,
    type: EasyGame.sameOrDifferent,
    answer: item.text,
  }
  const answers: AnswerGame[] = [
    {
      ...answerData,
      isCorrect: correctAnswers === 0,
      id: LabelGame.LabelDifferent,
    },
    {
      ...answerData,
      isCorrect: correctAnswers === 1,
      id: LabelGame.LabelSame,
    },
  ]
  return {
    question: questionData,
    answers,
  }
}
