import type React from 'react'
import type { ActivityIndicatorProps } from 'react-native'
import { ActivityIndicator, View } from 'react-native'
import type { Style } from 'twrnc'

import { tw } from '#components/utils/tw'

import type { TextProps } from './Text'
import { Text } from './Text'

type ILoadingProps = {
  isLoading?: boolean
  containerStyle?: Style
  size?: ActivityIndicatorProps['size']
  color?: ActivityIndicatorProps['color']
  style?: Style
  activityIndicatorProps?: ActivityIndicatorProps
  text?: string
  textProps?: TextProps
}

export const Loading: React.FC<ILoadingProps> = ({
  isLoading,
  containerStyle,
  size = 'large',
  color,
  style,
  activityIndicatorProps,
  text = 'Loading...',
  textProps,
}) => {
  if (isLoading) {
    return (
      <View
        style={tw.style(
          'flex-1 justify-center items-center p-4',
          containerStyle,
        )}
      >
        <ActivityIndicator
          size={size}
          color={color || tw.color('primary-400')}
          style={style}
          {...activityIndicatorProps}
        />
        {text && <Text {...textProps}>{text}</Text>}
      </View>
    )
  }
  return <View />
}
