import { Flex, Pagination, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import type { ExpandableConfig } from 'antd/es/table/interface'
import { Dimensions } from 'react-native'
import type { Style } from 'twrnc'

import { tw } from '#components/utils/tw'

type Props<T> = {
  dataSource: T[]
  columns: ColumnsType<T>
  rowKey?: string
  loading: boolean
  onRowClick?: (record: T) => void
  total: number
  currentPage: number
  pageSize: number
  setCurrentPage: (page: number) => void
  setPageSize: (size: number) => void
  scrollHeight?: number | string
  scrollWidth?: number
  pageSizeOptions?: string[]
  customStyles?: {
    table?: Style
    pagination?: Style
    tableWrapper?: Style
  }
  expandable?: ExpandableConfig<T>
}

export const PaginatedTable = <T extends object>({
  dataSource,
  columns,
  rowKey = 'id',
  loading,
  onRowClick,
  total,
  currentPage,
  pageSize,
  setCurrentPage,
  setPageSize,
  scrollHeight = Dimensions.get('window').height * 0.65,
  scrollWidth = Dimensions.get('window').height * 0.65,

  pageSizeOptions = ['3', '5', '10', '20', '50'],
  customStyles = {},
  expandable,
}: Props<T>) => {
  const tableRowProps = onRowClick
    ? (record: T) => ({
        onClick: () => onRowClick(record),
      })
    : undefined

  return (
    <Flex style={tw.style(customStyles.tableWrapper, 'w-full flex-col')}>
      {/* <div
        style={{
          overflowY: 'scroll',
          height: '60vh',
        }}
      > */}
      <Table<T>
        dataSource={dataSource}
        columns={columns}
        rowKey={rowKey}
        loading={loading}
        pagination={false}
        onRow={tableRowProps}
        scroll={{ y: scrollHeight, x: scrollWidth }}
        style={tw.style(customStyles.table, 'w-full')}
        expandable={expandable}
      />
      {/* </div> */}

      <Pagination
        style={tw.style(customStyles.pagination, 'self-end p-4')}
        total={total}
        current={currentPage}
        pageSize={pageSize}
        showSizeChanger
        showQuickJumper
        pageSizeOptions={pageSizeOptions}
        onChange={(page, size) => {
          setCurrentPage(page)
          setPageSize(size)
        }}
        showTotal={(t, r) => `${r[0]}-${r[1]} of ${t} items`}
      />
    </Flex>
  )
}
