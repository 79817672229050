import { ulid } from 'ulidx'

import { isValidAudioUrl, isValidImageUrl } from '#components/utils/checkMedia'
import { gql } from '#graphql/urql'
import type {
  AnswerGame,
  EasyGameFunctions,
  EasyGameType,
  GamesProps,
  GameType,
  GenerateProps,
  HardGameFunctions,
  HardGameType,
  QuestionGame,
} from '#types/games'
import { HardGame } from '#types/games'

import { generateCompleteTheDialogue } from './completeTheDialogue'
import { generateCorrectPhrase } from './correctPhrase'
import { generateCorrectTranslation } from './correctTranslation'
import { generateFillTheBlank } from './fillTheBlank'
import { getItemInList } from './getItemInList'
import { generateHearAudio } from './hearAudio'
import { generateHearVideo } from './hearVideo'
import { generateRearrangement } from './rearrangement'
import { generateSameOrDifferent } from './sameOrDifferent'
import { generateSelectImage } from './selectImage'

export const easyGameFunc: { [key in EasyGameType]: EasyGameFunctions } = {
  sameOrDifferent: generateSameOrDifferent,
  selectImage: generateSelectImage,
  hearAudio: generateHearAudio,
  hearVideo: generateHearVideo,
  correctTranslation: generateCorrectTranslation,
  rearrangement: generateRearrangement,
  correctPhrase: generateCorrectPhrase,
}

export const hardGameFunc: { [key in HardGameType]: HardGameFunctions } = {
  fillTheBlank: generateFillTheBlank,
  completeTheDialogue: generateCompleteTheDialogue,
}

type AnswerChat = {
  text: string
  isCorrect: boolean
}

type QuestionDialogue = {
  question: { index: number; text: string }
  answers: AnswerChat[]
}

export const completeTheDialogue = async ({
  language,
  item,
}: GenerateProps): Promise<GamesProps> => {
  const resp = await gql.generateCompleteTheDialogue({
    content: item.text,
    language,
  })
  let dataStr =
    resp.data?.generateCompleteTheDialogue ||
    '{"question": {"index":0,"text": ""}, "answers": []}'
  if (!dataStr.startsWith('{')) {
    dataStr = `{"${dataStr}"}`
  }
  const data: QuestionDialogue = JSON.parse(dataStr)
  const audios = item.media.filter(i => isValidAudioUrl(i.media?.url || ''))
  const question: QuestionGame = {
    text: data.question.text || '',
    media: getItemInList(audios)?.media || null,
    type: HardGame.completeTheDialogue,
    translation: '',
    index: 0,
  }
  const images = item.media.filter(i => isValidImageUrl(i.media?.url || ''))
  const answers: AnswerGame[] = data.answers.map(i => ({
    text: i.isCorrect ? item.text : i.text || '',
    isCorrect: i.isCorrect,
    audio: getItemInList(audios)?.media || null,
    image: getItemInList(images)?.media || null,
    translation: '',
    type: HardGame.completeTheDialogue,
    id: ulid(),
    answer: item.text || '',
  }))
  return { question, answers }
}

type QuestionFill = {
  question: string
  answers: AnswerChat[]
}

export const fillTheBlank = async ({
  language,
  isPhrase,
  item,
}: GenerateProps): Promise<GamesProps> => {
  const resp = await gql.generateFillTheBlank({
    content: item.text,
    isPhrase,
    language,
  })
  let dataStr =
    resp.data?.generateFillTheBlank || '{"question": "", "answers": []}'
  if (!dataStr.startsWith('{')) {
    dataStr = `{"${dataStr}"}`
  }
  const data: QuestionFill = JSON.parse(dataStr)
  const audios = item.media.filter(i => isValidAudioUrl(i.media?.url || ''))
  const questionData: QuestionGame = {
    text: data.question || item.text,
    media: getItemInList(audios)?.media || null,
    type: HardGame.fillTheBlank,
    translation: '',
    index: 0,
  }
  const images = item.media.filter(i => isValidImageUrl(i.media?.url || ''))
  const answers: AnswerGame[] = !data
    ? []
    : data.answers.map(i => ({
        text: i.text || '',
        isCorrect: i.isCorrect,
        audio: getItemInList(audios)?.media || null,
        image: getItemInList(images)?.media || null,
        translation: '',
        type: HardGame.fillTheBlank,
        id: ulid(),
        answer: item.text || '',
      }))
  return { question: questionData, answers }
}

type GameFunctions = (props: GenerateProps) => Promise<GamesProps>

export const gameFunc: { [key in GameType]: GameFunctions } = {
  sameOrDifferent: generateSameOrDifferent,
  selectImage: generateSelectImage,
  hearAudio: generateHearAudio,
  hearVideo: generateHearVideo,
  correctTranslation: generateCorrectTranslation,
  rearrangement: generateRearrangement,
  correctPhrase: generateCorrectPhrase,
  fillTheBlank,
  completeTheDialogue,
}
