import { createNativeStackNavigator } from '@react-navigation/native-stack'

import { Login } from '#admin/screens/Login'
import type { AdminAuthParamList } from '#navigator/types'

const Stack = createNativeStackNavigator<AdminAuthParamList>()
export const AdminAuthNavigator = () => (
  <Stack.Navigator screenOptions={{ headerShown: false, animation: 'ios' }}>
    <Stack.Screen name='AdminLogin' component={Login} />
  </Stack.Navigator>
)
