import { io } from 'socket.io-client'

import { rnServerOrigin } from '#config'

export const socketInstance = () => {
  const socket = io(`${rnServerOrigin}`, {
    path: '/api/socket-io',
    transports: ['websocket'],
    forceNew: true,
  })
  return socket
}
