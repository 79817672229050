import qs from 'qs'

export { qs }

type Options = Omit<qs.IStringifyOptions, 'sort'>

const sort = (a: string, b: string) => a.localeCompare(b)
export const qsStableStringify = (q: object, o?: Options) =>
  qs.stringify(q, { ...o, sort })

export const qsIdSecret = (t: { id: string; secret: string }) => {
  const { id, secret } = t
  return qs.stringify({ id, secret })
}

export const qsIdSecretParse = (encoded?: string) => {
  if (!encoded) {
    return
  }
  const { id, secret } = qs.parse(encoded)
  if (!id || !secret || typeof id !== 'string' || typeof secret !== 'string') {
    return
  }
  return { id, secret }
}
