import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Flex, Form, Input } from 'antd'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FormItem } from 'react-hook-form-antd'
import { z } from 'zod'

import { tw } from '#components/utils/tw'

import { onLoginSubmit } from './onLoginSubmit'

const schema = z.object({
  email: z
    .string()
    .min(1, { message: 'Email is required' })
    .email('Invalid email address'),
  password: z.string().min(1, { message: 'Password is required' }),
})

export const Login = observer(() => {
  const { control, handleSubmit } = useForm({
    defaultValues: { email: '', password: '' },
    resolver: zodResolver(schema),
  })

  const [loading, setLoading] = useState(false)

  const onSubmit = async data => {
    setLoading(true)
    try {
      await onLoginSubmit(data)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Flex style={tw`flex-1 flex-col items-center justify-center`}>
      <Form
        onFinish={handleSubmit(onSubmit)}
        style={{ width: '100%', maxWidth: '400px' }}
      >
        <FormItem
          control={control}
          name='email'
          label='Email'
          labelCol={{ span: 24 }}
        >
          <Input />
        </FormItem>
        <FormItem
          control={control}
          name='password'
          label='Password'
          labelCol={{ span: 24 }}
        >
          <Input.Password />
        </FormItem>
        <Form.Item style={{ textAlign: 'center' }}>
          <Button
            type='primary'
            htmlType='submit'
            disabled={loading}
            style={tw`self-center mr-4`}
            loading={loading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Flex>
  )
})
