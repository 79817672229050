import type { FC } from 'react'
import { useRef } from 'react'
import type { GestureResponderEvent } from 'react-native'
import { PanResponder, Pressable, View } from 'react-native'

import { tw } from '#components/utils/tw'

type Props = {
  progress: number
  onSeek?: (progress: number) => void
  locationX: number
}

export const SwipeableProgressBar: FC<Props> = ({
  progress,
  onSeek,
  locationX,
}) => {
  const progressBarWidth = useRef<number>(0)

  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onPanResponderMove: (_evt, gestureState) => {
        onChangeProgress(gestureState.moveX)
      },
      onPanResponderRelease: (_evt, gestureState) => {
        onChangeProgress(gestureState.moveX)
      },
    }),
  ).current

  const handlePress = (event: GestureResponderEvent) => {
    const offsetX = event.nativeEvent.pageX || 0
    onChangeProgress(offsetX)
  }

  const onChangeProgress = (num: number) => {
    const newProgress = Math.round(
      Math.min(
        Math.max(((num - locationX) / progressBarWidth.current) * 100, 0),
        100,
      ),
    )
    onSeek?.(newProgress)
  }

  return (
    <View style={tw.style('w-full justify-center min-h-12 flex-col')}>
      <Pressable
        style={tw.style('w-full  rounded-md relative bg-neutral-100', {
          height: 5,
        })}
        onPress={handlePress}
        onLayout={event => {
          progressBarWidth.current = event.nativeEvent.layout.width
        }}
      >
        <View
          style={tw.style('absolute h-full rounded-md bg-neutral-100', {
            width: `${progress}%`,
          })}
        />
        <View
          style={tw.style(
            'absolute w-5 h-5 rounded-full bg-background-light-white',
            {
              transform: [{ translateX: -10 }],
              top: -7.5,
              left: `${progress}%`,
            },
          )}
          {...panResponder.panHandlers}
        />
      </Pressable>
    </View>
  )
}
