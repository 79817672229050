import type { FC } from 'react'
import { useEffect, useRef } from 'react'
import type { ViewProps } from 'react-native'
import { Animated, TouchableOpacity } from 'react-native'

import { tw } from '#components/utils/tw'

export type SwitchProps = {
  id?: string | number
  switchStyle?: ViewProps['style']
  value?: boolean
  disabled?: boolean
  onChange?: () => void
  size?: number
}

export const Switch: FC<SwitchProps> = ({
  id,
  switchStyle,
  value = false,
  disabled = false,
  onChange,
  size = 32,
}) => {
  const max = size * 0.9
  const min = size * 0.12
  const widthCircle = size * 0.7

  const bgActive = tw.color('primary-400') || '#34C759'

  const bgInactive = tw.color('neutral-200') || '#D9E2EC'

  const thumbAnim = useRef(new Animated.Value(value ? max : min)).current
  const bgColorAnim = useRef(new Animated.Value(value ? 1 : 0)).current

  useEffect(() => {
    Animated.parallel([
      Animated.timing(thumbAnim, {
        toValue: value ? max : min,
        duration: 250,
        useNativeDriver: true,
      }),
      Animated.timing(bgColorAnim, {
        toValue: value ? 1 : 0,
        duration: 250,
        useNativeDriver: true,
      }),
    ]).start()
  }, [value])

  const backgroundColor = bgColorAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [bgInactive, bgActive],
  })

  return (
    <TouchableOpacity
      id={`${id}-switch`}
      onPress={onChange}
      activeOpacity={1}
      disabled={disabled}
      //   accessibilityLabel='Toggle switch'
      accessibilityRole='switch'
      accessibilityState={{ disabled, checked: value }}
    >
      <Animated.View
        style={[
          {
            width: size * 1.75,
            height: size,
            borderRadius: size * 0.5,
            justifyContent: 'center',
          },
          { backgroundColor },
          switchStyle,
        ]}
      >
        <Animated.View
          style={[
            {
              width: widthCircle,
              height: widthCircle,
              borderRadius: widthCircle * 0.5,
            },
            { backgroundColor: tw.color('white') },
            { transform: [{ translateX: thumbAnim }] },
          ]}
        />
      </Animated.View>
    </TouchableOpacity>
  )
}
