import { PlayCircleOutlined } from '@ant-design/icons'
import { Flex } from 'antd'
import type React from 'react'

import { tw } from '#components/utils/tw'

interface VoiceUploaderProps {
  voiceFile: string
}

export const VoiceAi: React.FC<VoiceUploaderProps> = ({ voiceFile }) => {
  const handlePlayVoice = () => {
    window.open(voiceFile, '_blank')
  }

  return (
    <Flex style={tw`flex-1 items-center justify-center`}>
      {voiceFile && (
        <Flex style={tw`items-center justify-center`}>
          <PlayCircleOutlined
            onClick={handlePlayVoice}
            style={{
              fontSize: 24,
              color: '#1890ff',
              margin: 'auto',
            }}
          />
        </Flex>
      )}
    </Flex>
  )
}
