import { debounce } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'

import { Switch } from '#components/base/Switch'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { Divider } from '#components/widgets/Divider'
import { StepSlider } from '#components/widgets/StepSlider'
import type { Exact, InputMaybe, UpdateMyProfile } from '#graphql/codegen'
import { useUpdateUser } from '#graphql/codegen'
import { navigate } from '#navigator/helpers'
import { S } from '#store'

const TALK_SPEED_OPTIONS = [
  { value: 0.5, label: 'Slow' },
  { value: 1, label: 'Normal' },
  { value: 1.5, label: 'Fast' },
]

const REPAIR_OPTIONS = [
  { label: 'Never', value: 0 },
  { label: 'Sometimes', value: 1 },
  { label: 'Always', value: 2 },
]

type Pr = {
  closeActionsheet: () => void
}

export const ActionSheetChatSetting: React.FC<Pr> = observer(
  ({ closeActionsheet }) => {
    const [, updateUser] = useUpdateUser()

    const debounceUpdateUser = useCallback(
      debounce((params: Exact<{ data?: InputMaybe<UpdateMyProfile> }>) => {
        updateUser(params)
      }, 500),
      [],
    )

    const handleToggleAutoPlay = () => {
      if (!!S.shared.currentUser) {
        const autoPlay = !S.shared.currentUser.autoPlay
        S.shared.currentUser.autoPlay = autoPlay
        debounceUpdateUser({ data: { autoPlay } })
      }
    }

    const handleSliderChange =
      (type: 'speed' | 'repair') => (value: number) => {
        if (!!S.shared.currentUser) {
          S.shared.currentUser[type] = value
          debounceUpdateUser({ data: { [type]: value } })
        }
      }

    return (
      <View style={tw`w-full px-2 pb-2`}>
        <Text specialType='Headline4' textAlign='center' style={tw`p-4`}>
          Chat settings
        </Text>
        <View style={tw`bg-white shadow-md rounded-3xl py-4`}>
          <TouchableOpacity
            activeOpacity={0.5}
            style={tw`flex-row justify-between items-center p-4`}
            onPress={() => {
              closeActionsheet()
              navigate('App', { screen: 'ChooseTeacher' })
            }}
          >
            <Text specialType='paragraph1'>Change AI tutor</Text>
            <SystemIcon
              type='SAX'
              name='ArrowRight2'
              color={tw.color('neutral-400')}
            />
          </TouchableOpacity>
          <Divider style={tw`my-2`} />
          <View style={tw`flex-row justify-between items-center p-4`}>
            <Text specialType='paragraph1'>Auto-play voice</Text>
            <Switch
              value={S.shared.currentUser?.autoPlay}
              onChange={handleToggleAutoPlay}
            />
          </View>
          <Divider style={tw`my-2`} />
          <View style={tw`flex-col p-4`}>
            <Text specialType='paragraph1'>Voice Speed</Text>
            <StepSlider
              defaultValue={S.shared.currentUser?.speed}
              containerStyle={tw`mt-2`}
              steps={TALK_SPEED_OPTIONS}
              onChange={handleSliderChange('speed')}
            />
          </View>
          <Divider style={tw`my-2`} />
          <View style={tw`flex-col p-4`}>
            <Text specialType='paragraph1'>Auto-correct mistakes</Text>
            <StepSlider
              defaultValue={S.shared.currentUser?.repair}
              containerStyle={tw`mt-2`}
              steps={REPAIR_OPTIONS}
              onChange={handleSliderChange('repair')}
            />
          </View>
        </View>
      </View>
    )
  },
)
