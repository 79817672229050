import type { ForwardedRef } from 'react'
import { forwardRef, useImperativeHandle, useState } from 'react'

import { InputPayment } from './InputPayment'

export const ZipCodeInput = forwardRef(
  (
    _,
    ref: ForwardedRef<{
      getZip: () => string
      setZip: (n: string) => void
    }>,
  ) => {
    const [zip, setZipValue] = useState('')
    const handleChange = (text: string) => {
      const numericValue = text.replace(/[^0-9]/g, '')
      setZipValue(numericValue)
    }

    useImperativeHandle(ref, () => ({
      getZip: () => zip,
      setZip: n => setZipValue(n),
    }))

    return (
      <InputPayment
        value={zip}
        name='Zip code'
        mt={'mt-4'}
        onChangeText={t => {
          handleChange(t)
        }}
        placeholder='00000'
        keyboardType='numeric'
      />
    )
  },
)
