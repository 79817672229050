import type { CompositeScreenProps } from '@react-navigation/native'
import type { FC } from 'react'
import { View } from 'react-native'

import { tw } from '#components/utils/tw'
import { Header } from '#components/widgets/Header'
import type {
  AppStackScreenProps,
  AuthStackScreenProps,
} from '#navigator/types'

type Props = CompositeScreenProps<
  AuthStackScreenProps<'TermsOfUse'>,
  AppStackScreenProps<'TermsOfUse'>
>

export const TermsOfUse: FC<Props> = ({}) => (
  <View style={tw`flex-1 flex-col`}>
    <Header title='Terms of use' />
    <View style={tw`flex-1 flex-col px-4 pb-4 pt-6`} />
  </View>
)
