import { gql } from '#graphql/urql'
import type { QuestionGame } from '#types/games'
import type { LevelTestContainer, LevelTestItem } from '#types/levelTest'
import type { QuesTionType } from '#types/question'

type GenerateGames = {
  item: LevelTestItem
}

export const fetchListen = async ({
  item,
}: GenerateGames): Promise<LevelTestContainer> => {
  const respQuestion = await fetchQuestion(item)
  const question: QuestionGame = {
    index: 0,
    media: respQuestion?.media || null,
    text: respQuestion?.text || '',
    translation: '',
    type: respQuestion?.type as QuesTionType,
  }
  const respAnswers = await fetchAnswers(respQuestion?.id || '')
  const answers = respAnswers.map(i => ({
    text: i.answer?.text || '',
    isCorrect: i.isTrue,
    audio: i.media || null,
    image: i.thumbnail || null,
    translation: '',
    type: question.type,
    id: i.id,
    answer: i.answer?.text || '',
  }))
  return { ...item, question, answers }
}

const fetchQuestion = async ({ position, language, level }: LevelTestItem) => {
  const respQuestion = await gql.searchQuestion({
    filter: { wordId: '', topic_some: { languageTopic: language, level } },
    order: ['createdAt_asc'],
    page: { limit: 1, offset: position },
  })

  const questionsData = respQuestion.data?.searchQuestion || []
  return questionsData.length > 0 ? questionsData[0] : null
}

const fetchAnswers = async (questionId: string) => {
  if (!questionId) {
    return []
  }
  const resp = await gql.searchAnswerInQuestion({
    filter: { questionId },
    order: ['createdAt_asc'],
  })
  const answerData = resp.data?.searchAnswerInQuestion || []
  return answerData
}
