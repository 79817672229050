import type React from 'react'
import { View } from 'react-native'
import Svg, { Circle, G, Path } from 'react-native-svg'

import { SystemIcon } from '#components/base/SystemIcon'
import { tw } from '#components/utils/tw'

interface ProgressCircleProps {
  progress: number
}

export const ProgressCircle: React.FC<ProgressCircleProps> = ({ progress }) => {
  const size = 44
  const strokeWidth = size / 8
  const radius = (size - strokeWidth) / 2
  const segmentAngle = 60
  const gapAngle = 5
  const colors = Array(6).fill(tw.color('neutral-300'))
  const colorsFinished = tw.color('success-300')

  for (let i = 0; i < progress; i++) {
    colors[i] = colorsFinished
  }

  const renderSegments = () =>
    colors.map((color, index) => {
      const startAngle = index * segmentAngle + gapAngle / 2
      const endAngle = startAngle + segmentAngle - gapAngle
      const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'

      const startX = radius * Math.cos((Math.PI * startAngle) / 180) + size / 2
      const startY = radius * Math.sin((Math.PI * startAngle) / 180) + size / 2
      const endX = radius * Math.cos((Math.PI * endAngle) / 180) + size / 2
      const endY = radius * Math.sin((Math.PI * endAngle) / 180) + size / 2

      return (
        <Path
          key={index}
          d={`M${size / 2},${size / 2} L${startX},${startY} A${radius},${radius} 0 ${largeArcFlag},1 ${endX},${endY} Z`}
          fill={color}
        />
      )
    })

  return (
    <View>
      <Svg height={size} width={size}>
        <G transform={`rotate(270, ${size / 2}, ${size / 2})`}>
          <Circle
            cx={size / 2}
            cy={size / 2}
            r={radius}
            stroke='transparent'
            strokeWidth={strokeWidth}
            fill='white'
          />
          {renderSegments()}
          <Circle
            cx={size / 2}
            cy={size / 2}
            r={radius - strokeWidth}
            fill='white'
          />
        </G>
      </Svg>
      {progress === 6 && (
        <View
          style={[
            tw.style('flex-1 absolute justify-center items-center'),
            {
              height: size,
              width: size,
              left: 0.25,
              top: 0.25,
            },
          ]}
        >
          <View
            style={[
              tw.style('justify-center items-center rounded-full'),
              {
                height: size / 2,
                width: size / 2,
                backgroundColor: colorsFinished,
              },
            ]}
          >
            <SystemIcon
              type='SVG'
              name='check'
              color='white'
              size={radius / 1.5}
            />
          </View>
        </View>
      )}
    </View>
  )
}
