import { gql } from '#graphql/urql'
import { GameTimes } from '#types/games'

export const createWordAndPhrase = async (topicId: string, userId: string) => {
  if (!topicId || !userId) {
    return
  }
  const wordInUserRes = await gql.searchWordInUser(
    {
      filter: { topicId, userId },
      order: 'times_asc',
    },
    { requestPolicy: 'network-only' },
  )

  const dataWordInUser = wordInUserRes.data?.searchWordInUser || []

  const wordIds = dataWordInUser.map(item => item.wordId)

  const wordRes = await gql.searchWord(
    {
      filter: { topicId, id_notIn: wordIds },
      order: ['vocabAndPhrase_asc'],
      page: {},
    },
    { requestPolicy: 'network-only' },
  )

  const wordsData = wordRes.data?.searchWord || []

  if (wordsData.length > 0) {
    wordsData.map(async w =>
      gql.createWordInUser({
        data: {
          userId,
          topicId,
          wordId: w.id,
          times: GameTimes.TimeMin,
        },
      }),
    )
  }
}
