import type { FC } from 'react'
import type { ColorValue } from 'react-native'
import {
  Platform,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

import type { BackButtonProps } from './BackButton'
import { BackButton } from './BackButton'

type IHeaderProps = {
  sliderValue?: number
  typeSlider?: 'slice' | 'basic'
  title?: string
  countTotal?: number
  isDark?: boolean
  isPopToTop?: boolean
  bg?: ColorValue
  isBack?: boolean
  onPressHint?: () => void
  isShowHint?: boolean
  onPressSetting?: () => void
  isShowSetting?: boolean
  onPressBack?: () => void
  iconType?: BackButtonProps['iconType']
}

export const Header: FC<IHeaderProps> = ({
  sliderValue = 0,
  typeSlider = 'basic',
  title,
  countTotal = 0,
  isDark = true,
  isPopToTop,
  bg,
  isBack = true,
  onPressHint,
  isShowHint,
  onPressBack,
  iconType,
  isShowSetting = false,
  onPressSetting,
}) => {
  const { width: screenWidth } = useWindowDimensions()
  const insets = useSafeAreaInsets()

  const paddingHorizontal = 96
  // const itemWidth = (screenWidth - paddingHorizontal) / countTotal
  const itemWidth =
    countTotal > 0 && typeof screenWidth === 'number'
      ? (screenWidth - paddingHorizontal) / countTotal
      : 0
  const slider = itemWidth * sliderValue
  const textColor = isDark ? tw.color('text-1') : tw.color('text-4')

  return (
    <View
      style={tw.style(
        'flex-row items-center',
        bg ? { backgroundColor: bg } : '',
        Platform.OS === 'ios'
          ? { height: 50 + insets.top, paddingTop: insets.top + 16 }
          : { height: 50 },
      )}
    >
      <View style={tw`w-12 h-12 justify-center items-center`}>
        {isBack && (
          <BackButton
            dark={isDark}
            iconType={
              iconType ? iconType : typeSlider === 'slice' ? 'close' : 'arrow'
            }
            isPopToTop={isPopToTop}
            onPressBack={onPressBack}
          />
        )}
      </View>
      {title && (
        <View style={tw`flex-1 flex-row items-center h-10`}>
          <View style={tw`flex-1 items-center`}>
            <Text specialType='Headline3' color={textColor} textAlign='center'>
              {title}
            </Text>
          </View>
        </View>
      )}
      {typeSlider === 'slice' && !title && (
        <View
          style={tw`flex-1 bg-neutral-200 w-full h-3 justify-center rounded-5`}
        >
          <View
            style={[tw`bg-secondary1-300 h-3 rounded-5`, { width: slider }]}
          />
        </View>
      )}
      <View style={tw`w-12 h-12 justify-center items-center`}>
        {typeSlider === 'slice' && isShowHint && !title && (
          <TouchableOpacity
            onPress={onPressHint}
            style={tw`w-12 h-12 justify-center items-center`}
          >
            <SystemIcon
              type='SAX'
              name='LampCharge'
              color={tw.color('warning-300')}
            />
          </TouchableOpacity>
        )}
        {typeSlider === 'basic' && isShowSetting && (
          <TouchableOpacity
            onPress={onPressSetting}
            style={tw`w-12 h-12 justify-center items-center`}
          >
            <SystemIcon type='SAX' name='Setting2' />
          </TouchableOpacity>
        )}
      </View>
    </View>
  )
}
