import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import { ButtonIcon } from '#components/base/ButtonIcon/ButtonIcon'
import { tw } from '#components/utils/tw'

interface Props {
  url: string
  currentIndex: number
}
export type VideoPropsRef = {
  stop: () => void
  play: () => void
}

export const Video = forwardRef<VideoPropsRef, Props>(
  ({ url, currentIndex }, ref) => {
    const videoRef = useRef<HTMLVideoElement>(null)
    const [isPlaying, setIsPlaying] = useState<boolean>(false)
    useEffect(() => {
      handlePlay()
    }, [currentIndex, url])

    useImperativeHandle(ref, () => ({ stop: handleStop, play: handlePlay }), [
      url,
    ])

    const handlePlay = () => {
      if (!url || !videoRef.current) {
        return
      }
      videoRef.current?.pause()
      videoRef.current.currentTime = 0
      videoRef.current.src = url
      videoRef.current?.play()
      setIsPlaying(true)
    }
    const handleStop = () => {
      if (videoRef.current) {
        videoRef.current?.pause()
        videoRef.current.currentTime = 0
        setIsPlaying(false)
      }
    }

    const handleVideoEnd = () => {
      if (videoRef.current) {
        videoRef.current.currentTime = 0
        setIsPlaying(false)
      }
    }

    return (
      <div
        style={{ position: 'relative', width: '100%', paddingTop: '56.25%' }}
      >
        <video
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'black',
            borderRadius: '20px',
          }}
          ref={videoRef}
          onClick={handlePlay}
          onPlay={() => setIsPlaying(true)}
          onEnded={handleVideoEnd}
        >
          <source src={url} type='video/mp4' />
        </video>
        {!isPlaying && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ButtonIcon
              icon={{
                name: 'play',
                type: 'SVG',
                size: 24,
                color: tw.color('neutral-75'),
              }}
              tone='secondary'
              size='medium'
              onPress={handlePlay}
            />
          </div>
        )}
      </div>
    )
  },
)
