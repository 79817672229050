import { Checkbox, Flex, Form, Input } from 'antd'
import type React from 'react'
import { useState } from 'react'

import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { Label } from '#admin/components/widgets/Label'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { tw } from '#components/utils/tw'
import { gql } from '#graphql/urql'

type Pr = {
  closeModal: () => void
}

export const UserModal: React.FC<Pr> = ({ closeModal }) => {
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [isAdmin, setIsAdmin] = useState<boolean>(false)

  const handleSubmit = async () => {
    try {
      if (!name || !email || !validEmail(email)) {
        toastError({ message: 'Name and email required fields.' })
        return
      }

      const res = await gql.createUser({
        data: { name, email, isAdmin },
      })

      if (res.error || !res.data) {
        toastError({ title: 'Error', message: res.error?.message })
        return
      }

      toastSuccess({ message: 'User created successfully.' })
      setEmail('')
      setName('')
      setIsAdmin(false)
      // closeModal()
    } catch {
      toastError({
        message: 'Failed to create user.',
      })
    }
  }
  const handleModalCancel = () => {
    closeModal()
  }

  return (
    <ModalLayout
      title={'Add User'}
      closeModal={handleModalCancel}
      onConfirm={handleSubmit}
      disableOk={!name || !email || !validEmail(email)}
      autoClose={false}
      isEnterEvent={false}
    >
      <Flex style={tw`flex-1/2`}>
        <Form layout='vertical' style={tw`w-120 bg-white rounded-5`}>
          <Form.Item label={<Label label='Name' required />}>
            <Input
              placeholder='Enter your name'
              value={name}
              onChange={e => {
                setName(e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item label={<Label label='Email' required />}>
            <Input
              placeholder='Enter your email'
              value={email}
              onChange={e => {
                setEmail(e.target.value)
              }}
            />
          </Form.Item>
          <Checkbox
            onChange={e => setIsAdmin(e.target.checked)}
            checked={isAdmin}
          >
            Is admin
          </Checkbox>
        </Form>
      </Flex>
    </ModalLayout>
  )
}

const validEmail = (email: string): boolean => {
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return emailRegex.test(email)
}
