import { observer } from 'mobx-react-lite'
import type React from 'react'
import { Platform, View } from 'react-native'

import { Button } from '#components/base/Button/Button'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { ActionSheet } from '#components/widgets/ActionSheet'

type Pr = {
  closeActionsheet: () => void
}

export const CannotContinue: React.FC<Pr> = observer(({ closeActionsheet }) => {
  const handleContinue = () => {
    closeActionsheet()
  }

  return (
    <ActionSheet
      containerStyle={tw.style(
        'bg-background-light-1',
        Platform.OS === 'web' ? 'flex-1' : 'h-85',
      )}
      isLoading={false}
    >
      <View style={tw.style('pt-6 pb-2.5 items-center')}>
        <SystemIcon name='paymentFail' type='SVG' size={74} />
      </View>
      <View
        style={tw.style('pb-5 w-full flex items-center justify-center px-10')}
      >
        <Text specialType='Headline3' textAlign='center'>
          Unable to continue payment
        </Text>
        <Text specialType='paragraph1' textAlign='center'>
          You cannot leave the fields above blank
        </Text>
      </View>
      <View style={tw`flex-col flex-1 w-full`}>
        <Button
          onPress={handleContinue}
          tone='secondary'
          size='large'
          style={tw`w-full mb-4 bg-red-400`}
          titleColor={tw.color('text-4')}
        >
          <Text color={tw.color('text-4')} specialType='Button'>
            OK
          </Text>
        </Button>
      </View>
    </ActionSheet>
  )
})
