import { createNativeStackNavigator } from '@react-navigation/native-stack'

import { ForgotPassword } from '#screens/ForgotPassword'
import { Login } from '#screens/Login'
import { NewPassword } from '#screens/NewPassword'
import { PrivacyPolicy } from '#screens/PrivacyPolicy/PrivacyPolicy'
import { TermsOfUse } from '#screens/TermsOfUse/TermsOfUse'
import { Welcome } from '#screens/Welcome/Welcome'

import type { AuthStackParamList } from './types'

const Stack = createNativeStackNavigator<AuthStackParamList>()

export const AuthStack = () => (
  <Stack.Navigator screenOptions={{ headerShown: false, animation: 'ios' }}>
    <Stack.Screen name='Welcome' component={Welcome} />
    <Stack.Screen name='Login' component={Login} />
    <Stack.Screen name='NewPassword' component={NewPassword} />
    <Stack.Screen name='ForgotPassword' component={ForgotPassword} />
    <Stack.Screen name='PrivacyPolicy' component={PrivacyPolicy} />
    <Stack.Screen name='TermsOfUse' component={TermsOfUse} />
  </Stack.Navigator>
)
