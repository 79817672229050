import { Flex, Form, Input } from 'antd'
import type { FC } from 'react'
import { useState } from 'react'

import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { Label } from '#admin/components/widgets/Label'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { tw } from '#components/utils/tw'
import { gql } from '#graphql/urql'
import type { SearchStreakDailyItem } from '#types/streakDaily'

type Pr = {
  closeModal: () => void
  item?: SearchStreakDailyItem
  reload: () => void
}

const { TextArea } = Input

export const DailyModal: FC<Pr> = ({ closeModal, item, reload }) => {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState<string>(item?.name || '')

  const handleSubmit = async () => {
    try {
      if (loading || !name) {
        return
      }
      setLoading(true)
      const params = { name }
      let message: string = ''
      if (item?.id) {
        const resp = await gql.updateStreakDaily({
          id: item.id,
          data: params,
        })
        message =
          resp.error || !resp.data
            ? resp.error?.message || 'Update failed!'
            : ''
      } else {
        const resp = await gql.createStreakDaily({
          data: params,
        })
        message =
          resp.error || !resp.data
            ? resp.error?.message || 'Create failed!'
            : ''
      }

      if (message) {
        toastError({ title: 'Error', message })
        return
      }

      const successMessage = item?.id
        ? 'Updated successfully.'
        : 'Created successfully.'
      toastSuccess({ message: successMessage })
      reload()
      closeModal()
    } catch {
      toastError({
        message: item ? 'Update failed!.' : 'Create failed!',
      })
    }
  }
  const handleModalCancel = () => {
    closeModal()
  }

  return (
    <ModalLayout
      title={item ? 'Edit' : 'Add'}
      closeModal={handleModalCancel}
      onConfirm={handleSubmit}
      disableOk={loading || !name}
      autoClose={false}
      isEnterEvent={false}
    >
      <Flex style={tw`flex-1/2`}>
        <Form layout='vertical' style={tw`w-120 bg-white rounded-5`}>
          <Form.Item label={<Label label='Slogan' required />}>
            <TextArea
              placeholder='Enter your slogan'
              value={name}
              onChange={e => {
                setName(e.target.value)
              }}
            />
          </Form.Item>
        </Form>
      </Flex>
    </ModalLayout>
  )
}
