import { useState } from 'react'

import { toastError } from '#components/utils/Toast'
import { gql } from '#graphql/urql'

import { onLogoutSuccess } from './onLogoutSuccess'

export const onLogoutPress = async () => {
  const r = await gql.logout()
  if (r.error || !r.data) {
    return
  }
  await onLogoutSuccess()
}

export const useLogout = () => {
  const [loading, setLoading] = useState(false)

  const onLogout = async () => {
    try {
      setLoading(true)
      const response = await gql.logout()
      if (response.error || !response.data) {
        throw new Error()
      }
      await onLogoutSuccess()
    } catch {
      setLoading(false)
      toastError({ message: 'Unable to logout' })
    }
  }

  return [loading, onLogout] as const
}
