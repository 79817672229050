import type { FC } from 'react'
import { TouchableOpacity, View } from 'react-native'

import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import type { SearchTopicInUserItem } from '#types/topicInUser'

type LessonItemProps = {
  item: SearchTopicInUserItem
  index: number
  onPress?: (item: SearchTopicInUserItem) => void
}

export const LessonItem: FC<LessonItemProps> = ({ item, onPress }) => (
  <TouchableOpacity
    onPress={() => onPress?.(item)}
    style={tw`flex-row items-center py-4`}
  >
    <View style={tw`flex-1 mr-2`}>
      <Text specialType='paragraph1'>{item.topic?.name || ''}</Text>
    </View>
    <SystemIcon type='SAX' name='ArrowRight2' />
  </TouchableOpacity>
)
