import type { FC } from 'react'
import { TouchableOpacity, View } from 'react-native'

import { ScrollView } from '#components/base/ScrollView'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

export type FilterType = 'words' | 'phrases' | 'saved'

type FiltersProps = {
  type: FilterType
  onChangeFilterType?: (type: FilterType) => void
  onChangeFilterSort?: () => void
}

export const Filters: FC<FiltersProps> = ({
  type,
  onChangeFilterType,
  onChangeFilterSort,
}) => (
  <View
    style={tw`flex-row items-center py-4 px-3 bg-background-light-white gap-1 rounded-t-2xl`}
  >
    <ScrollView horizontal>
      <View style={tw`flex-1 flex-row items-center py-1 gap-2`}>
        <Button
          title='Words'
          isSelected={type === 'words'}
          onPress={() => onChangeFilterType?.('words')}
        />
        <Button
          title='Phrases'
          isSelected={type === 'phrases'}
          onPress={() => onChangeFilterType?.('phrases')}
        />
        <Button
          title='Saved'
          isSelected={type === 'saved'}
          onPress={() => onChangeFilterType?.('saved')}
        />
      </View>
    </ScrollView>
    {type !== 'saved' && (
      <SystemIcon
        type='SAX'
        name='ArrangeVertical'
        onPress={onChangeFilterSort}
      />
    )}
  </View>
)

type ButtonProps = {
  title: string
  isSelected: boolean
  onPress?: () => void
}

const Button: FC<ButtonProps> = ({ title, isSelected, onPress }) => (
  <TouchableOpacity
    style={tw.style(
      'px-4 py-2.5 items-center justify-center border border-text-2 rounded-2xl',
      isSelected && 'border-primary-400 bg-background-light-2',
    )}
    onPress={onPress}
  >
    <Text
      specialType='Title'
      color={tw.color(isSelected ? 'primary-400' : 'text-2')}
    >
      {title}
    </Text>
  </TouchableOpacity>
)
