import { ulid } from 'ulidx'

import { isValidAudioUrl, isValidImageUrl } from '#components/utils/checkMedia'
import { shuffleArray } from '#components/utils/random'
import type { AnswerGame, GenerateProps } from '#types/games'
import type { QuesTionType } from '#types/question'

import { getItemInList } from './getItemInList'

type RandomWordsProps = Pick<GenerateProps, 'item' | 'data'> & {
  type: QuesTionType
}

export const getRandomWords = async ({
  item,
  type,
  data,
}: RandomWordsProps): Promise<AnswerGame[]> => {
  const words = data.filter(i => i.id !== item.wordId)
  const images = item.media.filter(i => isValidImageUrl(i.media?.url || ''))
  const audios = item.media.filter(i => isValidAudioUrl(i.media?.url || ''))
  const answerCorrect: AnswerGame = {
    audio: getItemInList(audios)?.media || null,
    image: getItemInList(images)?.media || null,
    isCorrect: true,
    text: item.text,
    translation:
      item.translation.length > 0 ? item.translation[0].text || '' : '',
    type,
    id: ulid(),
    answer: item.text,
  }

  const answersData: AnswerGame[] = words.map(i => {
    const imagesA = i.media.filter(
      media => i.id === media.wordId && isValidImageUrl(media.media?.url || ''),
    )
    const translation = i.translation
    return {
      audio: null,
      image: getItemInList(imagesA)?.media || null,
      isCorrect: false,
      text: i.vocabAndPhrase,
      translation: translation.length > 0 ? translation[0].text : '',
      type,
      id: ulid(),
      answer: i.vocabAndPhrase,
    }
  })
  const a = shuffleArray(answersData)
  return [...a, answerCorrect]
}
