import type {
  Stripe,
  StripeElements,
  StripeExpressCheckoutElement,
  StripeExpressCheckoutElementOptions,
} from '@stripe/stripe-js'

import { isCurrencySupport } from './utils/CheckCurrency'

type PaymentRequestOptions = {
  currency: string
  amount: number | undefined
}

export type TypeOrtherPayment = 'googlePay' | 'applePay'

var ex: StripeExpressCheckoutElement | undefined

export const createOrtherPaymentRequest = async (
  stripe: Stripe,
  { currency, amount }: PaymentRequestOptions,
  tp: TypeOrtherPayment,
  checkPayment: (s: boolean, typee: string) => void,
) => {
  if (!amount) {
    return null
  }
  unmountOrtherPay()
  const elements = stripe.elements({
    mode: 'subscription',
    amount: amount * 100,
    currency: isCurrencySupport(currency),
    setupFutureUsage: 'off_session',
    appearance: {
      theme: 'stripe',
      variables: {
        borderRadius: '26px',
      },
      labels: 'floating',
    },
    paymentMethodTypes: ['card'],
  })
  const o: StripeExpressCheckoutElementOptions = {
    buttonType: {
      googlePay: 'buy',
      applePay: 'buy',
    },
  }

  if (tp === 'googlePay') {
    o.paymentMethods = {
      applePay: 'never',
      googlePay: 'always',
      link: 'never',
    }
  } else {
    o.paymentMethods = {
      googlePay: 'never',
      applePay: 'always',
      link: 'never',
    }
  }
  const containerId = tp === 'googlePay' ? '#ggpay' : '#applepay'

  const expressCheckoutElement = elements.create('expressCheckout', o)
  ex = expressCheckoutElement

  setTimeout(() => {
    expressCheckoutElement.mount(containerId)
  }, 100)

  expressCheckoutElement.on('ready', event => {
    const aPM = event.availablePaymentMethods
    if (!event || !aPM) {
      checkPayment(false, tp)
    } else if (event && aPM) {
      checkPayment(tp === 'googlePay' ? aPM.googlePay : aPM.applePay, tp)
    }
  })

  expressCheckoutElement.on('click', event => {
    const options = {
      emailRequired: true,
    }
    event.resolve(options)
  })
  return elements
}

export const confirmOrtherPayment = async (
  elements: StripeElements,
  stripe: Stripe,
  cli: string,
) => {
  if (!ex) {
    return false
  }
  if (!stripe || !elements) {
    return false
  }
  return new Promise((r, rj) => {
    ex?.on('confirm', async event => {
      const { error: submitError } = await elements.submit()
      if (submitError) {
        r(false)
      }

      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret: cli,
        confirmParams: {
          return_url: 'https://cody.nongdan.dev/payment',
        },
        redirect: 'if_required',
      })
      r(!error)
    })
  })
}

export const unmountOrtherPay = () => {
  if (ex) {
    ex.unmount()
    ex.off('click')
    ex.off('confirm')
  }
}
