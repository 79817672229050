import { omit } from 'lodash'
import type { ReactElement } from 'react'
import { cloneElement } from 'react'
import type { FieldValues, UseControllerProps } from 'react-hook-form'
import { useController } from 'react-hook-form'
import { View } from 'react-native'
import type { Style } from 'twrnc'

import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

export type FormFieldProps<T extends FieldValues> = Omit<
  UseControllerProps<T>,
  'control'
> &
  Required<Pick<UseControllerProps<T>, 'control'>> & {
    style?: Style
    labelStyle?: Style
    children: ReactElement
    onChangePropName?: string
    valuePropName?: string
  }

export const FormField = <T extends FieldValues>({
  children,
  onChangePropName = 'onChangeText',
  valuePropName = 'value',
  style,
  labelStyle,
  ...props
}: FormFieldProps<T>) => {
  const { field, fieldState } = useController(props)

  return (
    <View style={tw.style(style)}>
      {cloneElement(children, {
        ...omit(field, ['onChange', 'value']),
        [valuePropName]: field.value,
        [onChangePropName]: field.onChange,
        _invalid: !!fieldState.error,
      })}
      {!!fieldState.error && (
        <View style={tw`mt-1 `}>
          <Text style={tw`text-error-300 text-base`} specialType='paragraph2'>
            {fieldState.error.message}
          </Text>
        </View>
      )}
    </View>
  )
}
