import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Flex, Image } from 'antd'
import { useState } from 'react'

import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { PaginatedTable } from '#admin/components/widgets/PaginatedTable'
import { SearchBar } from '#admin/components/widgets/SearchBar'
import { Text } from '#components/base/Text'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import { useCountWord, useSearchTopic, useSearchWord } from '#graphql/codegen'
import { gql } from '#graphql/urql'
// import { navigate } from '#navigator/helpers'
import type { SearchWordItem } from '#types/word'

import { TopicDetailModal } from './TopicDetailModal'

type Props = { id: string }
export const WordAndPhrases = ({ id }: Props) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchKeyword, setSearchKeyword] = useState('')
  const { openModal } = useOverlay()

  const [word, refetchWord] = useSearchWord({
    variables: {
      filter: {
        topicId: id,
        ...(searchKeyword
          ? { vocabAndPhrase_iLike: `%${searchKeyword}%` }
          : {}),
      },
      order: ['vocabAndPhrase_asc'],
      page: { limit: pageSize, offset: (currentPage - 1) * pageSize },
    },
  })

  const searchWord: SearchWordItem[] = word.data?.searchWord ?? []

  const [count, refetchCount] = useCountWord({
    variables: {
      filter: {
        topicId: id,
        ...(searchKeyword
          ? { vocabAndPhrase_iLike: `%${searchKeyword}%` }
          : {}),
      },
    },
  })

  const countWord = count.data?.countWord ?? 0

  const [topics] = useSearchTopic({ variables: { filter: { id } } })
  const topic = topics.data?.searchTopic?.[0]

  const handleAddData = () => {
    openModal(TopicDetailModal, {
      onOk: onModalOk,
      topicId: id,
      containerProps: { closeOnOverlayClick: false },
      language: topic?.languageTopic || '',
    })
  }

  // const onRowClick = (record: SearchWordItem) => {
  //   navigate('Admin', {
  //     screen: 'AdminTopicQues',
  //     params: { topicId: id, wordId: record.id },
  //   })
  // }

  const onModalOk = () => {
    refetchWord({ requestPolicy: 'network-only' })
    refetchCount({ requestPolicy: 'network-only' })
  }

  const reloadDelete = () => {
    refetchWord({ requestPolicy: 'network-only' })
    refetchCount({ requestPolicy: 'network-only' })

    if (count.data?.countWord !== undefined) {
      const remainingItemsCount = count.data.countWord - 1
      let newPageCount = Math.ceil(remainingItemsCount / pageSize)
      if (newPageCount < 1) {
        newPageCount = 1
      }
      if (currentPage > newPageCount) {
        setCurrentPage(newPageCount)
      }
    }
  }

  const actionsColumn = {
    title: 'Actions',
    key: 'actions',
    width: 100,
    render: (_, record) => (
      <Flex style={tw`justify-between`}>
        <Button
          icon={<EditOutlined />}
          onClick={e => {
            e.stopPropagation()
            openModal(TopicDetailModal, {
              selectedWord: record,
              onOk: onModalOk,
              topicId: id,
              containerProps: {
                closeOnOverlayClick: false,
              },
              language: topic?.languageTopic || '',
            })
          }}
          className='p-0 bg-transparent'
        />
        <Button
          icon={<DeleteOutlined />}
          onClick={async e => {
            e.stopPropagation()
            openModal(ModalLayout, {
              onConfirm: async () => {
                await gql.deleteWord({ ids: [record.id] })
                await gql.deleteWordInUser({ id: record.wordInUser?.id })
                reloadDelete()
              },
              title: 'Confirm delete',
              content: 'Are you sure you want to delete this word?',
              successMessage: 'Word deleted successfully',
              errorMessage: 'Delete word failed',
              containerProps: {
                closeOnOverlayClick: false,
              },
            })
          }}
          className='p-0 bg-transparent'
          danger={true}
        />
      </Flex>
    ),
  }

  const columns = [
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      width: 100,
      render: (_, record) => {
        const image = record?.media?.url
        return (
          <Flex
            onClick={e => {
              e?.stopPropagation()
            }}
          >
            <Image
              src={image}
              style={tw.style('w-12 h-12 border-2 border-gray-300', {
                cursor: 'pointer',
              })}
              onClick={e => {
                e?.stopPropagation()
              }}
            />
          </Flex>
        )
      },
    },
    {
      title: 'Word',
      dataIndex: 'vocabAndPhrase',
      key: 'vocabAndPhrase',
      render: (_, record) => (
        <div style={{ cursor: 'pointer' }}>{record.vocabAndPhrase}</div>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'isVocabulary',
      key: 'isVocabulary',
      render: isVocabulary => (
        <Text style={{ cursor: 'pointer' }}>
          {isVocabulary ? 'Word' : 'Phrase'}
        </Text>
      ),
    },
    actionsColumn,
  ]

  return (
    <Flex style={tw`flex-1 flex-col p-4`}>
      <Flex style={tw`gap-4`}>
        <SearchBar keyword={setSearchKeyword} />
        <Button type='primary' onClick={handleAddData}>
          Add
        </Button>
      </Flex>
      <PaginatedTable<SearchWordItem>
        dataSource={searchWord}
        columns={columns}
        loading={word.fetching}
        total={countWord}
        currentPage={currentPage}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        // onRowClick={onRowClick}
        scrollHeight={'45vh'}
      />
    </Flex>
  )
}
