import { Flex, Layout } from 'antd'
import type { FC, PropsWithChildren } from 'react'

import { tw } from '#components/utils/tw'

import { Siderbar } from './Siderbar'

export const AdminLayout: FC<PropsWithChildren> = props => {
  const { children } = props

  return (
    <Layout>
      <Siderbar />
      <Layout style={tw`bg-background-light-1 dark:bg-background-dark-1`}>
        <Flex
          vertical
          style={tw`w-full h-full bg-background-light-1 dark:bg-background-dark-1`}
        >
          <Flex flex={1} style={{ overflow: 'hidden' }}>
            {children}
          </Flex>
        </Flex>
      </Layout>
    </Layout>
  )
}
