import { useState } from 'react'

export const useRecordManager = () => {
  const [isRecording] = useState(false)
  const [recordTime] = useState(0)
  const [audioFile] = useState<string | null>(null)

  const startRecording = async () => {}

  const stopRecording = async () => {}

  return {
    startRecording,
    stopRecording,
    isRecording,
    recordTime,
    audioFile,
  }
}
