import type { FC } from 'react'
import { View } from 'react-native'

import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import type { SearchTranslateItem } from '#types/translate'
import type { SearchWordInUser } from '#types/wordInUser'

import { ProgressCircle } from './ProgressCircle'

type ListItemProps = {
  item: SearchWordInUser
  index: number
  total: number
  translate: SearchTranslateItem[]
}

export const ListItem: FC<ListItemProps> = ({
  item,
  index,
  total,
  translate,
}) => (
  <View
    style={[
      tw`flex-row bg-white p-4.5`,
      index === 0 ? tw`rounded-t-5` : undefined,
      index === total - 1 ? tw`rounded-b-5` : undefined,
    ]}
  >
    <View style={tw.style('flex-1')}>
      <Text specialType='Title'>{item?.word?.vocabAndPhrase}</Text>
      <Text specialType='paragraph2'>
        {translate.length > 0 ? translate[0].text : ''}
      </Text>
    </View>
    <ProgressCircle progress={item.times - 1} />
  </View>
)
