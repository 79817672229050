import CircularJson from 'circular-json'
import moment from 'moment'
import { Platform } from 'react-native'
import { format } from 'util'

const formatErrors = (...errs: Error[]) => {
  // normalize and fix circular json
  let msgs = errs.map(e =>
    !e
      ? `${e}`
      : e.message
        ? e.message
        : typeof e === 'object'
          ? CircularJson.stringify(e)
          : `${e}`,
  )
  let tpl = msgs.shift() || ''
  // remove %c on web from the `debug` lib
  if (Platform.OS === 'web') {
    const m: { [k: number]: boolean } = {}
    const regex = /%\w/g
    let mi = 0
    let match: RegExpExecArray | null = null
    while ((match = regex.exec(tpl)) !== null) {
      if (match[0] === '%c') {
        m[mi] = true
      }
      mi++
    }
    tpl = tpl.replace(/%c/g, '')
    msgs = msgs.filter((m0, i) => !m[i])
  }
  // apply format and remove whitespaces
  let msg = format(tpl, ...msgs)
    .replace(/\s+/g, ' ')
    .trim()
  // remove stack trace and cleanup output from the `debug` lib
  msg = msg.replace(/((\s+@\s+)|( : \w+@index)|(\W\w*@http)).+/, '')
  msg = msg.replace(/ : Error at \w+\.log \(http.+/, '')
  msg = msg.replace(/^.+\[(trial|debug|log|info|warn|error)\]\s*/, '')
  return moment().format('YYYY/MM/DD HH:mm:ss.SSS') + ' ' + msg
}

const consoleKeys = ['debug', 'log', 'info', 'warn', 'error'] as const
const registerConsoleCapture = () => {
  const customConsoleObject = consoleKeys.reduce(
    (m, k) => {
      const f = console[k].bind(console)
      m[k] = (...args: Error[]) => {
        const msg = formatErrors(...args)
        if (!msg) {
          return
        }
        f(msg)
        if (Platform.OS === 'web' || process.env.NODE_ENV !== 'production') {
          return
        }
        // TODO write to file
      }
      return m
    },
    {} as { [k: string]: Function },
  )
  Object.entries(customConsoleObject).forEach(([k, v]) => {
    Object.defineProperty(console, k, {
      get: () => v,
      set: () => {
        // prevent set to keep using our functions
      },
    })
  })
  // write a log to console to note about this
  console.log('registerConsoleCapture output is being captured')
}

if (process.env.NODE_ENV === 'production') {
  registerConsoleCapture()
}
