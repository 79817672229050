import { useIsFocused } from '@react-navigation/native'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { View } from 'react-native'

import { useAppState } from '#components/utils/appState'
import {
  getNextDateFromMilestones,
  getNextOrPreviousMilestone,
} from '#components/utils/milestones'
import {
  generateRandomAnswerPositions,
  generateRandomAnswers,
} from '#components/utils/random'
import { tw } from '#components/utils/tw'
import { GameContainer } from '#components/widgets/Games/GameContainer'
import { generateGameReview } from '#components/widgets/Games/generate/review'
import { logCustomEvent } from '#config/firebaseConfig'
import { gql } from '#graphql/urql'
import type { AppStackScreenProps } from '#navigator/types'
import { S } from '#store'
import type { AnswerGame, QuestionGame } from '#types/games'
import { answersGame } from '#types/games'
import type { LanguageType } from '#types/language'
import { LanguageLabel } from '#types/language'
import type { ReviewItem } from '#types/review'
import { emailData } from '#types/review'
import type { SearchTopicItem } from '#types/topic'
import type { TimeMilestonesValue } from '#types/wordInUser'
import { DirectionMilestones, TimeMilestones } from '#types/wordInUser'

import { EndReview } from './EndReview'
import { StartReview } from './StartReview'

enum ProgressReview {
  start = 'start',
  review = 'review',
  end = 'end',
}

type ProgressType = keyof typeof ProgressReview

export const VocabPhraseReview = observer(
  ({ route }: AppStackScreenProps<'VocabPhraseReview'>) => {
    const topicId = route.params.topicId
    const isFocus = useIsFocused()
    const appState = useAppState()

    const [data, setData] = useState<ReviewItem[]>([])
    const [currentIndex, setCurrentIndex] = useState<number>(0)
    const [total, setTotal] = useState<number>(15)
    const [answers, setAnswers] = useState<AnswerGame[]>([])
    const [question, setQuestion] = useState<QuestionGame | null>(null)
    const [wordInUser, setWordInUser] = useState<ReviewItem | null>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [topic, setTopic] = useState<SearchTopicItem[]>([])
    const [correctAnswerIndex, setCorrectAnswerIndex] = useState<number[]>(
      generateRandomAnswerPositions(total),
    )
    const [progressReview, setProgressReview] = useState<ProgressType>(
      ProgressReview.start,
    )
    const countAnswer = useRef<number>(0)
    const startTime = useRef<number>(0)
    const totalTime = useRef<number>(0)

    useEffect(() => {
      if (isFocus && appState === 'active') {
        setProgressReview(ProgressReview.start)
        setLoading(true)
        setCurrentIndex(0)
        fetchData()
      }
    }, [isFocus, appState])

    useEffect(() => {
      if (!loading) {
        if (currentIndex === 0) {
          totalTime.current = Date.now()
        }
        startTime.current = Date.now()
      }
    }, [loading, currentIndex])

    const fetchData = async () => {
      const userId = S.shared.currentUser?.id || ''
      const language =
        (S.shared.currentUser?.languageLearn as LanguageType) ||
        LanguageLabel.English
      const resp = await generateGameReview({ userId, language })
      const list = [...resp.data, ...resp.data]
      setTopic(resp.topic)
      setData(list)
      const newTotal = list.length
      setTotal(newTotal)
      const correctAnswerIdx = generateRandomAnswerPositions(newTotal)
      setCorrectAnswerIndex(correctAnswerIdx)
      if (newTotal > 0) {
        const w: ReviewItem = list[0]
        setWordInUser(w)
        setQuestion(w.easy.question)
        const correctAnswers = w.easy.answers.filter(item => item.isCorrect)
        const incorrectAnswers = w.easy.answers.filter(item => !item.isCorrect)
        const a = answersGame.includes(w.easy.question.type || '')
          ? w.easy.answers
          : generateRandomAnswers({
              correctAnswers,
              incorrectAnswers,
              correctAnswerIndex: correctAnswerIdx[0],
            })
        setAnswers(a)
      } else {
        setProgressReview(ProgressReview.end)
        // toastError({ message: 'Error no data' })
        // replace('Home', { screen: 'Topics' })
      }
      setLoading(false)
    }

    const onChangeIndex = () => {
      if (currentIndex < total - 1) {
        const index = currentIndex + 1
        const w = data[index]
        const game = {
          0: w.easy,
          1: w.hard,
          2: w.game,
        }
        const g = game?.[w.total] || w.game
        setQuestion(g.question)
        const correctAnswers = g.answers.filter(item => item.isCorrect)
        const incorrectAnswers = g.answers.filter(item => !item.isCorrect)
        const a = answersGame.includes(g.question.type || '')
          ? g.answers
          : generateRandomAnswers({
              correctAnswers,
              incorrectAnswers,
              correctAnswerIndex: correctAnswerIndex[index],
            })
        setAnswers(a)
        setWordInUser(w)
        setCurrentIndex(currentState => currentState + 1)
      } else {
        // replace('Home', { screen: 'Topics' })
        setProgressReview(ProgressReview.end)
      }
    }

    const updateMilestone = async ({
      id,
      isCorrect,
      currentMilestone,
    }: {
      id: string
      isCorrect: boolean
      currentMilestone: TimeMilestonesValue
    }) => {
      const milestone = getNextOrPreviousMilestone(
        currentMilestone,
        isCorrect ? DirectionMilestones.Next : DirectionMilestones.Previous,
      )
      const nextDay = getNextDateFromMilestones({
        currentMilestone,
        nextMilestone: milestone,
        isDays: !emailData.includes(S.shared.currentUser?.email || ''),
      })
      await gql.updateWordInUser({
        id,
        data: {
          isCompleted: isCorrect && TimeMilestones.Thirty === milestone,
          lastDay: moment().toDate(),
          milestone,
          nextDay,
        },
      })

      if (isCorrect && TimeMilestones.Thirty === milestone) {
        logCustomEvent(
          wordInUser?.word?.isVocabulary
            ? 'vocabulary_mastered'
            : 'phrase_mastered',
          {
            id: wordInUser?.wordId || '',
            name: wordInUser?.word?.vocabAndPhrase || '',
          },
        )
      }
    }

    const onChangeAnswer = async (isCorrect: boolean) => {
      countAnswer.current = isCorrect
        ? countAnswer.current + 1
        : countAnswer.current
      if (wordInUser) {
        const numCorrect = isCorrect ? wordInUser.count + 1 : wordInUser.count
        const totalQ = wordInUser.total + 1
        setData(currentState =>
          currentState.map(i =>
            i.id === wordInUser?.id
              ? { ...i, count: numCorrect, total: totalQ }
              : i,
          ),
        )
        if (totalQ === 2 || totalQ === 3) {
          if (numCorrect === 2) {
            updateMilestone({
              id: wordInUser.id,
              isCorrect: true,
              currentMilestone: wordInUser.milestone || TimeMilestones.Zero,
            })
          } else if (numCorrect === 1 && totalQ === 2) {
            setData(currentState => [
              ...currentState,
              { ...wordInUser, count: numCorrect, total: totalQ },
            ])
            setTotal(currentState => currentState + 1)
            const correctAnswerIdx = generateRandomAnswerPositions(1)
            setCorrectAnswerIndex(pre => [...pre, ...correctAnswerIdx])
          } else if (numCorrect === 0) {
            updateMilestone({
              id: wordInUser.id,
              isCorrect: false,
              currentMilestone: wordInUser.milestone || TimeMilestones.Zero,
            })
          }
        }
        const endTime = Date.now()
        const timer = (endTime - startTime.current) / 60000 // convert ms to m
        logCustomEvent(
          wordInUser?.word?.isVocabulary
            ? 'review_vocabulary_result'
            : 'review_phrase_result',
          {
            question: question?.text || '',
            answer_results: isCorrect,
            game_type: question?.type || '',
            text: wordInUser?.word?.vocabAndPhrase || '',
            answer_time_per_minute: timer,
          },
        )
        logCustomEvent(
          wordInUser?.word?.isVocabulary
            ? 'review_vocabulary_game_type_result'
            : 'review_phrase_game_type_result',
          {
            question: question?.text || '',
            answer_results: isCorrect,
            game_type: question?.type || '',
            text: wordInUser?.word?.vocabAndPhrase || '',
            answer_time_per_minute: timer,
          },
        )
        if (currentIndex === total - 1) {
          const time = (endTime - totalTime.current) / 60000 // convert ms to m
          logCustomEvent('review_session_time', {
            count_answer: countAnswer.current,
            total_time_per_section: time,
          })
          if (countAnswer.current === total) {
            logCustomEvent('session_review_result', {
              count_answer: countAnswer.current,
              total_time_per_section: time,
            })
          }
        }
      }
    }

    const onPressStartReviewNow = () => {
      const currentDate = moment().format('YYYY-MM-DD')
      if (S.shared.currentUser) {
        S.shared.currentUser = {
          ...S.shared.currentUser,
          lastUsedDate: currentDate,
        }
        gql.updateUser({
          data: { lastUsedDate: currentDate },
        })
      }

      setProgressReview(ProgressReview.review)
    }

    const getTopic = () => {
      const t = topic.find(item => item.id === wordInUser?.topicId)
      return t
    }

    const renderContent = () => {
      switch (progressReview) {
        case ProgressReview.start:
          return <StartReview onPress={onPressStartReviewNow} />
        case ProgressReview.end:
          return <EndReview topicId={topicId} />
        default:
          return (
            <GameContainer
              currentIndex={currentIndex}
              total={total}
              question={question}
              answers={answers}
              loading={loading}
              onChangeIndex={onChangeIndex}
              onChangeAnswer={onChangeAnswer}
              type='Game'
              topic={getTopic()}
            />
          )
      }
    }
    return <View style={tw`flex-1 flex-col`}>{renderContent()}</View>
  },
)
