import { useIsFocused } from '@react-navigation/native'
import { useEffect, useRef, useState } from 'react'

import { useAppState } from '#components/utils/appState'
import {
  generateRandomAnswerPositions,
  generateRandomAnswers,
  getRandomList,
  shuffleArray,
} from '#components/utils/random'
import { isLatestDateToday } from '#components/utils/streak'
import { toastError } from '#components/utils/Toast'
import { useSearchStreakInUser } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import { navigate } from '#navigator/helpers'
import type { SearchAnswerInQuestion } from '#types/answerInQuestion'
import type { AnswerGame, GameType, QuestionGame } from '#types/games'
import { PercentageTopic } from '#types/games'
import type { ListenGameType } from '#types/listenGame'
import { answersListen } from '#types/listenGame'
import type { QuesTionType, SearchQuestionItem } from '#types/question'

const fetchAnswers = async (
  questionId: string,
  type: ListenGameType,
  correctAnswerIndex: number,
) => {
  if (!questionId) {
    return []
  }
  const resp = await gql.searchAnswerInQuestion({
    filter: { questionId },
    order: ['createdAt_asc'],
  })
  const answerData = resp.data?.searchAnswerInQuestion || []
  if (answerData.length > 0) {
    const correctAnswers = answerData.filter(item => item.isTrue)
    const incorrectAnswers = answerData.filter(item => !item.isTrue)
    const answers = answersListen.includes(type)
      ? answerData
      : generateRandomAnswers({
          correctAnswers,
          incorrectAnswers,
          correctAnswerIndex,
        })
    return answers
  }
  return []
}

type RandomGame = {
  questionData: SearchQuestionItem[]
  currentIndex: number
  total: number
  question: QuestionGame | null
  answers: AnswerGame[]
  loading: boolean
  onChangeData: () => void
  correctAnswerIndex: number[]
}

export type QuestionData = {
  type: GameType
}

type NextGame = {
  question: SearchQuestionItem | null
  answers: SearchAnswerInQuestion[]
}

export const useRandomListen = (
  topicId: string,
  userId: string,
  point: number,
  isCompleted?: boolean,
): RandomGame => {
  const [questionData, setQuestionData] = useState<SearchQuestionItem[]>([])
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [totalCounts, setTotalCounts] = useState<number>(6)
  const [answers, setAnswers] = useState<AnswerGame[]>([])
  const [question, setQuestion] = useState<QuestionGame | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [correctAnswerIndex] = useState<number[]>(
    generateRandomAnswerPositions(totalCounts),
  )
  const nextGame = useRef<NextGame>({
    question: null,
    answers: [],
  })
  const [streakData] = useSearchStreakInUser({
    variables: { filter: { userId } },
  })
  const streakInUser = streakData?.data?.searchStreakInUser || []
  const lastDate = streakInUser.length > 0 ? streakInUser[0].lastDate : ''

  const isFocus = useIsFocused()
  const appState = useAppState()

  useEffect(() => {
    if (isFocus && appState === 'active') {
      setLoading(true)
      setCurrentIndex(0)
      fetchWords()
    }
  }, [isFocus, appState])

  useEffect(() => {
    if (currentIndex + 1 <= totalCounts - 1 && !loading) {
      fetchNext(questionData[currentIndex + 1])
    }
  }, [currentIndex])

  const fetchWords = async () => {
    const respQuestion = await gql.searchQuestion({
      filter: { topicId, wordId: '' },
      order: ['createdAt_asc'],
    })
    const quesData = respQuestion.data?.searchQuestion || []
    const questionList = getRandomList(quesData, PercentageTopic.MaxListen)
    setTotalCounts(questionList.length)
    if (questionList.length > 0) {
      const newList = shuffleArray(questionList)
      setTotalCounts(newList.length)
      setQuestionData(newList)
      const newQuestion = newList[0]
      setQuestion({
        index: 0,
        media: newQuestion.media || null,
        text: newQuestion.text,
        translation: '',
        type: newQuestion.type as QuesTionType,
      })
      setTotalCounts(newList.length)
      const newAnswers = await fetchAnswers(
        newQuestion.id,
        newQuestion.type as ListenGameType,
        correctAnswerIndex[currentIndex],
      )
      setAnswers(
        newAnswers.map(answer => ({
          text: answer.answer?.text || '',
          isCorrect: answer.isTrue,
          audio: answer.media || null,
          image: answer.thumbnail || null,
          translation: '',
          type: newQuestion.type as QuesTionType,
          id: answer.id,
          answer: answer.answer?.text || '',
        })),
      )
      if (currentIndex + 1 <= newList.length - 1) {
        fetchNext(newList[currentIndex + 1])
      }
    } else {
      navigate('Home', { screen: 'Topics' })
      toastError({
        message: 'The data for the Listening section is currently empty',
      })
    }
    setLoading(false)
  }

  const fetchNext = async (nextQuestion: SearchQuestionItem) => {
    const newAnswers = await fetchAnswers(
      nextQuestion.id,
      nextQuestion.type as ListenGameType,
      correctAnswerIndex[currentIndex + 1],
    )
    nextGame.current = { question: nextQuestion, answers: newAnswers }
  }

  const onChangeData = () => {
    if (currentIndex < totalCounts - 1) {
      setQuestion({
        index: 0,
        media: nextGame.current.question?.media || null,
        text: nextGame.current.question?.text || '',
        translation: '',
        type:
          (nextGame.current.question?.type as QuesTionType) || 'multipleChoice',
      })
      setAnswers(
        nextGame.current.answers.map(i => ({
          text: i.answer?.text || '',
          isCorrect: i.isTrue,
          audio: i.media || null,
          image: i.thumbnail || null,
          translation: '',
          type: (i.question?.type as QuesTionType) || 'multipleChoice',
          id: i.id,
          answer: i.answer?.text || '',
        })),
      )
      setCurrentIndex(currentState => currentState + 1)
    } else {
      if (isCompleted) {
        navigate('Home', { screen: 'Topics' })
      } else {
        if (
          point / totalCounts >= PercentageTopic.PercentageListen &&
          !isLatestDateToday(lastDate)
        ) {
          navigate('App', { screen: 'StreakDaily', params: {} })
        } else {
          navigate('App', {
            screen: 'ReviewListening',
            params: { topicId },
          })
        }
      }
    }
  }

  return {
    correctAnswerIndex,
    questionData,
    currentIndex,
    total: totalCounts,
    question,
    answers,
    loading,
    onChangeData,
  }
}
