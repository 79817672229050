import { Flex, Select } from 'antd'
import type { FC } from 'react'

import { tw } from '#components/utils/tw'
import type { LanguageType } from '#types/language'

type Props = {
  title: string
  language: LanguageType
  isEdit: boolean
  onChange: (value: LanguageType) => void
}

export const Dropdown: FC<Props> = ({ title, language, isEdit, onChange }) => (
  <Flex style={tw`flex-col w-[50%] p-4`} gap={8}>
    <div style={tw.style({ textWrap: 'nowrap' })}>{title}</div>
    {!isEdit ? (
      <div style={tw`text-gray-500`}>{language}</div>
    ) : (
      <Select
        value={language}
        options={[
          { label: 'English', value: 'English' },
          { label: 'Spanish', value: 'Spanish' },
        ]}
        onChange={e => onChange(e as LanguageType)}
      />
    )}
  </Flex>
)
