import {
  getRandomGames,
  getRandomList,
  getRandomListWithMaxOccurrences,
  shuffleArray,
} from '#components/utils/random'
import type { TypeGame } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import type {
  GameContainer,
  GenerateHardGameProps,
  GenerateProps,
  WordItem,
} from '#types/games'
import { GameTimes } from '#types/games'
import type { LanguageType, Level } from '#types/language'
import type { SearchWordInUser } from '#types/wordInUser'

import { easyGameFunc, hardGameFunc } from './gameFunc'
import { getItemInList } from './getItemInList'

type GenerateGames = {
  topicId: string
  userId: string
  isPhrase: boolean
  level: Level
  language: LanguageType
  isCompleted?: boolean
  isPlayAgain?: boolean
}

type ResultGenerateGames = GameContainer[]

export const generateGames = async ({
  topicId,
  userId,
  isPhrase,
  language,
  isCompleted,
  isPlayAgain,
}: GenerateGames): Promise<ResultGenerateGames> => {
  const resp = await gql.generateGame({
    filterWordInUser: {
      topicId,
      times_lt: isCompleted ? undefined : GameTimes.TimeMax,
      userId,
      word_some: { isVocabulary: isPlayAgain ? undefined : !isPhrase },
      wordId_ne: '',
    },
    orderWordInUser: 'times_asc',
    filterWord: { topicId, isVocabulary: isPlayAgain ? undefined : !isPhrase },
    filterResourceInWord: {
      word_some: { topicId, isVocabulary: isPlayAgain ? undefined : !isPhrase },
    },
    filterTranslate: {
      Word_some: { topicId, isVocabulary: isPlayAgain ? undefined : !isPhrase },
    },
  })
  const generateGame = resp.data

  const wordInUserList: SearchWordInUser[] = generateGame?.wordInUser || []

  if (wordInUserList.length > 0) {
    const list: SearchWordInUser[] = isCompleted
      ? shuffleArray(wordInUserList).slice(0, 7)
      : wordInUserList.slice(0, 5)
    const randomList: SearchWordInUser[] = getRandomList(list)
    const data = isCompleted
      ? getRandomListWithMaxOccurrences({ list: randomList, isCompleted: true })
      : getRandomListWithMaxOccurrences({ list: randomList })
    const games = getRandomGames(data)
    const resource = generateGame?.resourceInWord || []
    const translate = generateGame?.translate || []
    const words = games.map(item => {
      const mediaItems = resource.filter(media => media.wordId === item.wordId)
      return {
        ...item,
        media: mediaItems.length > 0 ? mediaItems : [],
        translation: translate.filter(i => i.wQAId === item.wordId),
      }
    })
    const d = words.map(i => ({
      id: i.id,
      text: i.word?.vocabAndPhrase || '',
      type: i.hardGame as TypeGame,
      isPhrase,
      language,
    }))
    const respHardGame = await gql.generateHardGame({
      data: d,
    })
    const hardGame = respHardGame.data?.generateHardGame || []

    const wordsData: WordItem[] = (generateGame?.word || []).map(w => {
      const m = resource.filter(media => media.wordId === w.id)
      const t = translate.filter(tr => tr.wQAId === w.id)
      return { ...w, media: m, translation: t }
    })

    const results = await Promise.all(
      words.map(async (item, idx) => {
        const translation = translate.filter(i => i.wQAId === item.wordId)
        const itemParams = {
          ...item,
          text: item.word?.vocabAndPhrase || '',
          translation,
        }
        const paramsEasy: GenerateProps = {
          language,
          isPhrase,
          item: itemParams,
          data: isPlayAgain
            ? wordsData.filter(w => w.isVocabulary === item.word?.isVocabulary)
            : wordsData,
        }

        const resultEasy = await easyGameFunc[item.easyGame](paramsEasy)
        // const resultEasy = await easyType.correctTranslation(params)
        const dataHardGame = hardGame.filter(
          i => i.id === item.id && i.type === item.hardGame,
        )

        const paramsHard: GenerateHardGameProps = {
          item: itemParams,
          data: getItemInList(dataHardGame) || null,
        }

        const resultHard = await hardGameFunc[item.hardGame](paramsHard)
        // const resultHard = await hardType.completeTheDialogue(paramsHard)
        return { ...item, easy: resultEasy, hard: resultHard }
      }),
    )
    return results
  }

  return []
}
