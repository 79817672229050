import { observer } from 'mobx-react-lite'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import { Image } from '#components/base/Image'
import { LinearGradientColor } from '#components/base/LinearGradientColor/LinearGradientColor'
import { Text } from '#components/base/Text'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import { logCustomEvent } from '#config/firebaseConfig'
import { S } from '#store'
import type { SearchTopicItem } from '#types/topic'

import { ActionSheetTopic } from '../ActionSheetTopic'

type Props = {
  topic: SearchTopicItem
}

export const CompletedItem = observer(({ topic }: Props) => {
  const { openActionsheet } = useOverlay()

  const handlePress = async () => {
    logCustomEvent('lessons_selected', {
      id: topic.id,
      name: topic.name,
    })
    S.lessonPercentage.setLessonPercentageById({
      topicId: topic.id,
      userId: S.shared.currentUser!.id,
      language: S.shared.currentUser!.languageLearn,
    })
    openActionsheet(ActionSheetTopic, {
      topicId: topic.id,
      isCompleted: true,
      lastDate: S.shared.currentUser!.lastUsedDate,
    })
  }

  return (
    <TouchableOpacity
      style={tw`rounded-3xl overflow-hidden`}
      activeOpacity={0.6}
      onPress={handlePress}
    >
      <Image source={{ uri: topic.media?.url }} style={tw.style('h-56')} />
      <View style={tw.style(StyleSheet.absoluteFillObject)}>
        <LinearGradientColor
          colorX='rgba(0, 0, 0, 0.8)'
          colorY='rgba(0, 0, 0, 0.55)'
          degree={90}
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
        >
          <View style={tw`flex-1 p-4 flex-col h-full justify-end`}>
            <Text
              numberOfLines={2}
              specialType='Headline1'
              style={tw.style('text-gray-50 mb-1')}
            >
              {topic.name}
            </Text>
            <Text
              numberOfLines={2}
              specialType='paragraph2'
              style={tw`text-gray-200`}
            >
              {topic.description}
            </Text>
          </View>
        </LinearGradientColor>
      </View>
    </TouchableOpacity>
  )
})
