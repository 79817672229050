export const images = {
  no_image: require('./images/no_image.png'),
  loading: require('./images/gif_loading.gif'),
  congratulationListen: require('./images/gif_congrateListen.gif'),
  silderThumbIcon: require('./images/thumbIcon.png'),
  logo: require('./images/logo.png'),
  congratulation: require('./images/gif_congrate.gif'),
  non_avatar: require('./images/non_avatar.png'),
  image_game: require('./images/image_game.png'),
  image_streak: require('./images/streak.png'),
}

export const sounds = {
  x_sound: require('./audio/x_sound.mp3'),
  check_sound: require('./audio/check_sound.mp3'),
  loadding: require('./images/gif_loading.gif'),
}
