import type { ISkeletonProps } from 'native-base'
import { Skeleton as NbSkeleton } from 'native-base'
import type { Style } from 'twrnc'

import { tw } from '#components/utils/tw'

export type SkeletonProps = Omit<ISkeletonProps, 'style'> & {
  style?: Style
}

export const Skeleton = ({ style, ...props }: SkeletonProps) => (
  <NbSkeleton
    style={tw.style('rounded-md', style)}
    startColor={tw.color('gray-300/60')}
    endColor={tw.color('gray-200')}
    {...props}
  />
)
