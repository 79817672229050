import { DefaultTheme, NavigationContainer } from '@react-navigation/native'
import { useRef } from 'react'

import { useDarkColor } from '#components/dark/hooks'
import { logCustomEvent } from '#config/firebaseConfig'
import { LaunchScreen } from '#screens/LaunchScreen'

import { rootNavigationRef } from './helpers'
import { RootNavigator } from './RootNavigator'
import { linking } from './routers'

export const AppNavigatorContainer = () => {
  const dark = useDarkColor()
  const routeNameRef = useRef<string>()

  return (
    <NavigationContainer
      ref={rootNavigationRef}
      linking={linking}
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: dark('background-dark-1', 'background-light-1'),
        },
      }}
      fallback={<LaunchScreen />}
      onReady={() => {
        routeNameRef.current = rootNavigationRef.getCurrentRoute()?.name
      }}
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current
        const currentRouteName = rootNavigationRef.getCurrentRoute()?.name

        if (currentRouteName && previousRouteName !== currentRouteName) {
          logCustomEvent('screen_view', { screen: currentRouteName })
        }

        routeNameRef.current = currentRouteName
      }}
    >
      <RootNavigator />
    </NavigationContainer>
  )
}
