import type React from 'react'
import { useCallback, useState } from 'react'
import type {
  ImageErrorEventData,
  ImageProps,
  NativeSyntheticEvent,
} from 'react-native'
import { ActivityIndicator, Image as Img, View } from 'react-native'
import type { Style } from 'twrnc'

import { images } from '#assets'
import { tw } from '#components/utils/tw'

type Props = Omit<ImageProps, 'style'> & {
  placeholderSource?: ImageProps['source']
  style?: Style
  containerStyle?: Style
}

export const Image: React.FC<Props> = ({
  source,
  style,
  resizeMode = 'cover',
  placeholderSource,
  onLoadStart,
  onLoadEnd,
  onError,
  containerStyle,
  ...restProps
}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const handleLoadStart = useCallback(() => {
    setLoading(true)
    setError(false)
    onLoadStart?.()
  }, [onLoadStart])

  const handleLoadEnd = useCallback(() => {
    setLoading(false)
    onLoadEnd?.()
  }, [onLoadEnd])

  const handleLoadError = useCallback(
    (e: NativeSyntheticEvent<ImageErrorEventData>) => {
      setLoading(false)
      setError(true)
      onError?.(e)
    },
    [onError],
  )

  return (
    <View style={containerStyle}>
      {loading && (
        <ActivityIndicator
          style={tw`absolute self-center inset-0`}
          size='large'
          color={tw.color('primary-500')}
        />
      )}

      <Img
        {...restProps}
        onLoadStart={handleLoadStart}
        onLoadEnd={handleLoadEnd}
        onError={handleLoadError}
        style={style}
        source={
          error ||
          !source ||
          (typeof source === 'object' && !Array.isArray(source) && !source.uri)
            ? placeholderSource || images.no_image
            : source
        }
        resizeMode={resizeMode}
      />
    </View>
  )
}
