export const fetchWithTimeout = (
  input: RequestInfo | URL,
  init?: RequestInit,
) => {
  const c = new AbortController()
  const i = setTimeout(() => c.abort(), 20000)
  return fetch(input, {
    ...init,
    signal: c.signal,
  }).finally(() => {
    clearTimeout(i)
  })
}
