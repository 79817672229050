import { notification } from 'antd'
import { Platform } from 'react-native'
import { ulid } from 'ulidx'

type MessageProps = {
  type: 'success' | 'error'
  title?: string
  message?: string
}

export const toast = ({ type, title, message }: MessageProps) => {
  if (!title && !message) {
    return null
  }
  const id = ulid()
  const isWeb = Platform.OS === 'web'
  notification.open({
    type,
    key: id,
    message: title,
    description: message,
    duration: 5,
    placement: isWeb ? 'topRight' : 'topLeft',
  })
  return id
}

// _prefix must be used via ctx. or internally in this context folder
export const toastSuccess = ({
  title,
  message,
}: {
  title?: string
  message?: string
}) => toast({ type: 'success', title, message })

export const toastError = ({
  title,
  message,
}: {
  title?: string
  message?: Error | string
}) => {
  const m =
    typeof message === 'object' && message.message
      ? message.message
      : `${message}`
  return toast({ type: 'error', title, message: m })
}
