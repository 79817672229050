import { useState } from 'react'
import { Pressable, View } from 'react-native'

import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { toastSuccess } from '#components/utils/Toast'
import { tw } from '#components/utils/tw'
import { ActionSheet } from '#components/widgets/ActionSheet'
import {
  useCreateWordbank,
  useDeleteWordbank,
  useTranslateMessage,
} from '#graphql/codegen'
import { S } from '#store'

type Props = {
  wordId?: string
  text: string
  onUnsaveWord: () => void
}

export const ActionSheetTranslate = ({
  wordId: propWordId,
  text,
  onUnsaveWord,
}: Props) => {
  const [wordId, setWordId] = useState(propWordId)

  const [translateData] = useTranslateMessage({ variables: { content: text } })
  const [createWordbankData, createWordbank] = useCreateWordbank()
  const [deleteWordbankData, deleteWordbank] = useDeleteWordbank()

  const handleSaveWord = async () => {
    const response = await createWordbank(
      {
        data: {
          word: text,
          userId: S.shared.currentUser!.id,
          topicId: S.shared.currentTopicId,
          translationLanguage: S.shared.currentUser!.nativeLanguage,
        },
      },
      { requestPolicy: 'network-only' },
    )
    setWordId(response.data?.createWordbank.id)
    toastSuccess({ message: 'Saved in wordbank' })
  }

  const handleDeleteWord = async () => {
    await deleteWordbank({ id: wordId! }, { requestPolicy: 'network-only' })
    onUnsaveWord()
    setWordId(undefined)
    toastSuccess({ message: 'Removed in wordbank' })
  }

  return (
    <ActionSheet isLoading={translateData.fetching}>
      <View
        style={tw`flex flex-row px-2 py-4 w-full justify-between items-center gap-x-2`}
      >
        <View style={tw.style('flex flex-col gap-y-1 max-w-[88%]')}>
          <Text specialType='Title'>{text}</Text>
          <Text specialType='Note'>{translateData.data?.translateMessage}</Text>
        </View>
        <Pressable
          disabled={createWordbankData.fetching || deleteWordbankData.fetching}
          onPress={!wordId ? handleSaveWord : handleDeleteWord}
          style={tw.style(
            'flex justify-center items-center w-10 h-10 rounded-full flex-shrink-0',
            wordId ? 'bg-background-light-2' : 'bg-background-light-1',
          )}
        >
          <SystemIcon
            type='SAX'
            name='ArchiveTick'
            variant={wordId ? 'Bold' : 'Outline'}
            color={wordId ? tw.color('primary-400') : 'currentColor'}
          />
        </Pressable>
      </View>
    </ActionSheet>
  )
}
