import { getLevelTest, shuffleArray } from '#components/utils/random'
import { gql } from '#graphql/urql'
import type { LanguageType, Level } from '#types/language'
import type { LevelTestContainer } from '#types/levelTest'

import { fetchGameLevelTest } from './fetchGameLevelTest'
import { fetchListen } from './fetchListen'

type GenerateGames = {
  level: Level
  language: LanguageType
}

type ResultGenerateGames = LevelTestContainer[]

export const generateLevelTest = async ({
  level,
  language,
}: GenerateGames): Promise<ResultGenerateGames> => {
  try {
    const res = await gql.searchTestTotal(
      {
        filterW: {
          isVocabulary: true,
          topic_some: { level, languageTopic: language, status: 'Active' },
        },
        filterP: {
          isVocabulary: false,
          topic_some: { level, languageTopic: language, status: 'Active' },
        },
        filterQ: {
          wordId: '',
          topic_some: { level, languageTopic: language },
        },
      },
      { requestPolicy: 'network-only' },
    )

    const totalListen = res.data?.listen ?? 0
    const totalWords = res.data?.word ?? 0
    const totalPhrases = res.data?.phrase ?? 0

    const list = getLevelTest({
      language,
      level,
      totalListen,
      totalPhrases,
      totalWords,
    })
    const data = shuffleArray(list)

    const results: ResultGenerateGames = await Promise.all(
      data.map(async item => {
        if (item.type === 'listen') {
          const respListen = await fetchListen({ item })
          return respListen
        } else {
          const respGame = await fetchGameLevelTest({ item })
          return respGame
        }
      }),
    )

    return shuffleArray(results)
  } catch (error) {
    return []
  }
}
