import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { View } from 'react-native'

import { images } from '#assets'
import { Button } from '#components/base/Button/Button'
import { FlatList } from '#components/base/FlatList'
import { Gif } from '#components/base/Gif/Gif'
import { redirectLearn } from '#components/utils/redirectLearn'
import { tw } from '#components/utils/tw'
import { Header } from '#components/widgets/Header'
import type { WordInUserFilter } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import { S } from '#store'
import { GameTimes } from '#types/games'
import type { LanguageType } from '#types/language'
import { LanguageLabel } from '#types/language'
import type { SearchTranslateItem } from '#types/translate'
import type { SearchWordInUser } from '#types/wordInUser'

import { Item } from './Item'
import { ListHeader } from './ListHeader'

type Props = { topicId: string }

type Item = SearchWordInUser & {
  translate: SearchTranslateItem[]
}

export const EndReview: FC<Props> = observer(({ topicId }) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize] = useState(20)
  const [total, setTotal] = useState<number>(0)
  const [data, setData] = useState<Item[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [fetching, setFetching] = useState<boolean>(false)
  const [loadingMore, setLoadingMore] = useState<boolean>(false)
  const [totalMastered, setTotalMastered] = useState<number>(0)

  useEffect(() => {
    if (loading) {
      fetchData()
    }
  }, [])

  useEffect(() => {
    if (fetching || loadingMore) {
      fetchData()
    }
  }, [fetching, loadingMore])

  const fetchData = async () => {
    const filter: WordInUserFilter = {
      userId: S.shared.currentUser?.id,
      topic_some: {
        languageTopic:
          (S.shared.currentUser?.languageLearn as LanguageType) ||
          LanguageLabel.English,
      },
      times: GameTimes.TimeMax,
    }
    const resp = await gql.searchWordInUser(
      {
        filter,
        order: ['milestone_asc', 'nextDay_asc'],
        page: { offset: (currentPage - 1) * 20, limit: 20 },
      },
      { requestPolicy: 'network-only' },
    )
    const listWord = resp.data?.searchWordInUser || []
    const respTranslate = await gql.searchTranslate({
      filter: {
        wQAId_in: listWord.map(i => i.wordId),
        suportLanguage:
          (S.shared.currentUser?.nativeLanguage as LanguageType) ||
          LanguageLabel.English,
      },
    })
    const translation = respTranslate.data?.searchTranslate || []
    const list = listWord.map(i => {
      const translate = translation.filter(j => j.wQAId === i.wordId)
      return { ...i, translate }
    })
    setData(pre => (currentPage < 2 ? list : [...pre, ...list]))
    if (currentPage < 2) {
      const respCount = await gql.countWordInUser(
        { filter },
        { requestPolicy: 'network-only' },
      )
      setTotal(respCount.data?.countWordInUser ?? list.length)
      const respMastered = await gql.countWordInUser(
        {
          filter: { ...filter, isCompleted: true },
        },
        { requestPolicy: 'network-only' },
      )
      setTotalMastered(respMastered.data?.countWordInUser ?? 0)
    }
    setLoading(false)
    setFetching(false)
    setLoadingMore(false)
  }

  const onRefresh = () => {
    if (!fetching) {
      setCurrentPage(1)
      setFetching(true)
    }
  }

  const onEndReached = () => {
    const totalLoadedItems = currentPage * pageSize
    if (totalLoadedItems <= total && !loadingMore) {
      setCurrentPage(currentPage + 1)
      setLoadingMore(true)
    }
  }

  const onPressButton = () => {
    const percentageTopic = S.lessonPercentage.getLessonPercentageById(topicId)
    redirectLearn({ ...percentageTopic, topicId })
  }

  const renderListHeader = () => (
    <ListHeader mastered={totalMastered} total={total} />
  )

  const renderItem = ({ item, index }: { item: Item; index: number }) => (
    <Item item={item} index={index} total={data.length} />
  )

  const renderItemSeparator = () => (
    <View
      style={tw.style(
        'flex-col items-center justify-center bg-background-light-white px-5',
      )}
    >
      <View style={tw`h-px bg-neutral-200 w-full`} />
    </View>
  )

  if (loading) {
    return (
      <View style={tw`flex-1 flex-col`}>
        <Header />
        <View style={tw`flex-1 flex-col items-center justify-center`}>
          <Gif source={images.loading} width={40} height={40} />
        </View>
      </View>
    )
  }

  return (
    <View style={tw`flex-1 flex-col`}>
      <Header />
      <FlatList
        data={data}
        renderItem={renderItem}
        refreshing={fetching}
        onRefresh={onRefresh}
        onEndReached={onEndReached}
        onEndReachedThreshold={0.7}
        ListHeaderComponent={renderListHeader}
        ItemSeparatorComponent={renderItemSeparator}
        contentContainerStyle={tw`p-4`}
      />
      <View style={tw`p-4`}>
        <Button onPress={onPressButton}>Start learning</Button>
      </View>
    </View>
  )
})
