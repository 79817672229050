import { Tooltip } from 'antd'
import { debounce } from 'lodash'
import { useCallback, useEffect, useReducer, useState } from 'react'

import { Text } from '#components/base/Text'
import { useOverlay } from '#components/overlay/hooks'
import { toastSuccess } from '#components/utils/Toast'
import { tw } from '#components/utils/tw'
import { Divider } from '#components/widgets/Divider'
import { useCreateWordbank, useDeleteWordbank } from '#graphql/codegen'
import { S } from '#store'

import { ActionSheetTranslate } from './ActionSheetTranslate'

type State = {
  text?: string
  x?: number
  y?: number
  actionSheetId?: string
}

const initState: State = {
  text: undefined,
  x: undefined,
  y: undefined,
  actionSheetId: undefined,
}

const TRIGGER_SIZE = 16

export const TextSelectionWeb = () => {
  const { openings } = useOverlay()
  const [{ text, x, y, actionSheetId }, dispatch] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState,
  )

  useEffect(() => {
    document.addEventListener('selectionchange', handleSelectionChange)
    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange)
    }
  }, [])

  const handleSelectionChange = () => {
    dispatch(initState)
    _handleSelectionChange()
  }

  const _handleSelectionChange = useCallback(
    debounce(() => {
      const selection = document.getSelection()
      const selectedText = selection?.toString().trim()
      const anchorNode = selection?.anchorNode?.parentNode as HTMLElement
      const focusNode = selection?.focusNode?.parentNode as HTMLElement

      if (
        !selection ||
        selection.type === 'None' ||
        !selectedText ||
        !anchorNode ||
        !focusNode ||
        !anchorNode.id.includes('selectable-text') ||
        !focusNode.id.includes('selectable-text') ||
        !anchorNode.isSameNode(focusNode)
      ) {
        return dispatch(initState)
      }

      const rect = selection.getRangeAt(0).getBoundingClientRect()
      dispatch({
        text: selectedText,
        x: rect.left + rect.width / 2 - TRIGGER_SIZE / 2,
        y: rect.top + rect.height,
      })
    }, 300),
    [],
  )

  const actionSheetOpen = openings.find(o => o.id === actionSheetId)

  return (
    <Tooltip
      open={!!text && !!x && !!y}
      trigger='click'
      placement='bottom'
      destroyTooltipOnHide
      title={() => (
        <SelectionMenu
          text={text}
          onActionSheetOpen={id => dispatch({ actionSheetId: id })}
        />
      )}
      overlayStyle={{
        opacity: !!actionSheetOpen ? 0 : undefined,
        pointerEvents: !!actionSheetOpen ? 'none' : undefined,
      }}
    >
      <div
        style={tw.style('-top-2 left-0 bg-transparent', {
          position: 'fixed',
          width: TRIGGER_SIZE,
          height: TRIGGER_SIZE / 2,
          transform: `translate3d(${x}px, ${y}px, 0)`,
          zIndex: 99,
          pointerEvents: 'none',
        })}
      />
    </Tooltip>
  )
}

// --------------------------------------------------------------- //

type SelectionMenuProps = {
  text?: string
  onActionSheetOpen: (id: string) => void
}

const SelectionMenu = ({ text, onActionSheetOpen }: SelectionMenuProps) => {
  const [wordId, setWordId] = useState<string>()

  const { openActionsheet } = useOverlay()
  const [createWordbankData, createWordbank] = useCreateWordbank()
  const [deleteWordbankData, deleteWordbank] = useDeleteWordbank()

  const handleTranslate = () => {
    const id = openActionsheet(ActionSheetTranslate, {
      text,
      wordId,
      onUnsaveWord: () => setWordId(undefined),
    })
    onActionSheetOpen(id)
  }

  const handleAddWord = async () => {
    const response = await createWordbank(
      {
        data: {
          word: text!,
          userId: S.shared.currentUser!.id,
          topicId: S.shared.currentTopicId,
          translationLanguage: S.shared.currentUser!.nativeLanguage,
        },
      },
      { requestPolicy: 'network-only' },
    )
    setWordId(response.data?.createWordbank.id)
    toastSuccess({ message: 'Saved in wordbank' })
  }

  const handleDeleteWord = async () => {
    await deleteWordbank({ id: wordId! }, { requestPolicy: 'network-only' })
    setWordId(undefined)
    toastSuccess({ message: 'Removed in wordbank' })
  }

  const disabled = createWordbankData.fetching || deleteWordbankData.fetching

  return (
    <div style={tw`flex flex-row`}>
      <div
        style={tw.style('w-20 text-center', { userSelect: 'none' })}
        onClick={!disabled ? handleTranslate : undefined}
      >
        <Text specialType='Note' style={tw`text-base text-white`}>
          Translate
        </Text>
      </div>
      <Divider
        orientation='vertical'
        style={tw`mx-2 h-5.5 border-gray-500/70`}
      />
      {!wordId && (
        <div
          style={tw.style('w-20 text-center', { userSelect: 'none' })}
          onClick={!disabled ? handleAddWord : undefined}
        >
          <Text specialType='Note' style={tw`text-base text-white`}>
            Bookmark
          </Text>
        </div>
      )}
      {!!wordId && (
        <div
          style={tw.style('w-20 text-center', { userSelect: 'none' })}
          onClick={!disabled ? handleDeleteWord : undefined}
        >
          <Text specialType='Note' style={tw`text-base text-white`}>
            Unsave
          </Text>
        </div>
      )}
    </div>
  )
}
