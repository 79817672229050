import { forwardRef, useImperativeHandle, useRef } from 'react'
import type { FlatListProps } from 'react-native'
import { FlatList as RNFlatList } from 'react-native'

export interface FlatListRef {
  scrollToTop: () => void
}

export const FlatList = forwardRef<FlatListRef, FlatListProps<any>>(
  (props, ref) => {
    const flatListRef = useRef<RNFlatList<any>>(null)

    useImperativeHandle(ref, () => ({
      scrollToTop: () => {
        flatListRef.current?.scrollToOffset({ offset: 0, animated: true })
      },
    }))

    return (
      <RNFlatList<any>
        ref={flatListRef}
        contentContainerStyle={{ flexGrow: 1 }}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        onEndReachedThreshold={0.7}
        initialNumToRender={30}
        windowSize={20}
        keyExtractor={(item, index) => {
          const key = item.id ? `${item.id}-${index}` : `index-${index}`
          return key
        }}
        {...props}
      />
    )
  },
)
