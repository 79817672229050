import type { EasyGameType, GamesProps, GameType, HardGameType } from './games'
import type { SearchWordInUser } from './wordInUser'

export type ReviewProps = SearchWordInUser & {
  gameType: GameType
  easyType: EasyGameType
  hardType: HardGameType
}

export type ReviewItem = SearchWordInUser & {
  gameType: GameType
  game: GamesProps
  easyType: EasyGameType
  easy: GamesProps
  hardType: HardGameType
  hard: GamesProps
  total: number
  count: number
}

export const emailData: string[] = [
  // 'thitran926@gmail.com',
  // 'thanduylinhtdl@gmail.com',
  'thi@nongdan.dev',
]
