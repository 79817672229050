import { zodResolver } from '@hookform/resolvers/zod'
import { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import { ScrollView, View } from 'react-native'
import { z } from 'zod'

import { Button } from '#components/base/Button/Button'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { FormField } from '#components/form/FormField'
import { Input } from '#components/form/Input'
import { useOverlay } from '#components/overlay/hooks'
import { toastError } from '#components/utils/Toast'
import { tw } from '#components/utils/tw'
import { Header } from '#components/widgets/Header'
import { OTPDialog } from '#components/widgets/ModalOTP'
import { useConfirmOtp, useForgotPassword } from '#graphql/codegen'
import type { AuthStackScreenProps } from '#navigator/types'

const FormSchema = z.object({
  email: z
    .string({ required_error: 'Email is required' })
    .email('Invalid email address'),
})

type Props = AuthStackScreenProps<'ForgotPassword'>
type FormValues = z.infer<typeof FormSchema>

export const ForgotPassword = ({ navigation, route }: Props) => {
  const { openModal } = useOverlay()
  const [forgotPasswordResult, forgotPassword] = useForgotPassword()
  const [, confirmOTP] = useConfirmOtp()

  const { control, handleSubmit, getValues } = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
    shouldFocusError: false,
    defaultValues: { email: route?.params?.emailValue },
  })

  const _handleSubmit = async () => {
    try {
      const { data, error } = await forgotPassword(getValues())
      if (!data && !!error) {
        throw new Error('This email is not in our system.')
      }
      openModal(OTPDialog, {
        handleConfirm,
        idForgotPassword: data?.forgotPassword,
        containerProps: { closeOnOverlayClick: false },
      })
    } catch (e: any) {
      toastError({ message: e || 'Something went wrong, please try again' })
    }
  }

  const handleConfirm = async (code: string, idForgotPassword: string) => {
    const response = await confirmOTP({ data: { code, id: idForgotPassword } })
    if (response?.data?.confirmOTP) {
      navigation.navigate('NewPassword', {
        id: idForgotPassword,
        secret: response?.data?.confirmOTP,
      })
      return true
    } else {
      return null
    }
  }

  return (
    <Fragment>
      <Header isBack />
      <ScrollView contentContainerStyle={tw`px-4 pb-4`}>
        <View style={tw`flex-col justify-center items-center my-8 gap-6`}>
          <View style={tw`p-6 bg-primary-200/40 rounded-full`}>
            <SystemIcon
              type='SAX'
              name='Lock1'
              size={48}
              color={tw.color('primary-400')}
            />
          </View>
          <View style={tw`flex-col items-center gap-1.5`}>
            <Text textAlign='center' specialType='Headline2'>
              Forgot Password
            </Text>
            <Text
              textAlign='center'
              specialType='Title'
              color={tw.color('text-2')}
            >
              We will send a verification code to email
            </Text>
          </View>
        </View>
        <View style={tw`flex-col gap-4`}>
          <FormField name='email' control={control}>
            <Input
              autoFocus
              autoCapitalize='none'
              spellCheck={false}
              placeholder='Enter your email address'
              onSubmitEditing={handleSubmit(_handleSubmit)}
              prefix={
                <View style={tw.style('pr-2')}>
                  <SystemIcon type='SAX' name='Sms' color={tw.color('icon')} />
                </View>
              }
            />
          </FormField>
          <Button
            loading={forgotPasswordResult.fetching}
            onPress={handleSubmit(_handleSubmit)}
          >
            Continue
          </Button>
        </View>
      </ScrollView>
    </Fragment>
  )
}
