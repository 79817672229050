import { DeleteOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Flex, Upload } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import type { UploadChangeParam } from 'antd/es/upload'
import type { FC } from 'react'
import { useEffect, useState } from 'react'

import { uploadFiles } from '#apis/files'
import { tw } from '#components/utils/tw'
import type { ResponseFile, UploadFileProps } from '#types/media'

import { handleFileType } from '../utils/checkFileType'
import { toastError, toastSuccess } from '../utils/Toast'
import { PaginatedTable } from './PaginatedTable'

type FileProps = UploadFileProps & { id: string }

type Props = {
  fileList: FileProps[]
  onChangeFileList: (value: FileProps[]) => void
  onChangeFileDeleted?: (value: FileProps) => void
  loading: boolean
  onChangeLoading: (value: boolean) => void
  warning?: string
}

export const AudioUploader: FC<Props> = ({
  fileList,
  onChangeFileList,
  onChangeFileDeleted,
  loading,
  onChangeLoading: setLoading,
  warning,
}) => {
  const [newFile, setNewFile] = useState<FileProps[]>([])
  const [isChange, setIsChange] = useState<boolean>(false)

  const checkDuplicateFile = (uid: string) => fileList.find(e => e.uid === uid)

  useEffect(() => {
    if (newFile && isChange) {
      handleChange(newFile)
      setIsChange(false)
    }
  }, [JSON.stringify(newFile)])
  const handleChange = async (files: FileProps[]) => {
    setLoading(true)
    try {
      setLoading(true)
      const response: ResponseFile[] = await uploadFiles(
        files.map(e => e.originFileObj!),
        'multiple',
      )
      const newData: FileProps[] = response.map(file => ({
        ...file,
        status: 'done',
        type: handleFileType(file.url),
        uid: file.id,
        id: '',
      }))
      onChangeFileList(newData)
      toastSuccess({ message: 'Uploaded file successfully.' })
    } catch (error) {
      toastError({ message: 'Failed to upload file' })
    } finally {
      setLoading(false)
    }
    setLoading(false)
  }

  const handleRemove = (file: FileProps) => {
    setIsChange(false)
    if (onChangeFileDeleted) {
      onChangeFileDeleted(file)
    }
    if (
      (currentPage - 1) * pageSize + 1 === fileList.length &&
      currentPage > 1
    ) {
      setCurrentPage(currentPage - 1)
    }
  }
  const columns = ({
    onRemove,
  }: {
    onRemove: (file: FileProps) => void
  }): ColumnsType<FileProps> => [
    {
      title: 'File name',
      dataIndex: 'name',
      key: 'name',
      render: (_, value) => (
        <div
          style={{
            textWrap: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {value.name ? value.name : ''}
        </div>
      ),
    },
    {
      title: 'File type',
      dataIndex: 'type',
      key: 'type',
      width: 140,
    },
    {
      title: 'Delete',
      dataIndex: 'delete',
      key: 'delete',
      width: 80,
      render: (_, data) => (
        <div style={tw`flex justify-center`}>
          <DeleteOutlined
            style={{ color: 'red' }}
            onClick={() => onRemove(data)}
          />
        </div>
      ),
    },
  ]

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(3)

  const column = columns({
    onRemove: file => {
      handleRemove(file)
    },
  })

  return (
    <Flex style={tw`flex-col w-full`} gap={16}>
      <Upload
        multiple
        beforeUpload={() => false}
        fileList={fileList}
        onChange={({
          fileList: newFileList,
        }: UploadChangeParam<UploadFileProps>) => {
          setNewFile([])
          newFileList.map(file => {
            if (!checkDuplicateFile(file.uid)) {
              setNewFile(prev => [...prev, { ...file, id: '' }])
            }
          })

          setIsChange(true)
        }}
        onRemove={() => {}}
        type='select'
        showUploadList={false}
        disabled={loading}
      >
        <Button loading={loading} icon={<UploadOutlined />}>
          Upload file
        </Button>
      </Upload>
      {warning && (
        <div style={tw`text-red-500`}>
          *You need to fill in all the information including {warning} to be
          able to create new word/phrase.
        </div>
      )}
      <PaginatedTable<FileProps>
        dataSource={fileList.slice(
          (currentPage - 1) * pageSize,
          (currentPage - 1) * pageSize + pageSize,
        )}
        columns={column}
        loading={loading}
        total={fileList?.length || 0}
        currentPage={currentPage}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        // scrollHeight={150}
      />
    </Flex>
  )
}
