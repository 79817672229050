import ColorHash from 'color-hash'
import type { IAvatarProps } from 'native-base'
import { Avatar as NbAvatar } from 'native-base'
import type { Style } from 'twrnc'

import { tw } from '#components/utils/tw'

export type AvatarProps = Omit<IAvatarProps, 'style'> & {
  id?: string
  name?: string
  style?: Style
}

const colorHash = new ColorHash()
export const idToColorHex = (id: string) => colorHash.hex(id)

const Avatar = ({ id = '', name = '', style, ...props }: AvatarProps) => (
  <NbAvatar
    {...props}
    style={tw.style(style, { backgroundColor: idToColorHex(id) })}
  >
    {name?.[0]?.toUpperCase?.()}
  </NbAvatar>
)

Avatar.Group = NbAvatar.Group
export { Avatar }
