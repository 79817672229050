import type { UploadFileProps } from '#types/media'

import { toastError } from './Toast'

export const checkVideo = (file: UploadFileProps): boolean => {
  const allowedTypes = ['video/mp4', 'video/avi', 'video/mov']
  const isVideo = !!(
    file.originFileObj?.type && allowedTypes.includes(file.originFileObj?.type)
  )
  if (!isVideo) {
    toastError({
      title: 'Error',
      message: 'You can only upload MP4/MOV/AVI file!',
    })
  }
  return isVideo
}
