import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { View } from 'react-native'

import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import type { TasksItem } from '#graphql/codegen'
import { useSearchTopicInUser, useUpdateTopicInUser } from '#graphql/codegen'
import { S } from '#store'
import type { SearchTopic } from '#types/topic'

import { Skeleton } from './Skeleton'
import { TaskListItem } from './TaskListItem'

type Props = {
  loading: boolean
  topicId: string
  tasksFromTopic: SearchTopic[number]['task']['data']
}

export const TaskList = observer(
  ({ loading, topicId, tasksFromTopic }: Props) => {
    const [updateResult, updateTopicInUser] = useUpdateTopicInUser()

    const [topicInUserData] = useSearchTopicInUser({
      variables: { filter: { topicId, userLearnId: S.shared.currentUser?.id } },
      requestPolicy: 'network-only',
    })

    const topicInUser = topicInUserData.data?.searchTopicInUser[0]
    const tasks: TasksItem[] = topicInUser?.task.data || []

    useEffect(() => {
      if (!loading && !!topicInUser?.id) {
        const newTasks = handleSyncTasks(tasksFromTopic, tasks)
        const updatedTasks = newTasks.map(task => ({
          ...task,
          isPassed: false,
          subTask: task.subTask.map(subTask => ({
            ...subTask,
            isPassed: false,
          })),
        }))
        S.shared.speakingDeafaultTask = JSON.stringify({ data: updatedTasks })
        updateTopicInUser({
          id: topicInUser.id,
          data: { task: { data: newTasks } },
        })
      }
    }, [topicInUser?.id, loading])

    const handleSyncTasks = (
      topicTasks: any,
      userTasks: any,
      isSubTask?: boolean,
    ) =>
      topicTasks.reduce((acc, cur) => {
        const task = userTasks.find(t => t.id === cur.id)

        const subTasks = !isSubTask
          ? handleSyncTasks(cur.subTask, task?.subTask || [], true)
          : undefined

        const newTask = {
          ...cur,
          isPassed: !!subTasks?.length
            ? subTasks.every(t => t.isPassed)
            : !!task?.isPassed,
          ...(!subTasks ? {} : { subTask: subTasks }),
        }

        acc.push(newTask)
        return acc
      }, [] as TasksItem[])

    return (
      <View style={tw`flex flex-col gap-y-3`}>
        <Text specialType='Headline3'>Task list</Text>
        <View style={tw`flex flex-col gap-y-1.5`}>
          {loading || topicInUserData.fetching || updateResult.fetching ? (
            <Skeleton.TaskList />
          ) : (
            tasks.map((task, index) => (
              <TaskListItem key={task.id} index={index} task={task} />
            ))
          )}
        </View>
      </View>
    )
  },
)
