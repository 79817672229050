import { Linking, Platform } from 'react-native'
import { check, PERMISSIONS, request, RESULTS } from 'react-native-permissions'

export const checkCameraPermission = async (): Promise<boolean> => {
  const permission =
    Platform.OS === 'ios' ? PERMISSIONS.IOS.CAMERA : PERMISSIONS.ANDROID.CAMERA

  const result = await check(permission)

  if (result === RESULTS.GRANTED) {
    return true
  } else if (result === RESULTS.DENIED) {
    const requestResult = await request(permission)
    return requestResult === RESULTS.GRANTED
  } else {
    Linking.openSettings()
    return false
  }
}
export const checkPhotoLibraryPermission = async (): Promise<boolean> => {
  const permission =
    Platform.OS === 'ios'
      ? PERMISSIONS.IOS.PHOTO_LIBRARY
      : PERMISSIONS.ANDROID.READ_MEDIA_IMAGES

  const result = await check(permission)
  if (result === RESULTS.GRANTED) {
    return true
  } else if (result === RESULTS.DENIED) {
    const requestResult = await request(permission)
    return requestResult === RESULTS.GRANTED
  } else {
    // Linking.openSettings()
    return false
  }
}
