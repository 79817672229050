import { checkAdmin } from '#components/utils/checkAdmin'
import { resetUrqlProvider } from '#graphql/urql'
import { initRouteStack } from '#navigator/helpers'
import { S } from '#store'

export const onLogoutSuccess = async () => {
  if (checkAdmin()) {
    await initRouteStack('AdminAuth', { screen: 'AdminLogin' })
  } else {
    await initRouteStack('Auth', { screen: 'Welcome' })
  }

  S.shared.authToken = ''
  S.shared.currentUser = undefined
  resetUrqlProvider()
}
