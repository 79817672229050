export enum LanguageLabel {
  Spanish = 'Spanish',
  English = 'English',
}

export type LanguageType = keyof typeof LanguageLabel

export enum ChooseLanguage {
  both = 'both',
  native = 'native',
  learn = 'learn',
}

export type ChooseLanguageType = keyof typeof ChooseLanguage

export const LevelDowngrade: { [key in Level]: Level } = {
  A1: 'A1',
  A2: 'A1',
  B1: 'A2',
  B2: 'B1',
}

export enum LevelLabel {
  A1 = 'A1',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
}

export type Level = keyof typeof LevelLabel

export const languageOptions: { value: LanguageType; label: string }[] = [
  { value: 'Spanish', label: 'Spanish' },
  { value: 'English', label: 'English' },
]

export type Language =
  | 'English'
  | 'Spanish'
  | 'French'
  | 'German'
  | 'Italian'
  | 'Portuguese'
  | 'Russian'
  | 'Chinese'
  | 'Japanese'
  | 'Korean'
  | 'Arabic'
  | 'Hindi'
  | 'Turkish'
  | 'Vietnamese'
  | 'Indonesian'
  | 'Polish'
  | 'Dutch'
  | 'Swedish'
  | 'Norwegian'
  | 'Finnish'
  | 'Danish'
  | 'Romanian'
  | 'Ukrainian'
  | 'Greek'
  | 'Czech'
  | 'Slovak'
  | 'Bulgarian'
  | 'Serbian'
  | 'Croatian'
  | 'Slovenian'
  | 'Macedonian'
  | 'Albanian'
  | 'Bosnian'
  | 'Estonian'
  | 'Latvian'
  | 'Lithuanian'
  | 'Hungarian'
  | 'Icelandic'
  | 'Faroese'
  | 'Maltese'
  | 'Thai'

export const SupportLanguage: { [key in Language]: string } = {
  English: 'en',
  Spanish: 'es',
  French: 'fr',
  German: 'de',
  Italian: 'it',
  Portuguese: 'pt',
  Russian: 'ru',
  Chinese: 'zh',
  Japanese: 'ja',
  Korean: 'ko',
  Arabic: 'ar',
  Hindi: 'hi',
  Turkish: 'tr',
  Vietnamese: 'vi',
  Indonesian: 'id',
  Thai: 'th',
  Polish: 'pl',
  Dutch: 'nl',
  Swedish: 'sv',
  Norwegian: 'no',
  Finnish: 'fi',
  Danish: 'da',
  Romanian: 'ro',
  Ukrainian: 'uk',
  Greek: 'el',
  Czech: 'cs',
  Slovak: 'sk',
  Bulgarian: 'bg',
  Serbian: 'sr',
  Croatian: 'hr',
  Slovenian: 'sl',
  Macedonian: 'mk',
  Albanian: 'sq',
  Bosnian: 'bs',
  Estonian: 'et',
  Latvian: 'lv',
  Lithuanian: 'lt',
  Hungarian: 'hu',
  Icelandic: 'is',
  Faroese: 'fo',
  Maltese: 'mt',
}

export enum ConvertLanguage {
  en = 'English',
  es = 'Spanish',
  fr = 'French',
  de = 'German',
  it = 'Italian',
  pt = 'Portuguese',
  ru = 'Russian',
  zh = 'Chinese',
  ja = 'Japanese',
  ko = 'Korean',
  vi = 'VietNamese',
}
