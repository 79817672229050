import { useState } from 'react'

import { S } from '#store'

export const useStatusPayment = () => {
  const [paymentSuccess, setPaymentSuccess] = useState<boolean>(false)
  const [sttPayment, setSttPayment] = useState(false)

  const isPaymentSuccess = () => {
    setPaymentSuccess(true)
    S.shared.setCheckUserSubscribed(true)
    setSttPayment(true)
  }

  const isPaymentFail = () => {
    setPaymentSuccess(false)
    S.shared.setCheckUserSubscribed(false)
    setSttPayment(true)
  }

  const tryPayAgain = () => {
    setPaymentSuccess(false)
    setSttPayment(false)
  }

  return {
    paymentSuccess,
    sttPayment,
    isPaymentSuccess,
    isPaymentFail,
    tryPayAgain,
  }
}
