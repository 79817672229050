import { observer } from 'mobx-react-lite'
import { useRef, useState } from 'react'
import { Platform, TouchableOpacity, View } from 'react-native'
import { check, PERMISSIONS, request, RESULTS } from 'react-native-permissions'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { ButtonIcon } from '#components/base/ButtonIcon/ButtonIcon'
import { Icon } from '#components/base/Icon'
import { Spinner } from '#components/base/Spinner'
import { SystemIcon } from '#components/base/SystemIcon'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import { S } from '#store'
import type { FooterButtonType } from '#types/chat'

import { BreathingButton } from './BreathButton'
import { CheckPermissionModal } from './CheckPermissionModal'
import { useRecordManager } from './useRecordManager'

type Props = {
  loading: boolean
  selectedButton: FooterButtonType
  onButtonPress: (buttonType: FooterButtonType) => void
  bufferCallback: (buffer: string) => void
  onChangeSuggestMessage: () => void
}

const arrayBufferToBase64 = (buffer: Uint8Array) => {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}
let isMeasuring = false
const thresholdVolume = 9
export const Footer = observer(
  ({
    loading,
    selectedButton,
    onButtonPress,
    bufferCallback,
    onChangeSuggestMessage,
  }: Props) => {
    const [r, setR] = useState<boolean>(false)
    const [permissionGranted, setPermissionGranted] = useState<boolean>(false)
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
      null,
    )

    const audioContextRef = useRef<AudioContext | null>(null)
    const analyserNodeRef = useRef<AnalyserNode | null>(null)
    const dataArrayRef = useRef<Uint8Array | null>(null)
    const insets = useSafeAreaInsets()
    const { openModal, closeModal } = useOverlay()
    const modalId = useRef<string>('')

    const state = useRef<FooterButtonType>(null)

    const {
      startRecording: startRecordApp,
      stopRecording: stopRecordApp,
      audioBuffer,
    } = useRecordManager()

    const requestMicPermissionApp = () => {
      const buffer = audioBuffer?.current
      if (buffer) {
        const base64String = buffer // Chuyển đổi sang base64
        bufferCallback(base64String)
      } else {
      }
    }

    const requestMicPermission = async () => {
      try {
        if (isMeasuring) {
          return true
        }
        isMeasuring = true
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        })
        const recorder = new MediaRecorder(stream)

        setMediaRecorder(recorder)
        const audioContext = new (window.AudioContext ||
          (window as any).webkitAudioContext)()
        audioContextRef.current = audioContext

        const analyserNode = audioContext.createAnalyser()
        analyserNode.fftSize = 2048
        analyserNodeRef.current = analyserNode
        const source = audioContext.createMediaStreamSource(stream)
        source.connect(analyserNode)
        const bufferLength = analyserNode.frequencyBinCount
        dataArrayRef.current = new Uint8Array(bufferLength)
        const lst: number[] = []
        const measureVolume = () => {
          if (!isMeasuring) {
            return
          }
          analyserNode.getByteFrequencyData(dataArrayRef.current!)
          if (!dataArrayRef.current) {
            return
          }
          const average =
            dataArrayRef.current?.reduce((a, b) => a + b) /
            dataArrayRef.current?.length
          const decibel = Math.floor(20 * Math.log10(average))
          if (
            decibel !== Number.NEGATIVE_INFINITY &&
            decibel !== Number.POSITIVE_INFINITY
          ) {
            lst.push(decibel)
          }
          requestAnimationFrame(measureVolume)
        }
        measureVolume()

        recorder.ondataavailable = event => {
          if (event.data.size > 0) {
            const reader = new FileReader()
            reader.readAsArrayBuffer(event.data)
            reader.onload = () => {
              if (state.current !== 'cancel') {
                isMeasuring = false
                const sum = lst.reduce((a, c) => a + c, 0)
                if (sum / lst.length >= thresholdVolume) {
                  const arrayBuffer = reader.result as ArrayBuffer
                  const uint8Array = new Uint8Array(arrayBuffer)
                  const base64String = arrayBufferToBase64(uint8Array)
                  bufferCallback(base64String)
                } else {
                  bufferCallback('')
                }
              }
              state.current === null
            }
          }
        }

        recorder.onstop = () => {
          setR(false)
          isMeasuring = false
        }

        recorder.start()
        setPermissionGranted(true)
        return true
      } catch (error) {
        console.error('Microphone access denied or error occurred', error)
        setPermissionGranted(false)
        return false
      }
    }

    const requestMicrophonePermission = async () => {
      try {
        const permissionStatus =
          Platform.OS === 'android'
            ? await check(PERMISSIONS.ANDROID.RECORD_AUDIO)
            : await check(PERMISSIONS.IOS.MICROPHONE)

        if (permissionStatus === RESULTS.DENIED) {
          const granted =
            Platform.OS === 'android'
              ? await request(PERMISSIONS.ANDROID.RECORD_AUDIO)
              : await request(PERMISSIONS.IOS.MICROPHONE)

          if (granted !== RESULTS.GRANTED) {
            return false
          }
        } else if (permissionStatus !== RESULTS.GRANTED) {
          return false
        }
        return true
      } catch (error) {
        console.error('Permission check failed', error)
        return false
      }
    }

    const handleStopRecording = () => {
      if (Platform.OS === 'web') {
        mediaRecorder?.stop()
      } else {
        stopRecordApp(requestMicPermissionApp)
      }
      setR(false)
    }

    const handleStopRecordingWithoutBuffer = () => {
      state.current = 'cancel'
      if (Platform.OS === 'web') {
        mediaRecorder?.stop()
      } else {
        stopRecordApp(requestMicPermissionApp)
      }
      setR(false)
      handlePress('cancel')
    }

    const handlePress = async (buttonType: FooterButtonType) => {
      if (buttonType === 'speak') {
        let hasPermission = false

        if (Platform.OS === 'web') {
          hasPermission = await requestMicPermission()
          if (!hasPermission) {
            modalId.current = openModal(CheckPermissionModal, {
              onClose: () => {
                closeModal(modalId.current)
                modalId.current = ''
              },
            })
            return
          }
        } else {
          hasPermission = await requestMicrophonePermission()
          if (!hasPermission) {
            modalId.current = openModal(CheckPermissionModal, {
              onClose: () => {
                closeModal(modalId.current)
                modalId.current = ''
              },
            })
            return
          }
        }

        onButtonPress(buttonType)
        state.current = null
        setR(prev => !prev)
        if (!r) {
          if (Platform.OS === 'web' && mediaRecorder?.state !== 'recording') {
            if (permissionGranted) {
              mediaRecorder?.start()
            } else {
              requestMicPermission()
            }
          } else {
            startRecordApp()
          }
        } else {
          if (Platform.OS === 'web') {
            handleStopRecording()
          } else {
            stopRecordApp(requestMicPermissionApp)
          }
        }
      } else {
        onButtonPress(buttonType)
      }
    }

    return (
      <View
        style={tw.style(
          'flex-col',
          Platform.OS === 'ios' && {
            paddingBottom: Math.max(insets.bottom),
          },
        )}
      >
        <View style={tw`flex-row justify-center items-center h-25`}>
          {selectedButton !== 'speak' ? (
            <ButtonIcon
              icon={{
                type: 'SAX',
                name: 'Keyboard',
                color:
                  selectedButton === 'input'
                    ? tw.color('primary-400')
                    : tw.color('icon'),
              }}
              tone='third'
              bg={tw.color('background-light-2')}
              style={tw.style(
                selectedButton === 'input'
                  ? 'border border-solid border-primary-400'
                  : 'border-0',
                { boxShadow: '' },
              )}
              disabled={loading}
              onPress={() => handlePress('input')}
            />
          ) : (
            <ButtonIcon
              tone='third'
              icon={{ type: 'SVG', name: 'x', color: tw.color('icon') }}
              bg={tw.color('background-light-2')}
              style={tw.style({ boxShadow: '' })}
              onPress={handleStopRecordingWithoutBuffer}
            />
          )}

          <View style={tw`h-25`}>
            <TouchableOpacity
              style={tw`h-25 flex-1 rounded-full overflow-hidden mx-6 p-3 `}
              disabled={loading}
              onPress={() => handlePress('speak')}
            >
              {selectedButton !== 'speak' && (
                <View style={tw`py-5 px-12 flex-1 rounded-full bg-primary-400`}>
                  {!loading && (
                    <SystemIcon
                      type='SAX'
                      size={40}
                      name='Microphone2'
                      variant='Bold'
                      color={'white'}
                    />
                  )}
                  {loading && (
                    <View
                      style={tw.style('w-10 h-10 justify-center items-center', {
                        transform: [{ scale: 3 }],
                      })}
                    >
                      <Spinner>
                        <Icon
                          provider='EvilIcons'
                          name='spinner-3'
                          style={tw`text-white`}
                        />
                      </Spinner>
                    </View>
                  )}
                </View>
              )}
              {selectedButton === 'speak' && (
                <View style={tw.style('flex-1')}>
                  <BreathingButton />
                </View>
              )}
            </TouchableOpacity>
          </View>

          <ButtonIcon
            icon={{
              type: 'SAX',
              name: 'LampCharge',
              color: S.shared.isChatSuggestShow
                ? tw.color('neutral-400')
                : tw.color('icon'),
            }}
            tone='third'
            bg={tw.color('background-light-2')}
            style={tw.style(
              'border-0',
              { boxShadow: '' },
              selectedButton === 'speak' ? { opacity: 0 } : '',
            )}
            disabled={selectedButton === 'speak' || loading}
            onPress={() => {
              onChangeSuggestMessage()
              S.shared.isChatSuggestShow = !S.shared.isChatSuggestShow
            }}
          />
        </View>
      </View>
    )
  },
)
