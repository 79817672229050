import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'

import { Spinner } from '#components/base/Spinner'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { toastError, toastSuccess } from '#components/utils/Toast'
import { tw } from '#components/utils/tw'
import { Divider } from '#components/widgets/Divider'
import { gql } from '#graphql/urql'
import { navigate } from '#navigator/helpers'
import { S } from '#store'
import { ChooseLanguage } from '#types/language'

type To = 'payment' | 'primary-language' | 'learn-language' | 'learn-level'

export const SectionLearning = observer(() => {
  const [deleting, setDeleting] = useState(false)

  const handleNavigate = (to: To) => () => {
    if (to === 'payment') {
      return navigate('App', { screen: 'Payment' })
    }
    if (to === 'primary-language') {
      return navigate('App', {
        screen: 'ChooseLanguage',
        params: { type: ChooseLanguage.native },
      })
    }
    if (to === 'learn-language') {
      return navigate('App', {
        screen: 'ChooseLanguage',
        params: { type: ChooseLanguage.learn },
      })
    }
    return navigate('App', {
      screen: 'ChooseLevel',
      params: { isShow: true },
    })
  }

  const handleDeleteRecords = async () => {
    try {
      setDeleting(true)
      await gql.deleteMessage()
      const wordInUserData = await gql.searchWordInUser(
        { filter: { userId: S.shared.currentUser?.id } },
        { requestPolicy: 'network-only' },
      )
      await Promise.all(
        (wordInUserData.data?.searchWordInUser || []).map(word =>
          gql.deleteWordInUser({ id: word.id }),
        ),
      )
      const respTopicInUser = await gql.searchTopicInUser(
        { filter: { userLearnId: S.shared.currentUser?.id } },
        { requestPolicy: 'network-only' },
      )
      await Promise.all(
        (respTopicInUser.data?.searchTopicInUser || []).map(topic =>
          gql.deleteTopicInUser({ id: topic.id }),
        ),
      )
      setDeleting(false)
      toastSuccess({ message: 'All records has been removed' })
    } catch {
      toastError({ message: 'Something went wrong, please try again later' })
    }
  }

  return (
    <View style={tw`mt-8 flex-col gap-3`}>
      <Text specialType='Headline4'>Learning settings</Text>
      <View style={tw`rounded-2xl bg-white py-2`}>
        <TouchableOpacity
          activeOpacity={0.6}
          style={tw`flex-row items-center justify-between px-4 h-14`}
          onPress={handleNavigate('payment')}
        >
          <View style={tw`flex-row items-center gap-2.5`}>
            <SystemIcon type='SAX' name='Star' />
            <Text specialType='paragraph1' style={tw`text-lg leading-none`}>
              Subscription
            </Text>
          </View>
          <View style={tw`flex-row items-center gap-1`}>
            <View style={tw`bg-green-500 rounded-md py-0.5 px-1.5`}>
              <Text style={tw`text-white`} specialType='Note'>
                Basic plan
              </Text>
            </View>
            <SystemIcon
              name='ArrowRight2'
              type='SAX'
              color={tw.color('neutral-300')}
            />
          </View>
        </TouchableOpacity>
        <Divider style={tw`my-1.5 bg-neutral-50`} />
        <TouchableOpacity
          activeOpacity={0.6}
          style={tw`flex-row items-center justify-between px-4 h-14`}
          onPress={handleNavigate('primary-language')}
        >
          <View style={tw`flex-row items-center gap-2.5`}>
            <SystemIcon type='SAX' name='Global' />
            <Text specialType='paragraph1' style={tw`text-lg leading-none`}>
              I speak
            </Text>
          </View>
          <View style={tw`flex-row items-center gap-1`}>
            <SystemIcon
              name='ArrowRight2'
              type='SAX'
              color={tw.color('neutral-300')}
            />
          </View>
        </TouchableOpacity>
        <Divider style={tw`my-1.5 bg-neutral-50`} />
        <TouchableOpacity
          activeOpacity={0.6}
          style={tw`flex-row items-center justify-between px-4 h-14`}
          onPress={handleNavigate('learn-language')}
        >
          <View style={tw`flex-row items-center gap-2.5`}>
            <SystemIcon type='SAX' name='Book1' />
            <Text specialType='paragraph1' style={tw`text-lg leading-none`}>
              I want to learn
            </Text>
          </View>
          <View style={tw`flex-row items-center gap-1`}>
            <SystemIcon
              name='ArrowRight2'
              type='SAX'
              color={tw.color('neutral-300')}
            />
          </View>
        </TouchableOpacity>
        <Divider style={tw`my-1.5 bg-neutral-50`} />
        <TouchableOpacity
          activeOpacity={0.6}
          style={tw`flex-row items-center justify-between px-4 h-14`}
          onPress={handleNavigate('learn-level')}
        >
          <View style={tw`flex-row items-center gap-2.5`}>
            <SystemIcon type='SAX' name='Medal' />
            <Text specialType='paragraph1' style={tw`text-lg leading-none`}>
              My level
            </Text>
          </View>
          <View style={tw`flex-row items-center gap-1`}>
            <Text
              specialType='Note'
              color={tw.color('text-2')}
              style={tw.style('text-sm', { lineHeight: undefined })}
            >
              Level {S.shared.currentUser?.level?.current}
            </Text>
            <SystemIcon
              name='ArrowRight2'
              type='SAX'
              color={tw.color('neutral-300')}
            />
          </View>
        </TouchableOpacity>
        {S.shared.currentUser?.isAdmin && (
          <Divider style={tw`my-1.5 bg-neutral-50`} />
        )}
        {S.shared.currentUser?.isAdmin && (
          <TouchableOpacity
            disabled={deleting}
            activeOpacity={0.6}
            style={tw`flex-row items-center justify-between px-4 h-14`}
            onPress={handleDeleteRecords}
          >
            <View style={tw`flex-row items-center gap-2.5`}>
              <SystemIcon type='SAX' name='Trash' color={tw.color('red-500')} />
              <Text
                specialType='paragraph1'
                style={tw`flex-1 text-lg leading-none text-red-500`}
              >
                Delete all records
              </Text>
            </View>
            {deleting && (
              <View style={tw`mr-1`}>
                <Spinner style={tw`text-red-500 text-lg`} />
              </View>
            )}
          </TouchableOpacity>
        )}
      </View>
    </View>
  )
})
