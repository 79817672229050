export const ImageDefault = () => (
  <svg
    width='27'
    height='22'
    viewBox='0 0 27 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='11' cy='4' r='3' fill='#B0AEAE' />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M24.3539 21.2694C25.9313 21.2694 26.8879 19.5287 26.0422 18.1972L20.1554 8.92778C19.3698 7.69074 17.5644 7.69074 16.7788 8.92779L12.5201 15.6335C11.7147 16.9017 9.85087 16.8623 9.09972 15.5613L7.19914 12.2694C6.42934 10.9361 4.50484 10.9361 3.73503 12.2694L0.270933 18.2694C-0.498868 19.6027 0.463385 21.2694 2.00299 21.2694H8.94081H12.3953H24.3539Z'
      fill='#B0AEAE'
    />
  </svg>
)
