import { useIsFocused } from '@react-navigation/native'
import { Flex, Tabs } from 'antd'
import { useEffect, useState } from 'react'

import { NavigateBackButton } from '#admin/components/utils/NavigateBackButton'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { useDetailTopic } from '#graphql/codegen'
import type { AdminScreenProps } from '#navigator/types'

import { Listening } from './Listen'
import { Speaking } from './Speaking'
import { WordAndPhrases } from './WordAndPhrases'

export type TopicDetailTabs = 'word_and_phrases' | 'listen'

export const AdminTopicDetail = ({
  navigation,
  route,
}: AdminScreenProps<'AdminTopicDetail'>) => {
  const { id, tab } = route.params
  const [activeKey, setActiveKey] = useState<TopicDetailTabs>(tab)
  const isFocus = useIsFocused()

  const [topic] = useDetailTopic({
    variables: { id },
  })

  useEffect(() => {
    if (tab !== activeKey) {
      setActiveKey(tab)
    }
  }, [tab, isFocus])

  const handleNavigateBack = () => {
    navigation.navigate('AdminTopics')
  }

  const onChangeTab = (key: string) => {
    const newTab: TopicDetailTabs = key as TopicDetailTabs
    setActiveKey(newTab)
    navigation.setParams({ id, tab: newTab })
  }

  return (
    <Flex style={tw`flex-1 flex-col p-4 h-full overflow-y-hidden`}>
      <Flex style={tw`flex pt-5 items-center`}>
        <NavigateBackButton direction={handleNavigateBack} />
        <Text style={tw`text-6 px-2`}>Topic detail</Text>
      </Flex>

      <Flex>
        <Text
          style={tw`py-4`}
          specialType='Subtitle'
        >{`Topic: ${topic?.data?.detailTopic.name ?? ''}`}</Text>
      </Flex>
      <Tabs
        type='card'
        defaultActiveKey={activeKey}
        activeKey={activeKey}
        onChange={onChangeTab}
      >
        <Tabs.TabPane tab='Word and Phrases' key='word_and_phrases'>
          <WordAndPhrases id={id} />
        </Tabs.TabPane>
        <Tabs.TabPane tab='Listen' key='listen'>
          <Listening id={id} />
        </Tabs.TabPane>
        <Tabs.TabPane tab='Speaking' key='speaking'>
          <Speaking id={id} />
        </Tabs.TabPane>
      </Tabs>
    </Flex>
  )
}
