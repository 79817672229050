import { useWindowDimensions } from 'react-native'

type Props = { padding: number }

export const useDimensions = ({ padding }: Props) => {
  const { width, height } = useWindowDimensions()
  const SCREEN_HEIGHT = height * 0.6
  const MAX_PADDING = padding * 2
  const screenWidth =
    width - MAX_PADDING < 550
      ? width - MAX_PADDING
      : SCREEN_HEIGHT * 0.7 + MAX_PADDING

  const maxWidth = Math.min(SCREEN_HEIGHT * 0.8, screenWidth - MAX_PADDING) + 8
  const maxHeight =
    height > 670 ? SCREEN_HEIGHT * 0.65 + 30 : SCREEN_HEIGHT * 0.45 + 30 // maxWidth * 1.1
  const swipeThreshold = screenWidth * 0.25

  return { maxWidth, maxHeight, swipeThreshold, screenWidth, width, height }
}
