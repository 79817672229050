import AsyncStorage from '@react-native-async-storage/async-storage'
import { get, lowerFirst, set, upperFirst } from 'lodash'
import { autorun, makeAutoObservable } from 'mobx'

import type {
  LowerFirst,
  NonNullish,
  Undefined,
  UpperFirst,
  UPromise,
} from '##/shared/ts'

import { LessonPercentageStore } from './LessonPercentageStore'
import { SharedStore } from './SharedStore'
import { SocketStore } from './SocketStore'

const stores = {
  SharedStore,
  SocketStore,
  LessonPercentageStore,
}

type U = keyof typeof stores
type L = ToL<U>

type ToL<T extends U> = T extends `${infer N}Store` ? LowerFirst<N> : never
const toL = <T extends U>(k: T) => lowerFirst(k).replace(/Store$/, '') as ToL<T>

type ToU<T extends L> = `${UpperFirst<T>}Store`
const toU = <T extends L>(k: T): ToU<T> =>
  `${upperFirst(k) as UpperFirst<T>}Store`

export const S = {} as {
  [k in L]: InstanceType<(typeof stores)[ToU<k>]>
}
export type RootStore = typeof S

Object.entries(stores).forEach(([uname, ctor]) => {
  const inst = new ctor()
  makeAutoObservable(inst)
  set(S, toL(uname as U), inst)
})

export const resetStore = <T extends L>(name: T) => {
  const ctor = stores[toU(name)]
  const inst = new ctor()
  makeAutoObservable(inst)
  set(S, name, inst)
}

/**
 * Automatically persist store with local storage.
 * Only supports those types: boolean, number, string.
 * ```ts
 * class ExampleStore {
 *    static persisted: Persisted<ExampleStore> = {
 *      // properties to be persisted...
 *    }
 * }
 * ```
 */
export type Persisted<T> = {
  [k in keyof T]?: NonNullish<T[k]> extends boolean
    ? 'boolean'
    : NonNullish<T[k]> extends number
      ? 'number'
      : NonNullish<T[k]> extends string
        ? 'string'
        : never
}

/**
 * Automatically persist store with local storage.
 */
let promise: UPromise
export const initPersistedStore = async () => {
  if (!promise) {
    promise = init()
  }
  return promise
}

const init = async () => {
  const tobeSynced: string[] = []
  Object.entries(stores).forEach(([uname, ctor]) => {
    if (!('persisted' in ctor)) {
      return
    }
    const { persisted } = ctor
    if (typeof persisted !== 'object' || !persisted) {
      return
    }
    Object.keys(persisted).forEach(k => {
      tobeSynced.push(`${toL(uname as U)}.${k}`)
    })
  })

  const ls = 'persistedStore'
  let persisted: Undefined = undefined
  try {
    const json = await AsyncStorage.getItem(ls)
    persisted = json && JSON.parse(json)
  } catch (err) {
    console.error(err)
    await AsyncStorage.removeItem(ls)
  }

  tobeSynced.forEach(sk => {
    const v = get(persisted, sk)
    const [lname, k] = sk.split('.')
    if (v === null || v === undefined) {
      return
    }
    const t = get(stores, `${toU(lname as L)}.persisted.${k}`)
    if (typeof v !== t) {
      return
    }
    set(S, sk, v)
  })

  autorun(() => {
    const data = tobeSynced.reduce((d, k) => set(d, k, get(S, k)), {})
    AsyncStorage.setItem(ls, JSON.stringify(data))
  })
}
