import type { UploadFile } from 'antd/es/upload'

import { postForm } from '#apis'
import type { ResourceType, ResponseFile } from '#types/media'

export const uploadFile = async (
  file: UploadFile,
  resourceType: ResourceType,
): Promise<ResponseFile> => {
  try {
    const formData = new FormData()
    formData.append('file', file as unknown as Blob)
    const resp = await postForm({
      endPoint: 'api/upload',
      data: formData,
      headers: { 'x-resource-type': resourceType },
    })
    return resp.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const uploadFileWithUrl = async (
  file: FormData,
  resourceType: ResourceType,
): Promise<ResponseFile> => {
  try {
    const resp = await postForm({
      endPoint: 'api/upload',
      data: file,
      headers: { 'x-resource-type': resourceType },
    })
    return resp.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const uploadFiles = async (
  files: UploadFile[],
  resourceType: ResourceType,
): Promise<ResponseFile[]> => {
  try {
    const formData = new FormData()
    files.map(file => {
      formData.append('file', file as unknown as Blob)
    })
    const resp = await postForm({
      endPoint: 'api/uploads',
      data: formData,
      headers: { 'x-resource-type': resourceType },
    })
    return resp.data
  } catch (error) {
    return Promise.reject(error)
  }
}
