import { ulid } from 'ulidx'

import type { UploadFileProps } from '#types/media'

export const getNameFromUrl = (url: string): string => {
  if (!url) {
    return ulid()
  }
  const path = url.split('/')
  return url.split('/').pop() || path[path.length - 1]
}

export const shortenFileName = (url: string, maxNameLength = 15): string => {
  const fileName = getNameFromUrl(url)

  if (fileName.length < maxNameLength) {
    return url
  }

  // Extract the base name from the file name
  const baseName = fileName.slice(0, maxNameLength - 3)

  // Shorten the name and keep the file extension
  const extension = fileName.split('.').pop()
  const shortName = `${baseName}....${extension}`

  return shortName
}

export const getUrlToFile = ({
  url,
  name,
}: {
  url: string
  name?: string
}): UploadFileProps => ({
  uid: ulid(),
  name: name || getNameFromUrl(url) || ulid(),
  status: 'done',
  url,
})
