import { useIsFocused } from '@react-navigation/native'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useEffect, useRef, useState } from 'react'
import { Platform, useWindowDimensions, View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { ulid } from 'ulidx'

import { Button } from '#components/base/Button/Button'
import type { FlatListRef } from '#components/base/FlatList'
import { FlatList } from '#components/base/FlatList'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { audioManager } from '#components/utils/audio/audioManager'
import { isLatestDateToday } from '#components/utils/streak'
import { tw } from '#components/utils/tw'
import { logCustomEvent } from '#config/firebaseConfig'
import type { Devices, LevelType } from '#graphql/codegen'
import { useSearchStreakInUser, useSearchTopicInUser } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import { navigate, replace } from '#navigator/helpers'
import type { AppStackScreenProps } from '#navigator/types'
import { S } from '#store'
import type {
  AITutorMessageType,
  ContentId,
  FooterButtonType,
  UserTasks,
} from '#types/chat'
import type { MessageItem, SearchMessageItem } from '#types/message'

import { contentData } from './baseChatContent'
import { createWordAndPhrase } from './createWordAndPhrase'
import { Footer } from './Footer'
import { Header } from './Header'
import { Input } from './Input'
import { ListFooter } from './ListFooter'
import { ListHeader } from './ListHeader'
import { Message } from './Message'
import { SuggestMessage } from './SuggestMessage'
import { TextSelectionWeb } from './TextSelectionWeb'
import { TipsChat } from './TipsChat'
import { calculateAudioDuration, getWordCount } from './utils'

type Props = AppStackScreenProps<'Chat'>

const addPreviousMessages = (
  messages: SearchMessageItem[],
  previousData: SearchMessageItem | null,
  fixedText: string,
): MessageItem[] => {
  const data = !previousData ? messages : [previousData, ...messages]
  return data.map((message, index) => {
    const previousMessage = index === data.length - 1 ? null : data[index + 1]
    return {
      ...message,
      previousMessage,
      fixedText,
      createdAt: new Date(message.createdAt),
    }
  })
}

export const Chat: FC<Props> = observer(({ route }) => {
  const { topicId } = route.params

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize] = useState<number>(15)
  const [total, setTotal] = useState<number>(0)
  const [data, setData] = useState<MessageItem[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingMessage, setLoadingMessage] = useState<boolean>(false)
  const [sttLoading, setSttLoading] = useState<boolean>(false)
  const [isLoadMore, setIsLoadMore] = useState<boolean>(false)
  const [selectedButton, setSelectedButton] = useState<FooterButtonType>(null)
  const [isNotHear, setNotHear] = useState(false)
  const [changingTutorState, setChangingTutorState] =
    useState<ContentId>('default')
  const [playingMessageId, setPlayingMessageId] = useState<string | null>(null)
  const [isFinishAllTask, setIsFinishAllTask] = useState<boolean>(false)
  const [key, setKey] = useState(0)
  const prevTasksRef = useRef()
  const voiceDuration = useRef(0)
  const { width } = useWindowDimensions()
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const isFocus = useIsFocused()
  const tutorId = useRef(S.shared.currentUser?.aiTutorId || '')
  const flatListRef = useRef<FlatListRef>(null)
  const [hidenKeyboard, setHidenKeyboard] = useState<boolean>(false)

  const resetPage = () => {
    setCurrentPage(1)
    setTotal(0)
    setData([])
    setLoading(true)
    setLoadingMessage(false)
    setIsLoadMore(false)
    setSelectedButton(null)
    setPlayingMessageId(null)
    prevTasksRef.current = undefined
    setKey(prevKey => prevKey + 1)
  }

  const [streakData] = useSearchStreakInUser({
    variables: { filter: { userId: S.shared.currentUser?.id } },
  })
  const streakInUser = streakData?.data?.searchStreakInUser || []
  const lastDate = streakInUser.length > 0 ? streakInUser[0].lastDate : ''

  const [topicInUser, refetch] = useSearchTopicInUser({
    variables: {
      filter: {
        topicId,
        userLearnId: S.shared.currentUser?.id,
      },
    },
    requestPolicy: 'network-only',
  })
  const topicInUserId = topicInUser.data?.searchTopicInUser[0]?.id ?? ''

  const currentTask: UserTasks =
    topicInUser.data?.searchTopicInUser[0]?.task ?? []

  useEffect(() => {
    const changeTutorMessage = async () => {
      if (tutorId.current !== S.shared.currentUser?.aiTutorId) {
        await createHardMessage({
          AIRole: true,
          message:
            S.shared.currentUser?.languageLearn === 'Spanish'
              ? contentData['changingTutor'].spanish
              : contentData['changingTutor'].english,
        })
        setHidenKeyboard(true)
        setChangingTutorState('changingTutor')
        tutorId.current = S.shared.currentUser?.aiTutorId || ''
      }
    }

    changeTutorMessage()
  }, [S.shared.currentUser?.aiTutorId])

  useEffect(() => {
    if (!isFocus) {
      audioManager.stop()
    }
    return () => {
      audioManager.stop()
      S.shared.isChatSuggestShow = false
    }
  }, [isFocus])

  useEffect(() => {
    if (!topicInUser.fetching && currentTask.data.length > 0) {
      setIsFinishAllTask(false)
      const taskDone = currentTask.data.filter(t => t.isPassed)
      if (currentTask.data.length === taskDone.length && !loadingMessage) {
        setIsFinishAllTask(true)
      }
    }
  }, [topicInUser])

  useEffect(() => {
    if (topicInUserId) {
      fetchData()
    }
  }, [currentPage, topicInUserId, key])

  useEffect(() => {
    S.shared.isShowBottomSheet = false
  }, [])

  useEffect(() => {
    let intervalId: number

    const checkPlaybackEnd = async () => {
      const currentPosition = await audioManager.getCurrentPosition()
      const duration = await audioManager.getDuration()

      if (currentPosition >= duration && isPlaying) {
        setIsPlaying(false)
        setPlayingMessageId(null)
        clearInterval(intervalId)
      }
    }

    if (isPlaying) {
      intervalId = setInterval(checkPlaybackEnd, 1000) as unknown as number
    }

    return () => clearInterval(intervalId)
  }, [isPlaying])

  const bufferCallback = async (buffer: string) => {
    setSttLoading(true)
    if (buffer === '') {
      createMessageNotHearingSound()
      setNotHear(true)
      setSttLoading(false)
      return
    }
    const devices = Platform.OS as Devices
    const resp = await gql.speechToText({ audioBuffer: buffer, devices })
    setSttLoading(false)
    if (resp.data?.speechToText && resp.data.speechToText === '\n') {
      createMessageNotHearingSound()
      setNotHear(true)
      return
    }
    voiceDuration.current = calculateAudioDuration(buffer)
    createMessage(resp.data?.speechToText || '', true, currentTask)
  }

  const checkAllTasksDone = async (ut: UserTasks) => {
    const currentTasks = ut.data
    const taskDone = currentTasks.filter(t => t.isPassed)
    if (currentTasks.length === taskDone.length && !loadingMessage) {
      setIsFinishAllTask(true)
      await createMessage('', true, { data: [] })
      const p = topicInUser.data?.searchTopicInUser[0]?.percent || 0

      if (p < 100) {
        const topicName =
          topicInUser.data?.searchTopicInUser[0]?.topic?.name || ''
        if (topicName) {
          logCustomEvent('completed_topic', {
            id: topicId,
            name: topicName,
          })
        }
        createWordAndPhrase(topicId, S.shared.currentUser?.id || '')
        await gql.updateTopicInUser({
          id: topicInUserId,
          data: { percent: 100 },
        })
      }
    }
  }

  const fetchData = async () => {
    setLoading(true)
    try {
      const resp = await gql.searchMessageList(
        {
          filter: {
            userId: S.shared.currentUser?.id ?? '',
            topicInUserId,
          },
          order: ['createdAt_desc'],
          page: {
            limit: pageSize,
            offset: (currentPage - 1) * pageSize,
          },
        },
        { requestPolicy: 'network-only' },
      )
      if (resp.data) {
        if (resp.data.messages.length === 0) {
          createMessage('', false, currentTask)
        } else {
          setTotal(resp.data?.total ?? 0)

          setData(current => {
            const newData = (resp.data?.messages ?? []).map(message => ({
              ...message,
              isNew: false,
              createdAt: new Date(message.createdAt),
              topicInUser: message.topicInUser
                ? {
                    ...message.topicInUser,
                    createdAt: new Date(message.topicInUser.createdAt),
                  }
                : null,
            }))
            const lastItem = data.length > 0 ? data[data.length - 1] : null
            const list = addPreviousMessages(
              newData,
              currentPage < 2 ? null : lastItem,
              '',
            )
            return currentPage < 2
              ? list
              : [...current.slice(0, current.length - 1), ...list]
          })
        }
      }

      setLoading(false)
      setIsLoadMore(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const onSendSuggestMessage = (s: string, isSpeaking: boolean) => {
    S.shared.isChatSuggestShow = false
    createMessage(s, isSpeaking, currentTask)
  }

  const createMessageNotHearingSound = () => {
    scrollToTop()
    const id = ulid()
    const lastMessage = data.length > 0 ? data[0] : null
    const currentUserId = S.shared.currentUser?.id || ''

    const newMessage: MessageItem = {
      content: '',
      userId: currentUserId,
      topicInUserId: topicInUserId || '',
      sentByUser: true,
      id,
      createdAt: new Date(),
      previousMessage: lastMessage,
      fixedText: '',
      canHear: false,
    }
    setData(current => [newMessage, ...current])
  }

  const clearNotHearingMessage = () => {
    if (!isNotHear) {
      return
    }
    setData(c => {
      if (c[0].canHear === false && c[0].sentByUser) {
        return c.slice(1)
      }
      return c
    })
    setNotHear(false)
  }

  const createHardMessage = async (AITutorMessage: AITutorMessageType) => {
    scrollToTop()
    const id = ulid()

    const lastMessage = data.length > 0 ? data[0] : null
    const currentUserId = S.shared.currentUser?.id || ''

    const newMessage: MessageItem = {
      content: AITutorMessage.message,
      userId: currentUserId,
      topicInUserId: topicInUserId || '',
      sentByUser: !AITutorMessage?.AIRole,
      id,
      createdAt: new Date(),
      previousMessage: lastMessage,
      fixedText: '',
    }

    setData(current => [newMessage, ...current])
    if (AITutorMessage.AIRole) {
      setLoadingMessage(true)
    }

    const resp = await gql.createMessage({
      data: {
        ...(AITutorMessage && {
          AITutor: {
            AIRole: AITutorMessage.AIRole,
            content: AITutorMessage.message,
          },
        }),
        content: '',
        topicInUserId,
      },
    })

    const createdMessage = resp.data?.createMessage

    if (createdMessage) {
      setData(current =>
        current.map(msg =>
          msg.id === id
            ? {
                ...msg,
                id: createdMessage.id,
                content: createdMessage.content,
              }
            : msg,
        ),
      )

      if (S.shared.currentUser?.autoPlay) {
        handlePlayTTS(createdMessage)
      }
    }

    setLoadingMessage(false)
  }

  const createMessage = async (
    content: string,
    isSpeaking: boolean,
    task: UserTasks,
  ) => {
    clearNotHearingMessage()
    setChangingTutorState('default')
    scrollToTop()
    const userTempId = ulid()
    const systemTempId = ulid()

    const lastMessage = data.length > 0 ? data[0] : null
    const currentUserId = S.shared.currentUser?.id || ''

    const newUserMessage: MessageItem = {
      content,
      userId: currentUserId,
      topicInUserId: topicInUserId || '',
      sentByUser: true,
      id: userTempId,
      createdAt: new Date(),
      previousMessage: lastMessage,
      fixedText: '',
      canHear: true,
    }

    const newSystemMessage: MessageItem = {
      content: '',
      userId: currentUserId,
      topicInUserId: topicInUserId || '',
      sentByUser: false,
      id: systemTempId,
      createdAt: new Date(),
      previousMessage: newUserMessage,
      fixedText: '',
      canHear: true,
    }

    setLoadingMessage(true)
    if (content !== '') {
      setData(current => [newUserMessage, ...current])
    }
    setData(current => [newSystemMessage, ...current])

    const resp = await gql.createMessage({
      data: {
        content,
        topicInUserId,
        topic_chat: topicInUser.data?.searchTopicInUser[0]?.topic?.name || '',
        tasks: task.data || [],
        botRole:
          topicInUser.data?.searchTopicInUser[0]?.topic?.aiTutorRole || '',
        userRole: topicInUser.data?.searchTopicInUser[0]?.topic?.userRole || '',
        scenario: topicInUser.data?.searchTopicInUser[0]?.topic?.scenario || '',
      },
    })

    const fixedObject = await autoCorrectMessage(content)

    const createdMessage = resp.data?.createMessage

    if (createdMessage) {
      const [correctedText, mistakeCount] = fixedObject
      if (fixedObject) {
        setData(current =>
          current.map(msg =>
            msg.id === userTempId
              ? {
                  ...msg,
                  fixedText: correctedText,
                }
              : msg,
          ),
        )
      }
      if (isSpeaking) {
        logCustomEvent('voice_message', {
          user_id: S.shared.currentUser?.id || '',
          topic_id: topicId,
          topic_name: topicInUser.data?.searchTopicInUser[0].topic?.name || '',
          number_of_words: getWordCount(content),
          time: voiceDuration.current,
          type: 'speaking',
          message_id: createdMessage.id,
          number_of_mistakes: mistakeCount,
        })
      } else {
        logCustomEvent('text_message', {
          user_id: S.shared.currentUser?.id || '',
          topic_id: topicId,
          topic_name: topicInUser.data?.searchTopicInUser[0].topic?.name || '',
          number_of_words: getWordCount(content),
          type: 'typing',
          message_id: createdMessage.id,
          number_of_mistakes: mistakeCount,
        })
      }

      setData(current =>
        current.map(msg =>
          msg.id === systemTempId
            ? {
                ...msg,
                id: createdMessage.id,
                content: createdMessage.content,
              }
            : msg,
        ),
      )

      setLoadingMessage(false)
      if (S.shared.currentUser?.autoPlay) {
        handlePlayTTS(createdMessage)
      }
      if (content !== '') {
        await callCheckTask(content)
      }
    }
  }

  const autoCorrectMessage = async (
    content: string,
  ): Promise<[string, number]> => {
    let correctedText = ''
    let mistake = 0
    if (content !== '') {
      const reps = await gql.testAutoCorrectMessage(
        { content },
        { requestPolicy: 'network-only' },
      )
      const d = reps.data?.testAutoCorrectMessage

      if (S.shared.currentUser?.repair === 2) {
        // always
        if (!d.hasNoMistake) {
          correctedText = d.fixedText
          mistake = d.numberOfMistake
        }
      } else if (S.shared.currentUser?.repair === 1) {
        if (S.shared.autoCorrectCount === 4) {
          // sometimes
          if (!d.hasNoMistake) {
            correctedText = d.fixedText
            mistake = d.numberOfMistake
            S.shared.autoCorrectCount = 0
          }
        } else {
          S.shared.autoCorrectCount += 1
        }
      }
    }

    return [correctedText, mistake]
  }

  const callCheckTask = async (content: string) => {
    const prevTaskCompletionState = currentTask.data.map(task => ({
      id: task.id,
      wasCompleted: task.isPassed || task.subTask.every(sub => sub.isPassed),
    }))

    if (currentTask?.data.length > 0) {
      const taskResp = await gql.checkTask({
        content,
        task: currentTask?.data || [],
        level:
          (topicInUser.data?.searchTopicInUser[0]?.topic?.level as LevelType) ||
          'A1',
        topicInUserId,
      })

      if (taskResp.data?.checkTask) {
        const dataString = taskResp.data.checkTask.replace(/[“”]/g, '"')

        let parsedData

        if (dataString.startsWith('[') && dataString.endsWith(']')) {
          parsedData = JSON.parse(dataString)
        } else {
          parsedData = []
        }

        const completedSubtasks = new Set(parsedData)

        const updatedTasks: UserTasks = {
          data: currentTask.data.map(task => {
            if (task.subTask.length === 0) {
              return {
                ...task,
                isPassed: completedSubtasks.has(task.id) ? true : task.isPassed,
              }
            }

            const updatedSubTask = task.subTask.map(sub => {
              const wasPreviouslyIncomplete = !sub.isPassed
              const isNowComplete = completedSubtasks.has(sub.id)

              if (wasPreviouslyIncomplete && isNowComplete) {
                logCustomEvent('completed_subtask', {
                  subtask_id: sub.id,
                  subtask_name: sub.name,
                  task_id: task.id,
                  task_name: task.name,
                })
              }

              return {
                ...sub,
                isPassed: isNowComplete ? true : sub.isPassed,
              }
            })

            return {
              ...task,
              subTask: updatedSubTask,
              isPassed: updatedSubTask.every(sub => sub.isPassed),
            }
          }),
        }

        updatedTasks.data.forEach((task, index) => {
          const allSubtasksPassed =
            task.subTask.every(sub => sub.isPassed) || task.isPassed

          const prevState = prevTaskCompletionState.find(t => t.id === task.id)

          if (
            (!prevState?.wasCompleted && allSubtasksPassed) ||
            (task.subTask.length === 0 && completedSubtasks.has(task.id))
          ) {
            logCustomEvent('completed_task', {
              task_id: task.id,
              task_name: task.name,
            })
            const taskMessage: MessageItem = {
              content: '',
              userId: '',
              topicInUserId: '',
              sentByUser: false,
              id: '',
              previousMessage: null,
              createdAt: new Date(),
              taskPassedString: `You completed task: ${index + 1}`,
              fixedText: '',
            }

            setData(current => [taskMessage, ...current])
          }
        })

        await gql.updateTopicInUser({
          id: topicInUserId,
          data: { task: updatedTasks },
        })
        checkAllTasksDone(updatedTasks)
      }
    }
  }

  const onEndReached = () => {
    if (!loading && !isLoadMore && total > currentPage * pageSize) {
      setIsLoadMore(true)
      setCurrentPage(currentPage + 1)
    }
  }

  const handleShowInputPress = () => {
    setSelectedButton(prev => (prev === 'input' ? null : 'input'))
  }

  const handleSpeakPress = () => {
    if (playingMessageId) {
      audioManager.stop()
      setPlayingMessageId(null)
    }
    setSelectedButton(prev => (prev === 'speak' ? null : 'speak'))
  }

  const scrollToTop = () => {
    flatListRef.current?.scrollToTop()
  }

  const renderItem = ({
    item,
    index,
  }: {
    item: MessageItem
    index: number
  }) => (
    <Message
      item={item}
      isLoading={loadingMessage && index === 0}
      onTTSPress={() => handlePlayTTS(item, index)}
      isPlaying={isPlaying}
      isItemPlaying={playingMessageId === item.id}
    />
  )
  const renderFooter = () => <ListFooter />

  const renderHeader = () => (
    <ListHeader
      state={changingTutorState}
      createHardMessage={async obj => {
        await createHardMessage(obj)
      }}
      createMessage={() => createMessage('', false, currentTask)}
      setState={setChangingTutorState}
      language={S.shared.currentUser?.languageLearn || 'English'}
      topicInUserId={topicInUserId}
      setHidenKeyboard={setHidenKeyboard}
    />
  )

  const handlePlayTTS = async (item: SearchMessageItem, index?: number) => {
    try {
      setIsPlaying(true)
      if (playingMessageId === item.id) {
        audioManager.stop()
        setPlayingMessageId(null)
        setIsPlaying(false)
        return
      }

      const tts = await gql.textToSpeech(
        { text: item.content },
        { requestPolicy: 'network-only' },
      )
      const base64Audio = tts.data?.textToSpeech

      if (base64Audio) {
        const base64Uri = `data:audio/mp3;base64,${base64Audio}`
        await audioManager.add(base64Uri)
        await audioManager.play(S.shared.currentUser?.speed || 1)
        // const duration = await audioManager.getDuration()
        setPlayingMessageId(item.id)
      }
    } catch (error) {
      console.error('Error playing TTS audio:', error)
      setIsPlaying(false)
    }
  }

  const handleCancelPress = () => {
    setSelectedButton(prev => (prev === 'cancel' ? null : 'cancel'))
  }

  const onPressNextTopic = () => {
    setIsFinishAllTask(false)
    replace('Home', { screen: 'Topics' })
    if (!isLatestDateToday(lastDate)) {
      navigate('App', { screen: 'StreakDaily', params: { isGoBack: true } })
    }
  }

  const onPressKeepPractising = async () => {
    if (!isLatestDateToday(lastDate)) {
      navigate('App', { screen: 'StreakDaily', params: { isGoBack: true } })
    }

    await gql.deleteMessageTopicInUser({ topicInUserId })

    if (S.shared.speakingDeafaultTask) {
      const taskData =
        typeof S.shared.speakingDeafaultTask === 'string'
          ? (JSON.parse(S.shared.speakingDeafaultTask) as unknown as UserTasks)
          : S.shared.speakingDeafaultTask

      await gql.updateTopicInUser({
        id: topicInUserId,
        data: { task: taskData },
      })
      refetch({ requestPolicy: 'network-only' })
      resetPage()
    }
  }

  return (
    <View style={tw`flex-1 flex-col`} key={key}>
      <Header
        tasks={topicInUser.data?.searchTopicInUser[0]?.task?.data || []}
        topicId={topicId}
        topicName={topicInUser.data?.searchTopicInUser[0].topic?.name || ''}
      />
      <View style={tw`flex-1`}>
        {Platform.OS === 'web' && <TextSelectionWeb />}
        <FlatList
          ref={flatListRef}
          data={data}
          renderItem={renderItem}
          contentContainerStyle={tw`flex-col pt-4`}
          inverted
          onEndReached={onEndReached}
          onEndReachedThreshold={0.3}
          ListFooterComponent={renderFooter}
          ListHeaderComponent={renderHeader}
        />
      </View>
      {S.shared.isChatSuggestShow && width >= 900 && (
        <SuggestMessage
          onSend={content => onSendSuggestMessage(content, false)}
          topicInUserId={topicInUserId}
        />
      )}
      <View
        style={tw.style('bg-background-light-white rounded-t-3xl flex-col')}
      >
        {S.shared.isChatSuggestShow && width < 900 && (
          <SuggestMessage
            onSend={content => onSendSuggestMessage(content, false)}
            topicInUserId={topicInUserId}
          />
        )}
      </View>
      {selectedButton === 'input' ? (
        !hidenKeyboard ? (
          <View
            style={tw.style('bg-background-light-white rounded-t-3xl flex-col')}
          >
            {!isFinishAllTask ? (
              <View>
                {width > 900 ? (
                  <View style={tw.style('flex-col rounded-t-3xl px-4')}>
                    <Input
                      onSend={content =>
                        createMessage(content, false, currentTask)
                      }
                      disableButton={loadingMessage}
                    />
                    <TouchableOpacity
                      style={[
                        tw.style('flex-row justify-center py-1 px-2 mb-6 mt-3'),
                        Platform.OS === 'web' && { cursor: 'pointer' },
                      ]}
                      onPress={handleShowInputPress}
                    >
                      <SystemIcon
                        type='SAX'
                        name='Microphone2'
                        variant='Bold'
                        color={tw.color('text-2')}
                      />
                      <Text specialType='Title' color={tw.color('text-2')}>
                        Voice Mode
                      </Text>
                    </TouchableOpacity>
                  </View>
                ) : (
                  <View
                    style={tw.style(
                      'bg-background-light-white h-35 overflow-hidden items-center',
                      !S.shared.isChatSuggestShow && width < 900 ? '' : '',
                      !S.shared.isChatSuggestShow && 'rounded-t-3xl',
                      width >= 900 &&
                        S.shared.isChatSuggestShow &&
                        'rounded-t-3xl',
                    )}
                  >
                    <Input
                      onSend={content =>
                        createMessage(content, false, currentTask)
                      }
                      disableButton={loadingMessage}
                      handleMicIconPress={() => setSelectedButton(null)}
                    />
                  </View>
                )}
              </View>
            ) : (
              <View
                style={tw.style(
                  'flex-1 px-2 w-full items-center gap-3 pt-8 pb-4 flex-row',
                )}
              >
                <View style={tw.style('flex-1')}>
                  <Button tone='primary' onPress={onPressNextTopic}>
                    Next topic
                  </Button>
                </View>
                <View
                  style={tw.style(
                    'flex-1 border border-primary-400 rounded-full',
                  )}
                >
                  <Button tone='secondary' onPress={onPressKeepPractising}>
                    Keep practicing
                  </Button>
                </View>
              </View>
            )}
          </View>
        ) : null
      ) : !hidenKeyboard ? (
        <View
          style={tw.style(
            'bg-background-light-white',
            !S.shared.isChatSuggestShow && width < 900 ? '' : '',
            !S.shared.isChatSuggestShow && 'rounded-t-3xl',
            width >= 900 && S.shared.isChatSuggestShow && 'rounded-t-3xl',
          )}
        >
          {!isFinishAllTask ? (
            <Footer
              loading={sttLoading}
              bufferCallback={bufferCallback}
              selectedButton={selectedButton}
              onChangeSuggestMessage={clearNotHearingMessage}
              onButtonPress={buttonType => {
                clearNotHearingMessage()
                switch (buttonType) {
                  case 'input':
                    handleShowInputPress()
                    break
                  case 'speak':
                    handleSpeakPress()
                    break
                  case 'cancel':
                    handleCancelPress()
                    break
                  default:
                    break
                }
              }}
            />
          ) : (
            <View
              style={tw.style(
                'flex-1 px-2 w-full items-center gap-3 pt-8 pb-4 flex-row',
              )}
            >
              <View style={tw.style('flex-1')}>
                <Button tone='primary' onPress={onPressNextTopic}>
                  Next topic
                </Button>
              </View>
              <View
                style={tw.style(
                  'flex-1 border border-primary-400 rounded-full',
                )}
              >
                <Button tone='secondary' onPress={onPressKeepPractising}>
                  Keep practicing
                </Button>
              </View>
            </View>
          )}
        </View>
      ) : null}

      {!S.shared.isTipsTopicSpeak && <TipsChat />}
    </View>
  )
})
