import moment from 'moment'

export const getStreakIndicators = (streakDays: string[]): boolean[] => {
  if (streakDays.length === 0) {
    return Array(7).fill(false)
  }

  const daysOfWeek = 7

  const firstDayOfWeek = new Date()
  firstDayOfWeek.setHours(0, 0, 0, 0)
  firstDayOfWeek.setDate(firstDayOfWeek.getDate() - firstDayOfWeek.getDay())

  const lastDayOfWeek = new Date(firstDayOfWeek)
  lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6)
  const sortedDatesAsc = streakDays
    .sort((a, b) => moment(a).diff(moment(b)))
    .map(i => new Date(i))

  const streakDates = sortedDatesAsc.filter(
    date => date >= firstDayOfWeek && date <= lastDayOfWeek,
  )

  return Array.from({ length: daysOfWeek }).map((_, index) => {
    const currentDate = new Date(firstDayOfWeek)
    currentDate.setDate(currentDate.getDate() + index)

    return streakDates.some(
      streakDate =>
        streakDate.getFullYear() === currentDate.getFullYear() &&
        streakDate.getMonth() === currentDate.getMonth() &&
        streakDate.getDate() === currentDate.getDate(),
    )
  })
}

export const isLatestDateToday = (streakDays: string): boolean => {
  if (!streakDays) {
    return false
  }
  const currentDate = moment().format('YYYY-MM-DD')
  const value = moment(currentDate).isSame(moment(streakDays), 'day')
  return value
}

export const isLastDateAdjacentToday = (streakDays: string[]): boolean => {
  if (streakDays.length === 0) {
    return false
  }

  const sortedDatesAsc = streakDays.sort((a, b) => moment(a).diff(moment(b)))

  const lastDate = moment(sortedDatesAsc[sortedDatesAsc.length - 1])

  const isAdjacent = lastDate.add(1, 'day').isSame(moment(), 'day')

  return isAdjacent
}
