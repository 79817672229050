import { Form, Input } from 'antd'
import type React from 'react'
import { useState } from 'react'

import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { tw } from '#components/utils/tw'
import { gql } from '#graphql/urql'
import type { SearchCategoryItem } from '#types/category'

type Prop = {
  selectedTag?: SearchCategoryItem
  closeModal: () => void
  onOk: () => void
}

export const TagModal = ({ selectedTag, closeModal, onOk }: Prop) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [tag, setTag] = useState<string>(selectedTag?.tagName ?? '')

  const handleModalOk = async () => {
    setLoading(true)
    try {
      let res
      if (selectedTag) {
        res = await gql.updateCategory({
          id: selectedTag.id,
          data: {
            tagName: tag,
          },
        })
      } else {
        res = await gql.createCategory({
          data: {
            tagName: tag,
          },
        })
      }

      if (res.error || !res.data) {
        toastError({ title: 'Error', message: res.error?.message })
        setLoading(false)
        return
      }
      toastSuccess({
        message: 'Update tag successfully',
      })
      onOk()
      closeModal()
    } catch (error) {
      toastError({ message: 'Failed to handle tag' })
    }
    setLoading(false)
  }

  const handleModalCancel = () => {
    closeModal()
  }

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && tag && !loading) {
      handleModalOk()
    }
  }

  return (
    <ModalLayout
      title={selectedTag ? 'Edit tag' : 'Add new tag'}
      closeModal={handleModalCancel}
      onConfirm={handleModalOk}
      disableOk={!tag || loading}
      autoClose={false}
      isLoading={loading}
    >
      <Form layout='vertical' onKeyUp={handleKeyPress} style={tw`w- pt-2`}>
        <Form.Item label='Tag'>
          <Input
            value={tag}
            onChange={e => {
              setTag(e.target.value)
            }}
          />
        </Form.Item>
      </Form>
    </ModalLayout>
  )
}
