import { ImageBackground, Platform, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import BeforeTestBg from '#assets/images/before_test_bg.png'

import { Button } from '#components/base/Button/Button'
import { LinearGradientColor } from '#components/base/LinearGradientColor/LinearGradientColor'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { goBack } from '#navigator/helpers'

type Props = {
  handleStartTest: () => void
}

export const OverView = ({ handleStartTest }: Props) => {
  const insets = useSafeAreaInsets()

  return (
    <View style={tw`flex-1 flex-col bg-transparent`}>
      <View style={tw`relative h-48 overflow-hidden`}>
        <LinearGradientColor start={{ x: 0, y: 0 }} end={{ x: 0, y: 1 }}>
          <ImageBackground
            source={{ uri: BeforeTestBg }}
            resizeMode='cover'
            style={tw`w-full h-full`}
          />
        </LinearGradientColor>
      </View>
      <View
        style={tw`flex-1 bg-white -mt-7 rounded-3xl rounded-b-none py-4 px-5`}
      >
        <View style={tw`flex-col gap-1 mt-4`}>
          <Text specialType='Headline2' style={tw`text-[28px]`}>
            Before we start
          </Text>
          <Text specialType='Subtitle' style={tw`text-gray-500`}>
            Ensure everything is ready for the test
          </Text>
        </View>

        <View style={tw`relative flex-col gap-4 mt-5`}>
          <View
            style={tw`w-full rounded-lg p-4 border border-gray-200 shadow overflow-hidden`}
          >
            <View style={tw`relative h-2 mb-4`}>
              <View
                style={tw`absolute top-0 left-0 w-10 h-full bg-pink-500 z-8 rounded-full border border-white`}
              />
              <View
                style={tw`absolute top-0 left-0 w-14 h-full bg-pink-600 rounded-full border border-white`}
              />
            </View>
            <Text specialType='paragraph1' style={tw`w-3/4`}>
              This test takes between 5-10 minutes.
            </Text>
            <View style={tw`absolute -right-7 -bottom-6`}>
              <SystemIcon
                type='SAX'
                name='Timer'
                variant='Bulk'
                color={tw.color('pink-600/20')}
                size={105}
              />
            </View>
          </View>
          <View
            style={tw`w-full rounded-lg p-4 border border-gray-200 shadow overflow-hidden`}
          >
            <View style={tw`relative h-2 mb-4`}>
              <View
                style={tw`absolute top-0 left-0 w-10 h-full bg-sky-500 z-8 rounded-full border border-white`}
              />
              <View
                style={tw`absolute top-0 left-0 w-14 h-full bg-sky-600 rounded-full border border-white`}
              />
            </View>
            <Text specialType='paragraph1' style={tw`w-3/4`}>
              Make sure you can hear the audio clearly.
            </Text>
            <View style={tw`absolute -right-6 -bottom-6`}>
              <SystemIcon
                type='SAX'
                name='MusicPlay'
                variant='Bulk'
                color={tw.color('sky-600/20')}
                size={100}
              />
            </View>
          </View>
          <View
            style={tw`w-full rounded-lg p-4 border border-gray-200 shadow overflow-hidden`}
          >
            <View style={tw`relative h-2 mb-4`}>
              <View
                style={tw`absolute top-0 left-0 w-10 h-full bg-indigo-500 z-8 rounded-full border border-white`}
              />
              <View
                style={tw`absolute top-0 left-0 w-14 h-full bg-indigo-600 rounded-full border border-white`}
              />
            </View>
            <Text specialType='paragraph1' style={tw`w-3/4`}>
              Make sure you have a good internet connection.
            </Text>
            <View style={tw`absolute -right-6 -bottom-6`}>
              <SystemIcon
                type='SAX'
                name='Airdrop'
                variant='Bulk'
                color={tw.color('indigo-600/20')}
                size={100}
              />
            </View>
          </View>
          <View
            style={tw`w-full rounded-lg p-4 border border-gray-200 shadow overflow-hidden`}
          >
            <View style={tw`relative h-2 mb-4`}>
              <View
                style={tw`absolute top-0 left-0 w-10 h-full bg-lime-500 z-8 rounded-full border border-white`}
              />
              <View
                style={tw`absolute top-0 left-0 w-14 h-full bg-lime-600 rounded-full border border-white`}
              />
            </View>
            <Text specialType='paragraph1' style={tw`w-3/4`}>
              You can access this test later from the settings menu.
            </Text>
            <View style={tw`absolute -right-6 -bottom-6`}>
              <SystemIcon
                type='SAX'
                name='Calendar'
                variant='Bulk'
                color={tw.color('lime-600/20')}
                size={100}
              />
            </View>
          </View>
        </View>

        <View
          style={tw.style(
            'mt-auto flex-col gap-2.5',
            Platform.OS === 'ios' && { paddingBottom: insets.bottom },
          )}
        >
          <Button onPress={handleStartTest} tone='primary'>
            Start now
          </Button>
          <View style={tw`border border-gray-300 rounded-full`}>
            <Button onPress={goBack} tone='plain'>
              Maybe later
            </Button>
          </View>
        </View>
      </View>
    </View>
  )
}
