import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { View } from 'react-native'

import { FlatList } from '#components/base/FlatList'
import { audioManager } from '#components/utils/audio/audioManager'
import { tw } from '#components/utils/tw'
import { Header } from '#components/widgets/Header'
import type { WordInUserFilter } from '#graphql/codegen'
import { useSearchTopicInUser } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import { S } from '#store'
import type { LanguageType } from '#types/language'
import { LanguageLabel } from '#types/language'
import type { ResourceInWord } from '#types/resourceInWord'
import type {
  SearchTopicInUserItem,
  SearchWordbankItem,
} from '#types/topicInUser'
import type { SearchTranslateItem } from '#types/translate'
import type { SearchWordInUser } from '#types/wordInUser'

import { Empty } from './Empty'
import type { FilterType } from './Filters'
import { Item } from './Item'
import { ListHeader } from './ListHeader'
import { SItem } from './SavedItem'

type WordBankProps = {}

type Item = SearchWordInUser & {
  translate: SearchTranslateItem[]
  media: ResourceInWord[]
}

const filters: { [key in FilterType]: WordInUserFilter } = {
  words: { word_some: { isVocabulary: true } },
  phrases: { word_some: { isVocabulary: false } },
  saved: {},
}

export const WordBank: FC<WordBankProps> = observer(() => {
  const [loading, setLoading] = useState<boolean>(false)
  const [fetching, setFetching] = useState<boolean>(false)
  const [filterType, setFilterType] = useState<FilterType>('words')
  const [filterSort, setFilterSort] = useState<boolean>(false)
  const [lesson, setLesson] = useState<SearchTopicInUserItem | null>(null)
  const [wordsList, setWordsList] = useState<Item[]>([])
  const [phrasesList, setPhrasesList] = useState<Item[]>([])
  const [savedList, setSavedList] = useState<SearchWordbankItem[]>([])
  const [playingIndex, setPlayingIndex] = useState<number | null>(null)

  useEffect(() => {
    if (lesson?.topicId || fetching) {
      fetchData()
    }
  }, [lesson, fetching])

  useEffect(() => {
    audioManager.stop()
  }, [filterType])

  const [topics] = useSearchTopicInUser({
    variables: {
      filter: {
        userLearnId: S.shared.currentUser?.id || '',
        // learning: true,
      },
    },
    requestPolicy: 'network-only',
  })

  const sortedTopics =
    topics.data?.searchTopicInUser?.slice().sort((a, b) => {
      if (!a.topic || !b.topic) {
        return !a.topic ? 1 : -1
      }
      return a.topic.level.localeCompare(b.topic.level)
    }) || []

  useEffect(() => {
    if (sortedTopics.length !== 0 && lesson === null) {
      const currentTopic = sortedTopics.filter(
        tiu =>
          tiu.id ===
          S.lessonPercentage.getLessonPercentageById(S.shared.currentTopicId)
            .currentTopicInUserId,
      )
      if (currentTopic.length > 0) {
        setLesson(currentTopic[0])
      } else {
        setLesson(sortedTopics[0])
      }
    }
  }, [sortedTopics])

  const fetchData = async () => {
    if (!lesson?.topicId) {
      return
    }
    setLoading(true)

    setWordsList([])
    setPhrasesList([])
    setSavedList([])

    const baseFilter: WordInUserFilter = {
      userId: S.shared.currentUser?.id,
      topicId: lesson.topicId,
      topic_some: {
        languageTopic:
          (S.shared.currentUser?.languageLearn as LanguageType) ||
          LanguageLabel.English,
      },
    }

    // Fetch "words"
    const respWords = await gql.searchWordInUser(
      {
        filter: {
          ...baseFilter,
          ...filters['words'],
        },
      },
      {
        requestPolicy: 'network-only',
      },
    )
    const listWords = respWords.data?.searchWordInUser || []
    // Fetch "phrases"
    const respPhrases = await gql.searchWordInUser(
      {
        filter: {
          ...baseFilter,
          ...filters['phrases'],
        },
      },
      {
        requestPolicy: 'network-only',
      },
    )
    const listPhrases = respPhrases.data?.searchWordInUser || []

    const respSaved = await gql.searchWordbanks(
      {
        filter: {
          userId: S.shared.currentUser?.id || '',
          topicId: lesson.topicId,
        },
      },
      {
        requestPolicy: 'network-only',
      },
    )

    const listSaved: SearchWordbankItem[] =
      respSaved.data?.searchWordbanks || []
    setSavedList(listSaved)

    // Fetch resources
    const combinedList = [...listWords, ...listPhrases]
    const respResource = await gql.searchResourceWord({
      filterTranslate: {
        wQAId_in: combinedList.map(i => i.wordId),
        suportLanguage:
          (S.shared.currentUser?.nativeLanguage as LanguageType) ||
          LanguageLabel.English,
      },
      filterResource: {
        wordId_in: combinedList.map(i => i.wordId),
      },
    })

    const translation = respResource.data?.translate || []

    const resource = respResource.data?.resource || []

    const wordsListWithDetails = listWords
      .map(i => {
        const translate = translation.filter(j => j.wQAId === i.wordId)
        const media = resource.filter(j => j.wordId === i.wordId)
        return { ...i, translate, media }
      })
      .sort((a, b) => (filterSort ? a.times - b.times : b.times - a.times))

    const phrasesListWithDetails = listPhrases
      .map(i => {
        const translate = translation.filter(j => j.wQAId === i.wordId)
        const media = resource.filter(j => j.wordId === i.wordId)
        return { ...i, translate, media }
      })
      .sort((a, b) => (filterSort ? a.times - b.times : b.times - a.times))

    setWordsList(wordsListWithDetails)
    setPhrasesList(phrasesListWithDetails)

    setLoading(false)
    setFetching(false)
  }

  const onRefresh = () => {
    if (!fetching) {
      setFetching(true)
    }
  }

  const onChangeLesson = (l: SearchTopicInUserItem) => {
    setLesson(l)
    setFilterType('words')
  }

  const onChangeFilterType = (type: FilterType) => {
    setFilterType(type)
  }

  const onChangeFilterSort = () => {
    setFilterSort(prev => !prev)

    const sortedWords = [...wordsList].sort((a, b) =>
      filterSort ? b.times - a.times : a.times - b.times,
    )
    setWordsList(sortedWords)

    const sortedPhrases = [...phrasesList].sort((a, b) =>
      filterSort ? b.times - a.times : a.times - b.times,
    )
    setPhrasesList(sortedPhrases)
  }

  const renderListHeader = () => (
    <ListHeader
      lesson={lesson}
      onChangeLesson={onChangeLesson}
      filterType={filterType}
      onChangeFilterType={onChangeFilterType}
      onChangeFilterSort={onChangeFilterSort}
      total={wordsList.length + phrasesList.length + savedList.length}
      currentTopicInUser={sortedTopics}
      fetching={topics.fetching}
    />
  )
  const handlePlayItem = (index: number) => {
    setPlayingIndex(index)
  }

  const renderItem = ({
    item,
    index,
  }: {
    item: Item | SearchWordbankItem
    index: number
  }) => {
    if (filterType === 'saved') {
      return (
        <SItem
          item={item as SearchWordbankItem}
          index={index}
          total={savedList.length}
          isTrue={playingIndex === index}
          onPlay={() => handlePlayItem(index)}
        />
      )
    } else {
      return (
        <Item
          item={item as Item}
          index={index}
          total={
            filterType === 'words'
              ? wordsList.length
              : filterType === 'phrases'
                ? phrasesList.length
                : savedList.length
          }
          isTrue={playingIndex === index}
          onPlay={() => handlePlayItem(index)}
        />
      )
    }
  }

  const renderListEmpty = () => (
    <View style={tw`flex-1 items-center justify-center`}>
      <Empty loading={loading} />
    </View>
  )

  return (
    <View style={tw`flex-1 flex-col`}>
      <Header isBack={false} title='Word bank' />
      <FlatList
        data={
          filterType === 'words'
            ? wordsList
            : filterType === 'phrases'
              ? phrasesList
              : savedList
        }
        renderItem={renderItem}
        refreshing={fetching}
        onRefresh={onRefresh}
        ListHeaderComponent={renderListHeader}
        ListEmptyComponent={renderListEmpty}
        ItemSeparatorComponent={() => (
          <View
            style={tw.style(
              'flex-col items-center justify-center bg-background-light-white px-5',
            )}
          >
            <View style={tw`h-px bg-neutral-200 w-full`} />
          </View>
        )}
        contentContainerStyle={tw`p-4 pt-6 pb-4 flex-1`}
      />
    </View>
  )
})
