/* eslint-disable func-style */
/* eslint-disable arrow-body-style */
/* eslint-disable prefer-arrow/prefer-arrow-functions */

import { Button } from 'antd'
import { debounce } from 'lodash'
import { Modal } from 'native-base'
import type React from 'react'
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import type { Crop, PixelCrop, ReactCropProps } from 'react-image-crop'
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'

import { uploadFileWithUrl } from '#apis/files'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import type { ResponseFile, UploadFileProps } from '#types/media'

import { toastError } from '../utils/Toast'
import { canvasPreview } from './canvasPreview'

type MakeCropParams = {
  width: number
  height: number
  circleSize: number
  aspect: number
}

const makeCrop = ({ width, height, aspect, circleSize }: MakeCropParams) => {
  const config: any = !!circleSize
    ? { unit: 'px', height: circleSize, width: circleSize }
    : { unit: '%', width: 90, height: undefined }
  const aspectCrop = makeAspectCrop(config, aspect, width, height)
  return centerCrop(aspectCrop, width, height)
}

// --------------------------------------------------------------------- //

type CropProps = Omit<ReactCropProps, 'onChange' | 'onComplete' | 'crop'> &
  Required<Pick<ReactCropProps, 'aspect'>> &
  ({ circularCrop?: false } | { circularCrop: true; circleSize: number })

export type CropImageProps = {
  isOpen: boolean
  file: UploadFileProps
  onCrop: (file: ResponseFile) => void
  onCancel: () => void
} & CropProps

export function CropImage({
  isOpen,
  file,
  onCrop,
  onCancel,
  aspect,
  ...props
}: CropImageProps) {
  const [imgSrc, setImgSrc] = useState('')
  const [isMobile, setIsMobile] = useState(false)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [loading, setLoading] = useState(false)

  const imgRef = useRef<HTMLImageElement>(null)
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)

  useLayoutEffect(() => {
    const updater = () => {
      setIsMobile(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        ),
      )
    }
    updater()
    window.addEventListener('resize', debounce(updater, 200))
    return () => window.removeEventListener('resize', updater)
  }, [])

  useEffect(() => {
    if (file.originFileObj) {
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      )
      reader.readAsDataURL(file.originFileObj)
    }
  }, [])

  useEffect(() => {
    handleUpdatePreview(completedCrop)
  }, [completedCrop, isMobile])

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget
    const circleSize = props.circularCrop ? props.circleSize : 0
    setCrop(makeCrop({ width, height, circleSize, aspect }))
  }

  const handleUpdatePreview = useCallback(
    debounce((_crop?: PixelCrop) => {
      if (imgRef.current && previewCanvasRef.current) {
        if (_crop?.width && _crop?.height) {
          canvasPreview(imgRef.current, previewCanvasRef.current, _crop)
        }
      }
    }, 200),
    [],
  )

  const handleConvertCanvasToBlob = async (scaleX: number, scaleY: number) => {
    const offscreen = new OffscreenCanvas(
      completedCrop!.width * scaleX,
      completedCrop!.height * scaleY,
    )

    const ctx = offscreen.getContext('2d')
    if (!ctx) {
      throw new Error('No 2d context')
    }

    ctx.drawImage(
      previewCanvasRef.current!,
      0,
      0,
      previewCanvasRef.current!.width,
      previewCanvasRef.current!.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    )

    return await offscreen.convertToBlob({
      type: 'image/png',
    })
  }

  const handleUpload = (blob: Blob) => {
    const formData = new FormData()
    const fileName = `cropped-avatar-${Date.now()}.png`
    formData.append('file', new File([blob], fileName, { type: 'image/png' }))
    return uploadFileWithUrl(formData, 'image_game')
  }

  const handleCrop = async () => {
    if (!imgRef.current || !previewCanvasRef.current || !completedCrop) {
      return
    }
    try {
      setLoading(true)
      const response = await handleUpload(
        await handleConvertCanvasToBlob(
          imgRef.current.naturalWidth / imgRef.current.width,
          imgRef.current.naturalHeight / imgRef.current.height,
        ),
      )
      onCrop(response)
      setLoading(false)
      onCancel()
    } catch {
      toastError({
        title: 'Error',
        message: 'Upload image failed!',
      })
      setLoading(false)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      style={tw.style(
        'relative flex p-6 bg-white rounded-lg h-auto',
        isMobile
          ? 'w-[80%] max-h-[60%] top-[15%] left-[10%]'
          : 'w-[35%] max-h-[75%] top-[12%] left-[35%]',
      )}
    >
      <Text style={tw`mb-6`} specialType='Title'>
        Crop image
      </Text>
      <div style={tw.style('overflow-hidden')}>
        {!!imgSrc && (
          <ReactCrop
            keepSelection
            crop={crop}
            onChange={(_, v) => setCrop(v)}
            onComplete={v => setCompletedCrop(v)}
            aspect={aspect}
            {...props}
          >
            <img ref={imgRef} alt='' src={imgSrc} onLoad={handleImageLoad} />
          </ReactCrop>
        )}
        {!!completedCrop && (
          <canvas
            ref={previewCanvasRef}
            style={{
              objectFit: 'contain',
              width: completedCrop.width,
              height: completedCrop.height,
              display: 'none',
            }}
          />
        )}
      </div>
      <div style={tw.style('mt-6 w-full flex justify-end gap-2')}>
        <Button style={tw`w-20`} onClick={onCancel} disabled={loading}>
          Cancel
        </Button>
        <Button
          style={tw`w-20`}
          type='primary'
          onClick={handleCrop}
          loading={loading}
          disabled={loading}
        >
          OK
        </Button>
      </div>
    </Modal>
  )
}
