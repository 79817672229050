import { Flex, Form, Input } from 'antd'
import { useState } from 'react'

import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { Upload } from '#admin/components/widgets/Upload'
import { tw } from '#components/utils/tw'
import type { AnswerInQuestion } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import type { InputQuestion } from '#types/listenGame'
import type { ResourceType, ResponseFile } from '#types/media'

type Prop = {
  closeModal: () => void
  question: InputQuestion
  item?: AnswerInQuestion
  onChangeSuccess: (answer: AnswerInQuestion) => void
}

export const AnswerModal = ({
  closeModal,
  question,
  item,
  onChangeSuccess,
}: Prop) => {
  const [answerText, setAnswerText] = useState(item?.answer?.text || '')
  const [media] = useState<ResponseFile>({
    id: item?.mediaId || item?.media?.id || '',
    name: item?.media?.name || '',
    url: item?.media?.url || '',
    createdById: item?.media?.createdById || '',
    fileSize: item?.media?.fileSize || 0,
    duration: item?.media?.duration || 0,
    type: (item?.media?.type as ResourceType) || 'multiple',
  })
  const [thumbnail, setThumbnail] = useState<ResponseFile>({
    id: item?.thumbnailId || item?.thumbnail?.id || '',
    name: item?.thumbnail?.name || '',
    url: item?.thumbnail?.url || '',
    createdById: item?.thumbnail?.createdById || '',
    fileSize: item?.thumbnail?.fileSize || 0,
    duration: item?.thumbnail?.duration || 0,
    type: 'image_game',
    createdAt: item?.thumbnail?.createdAt || new Date(),
  })
  const [isTrue] = useState<boolean>(false)
  const [uploading, setUploading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const handleOk = async () => {
    if (question.id) {
      if (item?.id) {
        callAPIUpdate()
      } else {
        callAPIAdd()
      }
    } else {
      onChangeSuccess({
        mediaId: media.id,
        thumbnailId: thumbnail.id,
        answerId: '',
        createdAt: new Date(),
        id: '',
        isTrue: false,
        questionId: '',
        answer: {
          index: 0,
          text: answerText,
          createdAt: new Date(),
          id: '',
        },
        thumbnail: {
          ...thumbnail,
          createdAt: thumbnail?.createdAt ?? new Date(),
        },
      })
      closeModal()
    }
  }

  const callAPIUpdate = async () => {
    try {
      setLoading(true)
      const res = await gql.updateAnswer({
        data: { text: answerText },
        id: item?.answerId ?? '',
      })
      if (res.error) {
        toastError({ message: 'Update answer failed' })
        return
      }
      if (item?.id) {
        onChangeSuccess({
          ...item,
          mediaId: media.id,
          thumbnailId: thumbnail.id,
          answer: {
            ...item.answer,
            index: 0,
            text: answerText,
            createdAt: item.answer?.createdAt ?? new Date(),
            id: item.answerId || item?.answer?.id || '',
          },
        })
        toastSuccess({ message: 'Update answer successfully' })
        closeModal()
      } else {
        toastError({ message: 'Update answer failed' })
      }
    } catch (error) {
      toastError({
        message: 'Update answer failed',
      })
      console.error('Update answer failed:', error)
    } finally {
      setLoading(false)
    }
  }

  const callAPIAdd = async () => {
    try {
      setLoading(true)
      const res = await gql.createAnswer({
        data: { text: answerText },
      })
      if (res.data?.createAnswer.id) {
        const resp = await gql.createAnswerInQuestion({
          data: {
            answerId: res.data?.createAnswer.id ?? '',
            questionId: question.id ?? '',
            isTrue,
            mediaId: media.id,
            thumbnailId: thumbnail.id,
          },
        })
        if (resp.data?.createAnswerInQuestion.id) {
          onChangeSuccess(resp.data?.createAnswerInQuestion as AnswerInQuestion)
          toastSuccess({ message: 'Create answer successfully' })
          closeModal()
        } else {
          toastError({ message: 'Create answer failed' })
        }
      } else {
        toastError({ message: 'Create answer failed' })
      }
    } catch (error) {
      toastError({ message: 'Create answer failed' })
    } finally {
      setLoading(false)
    }
  }

  const onChangeThumbnail = (data: ResponseFile) => {
    setThumbnail(s => ({ ...s, ...data }))
  }

  return (
    <ModalLayout
      title={item?.id ? 'Edit answer' : 'Add new answer'}
      closeModal={closeModal}
      onConfirm={handleOk}
      disableOk={
        uploading ||
        (!answerText && question.type !== 'selectImage') ||
        (question.type === 'selectImage' && !thumbnail.id)
      }
      disableCancel={uploading}
      autoClose={false}
      isLoading={loading}
    >
      <Flex style={tw`flex-1 w-120`}>
        <Form layout='vertical' style={tw`w-full pt-2`}>
          {question.type !== 'selectImage' ? (
            <Form.Item label='Answer'>
              <Input
                value={answerText}
                onChange={e => setAnswerText(e.target.value)}
                className='w-full'
                disabled={loading || uploading}
              />
            </Form.Item>
          ) : (
            <Form.Item label='Image'>
              <Upload
                resourceType='image_game'
                type='image'
                url={thumbnail.url}
                name={thumbnail.name}
                onChange={onChangeThumbnail}
                display='image'
                onFileUploading={e => setUploading(e)}
                disabled={loading}
              />
            </Form.Item>
          )}
        </Form>
      </Flex>
    </ModalLayout>
  )
}
