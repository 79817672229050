/* eslint-disable arrow-body-style */

import { Form, Input, InputNumber, Select } from 'antd'
import { useMemo, useState } from 'react'

import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { tw } from '#components/utils/tw'
import type { IntervalTime } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import { CURRENCY_OPTIONS } from '#types/payment'
import type { SearchProductItems } from '#types/product'

type Props = {
  payment?: SearchProductItems
  closeModal: () => void
  onSuccess: () => void
}

type FormValues = {
  name: string
  description: string
  active: (typeof STATUS_OPTIONS)[number]
  price: number
  currency: (typeof CURRENCY_OPTIONS)[number]
  discount: number
  timeUnit: (typeof TIME_UNIT_OPTIONS)[number]
  amountTime: number
}

const STATUS_OPTIONS = [
  { label: 'Active', value: true },
  { label: 'Inactive', value: false },
]

const TIME_UNIT_OPTIONS = [
  { value: 'day', label: 'Day' },
  { value: 'month', label: 'Month' },
  { value: 'year', label: 'Year' },
]

const PRICE = {
  min: 1,
  max: 99_999_999,
}

const validatePrice = (_: unknown, value: number) => {
  const cent = value
  if (cent < PRICE.min) {
    return Promise.reject(new Error('Minimum price is $1'))
  } else if (cent > PRICE.max) {
    return Promise.reject(new Error('Invalid price'))
  }
  return Promise.resolve()
}

export const PaymentModal = ({ payment, closeModal, onSuccess }: Props) => {
  const [form] = Form.useForm<FormValues>()
  const [loading, setLoading] = useState(false)

  const handleCreatePayment = async (values: FormValues) => {
    const response = await gql.createProduct({
      data: {
        name: values.name,
        description: values.description,
        discountPercent: values.discount,
      },
    })
    await gql.createPrice({
      data: {
        product: response.data?.createProduct.idProduct ?? '',
        currency: values.currency.value,
        unit_amount: values.price,
        recurring: {
          interval: values.timeUnit.value as IntervalTime,
          interval_count: values.amountTime,
        },
      },
    })
  }

  const handleUpdatePayment = async (values: FormValues) => {
    await gql.updateProduct({
      id: payment!.id,
      data: {
        name: values.name,
        description: values.description,
        active: !!values.active?.value,
        discountPercent: values.discount,
      },
    })
  }

  const handleSubmit = async () => {
    setLoading(true)
    const values = form.getFieldsValue()
    try {
      if (!payment) {
        if (values.price < 0.5) {
          setLoading(false)
          toastError({
            title: 'Error',
            message: 'Price must be greater than 0.50',
          })
          return
        }
        await handleCreatePayment(values)
      } else {
        await handleUpdatePayment(values)
      }

      toastSuccess({ message: `Payment ${!!payment ? 'updated' : 'created'}` })
      setLoading(false)
      closeModal()
      onSuccess()
    } catch (err: any) {
      toastError({ message: err?.message || 'Something went wrong' })
      setLoading(false)
    }
  }

  const initFormValues = useMemo<FormValues>(
    () => ({
      name: payment?.name ?? '',
      description: payment?.description ?? '',
      active: STATUS_OPTIONS.at(payment?.active ? 0 : 1)!,
      price: payment?.price?.unitAmount ?? 0,
      currency:
        CURRENCY_OPTIONS.find(
          c => c.value === payment?.price?.currency?.toUpperCase(),
        ) || CURRENCY_OPTIONS[0],
      discount: payment?.discountPercent ? payment.discountPercent : 0,
      timeUnit:
        TIME_UNIT_OPTIONS.find(
          t => t.value === payment?.price?.recurring?.interval,
        ) || TIME_UNIT_OPTIONS[1],
      amountTime: payment?.price?.recurring?.interval_count ?? 1,
    }),
    [payment?.id],
  )

  return (
    <ModalLayout
      title={payment ? 'Edit payment' : 'New payment'}
      closeModal={closeModal}
      onConfirm={form.submit}
      autoClose={false}
      isLoading={loading}
      isEnterEvent={false}
    >
      <Form
        form={form}
        layout='vertical'
        initialValues={initFormValues}
        onFinish={handleSubmit}
      >
        {payment && (
          <Form.Item label='Status' name='active'>
            <Select labelInValue options={STATUS_OPTIONS} />
          </Form.Item>
        )}
        <Form.Item
          label='Name'
          name='name'
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Description'
          name='description'
          rules={[{ required: true, message: 'Description is required' }]}
        >
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item
          label='Price'
          name='price'
          rules={[{ validator: validatePrice }]}
        >
          <InputNumber
            disabled={!!payment}
            min={0}
            style={tw`w-full`}
            addonAfter={
              <Form.Item name='currency' noStyle>
                <Select
                  labelInValue
                  options={CURRENCY_OPTIONS}
                  disabled={!!payment}
                />
              </Form.Item>
            }
          />
        </Form.Item>
        <Form.Item label='Amount of time' name='amountTime'>
          <InputNumber
            disabled={!!payment}
            min={1}
            style={tw`w-full`}
            addonAfter={
              <Form.Item name='timeUnit' noStyle>
                <Select
                  disabled={!!payment}
                  labelInValue
                  options={TIME_UNIT_OPTIONS}
                />
              </Form.Item>
            }
          />
        </Form.Item>
        <Form.Item label='Discount (%)' name='discount' style={tw`mb-0`}>
          <InputNumber min={0} style={tw`w-full`} addonAfter={<span>%</span>} />
        </Form.Item>
      </Form>
    </ModalLayout>
  )
}
