import { View } from 'native-base'
import { Platform } from 'react-native'

import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

export const PaymentNotSupport = (props: {
  type: 'Google Pay' | 'Apple Pay'
}) => {
  const { type } = props
  return (
    <View style={tw.style('flex-col items-center')}>
      <View style={tw.style('w-11/12 mt-3 items-center self-center gap-2')}>
        <Text style={tw`text-base text-black`}>
          Either your {Platform.OS === 'web' ? 'browser' : 'platform'} does not
          support {type}
          {Platform.OS === 'web' &&
            ', or you do not have a saved payment method.'}
        </Text>
      </View>
    </View>
  )
}
