import { DeleteFilled } from '@ant-design/icons'
import { Flex, Form, Image, Input, Select, Upload as UploadAntd } from 'antd'
import type React from 'react'
import { useEffect, useState } from 'react'

import { RichTextEditor } from '#admin/components/base/RichTextEditor/RichTextEditor'
import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { ButtonUpLoad } from '#admin/components/widgets/ButtonUpLoad'
import { CropImage } from '#admin/components/widgets/CropImage'
import { Label } from '#admin/components/widgets/Label'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { Upload } from '#admin/components/widgets/Upload'
import { tw } from '#components/utils/tw'
import type { AiTutorTypeGender } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import type { SearchAiTutorItem } from '#types/aiTutor'
import type { LanguageType } from '#types/language'
import type { ResponseFile, UploadFileProps } from '#types/media'

type Pr = {
  closeModal: () => void
  selectedAi?: SearchAiTutorItem
}
const optionsGender: { label: string; value: AiTutorTypeGender }[] = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Other', value: 'Other' },
]

const optionsLanguage: { label: string; value: LanguageType }[] = [
  { label: 'English', value: 'English' },
  { label: 'Spanish', value: 'Spanish' },
]

export const AITutorModal: React.FC<Pr> = ({ closeModal, selectedAi }) => {
  const [name, setName] = useState<string>(selectedAi?.name || '')
  const [voiceFile, setVoiceFile] = useState<{ url: string; id: string }>({
    url: selectedAi?.media?.url ?? '',
    id: selectedAi?.mediaId ?? '',
  })
  const [file, setFile] = useState<{ url: string; id: string }>({
    url: selectedAi?.thumbnail?.url ?? '',
    id: selectedAi?.thumbnailId ?? '',
  })

  const [description, setDescription] = useState<string>(
    selectedAi?.description.toString() || '',
  )
  const [gender, setGender] = useState<AiTutorTypeGender>(
    (selectedAi?.gender as AiTutorTypeGender) || 'Other',
  )

  const [languageAI, setLanguageAI] = useState<string>(
    selectedAi?.languageAI || 'English',
  )

  const [openModal, setOpenModal] = useState(false)
  const [fileUpload, setFileUpload] = useState<UploadFileProps>()

  useEffect(() => {
    if (selectedAi) {
      if (selectedAi?.thumbnail?.url) {
        setFile({
          url: selectedAi?.thumbnail?.url ?? '',
          id: selectedAi?.thumbnailId ?? '',
        })
      }
      if (selectedAi?.media?.url) {
        setVoiceFile({
          url: selectedAi?.media?.url ?? '',
          id: selectedAi?.mediaId ?? '',
        })
      }
    }
  }, [selectedAi])

  const handleSubmit = async () => {
    try {
      if (!name || !file || !voiceFile) {
        toastError({ message: 'Name, image, and voice are required fields.' })
        return
      }
      const variables = {
        name,
        description,
        thumbnailId: file.id,
        address: '',
        mediaId: voiceFile.id,
        gender,
        languageAI,
      }
      let res
      if (selectedAi) {
        res = await gql.updateAiTutor({
          id: selectedAi.id,
          data: variables,
        })
      } else {
        res = await gql.createAiTutor({ data: variables })
      }
      if (res.error || !res.data) {
        toastError({ title: 'Error', message: res.error?.message })
        return
      }

      const successMessage = selectedAi
        ? 'AItutor updated successfully.'
        : 'AItutor created successfully.'
      toastSuccess({ message: successMessage })
      closeModal()
    } catch {
      toastError({
        message: selectedAi
          ? 'Failed to update AI Tutor.'
          : 'Failed to create AI Tutor.',
      })
    }
  }
  const handleModalCancel = () => {
    closeModal()
  }

  const handleChooseGender = (value: AiTutorTypeGender) => {
    setGender(value)
  }

  const handleChooseLanguage = (value: string) => {
    setLanguageAI(value)
  }

  const onURLVoiceChange = (data: ResponseFile) => {
    setVoiceFile({
      url: data.url,
      id: data.id,
    })
  }

  const onURLChange = (data: ResponseFile) => {
    setFile({
      url: data.url,
      id: data.id,
    })
  }

  return (
    <ModalLayout
      title={selectedAi ? 'Edit AI Tutor' : 'Add AI Tutor'}
      closeModal={handleModalCancel}
      onConfirm={handleSubmit}
      disableOk={!name || !file || !voiceFile}
      autoClose={false}
      isEnterEvent={false}
    >
      <Flex style={tw`flex-1/2`}>
        <Form layout='vertical' style={tw`w-120 bg-white rounded-5`}>
          <Form.Item label={<Label label='Name' required />}>
            <Input
              placeholder='Enter your name'
              value={name}
              onChange={e => {
                setName(e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item label='Gender'>
            <Select
              value={gender}
              style={tw`flex-1`}
              options={optionsGender}
              onChange={handleChooseGender}
            />
          </Form.Item>
          <Form.Item label='Language'>
            <Select
              value={languageAI}
              style={tw`flex-1`}
              options={optionsLanguage}
              onChange={handleChooseLanguage}
            />
          </Form.Item>
          <Form.Item label='Description'>
            <RichTextEditor
              placeholder='Enter your description'
              value={description}
              onChange={d => {
                setDescription(d)
              }}
            />
          </Form.Item>
          <Flex style={tw`items-center justify-center flex-1`}>
            <Flex style={tw`flex-1 items-center justify-center`}>
              <Form.Item style={tw`text-center`}>
                <div style={tw`flex flex-col items-center`}>
                  {file.url ? (
                    <div style={tw`w-25 h-25 rounded-md border mb-1 -mt-4`}>
                      <Image
                        style={tw`w-25 h-25 rounded-md border`}
                        src={file.url}
                      />
                      <Flex style={tw`flex justify-center gap-2`}>
                        <div>
                          Avatar<span style={tw`text-red-600`}>*</span>
                        </div>
                        <DeleteFilled
                          style={tw.style('text-red-600', {
                            cursor: 'pointer',
                          })}
                          onClick={() => {
                            setFile({
                              url: '',
                              id: '',
                            })
                            setFileUpload(undefined)
                          }}
                        />
                      </Flex>
                    </div>
                  ) : (
                    <UploadAntd
                      onChange={e => setFileUpload(e.file)}
                      showUploadList={false}
                    >
                      <ButtonUpLoad
                        loading={false}
                        title={'Upload avatar'}
                        required={true}
                        onClick={() => setOpenModal(true)}
                      />
                    </UploadAntd>
                  )}
                  {/* </Button> */}
                </div>
                {fileUpload && (
                  <CropImage
                    isOpen={openModal}
                    file={fileUpload}
                    aspect={16 / 9}
                    minHeight={50}
                    onCrop={onURLChange}
                    onCancel={() => {
                      setOpenModal(false)
                      setFileUpload(undefined)
                    }}
                  />
                )}
              </Form.Item>
            </Flex>
            <Flex style={tw`flex-1 items-center justify-center`}>
              <Form.Item style={tw`text-center`}>
                <Upload
                  resourceType='audio'
                  url={voiceFile.url}
                  type='audio'
                  display='voice'
                  required
                  onChange={onURLVoiceChange}
                  onDeleted={() => setVoiceFile({ url: '', id: '' })}
                />
              </Form.Item>
            </Flex>
          </Flex>
        </Form>
      </Flex>
    </ModalLayout>
  )
}
