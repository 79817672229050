import { Flex, Typography } from 'antd'

import { tw } from '#components/utils/tw'

type Props = {
  isHiddenAudio?: boolean
}

export const AnswerHeader = ({ isHiddenAudio }: Props) => (
  <Flex gap={8} style={tw`items-center bg-white p-3 rounded-xl`}>
    <Flex style={tw`flex-1 pl-7.5`}>
      <Typography.Title level={5} style={tw`m-0`}>
        Answer
      </Typography.Title>
    </Flex>
    <Flex style={tw.style(!isHiddenAudio ? 'flex-1 items-center' : 'w-20')}>
      {!isHiddenAudio && (
        <Flex style={tw`w-100`}>
          <Typography.Title level={5} style={tw`m-0`}>
            Audio
          </Typography.Title>
        </Flex>
      )}
      <Flex style={tw`w-20`}>
        <Typography.Title level={5} style={tw`m-0`}>
          Action
        </Typography.Title>
      </Flex>
    </Flex>
  </Flex>
)
