import type { Content, ContentId } from '#types/chat'

export const contentData: { [key in ContentId]: Content } = {
  changingTutor: {
    id: 'changingTutor',
    english:
      "Hello! I'm your new tutor here to continue our practice. How would you like to proceed?",
    spanish:
      '¡Hola! Soy tu nuevo tutor aquí para continuar con nuestra práctica. ¿Cómo te gustaría proceder?',
  },
  user_changingTutor_countinue: {
    id: 'user_changingTutor_countinue',
    english: "Let's continue",
    spanish: 'Continuemos',
  },
  user_changingTutor_restart: {
    id: 'user_changingTutor_restart',
    english: 'Start Over',
    spanish: 'Empezar de nuevo',
  },
  countinue: {
    id: 'countinue',
    english: "Great! let's continue the conversation from before.",
    spanish: '¡Genial! Continuemos la conversación desde donde la dejamos.',
  },
  restart: {
    id: 'restart',
    english:
      "Got it! No problem—just a quick heads up, I'll reset the tasks on your list. Is that okay?",
    spanish:
      '¡Entendido! No hay problema. Solo un aviso rápido: voy a reiniciar las tareas en tu lista. ¿Está bien?',
  },
  user_restart_yes: {
    id: 'user_restart_yes',
    english: 'Yes',
    spanish: 'Sí',
  },
  user_restart_no: {
    id: 'user_restart_no',
    english: 'No',
    spanish: 'No',
  },
  bot_yes: {
    id: 'bot_yes',
    english: '',
    spanish: '',
  },
  bot_no: {
    id: 'bot_no',
    english: "Ok, let's continue the conversation from before.",
    spanish: 'Muy bien, continuamos la conversación desde donde la dejamos.',
  },
  default: {
    id: 'default',
    english: '',
    spanish: '',
  },
}
