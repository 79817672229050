import type { ReactNode } from 'react'
import { TouchableOpacity, View } from 'react-native'
import type { Style } from 'twrnc'

import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

export type RadioProps = Partial<RadioLabelProps> & {
  containerStyle?: Style
  value?: string
  size?: number
  onPress?: (value?: string) => void
  isChecked?: boolean
  variant?: 'Linear' | 'Bold'
}

export const Radio = ({
  containerStyle,
  size = 20,
  onPress,
  isChecked,
  value,
  position,
  containerLabelStyle,
  titleStyle,
  title,
  variant = 'Linear',
}: RadioProps) => (
  <View style={tw.style('flex-row items-center', containerStyle)}>
    {position === 'left' && title && (
      <RadioLabel
        position='left'
        containerLabelStyle={containerLabelStyle}
        titleStyle={titleStyle}
        title={title}
      />
    )}
    <TouchableOpacity
      style={tw.style(
        `w-[${size}px] h-[${size}px] rounded-full items-center justify-center `,
        isChecked ? { backgroundColor: 'transparent' } : 'bg-neutral-200',
      )}
      onPress={() => onPress?.(value)}
      activeOpacity={!onPress ? 1 : undefined}
    >
      {isChecked && (
        <SystemIcon
          type='SAX'
          name='TickCircle'
          color={tw.color('success-300')}
          size={variant === 'Bold' ? size + 7 : size}
          variant={variant}
        />
      )}
    </TouchableOpacity>
    {position === 'right' && title && (
      <RadioLabel
        position='right'
        containerLabelStyle={containerLabelStyle}
        titleStyle={titleStyle}
        title={title}
      />
    )}
  </View>
)

type IPosi = 'left' | 'right'

type RadioLabelProps = {
  position: IPosi
  containerLabelStyle?: Style
  titleStyle?: Style
  title: ReactNode
}

const positionStyle: { [key in IPosi]: string } = {
  left: 'mr-2',
  right: 'ml-2',
}

const RadioLabel = ({
  position,
  containerLabelStyle,
  title,
  titleStyle,
}: RadioLabelProps) => (
  <View
    style={tw.style('flex-1', positionStyle[position], containerLabelStyle)}
  >
    {typeof title === 'string' ? (
      <Text specialType='Note' style={tw.style(titleStyle)}>
        {title}
      </Text>
    ) : (
      title
    )}
  </View>
)
