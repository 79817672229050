import type { FC } from 'react'
import type { ColorValue } from 'react-native'
import { View } from 'react-native'

import { tw } from '#components/utils/tw'

type ArrowProps = {
  colorLarge: ColorValue
  colorSmall: ColorValue
  colorDot: ColorValue
}

export const Arrow: FC<ArrowProps> = ({ colorLarge, colorSmall, colorDot }) => (
  <View
    style={tw`flex-1 flex-row items-center justify-between top-[-8px] mx-4`}
  >
    <View
      style={tw.style('w-4 h-4 border-l border-b transform rotate-45 ', {
        transform: [{ rotate: '45deg' }],
        borderLeftColor: colorLarge,
        borderBottomColor: colorLarge,
      })}
    />
    <View
      style={tw.style('w-2.5 h-2.5 border-l border-b transform rotate-45', {
        transform: [{ rotate: '45deg' }],
        borderLeftColor: colorSmall,
        borderBottomColor: colorSmall,
      })}
    />
    <View
      style={tw.style('w-2 h-2 rounded-full items-center justify-center', {
        backgroundColor: colorDot,
      })}
    />
    <View
      style={tw.style('w-2.5 h-2.5 border-t border-r ', {
        transform: [{ rotate: '45deg' }],
        borderTopColor: colorSmall,
        borderRightColor: colorSmall,
      })}
    />
    <View
      style={tw.style('w-4 h-4 border-t border-r transform rotate-45', {
        transform: [{ rotate: '45deg' }],
        borderTopColor: colorLarge,
        borderRightColor: colorLarge,
      })}
    />
  </View>
)
