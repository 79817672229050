import { EllipsisOutlined, LoadingOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Dropdown } from 'antd'
import { useState } from 'react'

import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { useOverlay } from '#components/overlay/hooks'
import { useSetAdmin } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import type { SearchUserItem } from '#types/user'

export const DropdownSetRole = ({
  record,
  reloadUser,
}: {
  record: SearchUserItem
  reloadUser: () => void
}) => {
  const [f, setAdmin] = useSetAdmin()
  const [loading, setLoading] = useState(false)
  const { openModal } = useOverlay()

  const commonItems: MenuProps['items'] = [
    {
      key: 'set-role',
      label: record.isAdmin ? 'Make to user role' : 'Make to admin role',
    },
    {
      key: 'deactivate',
      label: record.deactivate ? 'Activate user' : 'Deactivate user',
    },
  ]

  const adminSpecificItems: MenuProps['items'] = [
    { key: 'reset-password', label: 'Reset password' },
  ]

  const userDeleteItems: MenuProps['items'] = [
    {
      key: 'delete',
      label: 'Delete user',
    },
  ]

  const items: MenuProps['items'] = record.isAdmin
    ? commonItems
    : [...commonItems, ...adminSpecificItems, ...userDeleteItems]

  const handleMenuClick: MenuProps['onClick'] = async value => {
    try {
      setLoading(true)
      if (value.key === 'set-role') {
        const res = await setAdmin({
          id: record.id,
          isAdmin: !record.isAdmin,
        })
        const user = res.data?.setAdmin
        if (user) {
          toastSuccess({
            message: `${record.email} is ${user.isAdmin ? 'Admin' : 'User'} role`,
          })
        } else {
          toastError({ message: `Failed to set role for user ${record.email}` })
        }
      } else if (value.key === 'reset-password') {
        const res = await gql.resetPassword({
          id: record.id,
        })
        const user = res.data?.resetPassword
        if (user) {
          toastSuccess({
            message: `${record.email} has had its password reset in email`,
          })
        } else {
          toastError({
            message: `Failed to reset password for user ${record.email}`,
          })
        }
      } else if (value.key === 'delete') {
        openModal(ModalLayout, {
          onConfirm: async () => {
            const res = await gql.deleteUserById({
              id: record.id,
            })
            if (!res.error) {
              toastSuccess({
                message: 'Account deleted successfully',
              })
              reloadUser()
            } else {
              toastError({
                message: 'Account deleted failed',
              })
            }
          },
          selectedAi: record,
          title: 'Confirm delete',
          content: 'Are you sure you want to delete this account?',
          containerProps: {
            closeOnOverlayClick: false,
          },
        })
      } else {
        const res = await gql.updateDeactivateUser({
          id: record.id,
          data: {
            deactivate: !record.deactivate,
          },
        })
        const user = res.data?.updateDeactivateUser
        if (user) {
          toastSuccess({
            message: `${record.email} is ${user.deactivate ? 'Deactivate' : 'Activate'} User`,
          })
        } else {
          toastError({
            message: `Failed to ${!record.deactivate ? 'Deactivate' : 'Activate'} user ${record.email}`,
          })
        }
      }
    } catch (error) {
      toastError({
        message: `Failed to
        ${value.key === 'set-role' ? 'set role' : value.key === 'delete' ? 'delete account' : 'reset password'} for user ${record.email}`,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dropdown menu={{ items, onClick: handleMenuClick }}>
      {f.fetching || loading ? (
        <LoadingOutlined style={{ fontSize: '24px', cursor: 'pointer' }} />
      ) : (
        <EllipsisOutlined style={{ fontSize: '24px', cursor: 'pointer' }} />
      )}
    </Dropdown>
  )
}
