import type { FC } from 'react'
import { View } from 'react-native'

import { tw } from '#components/utils/tw'
import { Video } from '#components/widgets/Video/Video'
import type { GameQuestionProps } from '#types/games'

export const VideoQuestion: FC<GameQuestionProps> = ({
  question,
  videoRef,
  currentIndex,
}) => (
  <View style={tw`flex-1 px-4`}>
    <Video
      url={question.media?.url || ''}
      currentIndex={currentIndex}
      ref={videoRef}
    />
  </View>
)
