import type { UploadFileProps } from '#types/media'

import { toastError } from './Toast'

export const checkAudio = (file: UploadFileProps): boolean => {
  const allowedTypes = ['audio/mpeg', 'audio/ogg', 'audio/wav']
  const isAudio = !!(
    file.originFileObj?.type && allowedTypes.includes(file.originFileObj?.type)
  )
  if (!isAudio) {
    toastError({
      title: 'Error',
      message: 'You can only upload MP3/OGG/WAV file!',
    })
  }
  return isAudio
}
