import { useIsFocused } from '@react-navigation/native'
import { useEffect, useState } from 'react'

import { useAppState } from '#components/utils/appState'
import {
  generateRandomAnswerPositions,
  generateRandomAnswers,
} from '#components/utils/random'
import { generateLevelTest } from '#components/widgets/Games/generate/levelTest'
import { logCustomEvent } from '#config/firebaseConfig'
import { gql } from '#graphql/urql'
import type { AnswerGame, QuestionGame } from '#types/games'
import type { LanguageType, Level } from '#types/language'
import type {
  LevelTestContainer,
  LevelTestItem,
  ReviewType,
  UpdateLevel,
} from '#types/levelTest'
import { answersLevelTest, fallback, levelUp } from '#types/levelTest'

export type RandomLevelTest = {
  language: LanguageType
  updateLevelUser: (level: Level) => void
  lv: string
}

type ContentType = 'Game' | 'Review' | 'OverView'

type ResultRandomLevelTes = {
  currentIndex: number
  totalCounts: number
  question: QuestionGame | null
  answers: AnswerGame[]
  loading: boolean
  onChangeData: (pointLevelTest: number) => void
  item: LevelTestItem | null
  isPopup: ContentType
  handleCloseReview: () => void
  typeReview: ReviewType
  nextItem: LevelTestItem | null
}

export const useRandomLevelTest = ({
  language,
  updateLevelUser,
  lv,
}: RandomLevelTest): ResultRandomLevelTes => {
  const [randomData, setRandomData] = useState<LevelTestContainer[]>([])
  const [question, setQuestion] = useState<QuestionGame | null>(null)
  const [answers, setAnswers] = useState<AnswerGame[]>([])
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [item, setItem] = useState<LevelTestContainer | null>(null)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [currentLevel, setCurrentLevel] = useState<Level>('A1')
  const [isPopup, setIsPopup] = useState<ContentType>('OverView')
  const [typeReview, setTypeReview] = useState<ReviewType>('Pass')
  const [nextItem, setNextItem] = useState<LevelTestContainer | null>(null)
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState<number[]>([])

  const isFocus = useIsFocused()
  const appState = useAppState()

  useEffect(() => {
    if (isFocus && appState === 'active') {
      setLoading(true)
      fetchData(currentLevel)
    }
  }, [isFocus, appState])

  const fetchData = async (level: Level) => {
    setLoading(true)
    try {
      const data = await generateLevelTest({ language, level })
      setTotalCount(data.length)
      const randomAnswers = generateRandomAnswerPositions(data.length)
      setCorrectAnswerIndex(randomAnswers)
      setRandomData(data)
      if (data.length > 0) {
        const i = data[0]
        setItem(i)
        setQuestion(i.question)
        const correctAnswers = i.answers.filter(j => j.isCorrect)
        const incorrectAnswers = i.answers.filter(j => !j.isCorrect)
        const a = answersLevelTest.includes(i.question.type || '')
          ? i.answers
          : generateRandomAnswers({
              correctAnswers,
              incorrectAnswers,
              correctAnswerIndex: randomAnswers[0],
            })
        setAnswers(a)
        if (data.length > 1) {
          setNextItem(data[1])
        }
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const clearState = async () => {
    setCurrentIndex(0)
    setRandomData([])
    setQuestion(null)
    setAnswers([])
    setItem(null)
    setTotalCount(0)
  }

  const handleLevelUp = async ({ newLevel, updateLevel }: UpdateLevel) => {
    await clearState()
    updateLevelUser(updateLevel)
    setCurrentLevel(newLevel)
    setIsPopup('Review')
    setTypeReview('Pass')
    fetchData(newLevel)
  }

  const handleFallback = ({ updateLevel }: UpdateLevel) => {
    gql.updateUser({
      data: { level: { isShow: false, current: updateLevel } },
    })
    logCustomEvent('change_level', {
      current: lv,
      next: updateLevel,
      reason: 'The user takes the test.',
    })
    updateLevelUser(updateLevel)
    setIsPopup('Review')
    setTypeReview('Fail')
  }

  const checkResult = async (pointLevelTest: number, totalCounts: number) => {
    const percentage = pointLevelTest / totalCounts
    if (percentage >= 0.75) {
      handleLevelUp(levelUp[currentLevel])
    } else {
      handleFallback(fallback[currentLevel])
    }
  }

  const onChangeData = (pointLevelTest: number) => {
    if (currentIndex < totalCount - 1) {
      const index = currentIndex + 1
      const nextI = randomData[index]
      setQuestion(nextI.question)
      const correctAnswers = nextI.answers.filter(i => i.isCorrect)
      const incorrectAnswers = nextI.answers.filter(i => !i.isCorrect)
      const a = answersLevelTest.includes(nextI.question.type || '')
        ? nextI.answers
        : generateRandomAnswers({
            correctAnswers,
            incorrectAnswers,
            correctAnswerIndex: correctAnswerIndex[index],
          })
      setAnswers(a)
      setItem(nextI)
      setCurrentIndex(currentState => currentState + 1)
      if (index + 1 < totalCount - 1) {
        setNextItem(randomData[index + 1])
      }
    } else {
      checkResult(pointLevelTest, totalCount)
    }
  }

  const handleCloseReview = () => {
    setIsPopup('Game')
  }

  return {
    question,
    answers,
    currentIndex,
    totalCounts: totalCount,
    loading,
    onChangeData,
    item,
    isPopup,
    handleCloseReview,
    typeReview,
    nextItem,
  }
}
