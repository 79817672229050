import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Flex, Image, Typography } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useState } from 'react'

import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { PaginatedTable } from '#admin/components/widgets/PaginatedTable'
import { SearchButton } from '#admin/components/widgets/SearchButton'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import { useCountStreak, useSearchStreak } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import type { SearchStreakItem } from '#types/streak'

import { MilestoneModal } from './MilestoneModal'

export const Milestone = () => {
  const { openModal } = useOverlay()

  const [searchKeyword, setSearchKeyword] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState(10)

  const [splash, refetch] = useSearchStreak({
    variables: {
      filter: searchKeyword ? { numberStreak: +searchKeyword } : {},
      order: ['numberStreak_asc'],
      page: { limit: pageSize, offset: (currentPage - 1) * pageSize },
    },
  })

  const [count, refetchCount] = useCountStreak({
    variables: {
      filter: searchKeyword ? { numberStreak: +searchKeyword } : {},
    },
  })

  const reload = () => {
    refetch({ requestPolicy: 'network-only' })
    refetchCount({ requestPolicy: 'network-only' })

    if (count.data?.countStreak) {
      const remainingItemsCount = count.data.countStreak - 1
      let newPageCount = Math.ceil(remainingItemsCount / pageSize)
      if (newPageCount < 1) {
        newPageCount = 1
      }
      if (currentPage > newPageCount) {
        setCurrentPage(newPageCount)
      }
    }
  }

  const handleAdd = () => {
    openModal(MilestoneModal, {
      containerProps: {
        closeOnOverlayClick: false,
      },
      onComplete: reload(),
      reload,
    })
  }

  const actionsColumn = {
    title: 'Actions',
    key: 'actions',
    width: 100,
    render: (text, record) => (
      <Flex style={tw`justify-between`}>
        <Button
          icon={<EditOutlined />}
          onClick={e => {
            e.stopPropagation()
            openModal(MilestoneModal, {
              item: record,
              containerProps: {
                closeOnOverlayClick: false,
              },
              reload,
            })
          }}
          className='p-0 bg-transparent'
        />
        <Button
          icon={<DeleteOutlined />}
          onClick={e => {
            e.stopPropagation()
            openModal(ModalLayout, {
              onConfirm: async () => {
                const res = await gql.deleteStreak({ id: record.id })
                if (res.data) {
                  reload()
                  toastSuccess({ message: 'Delete successfully' })
                } else {
                  toastError({ message: 'Delete failed' })
                }
              },
              title: 'Confirm delete',
              content: 'Are you sure you want to delete?',
              containerProps: {
                closeOnOverlayClick: false,
              },
            })
          }}
          className='p-0 bg-transparent'
          danger={true}
        />
      </Flex>
    ),
  }

  const columns: ColumnsType<SearchStreakItem> = [
    {
      title: 'Number streak',
      dataIndex: 'numberStreak',
      key: 'numberStreak',
    },
    {
      title: 'Badge active',
      dataIndex: 'mediaId',
      width: 100,
      key: 'mediaId',
      render: (_, record) =>
        record?.media?.url ? (
          <Flex
            onClick={e => {
              e?.stopPropagation()
            }}
          >
            <Image
              src={record?.media?.url}
              style={tw.style('w-12 h-12 border-2 border-gray-300', {
                cursor: 'pointer',
              })}
              onClick={e => {
                e?.stopPropagation()
              }}
            />
          </Flex>
        ) : null,
    },
    {
      title: 'Badge inactive',
      dataIndex: 'thumbnailId',
      width: 100,
      key: 'thumbnailId',
      render: (_, record) =>
        record?.thumbnail?.url ? (
          <Flex
            onClick={e => {
              e?.stopPropagation()
            }}
          >
            <Image
              src={record?.thumbnail?.url}
              style={tw.style('w-12 h-12 border-2 border-gray-300', {
                cursor: 'pointer',
              })}
              onClick={e => {
                e?.stopPropagation()
              }}
            />
          </Flex>
        ) : null,
    },
    {
      title: 'Slogan',
      dataIndex: 'infor',
      key: 'slogan',
      render: (_, record) => (
        <Flex vertical gap={4}>
          {record.infor.content.map(s => (
            <Typography>{s}</Typography>
          ))}
        </Flex>
      ),
    },
    actionsColumn,
  ]

  return (
    <Flex style={tw`flex-1 flex-col p-4 px-[10%]`}>
      <SearchButton
        title='Add'
        handleAddData={handleAdd}
        keyword={(k: string) => {
          const regex = /^\d+$/gm
          if (regex.test(k)) {
            setSearchKeyword(k)
            setCurrentPage(1)
          }
        }}
      />
      <PaginatedTable<SearchStreakItem>
        dataSource={splash.data?.searchStreak || []}
        columns={columns}
        loading={splash.fetching}
        total={count.data?.countStreak || 0}
        currentPage={currentPage}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        scrollHeight={'60vh'}
      />
    </Flex>
  )
}
