import type {
  AnswerInQuestion,
  CreateQuestion,
  UpdateQuestion,
} from '#graphql/codegen'

export enum ListenGame {
  selectImage = 'selectImage',
  multipleChoice = 'multipleChoice',
  yesNo = 'yesNo',
}

export type ListenGameType = keyof typeof ListenGame

export enum ListenGameLabelQuestion {
  SelectImage = 'Select the correct image',
  MultipleChoice = 'Multiple choice questions',
  YesNo = 'True/False',
}

export const listenGameQuestion: { value: ListenGameType; label: string }[] = [
  { value: ListenGame.selectImage, label: ListenGameLabelQuestion.SelectImage },
  {
    value: ListenGame.multipleChoice,
    label: ListenGameLabelQuestion.MultipleChoice,
  },
  { value: ListenGame.yesNo, label: ListenGameLabelQuestion.YesNo },
]

export type ParamsSubmit = {
  question: string
  media: Array<{ id: string; url: string }>
  answerType: ListenGameType
  answers: AnswerInQuestion[]
}

export type ListenMedia = {
  id: string
  url: string
  name: string
}

export type InputQuestion = (
  | Required<CreateQuestion>
  | Required<UpdateQuestion>
) & {
  id: string
  type: ListenGameType
}

export const mediaGameListen: {
  [key in ListenGameType]: 'audio' | 'video' | 'image'
} = {
  multipleChoice: 'audio',
  selectImage: 'audio',
  yesNo: 'audio',
}

export const questionListenGameLabel: {
  [key in ListenGameType]: string
} = {
  multipleChoice: ListenGameLabelQuestion.MultipleChoice,
  selectImage: ListenGameLabelQuestion.SelectImage,
  yesNo: ListenGameLabelQuestion.YesNo,
}

export enum LabelListenGame {
  LabelTrue = 'True',
  LabelFalse = 'False',
}

export enum ListenGameLabel {
  SelectImage = 'Select the correct image',
  MultipleChoice = 'Listen and select correct answer',
  YesNo = 'Listen and select true or false',
}

export const listenGameLabel: { value: ListenGameType; label: string }[] = [
  { value: 'selectImage', label: ListenGameLabel.SelectImage },
  { value: 'multipleChoice', label: ListenGameLabel.MultipleChoice },
  { value: 'yesNo', label: ListenGameLabel.YesNo },
]

export const answersListen: string[] = [ListenGame.yesNo]
