import { useIsFocused } from '@react-navigation/native'
import { Button, Flex, Spin, Typography } from 'antd'
import { useEffect, useState } from 'react'

import { NavigateBackButton } from '#admin/components/utils/NavigateBackButton'
import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { tw } from '#components/utils/tw'
import type { AnswerInQuestion } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import { replace } from '#navigator/helpers'
import type { AdminScreenProps } from '#navigator/types'
import type {
  InputQuestion,
  ListenGameType,
  ListenMedia,
} from '#types/listenGame'
import { LabelListenGame } from '#types/listenGame'

import { AnswersGame } from './AnswersGame'
import { QuestionGame } from './QuestionGame'

type AnswersData = AnswerInQuestion & { index: number }

const messageError =
  'Media, type, and question are required fields, answers must be at least 2 and must be at least 1 correct.'

export const ListenGame = ({
  route,
  navigation,
}: AdminScreenProps<'AdminListenGame'>) => {
  const { id, topicId } = route.params

  const isFocus = useIsFocused()

  const [question, setQuestion] = useState<InputQuestion>({
    id: id ?? '',
    text: '',
    mediaId: '',
    thumbnailId: '',
    type: 'selectImage',
    topicId,
    index: 0,
  })
  const [mediaQuestions, setMediaQuestions] = useState<ListenMedia>({
    url: '',
    id: '',
    name: '',
  })

  const [answers, setAnswers] = useState<AnswerInQuestion[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [reloadPage, setReloadPage] = useState<boolean>(false)

  useEffect(() => {
    if (isFocus) {
      setQuestion({
        id: '',
        text: '',
        mediaId: '',
        thumbnailId: '',
        type: 'selectImage',
        topicId,
        index: 0,
      })
      setMediaQuestions({ url: '', id: '', name: '' })
      setAnswers([])
      fetchData()
    }
  }, [isFocus, id])

  const fetchData = async () => {
    try {
      if (id) {
        setReloadPage(true)
        const d = await gql.searchQuestion(
          {
            filter: id ? { id } : {},
            order: ['topicId_asc'],
            page: {},
          },
          { requestPolicy: 'network-only' },
        )
        const fetchedQuestion = d.data?.searchQuestion[0]
        setQuestion(s => ({
          id,
          text: fetchedQuestion?.text ?? s.text,
          mediaId: fetchedQuestion?.mediaId ?? s.mediaId,
          thumbnailId: fetchedQuestion?.thumbnailId ?? s.thumbnailId,
          type: (fetchedQuestion?.type as ListenGameType) ?? s.type,
          topicId,
          index: 0,
        }))
        setMediaQuestions(s => ({
          ...s,
          url: fetchedQuestion?.media?.url ?? s.url,
          id: fetchedQuestion?.mediaId ?? s.id,
          name: fetchedQuestion?.media?.name ?? s.name,
        }))
        await fetchAnswers(id)
      }
    } catch {
    } finally {
      setReloadPage(false)
    }
  }

  const fetchAnswers = async (questionId: string) => {
    try {
      const resp = await gql.searchAnswerInQuestion(
        {
          filter: { questionId },
          order: ['createdAt_asc'],
        },
        { requestPolicy: 'network-only' },
      )
      setAnswers(resp.data?.searchAnswerInQuestion as AnswerInQuestion[])
    } catch {
    } finally {
      setReloadPage(false)
      setLoading(false)
    }
  }

  const handleAdd = async () => {
    try {
      if (checkError()) {
        toastError({ message: messageError })
        return
      }
      setLoading(true)
      const resQ = await gql.createQuestion({
        data: {
          text: question.text,
          mediaId: question.mediaId,
          type: question.type,
          topicId,
        },
      })
      if (resQ.data?.createQuestion?.id) {
        setQuestion(s => ({ ...s, id: resQ.data?.createQuestion?.id ?? s.id }))
        toastSuccess({ message: 'Question created successfully.' })
        await handleCreateAnswer(resQ.data?.createQuestion?.id ?? '')
        handleNavigateBack()
      } else {
        toastError({ message: 'Question created failed' })
      }
    } catch {
      toastError({ message: 'Question created failed' })
    } finally {
      setLoading(false)
    }
  }

  const handleUpdate = async () => {
    try {
      if (checkError()) {
        toastError({ message: messageError })
        return
      }
      setLoading(true)
      const updateQues = await gql.updateQuestion({
        data: {
          text: question.text,
          type: question.type,
          mediaId: question.mediaId,
          topicId,
        },
        id: question.id || '',
      })
      if (updateQues.data?.updateQuestion) {
        toastSuccess({ message: 'Question updated successfully.' })
      } else {
        toastError({ message: 'Question updated failed.' })
      }
      handleCreateAnswer(question.id || '')
    } catch (error) {
      toastError({ message: 'Question updated failed.' })
    } finally {
      setLoading(false)
    }
  }

  const createAnswer = async (answerData: AnswersData) => {
    const resp = await gql.createAnswer({
      data: { text: answerData.answer?.text ?? '' },
    })
    const responseAnswer = await gql.createAnswerInQuestion({
      data: {
        answerId: resp.data?.createAnswer?.id,
        questionId: answerData.questionId,
        thumbnailId: answerData.thumbnailId,
        isTrue: answerData.isTrue,
      },
    })
    return {
      ...answerData,
      ...(responseAnswer.data?.createAnswerInQuestion ?? {}),
    }
  }

  const handleCreateAnswer = async (questionId: string) => {
    try {
      const newAnswers: AnswersData[] = answers.map((a, idx) => ({
        ...a,
        questionId,
        index: idx,
      }))
      const list = newAnswers.filter(a => !a.id)
      if (list.length > 0) {
        const resp = await Promise.all(
          list.map(answerData => createAnswer(answerData)),
        )
        setAnswers(
          newAnswers.map(n => {
            const found = resp.find(r => r.index === n.index)
            const newAnswer = found ? found : {}
            const { ...rest } = newAnswer
            return found ? { ...n, ...rest } : n
          }),
        )
      }
    } catch (error) {
      toastError({ message: 'Answer created failed' })
    }
  }

  const handleNavigateBack = () => {
    replace('Admin', {
      screen: 'AdminTopicDetail',
      params: { id: topicId, tab: 'listen' },
    })
  }

  const onChangeQuestion = (q: Partial<InputQuestion>) => {
    setQuestion(s => ({ ...s, ...q }))
    if ('type' in q) {
      if (q.type === 'yesNo') {
        handleAddAnswers()
      } else {
        setAnswers([])
      }
    }
  }

  const onChangeMediaQuestion = (media: ListenMedia) => {
    setMediaQuestions(s => ({ ...s, ...media }))
    setQuestion(s => ({ ...s, mediaId: media.id }))
  }

  const handleAddAnswers = () => {
    setAnswers([
      {
        mediaId: '',
        thumbnailId: '',
        answerId: '',
        createdAt: new Date(),
        id: '',
        isTrue: false,
        questionId: '',
        answer: {
          index: 0,
          text: LabelListenGame.LabelTrue,
          createdAt: new Date(),
          id: '',
        },
      },
      {
        mediaId: '',
        thumbnailId: '',
        answerId: '',
        createdAt: new Date(),
        id: '',
        isTrue: false,
        questionId: '',
        answer: {
          index: 1,
          text: LabelListenGame.LabelFalse,
          createdAt: new Date(),
          id: '',
        },
      },
    ])
  }

  const handleAddAnswer = (answer: AnswerInQuestion) => {
    setAnswers(s => [...s, answer])
  }

  const onChangeAnswer = (answer: AnswerInQuestion, index: number) => {
    setAnswers(s => {
      const newAnswers = [...s]
      newAnswers[index] = answer
      return newAnswers
    })
  }

  const handleDeleteAnswer = (index: number) => {
    setAnswers(s => s.filter((_, i) => i !== index))
  }

  const onChangeCorrectAnswer = (index: number) => {
    setAnswers(s => s.map((i, idx) => ({ ...i, isTrue: index === idx })))
  }

  const checkError = () => {
    if (
      !question.type ||
      !question.text ||
      !question.mediaId ||
      answers.length < 2 ||
      answers.filter(answer => answer.isTrue).length < 1
    ) {
      return true
    }
    return false
  }

  return (
    <Flex vertical gap={16} style={tw`px-4`}>
      <Flex style={tw`flex pt-5 items-center`}>
        <Flex style={tw`flex-1`}>
          <NavigateBackButton direction={handleNavigateBack} />
          <Typography style={tw`text-6 px-2`}>{'Listen game'}</Typography>
        </Flex>
        <Button
          loading={loading}
          type='primary'
          onClick={id || question.id ? handleUpdate : handleAdd}
          disabled={loading || checkError()}
        >
          {question.id ? 'Update question' : 'Create question'}
        </Button>
      </Flex>
      {reloadPage ? (
        <Flex
          vertical
          style={tw`flex-1 w-full h-full items-center justify-center`}
        >
          <Flex
            vertical
            style={tw`absolute top-0 left-0 right-0 bottom-0 flex-1 items-center justify-center`}
          >
            <Spin />
          </Flex>
        </Flex>
      ) : (
        <Flex
          gap={16}
          vertical
          style={tw.style('w-full relative overflow-scroll', {
            height: '85vh',
          })}
        >
          <QuestionGame
            question={question}
            onChangeData={onChangeQuestion}
            mediaQuestions={mediaQuestions}
            onChangeMedia={onChangeMediaQuestion}
          />

          <AnswersGame
            question={question}
            answers={answers}
            handleAddAnswers={handleAddAnswers}
            handleAddAnswer={handleAddAnswer}
            onChangeAnswer={onChangeAnswer}
            handleDeleteAnswer={handleDeleteAnswer}
            onChangeCorrectAnswer={onChangeCorrectAnswer}
          />
          {loading && (
            <Flex
              vertical
              style={tw`absolute top-0 left-0 right-0 bottom-0 bg-gray-200 opacity-20 flex-1 items-center justify-center`}
            >
              <Spin />
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  )
}
