import type { FC } from 'react'
import { View } from 'react-native'

import { tw } from '#components/utils/tw'
import type { SearchTopicInUserItem } from '#types/topicInUser'

import type { FilterType } from './Filters'
import { Filters } from './Filters'
import { Lesson } from './Lesson'
import { LevelUpNow } from './LevelUpNow'

type ListHeaderProps = {
  lesson: SearchTopicInUserItem | null
  onChangeLesson?: (item: SearchTopicInUserItem) => void
  filterType: FilterType
  onChangeFilterType?: (type: FilterType) => void
  onChangeFilterSort?: () => void
  total: number
  currentTopicInUser: SearchTopicInUserItem[]
  fetching: boolean
}

export const ListHeader: FC<ListHeaderProps> = ({
  lesson,
  onChangeLesson,
  filterType,
  onChangeFilterType,
  onChangeFilterSort,
  total,
  currentTopicInUser,
  fetching,
}) => (
  <View style={tw`gap-6`}>
    <LevelUpNow />
    <Lesson
      lesson={lesson}
      onChangeLesson={onChangeLesson}
      currentTopicInUser={currentTopicInUser}
      fetching={fetching}
    />
    {total > 0 && (
      <Filters
        type={filterType}
        onChangeFilterType={onChangeFilterType}
        onChangeFilterSort={onChangeFilterSort}
      />
    )}
  </View>
)
