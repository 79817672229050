import { Button, Flex } from 'antd'
import type { MouseEventHandler } from 'react'

import { tw } from '#components/utils/tw'

import { SearchBar } from './SearchBar'

type Prop = {
  keyword: (key: string) => void
  handleAddData?: MouseEventHandler<HTMLElement>
  title?: string
}

export const SearchButton = ({ keyword, handleAddData, title }: Prop) => (
  <Flex style={tw`gap-4`}>
    <SearchBar keyword={keyword} />
    {handleAddData && title && (
      <Button type='primary' onClick={handleAddData}>
        {title}
      </Button>
    )}
  </Flex>
)
