import type { IActionsheetContentProps, IActionsheetProps } from 'native-base'
import { Actionsheet as NbActionsheet } from 'native-base'
import { useEffect } from 'react'
import { Platform } from 'react-native'
import type { Style } from 'twrnc'

import { useDarkBg } from '#components/dark/hooks'
import { tw } from '#components/utils/tw'

import type { OverlayContext } from './OverlayProvider'

type ActionsheetProps = IActionsheetProps & {
  ctx: OverlayContext
}
export const Actionsheet = ({ _backdrop, ...p }: ActionsheetProps) => {
  // fix bug android actionsheet
  // https://github.com/GeekyAnts/NativeBase/issues/4886
  if (Platform.OS === 'android') {
    _backdrop = {
      ..._backdrop,
      style: {
        backgroundColor: 'transparent',
        ..._backdrop?.style,
      },
    }
  }
  useEffect(() => {
    if (Platform.OS !== 'android') {
      return
    }
    p.ctx.androidFixActionsheetOpen()
    return p.ctx.androidFixActionsheetClose
  })

  return <NbActionsheet {...p} _backdrop={_backdrop} />
}

export const ActionsheetContent = ({
  style,
  ...p
}: IActionsheetContentProps) => {
  const bg = useDarkBg()

  return <NbActionsheet.Content {...p} style={tw.style(bg, style as Style)} />
}
