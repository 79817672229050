import type { PlaybackState, Progress } from 'react-native-track-player'
import TrackPlayer, { Capability } from 'react-native-track-player'
import { ulid } from 'ulidx'

class AudioManager {
  private url: string = ''
  private isLoaded: boolean = false
  // private onDurationUpdate?: (duration: number) => void
  constructor() {
    // this.onDurationUpdate = onDurationUpdate
    this.setupPlayer()
  }

  setupPlayer = async (): Promise<void> => {
    try {
      await TrackPlayer.setupPlayer({
        autoUpdateMetadata: false,
        autoHandleInterruptions: false,
      })
      await TrackPlayer.updateOptions({
        capabilities: [
          Capability.Play,
          Capability.Pause,
          Capability.Stop,
          Capability.SeekTo,
        ],
        compactCapabilities: [
          Capability.Play,
          Capability.Pause,
          Capability.Stop,
          Capability.SeekTo,
          Capability.SetRating,
        ],
        notificationCapabilities: [
          Capability.Play,
          Capability.Pause,
          Capability.Stop,
          Capability.SeekTo,
          Capability.SetRating,
        ],
      })
    } catch (error) {}
  }

  add = async (url: string) => {
    if (!url) {
      console.log('Invalid URL')
      return
    }

    try {
      this.url = url
      this.isLoaded = false
      await TrackPlayer.reset()
      await TrackPlayer.add({
        id: ulid(),
        url,
        title: '',
        artist: '',
        capabilities: [],
      })
      this.isLoaded = true
    } catch (error) {
      console.log('Error adding track:', error)
    }
  }

  play = async (rate: number = 1.0) => {
    try {
      // const playbackState = await this.getPlaybackState()
      if (
        !this.url ||
        !this.isLoaded
        // playbackState.state === State.Playing
      ) {
        return
      }
      await this.setRate(rate)
      await TrackPlayer.play()
    } catch (error) {
      console.log('Error during play:', error)
    }
  }

  pause = async () => {
    try {
      await TrackPlayer.pause()
    } catch (error) {
      console.log('Error during pause:', error)
    }
  }

  stop = async () => {
    try {
      await TrackPlayer.pause()
      await TrackPlayer.seekTo(0)
    } catch (error) {
      console.log('Error during stop:', error)
    }
  }

  seekBy = async (offset: number) => {
    try {
      const { position } = await this.getProgress()
      await TrackPlayer.seekTo(position + offset)
    } catch (error) {
      console.log('Error seeking by offset:', error)
    }
  }

  seekTo = async (time: number) => {
    try {
      await TrackPlayer.seekTo(time)
    } catch (error) {
      console.log('Error seeking to time:', error)
    }
  }

  setVolume = async (volume: number) => {
    try {
      await TrackPlayer.setVolume(volume)
    } catch (error) {
      console.log('Error setting volume:', error)
    }
  }

  getVolume = async (): Promise<number> => {
    try {
      return await TrackPlayer.getVolume()
    } catch (error) {
      console.log('Error getting volume:', error)
      return 0
    }
  }

  setRate = async (rate: number) => {
    try {
      await TrackPlayer.setRate(rate)
    } catch (error) {
      console.log('Error setting rate:', error)
    }
  }

  getRate = async () => {
    try {
      return await TrackPlayer.getRate()
    } catch (error) {
      console.log('Error getting rate:', error)
      return 1.0
    }
  }

  getProgress = async (): Promise<Progress> => {
    try {
      return await TrackPlayer.getProgress()
    } catch (error) {
      console.log('Error getting progress:', error)
      return { position: 0, duration: 0, buffered: 0 }
    }
  }

  getPlaybackState = async (): Promise<PlaybackState> => {
    try {
      return await TrackPlayer.getPlaybackState()
    } catch (error) {
      console.log('Error getting playback state:', error)
      throw error
    }
  }

  getPlayWhenReady = async (): Promise<boolean> => {
    try {
      return await TrackPlayer.getPlayWhenReady()
    } catch (error) {
      console.log('Error getting play when ready:', error)
      return false
    }
  }

  setPlayWhenReady = async (playWhenReady: boolean) => {
    try {
      await TrackPlayer.setPlayWhenReady(playWhenReady)
    } catch (error) {
      console.log('Error setting play when ready:', error)
    }
  }

  getCurrentPosition = async (): Promise<number> => {
    const { position } = await this.getProgress()
    return position
  }

  getDuration = async (): Promise<number> => {
    const { duration } = await this.getProgress()
    return duration
  }

  // master
  getCurrentTime = (): number => 0
  getIsPlaying = (): boolean => false
}

export const audioManager = new AudioManager()
