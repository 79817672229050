import type { SharedValue } from 'react-native-reanimated'
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedStyle,
} from 'react-native-reanimated'

import { tw } from '#components/utils/tw'

type Props = {
  index: number
  scrollX: SharedValue<number>
}

export const Dot = ({ index, scrollX }: Props) => {
  const styles = useAnimatedStyle(
    () => ({
      width: interpolate(
        scrollX.value,
        [index - 1, index, index + 1],
        [12, 20, 12],
        Extrapolation.CLAMP,
      ),
      opacity: interpolate(
        scrollX.value,
        [index - 1, index, index + 1],
        [0.5, 1, 0.5],
        Extrapolation.CLAMP,
      ),
    }),
    [scrollX],
  )

  return (
    <Animated.View
      style={[tw`w-3 h-2 rounded-md bg-background-light-white`, styles]}
    />
  )
}
