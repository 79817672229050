import { Fragment } from 'react'
import { StyleSheet, View } from 'react-native'

import { Text } from '#components/base/Text'

export const TalkBubble = ({
  text,
  position,
  backgroundColor = 'white',
  borderColor = 'transparent',
  textColor = 'black',
}) => (
  <View style={styles.container}>
    {position === 'left' && (
      <Fragment>
        <View
          style={[
            styles.talkBubbleTriangle,
            styles.talkBubbleTriangleLeft,
            { borderRightColor: borderColor },
          ]}
        />
        <View
          style={[
            styles.talkBubbleTriangle,
            styles.talkBubbleTriangleInsideLeft,
            { borderRightColor: backgroundColor },
          ]}
        />
      </Fragment>
    )}
    {position === 'top' && (
      <Fragment>
        <View
          style={[
            styles.talkBubbleTriangle,
            styles.talkBubbleTriangleTop,
            { borderBottomColor: borderColor },
          ]}
        />
        <View
          style={[
            styles.talkBubbleTriangle,
            styles.talkBubbleTriangleTopInside,
            { borderBottomColor: backgroundColor },
          ]}
        />
      </Fragment>
    )}
    <View style={[styles.talkBubbleSquare, { backgroundColor, borderColor }]}>
      <Text
        style={styles.talkBubbleText}
        textAlign='center'
        specialType='Title'
        color={textColor}
      >
        {text}
      </Text>
    </View>
    {position === 'right' && (
      <Fragment>
        <View
          style={[
            styles.talkBubbleTriangle,
            styles.talkBubbleTriangleRight,
            { borderLeftColor: borderColor },
          ]}
        />
        <View
          style={[
            styles.talkBubbleTriangle,
            styles.talkBubbleTriangleInsideRight,
            { borderLeftColor: backgroundColor },
          ]}
        />
      </Fragment>
    )}
    {position === 'bottom' && (
      <Fragment>
        <View
          style={[
            styles.talkBubbleTriangle,
            styles.talkBubbleTriangleBottom,
            { borderTopColor: borderColor },
          ]}
        />
        <View
          style={[
            styles.talkBubbleTriangle,
            styles.talkBubbleTriangleBottomInside,
            { borderTopColor: backgroundColor },
          ]}
        />
      </Fragment>
    )}
  </View>
)

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    position: 'relative',
    width: '100%',
  },
  talkBubbleSquare: {
    width: '100%',
    minHeight: 80,
    backgroundColor: 'white',
    borderRadius: 20,
    borderWidth: 1,
    justifyContent: 'center',
    paddingHorizontal: 16,
    paddingBottom: 10,
    paddingTop: 10,
  },
  talkBubbleTriangle: {
    position: 'absolute',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    zIndex: 1,
  },
  talkBubbleTriangleRight: {
    right: -19,
    top: 20,
    borderLeftWidth: 20,
    borderRightWidth: 0,
    borderTopColor: 'transparent',
    borderTopWidth: 24,
    borderBottomColor: 'black',
  },
  talkBubbleTriangleInsideRight: {
    right: -17,
    top: 21.5,
    borderLeftWidth: 18,
    borderRightWidth: 0,
    borderTopColor: 'transparent',
    borderTopWidth: 21.5,
    borderBottomColor: 'transparent',
  },
  talkBubbleTriangleLeft: {
    left: -19,
    top: 23,
    borderRightWidth: 20,
    borderLeftWidth: 0,
    borderTopColor: 'transparent',
    borderTopWidth: 24,
    borderBottomColor: 'black',
  },
  talkBubbleTriangleInsideLeft: {
    left: -17,
    top: 24.5,
    borderRightWidth: 18,
    borderLeftWidth: 0,
    borderTopColor: 'transparent',
    borderTopWidth: 21.5,
    borderBottomColor: 'transparent',
  },
  talkBubbleTriangleBottom: {
    left: 90,
    top: 80,
    borderLeftColor: 'transparent',
    borderLeftWidth: 2,
    borderRightColor: 'transparent',
    borderRightWidth: 30,
    borderTopColor: 'blue',
    borderTopWidth: 28,
  },
  talkBubbleTriangleBottomInside: {
    left: 90.8,
    top: 79,
    borderLeftColor: 'transparent',
    borderLeftWidth: 2,
    borderRightColor: 'transparent',
    borderRightWidth: 29,
    borderTopColor: 'white',
    borderTopWidth: 27.5,
  },
  talkBubbleTriangleTop: {
    right: 10,
    top: -20,
    transform: [{ translateX: -20 }],
    borderLeftColor: 'transparent',
    borderLeftWidth: 15,
    borderRightColor: 'transparent',
    borderRightWidth: 15,
    borderBottomColor: 'black',
    borderBottomWidth: 24,
  },
  talkBubbleTriangleTopInside: {
    right: 10.8,
    top: -16,
    transform: [{ translateX: -18 }],
    borderLeftColor: 'transparent',
    borderLeftWidth: 12,
    borderRightColor: 'transparent',
    borderRightWidth: 12,
    borderBottomColor: 'white',
    borderBottomWidth: 21.5,
  },
  talkBubbleText: {
    textAlign: 'center',
  },
})
