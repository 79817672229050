import { useNavigation } from '@react-navigation/native'
import { Button } from 'antd'
import type React from 'react'

import { ArrowLeft } from '#components/base/IconSvg/SVG'

interface NavigateBackButtonProps {
  color?: string
  direction?: Function
  disabled?: boolean
}

export const NavigateBackButton: React.FC<NavigateBackButtonProps> = ({
  color = 'black',
  direction,
  disabled = false,
}) => {
  const navigation = useNavigation()

  const handlePress = () => {
    if (!direction) {
      navigation.goBack()
    } else {
      direction()
    }
  }

  return (
    <Button
      onClick={handlePress}
      disabled={disabled}
      icon={<ArrowLeft />}
      type='text'
    />
  )
}
