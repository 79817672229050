import { gql } from '#graphql/urql'
import { S } from '#store'
import type { SearchWordItem, WordOrPhraseItem } from '#types/word'
import type { SearchWordInUser } from '#types/wordInUser'

export const fetchDataWordOrPhrase = async (
  topicId: string,
  isPhrase: boolean,
  page: number,
  pageSize: number,
): Promise<{ items: WordOrPhraseItem[]; total: number }> => {
  const idList = async () => {
    let wordsId: string[] = []
    let totalWord = 0
    let wordDatas: SearchWordItem[] = []
    let wordInUserData: SearchWordInUser[] = []

    if (topicId === '') {
      const wiu = await gql.searchWordInUser(
        {
          filter: {
            times: 7,
            userId: S.shared.currentUser?.id,
            word_some: { isVocabulary: !isPhrase },
            isCompleted: true,
          },
          page: { limit: pageSize, offset: (page - 1) * pageSize },
        },
        { requestPolicy: 'network-only' },
      )

      const cwiu = await gql.countWordInUser(
        {
          filter: {
            times: 7,
            userId: S.shared.currentUser?.id,
            word_some: { isVocabulary: !isPhrase },
            isCompleted: true,
          },
        },
        { requestPolicy: 'network-only' },
      )

      wordInUserData = wiu.data?.searchWordInUser || []
      totalWord = cwiu.data?.countWordInUser || 0

      wordsId = wordInUserData
        .map(item => item.word?.id)
        .filter((id): id is string => id !== undefined)
    } else {
      const respWords = await gql.searchWord(
        {
          filter: { topicId, isVocabulary: !isPhrase },
          page: { limit: pageSize, offset: (page - 1) * pageSize },
        },
        { requestPolicy: 'network-only' },
      )

      const cw = await gql.countWord(
        {
          filter: { topicId, isVocabulary: !isPhrase },
        },
        { requestPolicy: 'network-only' },
      )

      wordDatas = respWords.data?.searchWord || []
      totalWord = cw.data?.countWord || 0

      wordsId = wordDatas.map(w => w.id)
    }

    return { wordsId, totalWord, wordDatas, wordInUserData }
  }

  const {
    wordsId,
    totalWord: finalCount,
    wordDatas,
    wordInUserData,
  } = await idList()

  const resourceInWord = await gql.searchResourceInWord(
    {
      filter: { wordId_in: wordsId },
    },
    { requestPolicy: 'network-only' },
  )
  const resource = resourceInWord.data?.searchResourceInWord || []

  const respTranslate = await gql.searchTranslate(
    {
      filter: { wQAId_in: wordsId },
    },
    { requestPolicy: 'network-only' },
  )
  const translate = respTranslate.data?.searchTranslate || []

  const wordIU = wordInUserData
    .filter(item => item.word?.isVocabulary === !isPhrase)
    .map(item => item.word)

  const wordOrPhrase: WordOrPhraseItem[] = (
    topicId === '' ? wordIU : wordDatas
  ).map(item => {
    const mediaItems = resource.filter(media => media.wordId === item.id)
    return {
      ...item,
      media: mediaItems.length > 0 ? mediaItems : [],
      translation: translate.filter(i => i.wQAId === item.id),
    }
  })

  return { items: wordOrPhrase, total: finalCount }
}
