import { qsIdSecret } from '##/shared/qs'
import { checkAdmin } from '#components/utils/checkAdmin'
import { initNetInfo } from '#components/utils/NetInfo'
import { setUserForAnalytics } from '#config/firebaseConfig'
import type { CurrentAuthSessionQuery } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import { initRouteStack } from '#navigator/helpers'
import { onLogoutSuccess } from '#screens/Profile/onLogoutSuccess'
import { initPersistedStore, S } from '#store'

type InitAppOption = {
  currentAuthSession?: CurrentAuthSessionQuery['currentAuthSession']
}

export const onAppInit = async (o: InitAppOption = {}) => {
  await Promise.all([initPersistedStore(), initNetInfo()])

  if (!o.currentAuthSession) {
    const r1 = await gql.currentAuthSession()
    if (r1.error || !r1.data?.currentAuthSession) {
      await onLogoutSuccess()
      return
    }
    o.currentAuthSession = r1.data.currentAuthSession
  }

  if (!o.currentAuthSession.user?.isAdmin && checkAdmin()) {
    alert('You do not have permission to access this admin page.')
    await initRouteStack('Home', { screen: 'Topics' })
    return
  }

  S.shared.authToken = qsIdSecret(o.currentAuthSession)
  S.shared.currentUser = o.currentAuthSession?.user ?? null
  const u = o.currentAuthSession.user
  if (!u || u.deactivate) {
    if (u?.deactivate) {
      alert(
        'Your account has been deactivated successfully. If you need further assistance or would like to reactivate your account, please don’t hesitate to contact us.',
      )
    }
    await onLogoutSuccess()
    return
  }

  setUserForAnalytics(u)

  if (u.isAdmin && checkAdmin()) {
    // await initRouteStack('Admin', { screen: 'AdminUsers' })
    return
  }

  if (!u.nativeLanguage || !u.languageLearn) {
    await initRouteStack('App', { screen: 'ChooseLanguage', params: {} })
    return
  }

  if (!u.level.current) {
    await initRouteStack('App', { screen: 'ChooseLevel' })
    return
  }

  await initRouteStack('Home', { screen: 'Topics' })
}
