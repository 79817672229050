import type React from 'react'
import { useEffect, useRef } from 'react'
import { Animated, View } from 'react-native'

import { tw } from '#components/utils/tw'

type Props = {
  size?: number
  spacing?: number
  color?: string
}

export const LoadingDots: React.FC<Props> = ({
  size = 2,
  spacing = 6,
  color = '#1f6ca6',
}) => {
  const dotScale = [
    useRef(new Animated.Value(0)).current,
    useRef(new Animated.Value(0)).current,
    useRef(new Animated.Value(0)).current,
  ]

  const dotTranslateY = [
    useRef(new Animated.Value(0)).current,
    useRef(new Animated.Value(0)).current,
    useRef(new Animated.Value(0)).current,
  ]

  useEffect(() => {
    const createAnimation = (index: number) =>
      Animated.sequence([
        Animated.parallel([
          Animated.timing(dotScale[index], {
            toValue: 1.2,
            duration: 400,
            useNativeDriver: true,
          }),
          Animated.timing(dotTranslateY[index], {
            toValue: -5,
            duration: 400,
            useNativeDriver: true,
          }),
        ]),
        Animated.parallel([
          Animated.timing(dotScale[index], {
            toValue: 1,
            duration: 400,
            useNativeDriver: true,
          }),
          Animated.timing(dotTranslateY[index], {
            toValue: 0,
            duration: 400,
            useNativeDriver: true,
          }),
        ]),
      ])

    const animations = dotScale.map((_, index) => createAnimation(index))

    const startAnimations = () => {
      Animated.loop(Animated.stagger(200, animations)).start()
    }

    startAnimations()
  }, [])

  return (
    <View style={tw`flex-row justify-center items-center`}>
      {dotScale.map((scale, index) => (
        <Animated.View
          key={index}
          style={[
            tw.style(`w-${size} h-${size} rounded-full`, {
              backgroundColor: color,
            }),
            {
              marginHorizontal: spacing / 2,
              transform: [{ scale }, { translateY: dotTranslateY[index] }],
            },
          ]}
        />
      ))}
    </View>
  )
}
