import type { FC } from 'react'
import { useEffect } from 'react'
import { View } from 'react-native'
import type { SvgProps } from 'react-native-svg'

import { Avatar } from '#components/base/Avatar'
import { Button } from '#components/base/Button/Button'
import { ButtonIcon } from '#components/base/ButtonIcon/ButtonIcon'
import { Spain } from '#components/base/IconSvg/Spain'
import { English } from '#components/base/IconSvg/SVG'
import { Text } from '#components/base/Text'
import { audioManager } from '#components/utils/audio/audioManager'
import { tw } from '#components/utils/tw'
import { ActionSheet } from '#components/widgets/ActionSheet'
import { Divider } from '#components/widgets/Divider'
import { HtmlContent } from '#components/widgets/HtmlContent/HtmlContent'
import type { SearchAiTutorItem } from '#types/aiTutor'

export const IconComponent = {
  English,
  Spain,
}

type Pr = {
  closeActionsheet: () => void
  data: SearchAiTutorItem
  onPress: () => void
}

export const MoreInfo: FC<Pr> = ({ data, onPress, closeActionsheet }) => {
  const Icon: FC<SvgProps> = IconComponent[data.languageAI]

  useEffect(
    () => () => {
      audioManager.stop()
    },
    [],
  )

  const onPressVolumeHigh = () => {
    const url = data.media?.url || ''
    if (!url) {
      return
    }
    audioManager.add(url)
    audioManager.stop()
    audioManager.play(1)
  }

  return (
    <ActionSheet
      containerStyle={tw`bg-background-light-1`}
      renderFooter={() => (
        <Button
          onPress={() => {
            closeActionsheet()
            onPress()
          }}
        >
          Speak now
        </Button>
      )}
    >
      <View style={tw.style('flex-1 w-full px-2 pb-6')}>
        <Avatar
          _image={{ rounded: '2xl' }}
          rounded={'2xl'}
          width='full'
          height='3xs'
          size='2xl'
          source={{ uri: data.thumbnail?.url || '' }}
          name={data.name}
        />
        <View style={tw`justify-center mt-6 gap-y-1`}>
          <View style={tw`flex-row item-center`}>
            <View style={tw`flex-1 mr-3`}>
              <Text specialType='Headline3'>{data.name}</Text>
            </View>
            <ButtonIcon
              tone='four'
              bg={tw.color('transparent')}
              padding={1.5}
              size={16}
              icon={{
                name: 'VolumeHigh',
                type: 'SAX',
                color: tw.color('primary-400'),
                variant: 'Bold',
              }}
              onPress={onPressVolumeHigh}
            />
          </View>
          <View style={tw`flex-row items-center`}>
            {data.languageAI && <Icon height={24} width={24} />}
            <View style={tw.style('flex-1', data.languageAI && 'ml-2')}>
              <Text specialType='paragraph1'>{data.languageAI}</Text>
            </View>
          </View>
        </View>

        <Divider style={tw.style('my-4 border-separation')} />

        <View style={tw``}>
          <Text specialType='Headline4'>About Me</Text>
        </View>
        <Text specialType='paragraph2'>
          <HtmlContent description={data.description} />
        </Text>
      </View>
    </ActionSheet>
  )
}
