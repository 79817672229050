import type { FC } from 'react'
import ReactQuill from 'react-quill'

type Props = {
  value?: string
  onChange?: (value: string) => void
  placeholder?: string
}

export const RichTextEditor: FC<Props> = ({ value, onChange, placeholder }) => (
  <ReactQuill
    modules={modules}
    formats={formats}
    theme='snow'
    value={value}
    onChange={onChange}
    placeholder={placeholder}
  />
)

// const Size = ReactQuill.Quill.import('formats/size')
// Size.whitelist = [
//   '8px',
//   '10px',
//   '12px',
//   '14px',
//   '18px',
//   '24px',
//   '36px',
//   '48px',
//   '60px',
//   '72px',
//   '96px',
// ] // Custom font sizes
// ReactQuill.Quill.register(Size, true)

const modules = {
  toolbar: [
    [
      { font: [] },
      { header: [1, 2, 3, 4, 5, 6, false] },
      // { size: Size.whitelist },
      { color: [] },
      { background: [] },
      'bold',
      'italic',
      'underline',
      'strike',
      { script: 'sub' },
      { script: 'super' },
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
      { align: [] },
      'blockquote',
      'code-block',
      // 'link', 'image', 'video'
      'clean', // Clear formatting
    ],
  ],
}

const formats = [
  'font',
  'size',
  'header',
  'color',
  'background',
  'bold',
  'italic',
  'underline',
  'strike',
  'script',
  'list',
  'bullet',
  'indent',
  'align',
  'blockquote',
  'code-block',
  // 'link', 'image', 'video',
]
