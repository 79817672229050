import { Platform } from 'react-native'
import {
  setJSExceptionHandler,
  setNativeExceptionHandler,
} from 'react-native-exception-handler'

if (Platform.OS !== 'web' && process.env.NODE_ENV === 'production') {
  // can not use console.error, will crash maximum call stack size
  // unless registerConsoleCapture in production
  const jsExceptionHandler = (err: Error, isFatal: boolean) => {
    console.error(`jsExceptionHandler isFatal=${isFatal}`)
    console.error(err)
  }
  setJSExceptionHandler(jsExceptionHandler, true)

  const nativeExceptionHandler = (err: string) => {
    console.error('nativeExceptionHandler isFatal=true')
    console.error(err)
  }
  setNativeExceptionHandler(nativeExceptionHandler, true, false)
}
