import { useEffect, useState } from 'react'
import { Platform, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { Button } from '#components/base/Button/Button'
import { LevelReviewSvg } from '#components/base/IconSvg/SVG'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { Header } from '#components/widgets/Header'
import { replace } from '#navigator/helpers'
import type { Level } from '#types/language'
import type { ReviewType } from '#types/levelTest'

type Props = {
  level: Level
  handleClose: () => void
  type: ReviewType
}

export const ReviewLevelTest = ({ type, level, handleClose }: Props) => {
  const [indexLevel, setIndexLevel] = useState<number>(0)
  const insets = useSafeAreaInsets()

  useEffect(() => {
    if (level === 'A1') {
      setIndexLevel(1)
    } else if (level === 'A2') {
      setIndexLevel(2)
    } else if (level === 'B1') {
      setIndexLevel(3)
    } else {
      setIndexLevel(4)
    }
  }, [level])

  const handleOnPress = () => {
    if (type === 'Fail') {
      replace('Home', { screen: 'Topics' })
    } else {
      handleClose()
    }
  }

  const textLevel: { [key in Level]: string } = {
    A1: 'Beginner',
    A2: 'Elementary',
    B1: 'Intermediate',
    B2: 'Upper Intermediate',
  }

  return (
    <View
      style={tw.style(
        'flex-1 bg-background-light-1 dark:bg-background-dark-1',
        Platform.OS === 'ios' && { paddingBottom: insets.bottom },
      )}
    >
      <Header onPressBack={handleOnPress} />
      <View style={tw`flex-grow pb-8 mx-4`}>
        <View
          style={tw` w-full  mt-8 bg-background-light-white rounded-2xl px-4 py-8 items-center`}
        >
          {type === 'Fail' && (
            <View style={tw`flex-col`}>
              <Text
                textAlign='center'
                specialType='Headline2'
                color={tw.color('primary-400')}
              >
                You have reached {'\n'} {level} {textLevel[level]}
              </Text>
              <View style={tw`mt-8 items-center gap-y-8 px-5 mb-10`}>
                <LevelReviewSvg index={indexLevel} />
                <Text
                  color={tw.color('text-2')}
                  specialType='Title'
                  textAlign='center'
                >
                  Based on your results, you will start at the {level}{' '}
                  {textLevel[level]} level.
                </Text>
              </View>
            </View>
          )}
          {type === 'Pass' && (
            <View style={tw`flex-col`}>
              <View style={tw`items-center mb-4`}>
                <SystemIcon
                  size={64}
                  type='SAX'
                  name='MessageQuestion'
                  color={tw.color('primary-400')}
                />
              </View>
              <Text specialType='Headline3' textAlign='center'>
                Take a Moment to Rest!
              </Text>
              <View style={tw`mt-8`}>
                <Text specialType='Title' textAlign='center'>
                  Did you know?
                </Text>
                <View style={tw`mt-3 gap-y-2 px-2`}>
                  <View style={tw`flex-row`}>
                    <Text style={tw`w-5`}>•</Text>
                    <Text specialType='paragraph1'>
                      English is the official language of 67 countries.
                    </Text>
                  </View>
                  <View style={tw`flex-row`}>
                    <Text style={tw`w-5`}>•</Text>
                    <Text specialType='paragraph1'>
                      The most commonly used noun is “time”.{' '}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          )}
        </View>
        {type === 'Pass' && (
          <View style={tw`mt-6 px-4`}>
            <Text
              color={tw.color('text-2')}
              textAlign='center'
              specialType='Title'
            >
              You’re nearly there, let’s get back to it
            </Text>
          </View>
        )}
      </View>
      <View style={tw`justify-end mb-4 mx-4`}>
        <Button
          shape='pill'
          tone='primary'
          size='large'
          style={tw`rounded-r-full`}
          onPress={handleOnPress}
        >
          Continue
        </Button>
      </View>
    </View>
  )
}
