import type { FC } from 'react'
import { useWindowDimensions, View } from 'react-native'

import { images } from '#assets'
import { FlatList } from '#components/base/FlatList'
import { Gif } from '#components/base/Gif/Gif'
import { tw } from '#components/utils/tw'
import { Header } from '#components/widgets/Header'
import type { SearchTopicInUserItem } from '#types/topicInUser'

import { Empty } from './Empty'
import { LessonItem } from './LessonItem'

type LessonListProps = {
  closeModal: () => void
  userId: string
  onChangeLesson?: (item: SearchTopicInUserItem) => void
  currentTopicInUser: SearchTopicInUserItem[]
  fetching: boolean
}

export const LessonList: FC<LessonListProps> = ({
  closeModal,
  onChangeLesson,
  currentTopicInUser,
  fetching,
}) => {
  const { height, width } = useWindowDimensions()

  const onPressItem = (item: SearchTopicInUserItem) => {
    onChangeLesson?.(item)
    closeModal()
  }

  const renderItem = ({
    item,
    index,
  }: {
    item: SearchTopicInUserItem
    index: number
  }) => <LessonItem item={item} index={index} onPress={onPressItem} />

  const renderListEmpty = () => <Empty />

  const renderItemSeparator = () => (
    <View
      style={tw.style(
        'flex-col items-center justify-center bg-background-light-white my-2',
      )}
    >
      <View style={tw`h-px bg-neutral-200 w-full`} />
    </View>
  )

  if (fetching) {
    return (
      <View
        style={tw.style('flex-1 flex-col bg-background-light-1', {
          width,
          height,
        })}
      >
        <Header onPressBack={closeModal} title='Lesson list' />
        <View style={tw`flex-1 flex-col items-center justify-center`}>
          <Gif source={images.loading} width={40} height={40} />
        </View>
      </View>
    )
  }

  return (
    <View
      style={tw.style('flex-1 flex-col bg-background-light-1', {
        width,
        height,
      })}
    >
      <Header onPressBack={closeModal} title='Lesson list' />
      <FlatList
        data={currentTopicInUser}
        renderItem={renderItem}
        refreshing={fetching}
        ListEmptyComponent={renderListEmpty}
        ItemSeparatorComponent={renderItemSeparator}
        contentContainerStyle={tw`bg-background-light-white rounded-3xl p-4 m-4`}
      />
    </View>
  )
}
