import { Fragment } from 'react'
import { View } from 'react-native'

import { Avatar } from '#components/base/Avatar'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { useSearchAiTutor } from '#graphql/codegen'
import { S } from '#store'

import { Skeleton } from './Skeleton'

type Props = {
  loading: boolean
  AIRole: string
  userRole: string
}

export const RolePlay = ({ loading, AIRole, userRole }: Props) => {
  const [aiTutorData] = useSearchAiTutor({
    variables: { filter: { id: S.shared.currentUser?.aiTutorId } },
    requestPolicy: 'network-only',
  })

  const aiTutor = aiTutorData.data?.searchAiTutor[0]

  return (
    <View style={tw`flex flex-col gap-y-3`}>
      <Text specialType='Headline3'>Role</Text>
      <View style={tw`flex flex-row gap-x-4`}>
        {aiTutorData.fetching || loading ? (
          <Skeleton.RolePlay />
        ) : (
          <Fragment>
            <View
              style={tw`px-6 py-8 bg-white border border-gray-200 shadow flex flex-1 rounded-2xl justify-center items-center gap-y-3.5`}
            >
              <Avatar
                size='xl'
                style={tw`w-20 h-20`}
                name={aiTutor?.name}
                source={{ uri: aiTutor?.thumbnail?.url }}
              />
              <View
                style={tw`flex flex-col justify-center items-center gap-y-1`}
              >
                <Text specialType='Headline3'>AI tutor</Text>
                <Text specialType='Note' textAlign='center'>
                  {AIRole}
                </Text>
              </View>
            </View>
            <View
              style={tw`relative px-6 py-8 bg-white border border-gray-200 shadow flex flex-1 rounded-2xl justify-center items-center gap-y-3.5`}
            >
              <View
                style={tw`absolute top-0 right-0 w-8 h-8 bg-primary-300 rounded-tr-2xl`}
              >
                <View
                  style={tw.style('absolute z-10 top-2/4 left-2/4', {
                    transform: [{ translateX: -14 }, { translateY: -8 }],
                  })}
                >
                  <SystemIcon
                    type='SAX'
                    name='Crown'
                    variant='Bold'
                    color='white'
                    size={22}
                  />
                </View>
                <View
                  style={tw.style(
                    'absolute top-0 right-4 w-8 h-8 bg-primary-300',
                    {
                      transform: [
                        { rotate: '46deg' },
                        { skewX: '-45deg' },
                        { scaleX: 1.414 },
                        { scaleY: 0.707 },
                      ],
                    },
                  )}
                />
                <View
                  style={tw.style(
                    'absolute top-4 right-0 w-8 h-8 bg-primary-300',
                    {
                      transform: [
                        { rotate: '135deg' },
                        { skewY: '-45deg' },
                        { scaleX: 0.707 },
                        { scaleY: 1.414 },
                      ],
                    },
                  )}
                />
              </View>
              <Avatar
                size='xl'
                style={tw`w-20 h-20`}
                name={S.shared.currentUser?.name}
                source={{ uri: S.shared.currentUser?.thumbnail?.url }}
              />
              <View
                style={tw`flex flex-col justify-center items-center gap-y-1`}
              >
                <Text specialType='Headline3'>You</Text>
                <Text specialType='Note' textAlign='center'>
                  {userRole}
                </Text>
              </View>
            </View>
          </Fragment>
        )}
      </View>
      <View style={tw`mt-2 flex flex-row items-center gap-x-1`}>
        <SystemIcon
          type='SAX'
          name='InfoCircle'
          variant='Bold'
          size={20}
          color={tw.color('gray-400')}
        />
        <Text
          specialType='paragraph1'
          style={tw`text-base`}
          color={tw.color('gray-500')}
        >
          You can change AI tutor in chat settings
        </Text>
      </View>
    </View>
  )
}
