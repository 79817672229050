import { isValidAudioUrl } from '#components/utils/checkMedia'
import type { GamesProps, GenerateProps, QuestionGame } from '#types/games'
import { EasyGame } from '#types/games'

import { getItemInList } from './getItemInList'
import { getRandomWords } from './getRandomWords'

export const generateSelectImage = async ({
  item,
  data,
}: GenerateProps): Promise<GamesProps> => {
  const audios = item.media.filter(i => isValidAudioUrl(i.media?.url || ''))
  const audio = getItemInList(audios)?.media || null
  const questionData: QuestionGame = {
    text: item.text,
    media: audio,
    type: EasyGame.selectImage,
    translation: '',
    index: 0,
  }
  const respAnswers = await getRandomWords({
    item,
    type: EasyGame.selectImage,
    data,
  })

  return {
    question: questionData,
    answers: respAnswers,
  }
}
