import { debounce } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { Platform, useWindowDimensions, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { images } from '#assets'
import { Button } from '#components/base/Button/Button'
import { Gif } from '#components/base/Gif/Gif'
import { ScrollView } from '#components/base/ScrollView'
import { Text } from '#components/base/Text'
import { useOverlay } from '#components/overlay/hooks'
import { isValidAudioUrl } from '#components/utils/checkMedia'
import { tw } from '#components/utils/tw'
import type { AudioPropsRef } from '#components/widgets/Audio/Audio'
import type { RearrangementRef } from '#components/widgets/Games/Answer/RearrangementAnswer'
import type { SameOrDifferentRef } from '#components/widgets/Games/Answer/SameOrDifferentAnswer'
import { GameAnswers } from '#components/widgets/Games/Answers'
import { NotFound } from '#components/widgets/Games/NotFound'
import { TitleGame } from '#components/widgets/Games/TitleGame'
import { Header } from '#components/widgets/Header'
import type { VideoPropsRef } from '#components/widgets/Video/Video'
import { replace } from '#navigator/helpers'
import { ActionSheetCancelGame } from '#screens/LevelTest/ActionSheetCancelGame'
import type {
  AnswerGame,
  GameContainerType,
  HintProps,
  LayoutState,
  QuestionGame,
  SwipeType,
} from '#types/games'
import { EasyGame, gameLabel, LabelGame } from '#types/games'
import { listenGameLabel } from '#types/listenGame'
import type { QuesTionType } from '#types/question'
import type { SearchTopicItem } from '#types/topic'

import { AudioQuestion } from './Question/AudioQuestion'
import { CompleteTheDialogueQuestion } from './Question/CompleteTheDialogue'
import { CorrectPhraseQuestion } from './Question/CorrectPhraseQuestion'
import { ListenQuestion } from './Question/ListenQuestion'
import { SameOrDifferentQuestion } from './Question/SameOrDifferentQuestion'
import { TextQuestion } from './Question/TextQuestion'
import { VideoQuestion } from './Question/VideoQuestion'
import { ReviewAnswer } from './ReviewAnswer'

// import { SearchWordInUser } from '#types/wordInUser'

type StateReview = {
  audio: string
  isCorrect: boolean
  isShow: boolean
  text: string
  image: string
}

const DATA_REVIEW: StateReview = {
  audio: '',
  isCorrect: false,
  isShow: false,
  text: '',
  image: '',
}

type Props = {
  currentIndex: number
  total: number
  question: QuestionGame | null
  answers: AnswerGame[]
  loading: boolean
  onChangeIndex: () => void
  showSuggestDownGrade?: () => void
  onChangeAnswer: (isCorrect: boolean) => void
  type: GameContainerType
  isShowPopupClose?: boolean
  nextType?: GameContainerType
  topic?: SearchTopicItem
  isShowReview?: boolean
}

const ARR_TYPE_GAME: QuesTionType[] = ['yesNo', 'sameOrDifferent']

export const GameContainer = observer(
  ({
    answers,
    currentIndex,
    question,
    total,
    loading,
    onChangeIndex,
    showSuggestDownGrade,
    onChangeAnswer,
    type,
    isShowPopupClose,
    nextType,
    topic,
    isShowReview = true,
  }: Props) => {
    const { height } = useWindowDimensions()

    const [layoutStates, setLayoutStates] = useState<LayoutState[]>([])
    const [stateReview, setStateReview] = useState<StateReview>(DATA_REVIEW)
    const [hint, setHint] = useState<HintProps>([])
    const [isHint, setIsHint] = useState<boolean>(true)

    const videoRef = useRef<VideoPropsRef | null>(null)
    const audioRef = useRef<AudioPropsRef | null>(null)
    const rearrangementRef = useRef<RearrangementRef | null>(null)
    const sameOrDifferentRef = useRef<SameOrDifferentRef | null>(null)
    const { openActionsheet } = useOverlay()
    const insets = useSafeAreaInsets()

    useEffect(() => {
      if (!loading && !currentIndex && type !== 'Listen') {
        audioRef.current?.play(1)
      }
    }, [currentIndex, loading])

    useEffect(() => {
      resetState()
    }, [currentIndex])

    const resetState = (isReview = true) => {
      setLayoutStates(answers.map(() => 'Unselected'))
      setIsHint(true)
      setHint([])
      if (isReview) {
        setStateReview(DATA_REVIEW)
      }
    }

    const onChange = () => {
      onChangeIndex()
    }

    const onCloseReview = debounce(() => {
      if (nextType !== 'Listen') {
        audioRef.current?.play(1)
      }
      showSuggestDownGrade?.()
    }, 200)

    const handleAnswer = async (isTrue: boolean) => {
      onChangeStateReview(isTrue)
      onChangeAnswer(isTrue)
    }

    const onPressHint = () => {
      if (question?.type === 'rearrangement') {
        rearrangementRef.current?.hint()
      } else {
        const incorrectIndexes = answers
          .map((answer, index) => (answer.isCorrect ? -1 : index))
          .filter(index => index !== -1)
          .slice(0, Math.floor(answers.length / 2))
        setHint(incorrectIndexes)
      }
      setIsHint(false)
    }

    const onSwipe = (direction: SwipeType) => {
      sameOrDifferentRef.current?.onStartSwipe(direction)
    }

    const onEndSwipe = (direction: SwipeType) => {
      sameOrDifferentRef.current?.onStartSwipe(direction)
      if (direction !== 'none') {
        const isAnswerCorrect = answers.filter(a => a.isCorrect)
        if (direction === 'left') {
          handleAnswer(LabelGame.LabelDifferent === isAnswerCorrect[0].id)
        } else {
          handleAnswer(LabelGame.LabelSame === isAnswerCorrect[0].id)
        }
      }
    }

    const onPressAnswer = debounce(async (item: AnswerGame, i: number) => {
      const newLayoutStates = [...layoutStates]

      if (!item.isCorrect) {
        const correctAnswerIndex = answers.findIndex(answer => answer.isCorrect)
        if (correctAnswerIndex !== -1) {
          newLayoutStates[correctAnswerIndex] = 'True'
        }
      }
      newLayoutStates[i] = item.isCorrect ? 'True' : 'False'
      setLayoutStates(newLayoutStates)
      onChangeStateReview(item.isCorrect)
      onChangeAnswer(item.isCorrect)
    }, 200)

    const onChangeStateReview = (isCorrect: boolean) => {
      videoRef.current?.stop()
      audioRef.current?.stop()
      const i = answers.find(answer => answer.isCorrect)
      setStateReview({
        isCorrect,
        isShow: true,
        audio: i?.audio?.url || '',
        text: i?.answer || question?.text || '',
        image: i?.image?.url || '',
      })
    }

    const handleCancelGame = () => {
      if (isShowPopupClose) {
        openActionsheet(ActionSheetCancelGame, {})
      } else {
        replace('Home', { screen: 'Topics' })
      }
    }

    const renderQuestion = (q: QuestionGame) => {
      if (type === 'Listen') {
        return (
          <ListenQuestion
            question={q}
            ref={audioRef}
            currentIndex={currentIndex}
          />
        )
      }
      switch (q.type) {
        case 'correctTranslation':
          const isAudio = isValidAudioUrl(q.media?.url ?? '')
          if (isAudio) {
            return (
              <AudioQuestion
                question={q}
                currentIndex={currentIndex}
                ref={audioRef}
              />
            )
          }
          return (
            <VideoQuestion
              question={q}
              videoRef={videoRef}
              currentIndex={currentIndex}
            />
          )
        case 'completeTheDialogue':
          return (
            <CompleteTheDialogueQuestion
              question={q}
              currentIndex={currentIndex}
              ref={audioRef}
              topic={topic}
            />
          )
        case 'rearrangement':
        case 'fillTheBlank':
          return <TextQuestion question={q} currentIndex={currentIndex} />
        case 'hearVideo':
          return (
            <VideoQuestion
              question={q}
              videoRef={videoRef}
              currentIndex={currentIndex}
            />
          )
        case 'sameOrDifferent':
          return (
            <SameOrDifferentQuestion
              question={q}
              onSwipe={onSwipe}
              onEndSwipe={onEndSwipe}
              currentIndex={currentIndex}
            />
          )
        case 'correctPhrase':
          return (
            <CorrectPhraseQuestion question={q} currentIndex={currentIndex} />
          )

        default:
          return (
            <AudioQuestion
              question={q}
              currentIndex={currentIndex}
              ref={audioRef}
            />
          )
      }
    }

    const renderContent = () => {
      if (!question || !question.type) {
        return <NotFound onPress={onChangeIndex} />
      }
      return (
        <View style={tw`flex-1 flex-col`}>
          <View
            style={tw.style(
              question.type === EasyGame.sameOrDifferent
                ? tw.style(
                    'flex-1 flex-col justify-center items-center w-full overflow-hidden',
                    {
                      minHeight: height * 0.69,
                    },
                  )
                : { minHeight: height * 0.25 },
            )}
          >
            {renderQuestion(question)}
          </View>
          <GameAnswers
            word={{ text: question.text, isPhrase: question.isPhrase ?? false }}
            data={answers}
            currentIndex={currentIndex}
            type={question.type as QuesTionType}
            layoutStates={layoutStates}
            hint={hint}
            onPressAnswer={onPressAnswer}
            onChangeData={handleAnswer}
            sameOrDifferentRef={sameOrDifferentRef}
            rearrangementRef={rearrangementRef}
            onChooseAnswer={onEndSwipe}
          />
        </View>
      )
    }

    return (
      <View style={tw`flex-1 flex-col bg-neutral-200`}>
        <Header
          bg={tw.color('background-light-white')}
          typeSlider='slice'
          sliderValue={currentIndex + 1}
          countTotal={total}
          isPopToTop
          isShowHint={
            ARR_TYPE_GAME.includes((question?.type ?? '') as QuesTionType)
              ? false
              : isHint
          }
          onPressHint={onPressHint}
          onPressBack={handleCancelGame}
        />
        <View
          style={tw.style(
            'flex-1 mt-3 rounded-t-2xl bg-background-light-white dark:bg-background-dark-white',
            Platform.OS === 'ios' && {
              paddingBottom: Math.max(insets.bottom, 16),
            },
          )}
        >
          {loading ? (
            <View style={tw`flex-1 justify-center items-center`}>
              <Gif source={images.loading} width={40} height={40} />
            </View>
          ) : (
            <View style={tw`flex-1 flex-col`}>
              <ScrollView contentContainerStyle={tw`pb-4`}>
                <TitleGame
                  title={
                    question
                      ? [...gameLabel, ...listenGameLabel].find(
                          item => item.value === question.type,
                        )?.label || ''
                      : ''
                  }
                  count={currentIndex + 1}
                  total={total}
                />
                {renderContent()}
              </ScrollView>
            </View>
          )}
          {question?.type === 'rearrangement' && (
            <View style={tw`m-4`}>
              <Button
                style={tw`border border-solid border-primary-400`}
                tone='secondary'
                onPress={() => rearrangementRef.current?.handleIDontKnowClick()}
              >
                <Text color={tw.color('primary-400')} specialType='Title'>
                  I don't know
                </Text>
              </Button>
            </View>
          )}
        </View>
        {stateReview.isShow && (
          <ReviewAnswer
            isCorrect={stateReview.isCorrect}
            text={stateReview.text}
            imageUrl={stateReview.image}
            audioUrl={stateReview.audio}
            onChangeIndex={onChange}
            onCloseView={onCloseReview}
            isLast={currentIndex === total - 1}
            isShowAnimation={isShowReview}
          />
        )}
      </View>
    )
  },
)
