import { View } from 'native-base'
import { Platform } from 'react-native'

import { Button } from '#components/base/Button/Button'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

type PaymentStatusType = {
  onClick: () => void
  status: boolean
}

export const PaymentStatus = (props: PaymentStatusType) => {
  const { onClick, status } = props
  return (
    <View style={tw.style('flex-col items-center')}>
      <SystemIcon
        type='SVG'
        name={status ? 'tickCircle' : 'paymentFail'}
        size={75}
      />
      <View style={tw.style('mt-3 items-center gap-2')}>
        <Text
          specialType='Headline3'
          color={tw.color(`${status ? 'primary-400' : 'red-400'}`)}
        >
          Payment {status ? 'success' : 'fail'}!!
        </Text>
        <View style={tw`w-3/4`}>
          <Text textAlign='center' specialType='Subtitle'>
            {status
              ? 'Congratulations on being our valued member'
              : 'Payment error. Please check your details and try again'}
          </Text>
        </View>
      </View>
      <View
        style={tw.style(
          `${Platform.OS === 'web' ? 'w-5/6' : 'w-full'} mt-10 mb-5 border ${status ? 'border-primary-400' : 'border-red-400'} rounded-full`,
        )}
      >
        <Button
          style={tw`${Platform.OS === 'web' ? '' : 'w-2/3'}`}
          onPress={onClick}
          tone='secondary'
          size='large'
        >
          <Text
            style={tw`${status ? 'text-primary-400' : 'text-red-400'} text-base`}
          >
            {status ? 'Continue Learning' : 'Try again'}
          </Text>
        </Button>
      </View>
    </View>
  )
}
