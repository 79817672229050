import { Skeleton } from 'native-base'
import { useWindowDimensions, View } from 'react-native'

import { tw } from '#components/utils/tw'

export const LoadingKnownOrUnknown = () => {
  const { width, height } = useWindowDimensions()
  const SCREEN_WIDTH = width

  return (
    <View style={tw`flex-1 flex-col`}>
      <View style={tw`flex-1 py-6 items-center justify-center`}>
        <Skeleton h={height * 0.6} w={SCREEN_WIDTH - 48} rounded={20} />
      </View>
      <View style={tw` flex-row justify-center pb-16`}>
        <View style={tw`flex-1 flex-col items-center  justify-center`}>
          <Skeleton size='56px' rounded='full' />
        </View>
        <View style={tw`flex-1 flex-col items-center  justify-center`}>
          <Skeleton size='56px' rounded='full' />
        </View>
      </View>
    </View>
  )
}
