import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { View } from 'react-native'

import { Button } from '#components/base/Button/Button'
import { tw } from '#components/utils/tw'
import { gql } from '#graphql/urql'
import { S } from '#store'
import type { AITutorMessageType, ContentId, UserTasks } from '#types/chat'

import { contentData } from './baseChatContent'

type ListHeaderProps = {
  state: ContentId
  setState: (state: ContentId) => void
  createHardMessage: (obj: AITutorMessageType) => void
  createMessage: () => void
  language: string
  topicInUserId: string
  setHidenKeyboard: (s: boolean) => void
}

export const ListHeader: FC<ListHeaderProps> = observer(
  ({
    state,
    setState,
    createHardMessage,
    createMessage,
    language,
    topicInUserId,
    setHidenKeyboard,
  }) => {
    const onStartOverPress = async () => {
      setState('default')
      setHidenKeyboard(true)
      await createHardMessage({
        AIRole: false,
        message:
          language === 'Spanish'
            ? contentData['user_changingTutor_restart'].spanish
            : contentData['user_changingTutor_restart'].english,
      })

      await createHardMessage({
        AIRole: true,
        message:
          language === 'Spanish'
            ? contentData['restart'].spanish
            : contentData['restart'].english,
      })
      setState('restart')
    }

    const onContinutePress = async () => {
      setState('default')
      setHidenKeyboard(false)
      await createHardMessage({
        AIRole: false,
        message:
          language === 'Spanish'
            ? contentData['user_changingTutor_countinue'].spanish
            : contentData['user_changingTutor_countinue'].english,
      })
      await createHardMessage({
        AIRole: true,
        message:
          language === 'Spanish'
            ? contentData['countinue'].spanish
            : contentData['countinue'].english,
      })
      createMessage()
    }
    const onPressYes = async () => {
      setState('default')
      setHidenKeyboard(false)
      await createHardMessage({
        AIRole: false,
        message:
          language === 'Spanish'
            ? contentData['user_restart_yes'].spanish
            : contentData['user_restart_yes'].english,
      })
      if (S.shared.speakingDeafaultTask) {
        const taskData =
          typeof S.shared.speakingDeafaultTask === 'string'
            ? (JSON.parse(
                S.shared.speakingDeafaultTask,
              ) as unknown as UserTasks)
            : S.shared.speakingDeafaultTask

        await gql.updateTopicInUser({
          id: topicInUserId,
          data: { task: taskData },
        })
      }
      createMessage()
    }
    const onPressNo = async () => {
      setState('default')
      setHidenKeyboard(false)
      await createHardMessage({
        AIRole: false,
        message:
          language === 'Spanish'
            ? contentData['user_restart_no'].spanish
            : contentData['user_restart_no'].english,
      })
      await createHardMessage({
        AIRole: true,
        message:
          language === 'Spanish'
            ? contentData['bot_no'].spanish
            : contentData['bot_no'].english,
      })
      createMessage()
    }
    return (
      <View style={tw`flex-col`}>
        {(state === 'changingTutor' || state === 'restart') && (
          <View style={tw`flex-row items-end mt-4 mx-4 gap-3 justify-end`}>
            <View style={tw.style('border border-primary-400 rounded-full ')}>
              <Button
                tone='secondary'
                size='small'
                style={tw.style('w-auto px-3 bg-background-light-white')}
                onPress={() => {
                  state === 'changingTutor' ? onStartOverPress() : onPressYes()
                }}
              >
                {state === 'changingTutor' ? 'Start Over' : 'Yes'}
              </Button>
            </View>
            <View style={tw.style('border border-primary-400 rounded-full')}>
              <Button
                tone='secondary'
                size='small'
                style={tw.style('w-auto px-3 bg-background-light-white')}
                onPress={() => {
                  state === 'changingTutor' ? onContinutePress() : onPressNo()
                }}
              >
                {state === 'changingTutor' ? "Let's continue" : 'No'}
              </Button>
            </View>
          </View>
        )}
      </View>
    )
  },
)
