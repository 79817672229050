import { useIsFocused } from '@react-navigation/native'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'

import { GameContainer } from '#components/widgets/Games/GameContainer'
import { logCustomEvent } from '#config/firebaseConfig'
import { useSearchTopicInUser, useSearchWordInUser } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import type { AppStackScreenProps } from '#navigator/types'
import { S } from '#store'

import { useRandomListen } from './useRandomListen'

export const ListenGame = observer(
  ({ route }: AppStackScreenProps<'ListenGame'>) => {
    const { topicId, isCompleted } = route.params

    const [wordInUser, setWordInUser] = useState<string>('')
    const countAnswer = useRef<number>(0)
    const startTime = useRef<number>(0)
    const totalTime = useRef<number>(0)
    const isFocus = useIsFocused()

    const { answers, currentIndex, onChangeData, question, total, loading } =
      useRandomListen(
        topicId,
        S.shared.currentUser?.id ?? '',
        S.shared.point,
        isCompleted,
      )

    const [d] = useSearchWordInUser({
      variables: {
        filter: {
          topicId,
          userId: S.shared.currentUser?.id,
          wordId: '',
        },
      },
    })

    const [u] = useSearchTopicInUser({
      variables: {
        filter: {
          topicId,
          userLearnId: S.shared.currentUser?.id,
        },
      },
      requestPolicy: 'network-only',
    })

    useEffect(() => {
      if (!loading) {
        if (currentIndex === 0) {
          totalTime.current = Date.now()
        }
        startTime.current = Date.now()
      }
    }, [loading, currentIndex])

    useEffect(() => {
      if (!isCompleted) {
        if (
          (!d.data?.searchWordInUser.length || !d.data?.searchWordInUser) &&
          !d.fetching
        ) {
          initCreateWordInUser()
        } else {
          setWordInUser(d.data?.searchWordInUser[0].id ?? '')
        }
      }
    }, [d.data, d.fetching, topicId])

    useEffect(() => {
      if (!isCompleted) {
        S.shared.setPoint(0)
      }
    }, [isFocus])

    const initCreateWordInUser = async () => {
      const resp = await gql.createWordInUser({
        data: {
          topicId,
          userId: S.shared.currentUser?.id,
          times: 0,
          wordId: '',
        },
      })
      setWordInUser(resp.data?.createWordInUser.id ?? '')
    }

    const onChangeAnswer = async (isTrue: boolean) => {
      countAnswer.current = isTrue
        ? countAnswer.current + 1
        : countAnswer.current
      if (isTrue && wordInUser && !isCompleted) {
        S.shared.setPoint(S.shared.point + 1)
        if (u.data?.searchTopicInUser[0]?.percent !== 100) {
          await gql.updateWordInUser({
            id: wordInUser,
            data: {
              topicId,
              userId: S.shared.currentUser?.id,
              percentListen: S.shared.point,
            },
          })
        }
      }
      const endTime = Date.now()
      const a = answers.filter(item => item.isCorrect)
      const timer = (endTime - startTime.current) / 60000 // convert ms to m

      logCustomEvent('listen_result', {
        question: question?.text || '',
        answer_results: isTrue,
        game_type: question?.type || '',
        text: a.length > 0 ? a[0]?.text || '' : '',
        topic_id: topicId,
        answer_time_per_minute: timer,
      })
      logCustomEvent('listen_game_type_result', {
        question: question?.text || '',
        answer_results: isTrue,
        game_type: question?.type || '',
        text: a.length > 0 ? a[0]?.text || '' : '',
        topic_id: topicId,
        answer_time_per_minute: timer,
      })
      if (currentIndex === total - 1 && countAnswer.current === total) {
        const time = (endTime - totalTime.current) / 60000 // convert ms to m
        logCustomEvent('listen_session_time', {
          topic_id: topicId,
          count_answer: countAnswer.current,
          total_time_per_section: time,
        })
        if (countAnswer.current === total) {
          logCustomEvent('session_listen_result', {
            topic_id: topicId,
            count_answer: countAnswer.current,
            total_time_per_section: time,
          })
        }
      }
    }

    return (
      <GameContainer
        currentIndex={currentIndex}
        total={total}
        question={question}
        answers={answers}
        loading={loading}
        onChangeIndex={onChangeData}
        onChangeAnswer={onChangeAnswer}
        type='Listen'
        nextType='Listen'
        isShowReview={false}
      />
    )
  },
)
