import { TouchableOpacity } from 'react-native'

import { tw } from '#components/utils/tw'

import type { SvgIconName } from '../utils/utils'
import { IconComponent } from '../utils/utils'

type ItemPaymentType = {
  item: { id: string; svg: SvgIconName }
  selectedMethod: string
  onPress: () => void
}

export const ItemPayment = (props: ItemPaymentType) => {
  const { item, selectedMethod, onPress } = props
  const Icon = IconComponent[item.svg]
  return (
    <TouchableOpacity
      style={tw.style(
        'w-35 p-3 border border-gray-300 rounded-3xl mx-1 bg-transparent items-center',
        item.id === selectedMethod && 'border-primary-400',
      )}
      onPress={onPress}
    >
      <Icon />
    </TouchableOpacity>
  )
}
