import type { SearchWordInUserQuery } from '#graphql/codegen'

export type SearchWordInUser =
  SearchWordInUserQuery['searchWordInUser'] extends Array<infer T> ? T : never

export enum TimeMilestones {
  Zero = 0,
  One = 1,
  Three = 3,
  Seven = 7,
  Fourteen = 14,
  Thirty = 30,
  // Sixty = 60,
}

export type TimeMilestonesKey = keyof typeof TimeMilestones

export type TimeMilestonesValue =
  (typeof TimeMilestones)[keyof typeof TimeMilestones]

export enum DirectionMilestones {
  Next = 'Next',
  Previous = 'Previous',
}

export type DirectionMilestonesType = keyof typeof DirectionMilestones

export const MILESTONES: TimeMilestonesValue[] = [0, 1, 3, 7, 14, 30]
