import { createNativeStackNavigator } from '@react-navigation/native-stack'

import { LaunchScreen } from '#screens/LaunchScreen'

import { AdminAuthNavigator } from './AdminAuthNavigator'
import { AdminNavigator } from './AdminNavigator'
import { AppNavigator } from './AppNavigator'
import { AuthStack } from './AuthStack'
import { HomeBottomTab } from './HomeBottomTab'
import type { RootStackParamList } from './types'

const Stack = createNativeStackNavigator<RootStackParamList>()

export const RootNavigator = () => (
  <Stack.Navigator
    initialRouteName='LaunchScreen'
    screenOptions={{ headerShown: false, animation: 'ios' }}
  >
    <Stack.Screen
      name='LaunchScreen'
      component={LaunchScreen}
      options={{ gestureEnabled: false }}
    />
    <Stack.Screen name='App' component={AppNavigator} />
    <Stack.Screen name='Auth' component={AuthStack} />
    <Stack.Screen name='Home' component={HomeBottomTab} />
    <Stack.Screen name='Admin' component={AdminNavigator} />
    <Stack.Screen name='AdminAuth' component={AdminAuthNavigator} />
  </Stack.Navigator>
)
