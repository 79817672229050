import { useNavigation } from '@react-navigation/native'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Platform, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { Button } from '#components/base/Button/Button'
import { FlatList } from '#components/base/FlatList'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { Header } from '#components/widgets/Header'
import { logCustomEvent } from '#config/firebaseConfig'
import { useSearchAiTutor } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import { goBack, initRouteStack, navigate, replace } from '#navigator/helpers'
import type { AppStackScreenProps } from '#navigator/types'
import type { ItemLanguageType } from '#screens/ChooseLanguage/ItemLanguage'
import { ItemLanguage } from '#screens/ChooseLanguage/ItemLanguage'
import { S } from '#store'
import type { Level } from '#types/language'

const data: ItemLanguageType[] = [
  {
    id: '3',
    value: 'A1',
    label: 'Start at the beginning\n(Beginner A1)',
    icon: 'A1',
  },
  {
    id: '4',
    value: 'OwnLevel',
    label: 'Choose your own level',
    icon: 'OwnLevel',
  },
  {
    id: '5',
    value: 'PlacementTest',
    label: 'Take a placement test',
    icon: 'PlacementTest',
  },
]

const dataOwnLevel: ItemLanguageType[] = [
  {
    id: '7',
    value: 'A2',
    label: 'Elementary A2',
    icon: 'A2',
    description: 'I can handle basic conversations in English',
  },
  {
    id: '8',
    value: 'B1',
    label: 'Intermediate B1',
    icon: 'B1',
    description: 'I can manage everyday conversations in English',
  },
  {
    id: '9',
    value: 'B2',
    label: 'Upper Intermediate B2',
    icon: 'B2',
    description: 'I can discuss various topics confidently in English',
  },
]

export const ChooseLevel = observer(
  ({ route }: AppStackScreenProps<'ChooseLevel'>) => {
    const isShowSlice = route.params?.isShow
    const [level, setLevel] = useState<ItemLanguageType['value'] | null>(null)
    const [isOwnLevel, setIsOwnLevel] = useState<boolean>(false)
    const navigation = useNavigation()

    const [aiTutor] = useSearchAiTutor()
    const insets = useSafeAreaInsets()

    const onPressItem = (item: ItemLanguageType) => {
      setLevel(item.value)
    }

    const updateLevel = async (isShow: boolean, current: Level) => {
      const newUser = S.shared.currentUser
        ? {
            ...S.shared.currentUser,
            level: { isShow, current },
            aiTutorId: aiTutor.data?.searchAiTutor?.[0]?.id ?? '',
          }
        : null
      S.shared.currentUser = newUser
    }

    const apiUpdateLevel = async (isShow: boolean, current: Level) => {
      await gql.updateUser({
        data: {
          level: { isShow, current },
          aiTutorId: aiTutor.data?.searchAiTutor?.[0]?.id ?? '',
        },
      })
    }

    const navigateToScreen = () => {
      initRouteStack('Home', { screen: 'Topics' })
    }

    const handleContinue = async () => {
      logCustomEvent('change_level', {
        current: S.shared.currentUser?.level?.current || '',
        next: level,
        reason: 'The user actively changes',
      })
      await updateLevel(true, level as Level)
      await apiUpdateLevel(true, level as Level)
      navigateToScreen()
    }

    const onPressContinue = async () => {
      if (isOwnLevel) {
        handleContinue()
      } else {
        switch (level) {
          case 'OwnLevel':
            setIsOwnLevel(true)
            setLevel(null)
            break
          case 'PlacementTest':
            setIsOwnLevel(false)
            await updateLevel(false, 'A1')
            navigate('App', { screen: 'LevelTest' })
            break
          default:
            setIsOwnLevel(false)
            await updateLevel(false, 'A1')
            await apiUpdateLevel(false, 'A1')
            navigateToScreen()
            break
        }
      }
      S.shared.setWrongNext(0)
    }

    const onPressBack = () => {
      if (isOwnLevel) {
        setIsOwnLevel(false)
        setLevel(null)
      } else {
        if (navigation.canGoBack()) {
          goBack()
        } else {
          replace('App', { screen: 'ChooseLanguage', params: {} })
        }
      }
    }

    const renderItem = ({
      item,
    }: {
      item: ItemLanguageType
      index: number
    }) => (
      <ItemLanguage
        item={item}
        isSelected={item.value === level}
        onPress={onPressItem}
      />
    )

    const renderItemSeparator = () => <View style={tw`h-3 w-full`} />

    return (
      <View style={tw`flex-1 bg-background-light-1 dark:bg-background-dark-1`}>
        <Header
          typeSlider={isShowSlice ? 'basic' : 'slice'}
          countTotal={3}
          sliderValue={3}
          onPressBack={onPressBack}
          iconType='arrow'
        />
        <View
          style={tw.style(
            'flex-1 flex-col p-4',
            Platform.OS === 'ios' && {
              paddingBottom: Math.max(insets.bottom, 16),
            },
          )}
        >
          <View style={tw`mb-6 mt-4`}>
            <Text specialType='Headline3' textAlign='center'>
              Choose Your Learning Path
            </Text>
          </View>
          <View style={tw`flex-1 flex-col`}>
            <FlatList
              data={isOwnLevel ? dataOwnLevel : data}
              renderItem={renderItem}
              ItemSeparatorComponent={renderItemSeparator}
            />
          </View>
          <Button size='large' onPress={onPressContinue} disabled={!level}>
            Continue
          </Button>
        </View>
      </View>
    )
  },
)
