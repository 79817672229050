import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Platform, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { z } from 'zod'

import { images } from '#assets'
import { Button } from '#components/base/Button/Button'
import { Image } from '#components/base/Image'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { FormField } from '#components/form/FormField'
import { Input } from '#components/form/Input'
import { InputPassword } from '#components/form/InputPassword'
import { tw } from '#components/utils/tw'
import { BackgroundAuth } from '#components/widgets/BackgroundAuth'
import type { AuthStackScreenProps } from '#navigator/types'

import { onLoginSubmit } from './onLoginSubmit'

const schema = z.object({
  email: z
    .string()
    .min(1, { message: 'Email is required' })
    .email('Invalid email address'),
  password: z.string().min(1, { message: 'Password is required' }),
})

export const Login = ({ navigation }: AuthStackScreenProps<'Login'>) => {
  const {
    control,
    watch,
    handleSubmit,
    clearErrors: clearEmailErrors,
  } = useForm({
    defaultValues: { email: '', password: '' },
    resolver: zodResolver(schema),
  })

  const [isLoading, setIsLoading] = useState(false)

  const emailValue = watch('email')
  const passwordValue = watch('password')
  const insets = useSafeAreaInsets()

  const handleButtonPress = async () => {
    setIsLoading(true)
    if (emailValue && passwordValue) {
      await onLoginSubmit({ email: emailValue, password: passwordValue })
    }
    setIsLoading(false)
  }

  const onPressPrivacyPolicy = () => {
    navigation.navigate('PrivacyPolicy')
  }

  const onPressTermOfUse = () => {
    navigation.navigate('TermsOfUse')
  }

  const renderTitle = () => (
    <View style={tw.style('items-center pt-8')}>
      <View style={tw`items-center pb-6`}>
        <Image source={images.logo} style={tw.style('w-35 h-30')} />
      </View>
      <View style={tw`items-center gap-2 pb-8`}>
        <Text textAlign='center' specialType='Headline2'>
          Sign in
        </Text>
        <Text textAlign='center' specialType='Title' color={tw.color('text-2')}>
          Hello, welcome back
        </Text>
      </View>
    </View>
  )

  return (
    <View
      style={tw.style(
        'flex-1 flex-col bg-background-light-1',
        Platform.OS === 'ios' && {
          paddingTop: Math.max(insets.top, 16),
        },
      )}
    >
      <BackgroundAuth>
        {renderTitle()}
        <View style={tw`gap-4`}>
          <FormField name='email' control={control}>
            <Input
              autoCapitalize='none'
              placeholder='Type your email here'
              value={emailValue}
              keyboardType='email-address'
              onChangeText={() => {
                clearEmailErrors('email')
              }}
              onSubmitEditing={handleSubmit(handleButtonPress)}
              autoFocus
              prefix={
                <View style={tw.style('pr-2')}>
                  <SystemIcon type='SAX' name='Sms' color={tw.color('icon')} />
                </View>
              }
            />
          </FormField>
          <View>
            <FormField name='password' control={control}>
              <InputPassword
                spellCheck={false}
                onSubmitEditing={handleSubmit(handleButtonPress)}
                placeholder='Enter your password'
                prefix={
                  <View style={tw.style('pr-2')}>
                    <SystemIcon type='SAX' name='Lock1' />
                  </View>
                }
              />
            </FormField>
            <Button
              onPress={() =>
                navigation.navigate('ForgotPassword', {
                  emailValue,
                })
              }
              size='plain'
              tone='plain'
              style={tw`self-end`}
              titleColor={tw.color('primary-400')}
            >
              <Text color={tw.color('primary-400')} specialType='paragraph2'>
                Forgot your password?
              </Text>
            </Button>
          </View>
          <Button
            tone='primary'
            onPress={handleSubmit(handleButtonPress)}
            disabled={!emailValue || !passwordValue}
            loading={isLoading}
          >
            Continue
          </Button>
        </View>
      </BackgroundAuth>

      <View
        style={tw.style('flex-col items-center', {
          paddingBottom: Math.max(insets.bottom, 16),
        })}
      >
        <Text textAlign='center' color={tw.color('text-2')} specialType='Note'>
          By sign in you agree to
        </Text>
        <Text textAlign='center' color={tw.color('text-2')} specialType='Note'>
          <Text
            color={tw.color('primary-400')}
            specialType='Note2'
            onPress={onPressPrivacyPolicy}
          >
            privacy policy
          </Text>
          {' and '}
          <Text
            color={tw.color('primary-400')}
            specialType='Note2'
            onPress={onPressTermOfUse}
          >
            terms of use
          </Text>
        </Text>
      </View>
    </View>
  )
}
