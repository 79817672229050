import { useRef, useState } from 'react'
import type { FlatList } from 'react-native'
import { Platform, Pressable, useWindowDimensions, View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import Animated, {
  runOnJS,
  useAnimatedScrollHandler,
  useSharedValue,
} from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { Button } from '#components/base/Button/Button'
import { LinearGradientColor } from '#components/base/LinearGradientColor/LinearGradientColor'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { useSearchSplash } from '#graphql/codegen'
import { replace } from '#navigator/helpers'

import { Dot } from './Dot'
import { Skeleton } from './Skeleton'
import { SlideItem } from './SlideItem'

export const Welcome = () => {
  const { width } = useWindowDimensions()
  const slideWidth = width - 48
  const slideHeight = slideWidth * 1.2

  const [scrollIndex, setScrollIndex] = useState(0)
  const scrollX = useSharedValue(0)
  const flatListRef = useRef<FlatList>(null)
  const insets = useSafeAreaInsets()

  const [splash] = useSearchSplash({
    variables: { order: ['createdAt_asc'] },
  })
  const data = splash.data?.searchSplash || []

  const handleSetScrollIndex = (index: number) => {
    setScrollIndex(Math.round(index))
  }

  const handleScroll = useAnimatedScrollHandler(e => {
    const index = e.contentOffset.x / width
    scrollX.value = index
    runOnJS(handleSetScrollIndex)(index)
  })

  const handleRedirect = () => replace('Auth', { screen: 'Login' })

  const handlePress = () => {
    if (scrollIndex + 1 === data.length) {
      return handleRedirect()
    }
    return flatListRef.current?.scrollToOffset({
      offset: (scrollIndex + 1) * width,
    })
  }

  return (
    <LinearGradientColor>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        showsHorizontalScrollIndicator={false}
        style={tw.style(
          'h-full flex-col',
          Platform.OS === 'ios' && { paddingTop: insets.top / 2 },
        )}
      >
        <Pressable
          style={tw.style(
            'py-6 px-7',
            scrollIndex + 1 === data.length && 'opacity-0',
          )}
          onPress={handleRedirect}
        >
          <Text specialType='Button' style={tw`justify-end flex text-white`}>
            Skip
          </Text>
        </Pressable>
        <Animated.FlatList
          ref={flatListRef}
          data={data}
          style={{ flexGrow: 0 }}
          keyExtractor={item => item.id}
          renderItem={({ item, index }) => (
            <SlideItem
              item={item}
              index={index}
              scrollX={scrollX}
              containerWidth={width}
              width={slideWidth}
              height={slideHeight}
            />
          )}
          horizontal
          showsHorizontalScrollIndicator={false}
          pagingEnabled
          onScroll={handleScroll}
          scrollEventThrottle={1000 / 60}
          ListEmptyComponent={() => (
            <Skeleton.SlideItem
              containerWidth={width}
              width={slideWidth}
              height={slideHeight}
            />
          )}
        />
        <View style={tw`flex-row justify-center items-center my-10 gap-1`}>
          {!data.length && <Skeleton.Dot />}
          {data.map((_, idx) => (
            <Dot key={idx} index={idx} scrollX={scrollX} />
          ))}
        </View>
        <View style={tw`p-4 pb-6 mt-auto`}>
          {!data.length ? (
            <Skeleton.Button />
          ) : (
            <Button tone='secondary' onPress={handlePress}>
              <Text specialType='Title' color={tw.color('primary-400')}>
                {scrollIndex + 1 < data.length ? 'Continue' : "Let's start"}
              </Text>
            </Button>
          )}
        </View>
      </ScrollView>
    </LinearGradientColor>
  )
}
