import { gql } from '#graphql/urql'
import type { UserTasks } from '#types/chat'
import { GameTimes, PercentageTopic } from '#types/games'
import type { LanguageType } from '#types/language'
import type { ResultPercentageTopic } from '#types/topic'

type Props = {
  topicId: string
  userId: string
  language?: LanguageType | string
}

const DEFAULT_DATA: ResultPercentageTopic = {
  count: 0,
  countKnown: 0,
  countUnKnown: 0,
  countVocabulary: 0,
  countVocabularyUnKnown: 0,
  countPhraseUnknown: 0,
  countPhrase: 0,
  fetching: false,
  percent: 0,
  currentTopicInUserId: '',
  percentGame: 0,
  percentListening: 0,
  percentSpeaking: 0,
  topicInUser: [],
  countQuestion: 0,
  wordInUser: [],
  countVocabPhraseReview: 0,
  countWord: 0,
}

export class LessonPercentageStore {
  data: {
    [key: string]: ResultPercentageTopic
  } = {}

  percentageOfTopic = async ({ topicId, userId, language }: Props) => {
    const currentUserId = userId

    const resp = await gql.checkWordInUser(
      {
        filter: { topicId, userId: currentUserId, wordId_ne: '' },
        filterKnown: {
          topicId,
          times: GameTimes.TimeMax,
          userId: currentUserId,
          wordId_ne: '',
        },
        filterUnKnown: {
          topicId,
          times: GameTimes.TimeMin,
          userId: currentUserId,
          wordId_ne: '',
        },
        filterVocabularyUnknown: {
          topicId,
          userId: currentUserId,
          word_some: { isVocabulary: true },
        },
        filterVocabulary: {
          topicId,
          times: GameTimes.TimeMax,
          userId: currentUserId,
          word_some: { isVocabulary: true },
        },
        filterPhraseUnKnown: {
          topicId,
          userId: currentUserId,
          word_some: { isVocabulary: false },
        },
        filterPhrase: {
          topicId,
          times: GameTimes.TimeMax,
          userId: currentUserId,
          word_some: { isVocabulary: false },
        },
        filterTopicInUser: {
          topicId,
          userLearnId: currentUserId,
        },
        filterQuestion: {
          topicId,
          wordId: '',
        },
        filterWordInUser: {
          topicId,
          userId: currentUserId,
          percentListen_gt: 0,
        },
        filterWord: { topicId },
        filterVocabPhraseReview: {
          userId,
          topic_some: { languageTopic: language },
        },
      },
      { requestPolicy: 'network-only' },
    )
    // Game
    const totalCountGame = resp.data?.count ?? 0
    const countKnown = resp.data?.countKnown ?? 0
    const checkPercent = countKnown ? countKnown / totalCountGame : 0
    const game: number =
      checkPercent >= PercentageTopic.PercentageGame
        ? PercentageTopic.Game
        : Math.round(checkPercent * PercentageTopic.Game)
    // Listen
    const totalCountListen =
      (resp.data?.countQuestion || 0) < PercentageTopic.MaxListen
        ? resp.data?.countQuestion ?? 0
        : PercentageTopic.MaxListen
    const point = resp.data?.wordInUser[0]?.percentListen ?? 0
    const listen: number =
      point / totalCountListen >= PercentageTopic.PercentageListen
        ? PercentageTopic.Listen
        : point > 0
          ? Math.round((point / totalCountListen) * PercentageTopic.Listen)
          : 0
    // Speak
    // const currentPercent = resp.data?.topicInUser[0]?.percent ?? 0
    const currentTask: UserTasks = resp.data?.topicInUser[0]?.task || []
    const totalTasks = currentTask.data?.length || 0
    const completedTasks =
      currentTask.data?.filter(task => task.isPassed).length || 0

    const completionPercentage =
      resp.data?.topicInUser[0]?.percent === 100
        ? 100
        : totalTasks > 0
          ? (completedTasks / totalTasks) * 100
          : 0

    let topicInUserId = resp.data?.topicInUser[0]?.id ?? ''
    if (!topicInUserId) {
      const res = await gql.createTopicInUser({
        data: { topicId, userLearnId: currentUserId },
      })
      topicInUserId = res.data?.createTopicInUser.id ?? ''
    }

    if (!topicInUserId && !resp.data?.topicInUser[0]?.learning) {
      gql.updateTopicInUser({
        id: topicInUserId,
        data: { unlockTopic: true, learning: true },
      })
    }
    return {
      ...DEFAULT_DATA,
      count: totalCountGame,
      countKnown,
      countUnKnown: resp.data?.countUnKnown ?? 0,
      countVocabulary: resp.data?.countVocabulary ?? 0,
      countVocabularyUnKnown: resp.data?.countVocabularyUnKnown ?? 0,
      countPhraseUnknown: resp.data?.countPhraseUnknown ?? 0,
      countPhrase: resp.data?.countPhrase ?? 0,
      fetching: false,
      percent: completionPercentage,
      currentTopicInUserId: topicInUserId,
      percentGame: game,
      percentListening: listen,
      percentSpeaking: completionPercentage,
      topicInUser: resp.data?.topicInUser || [],
      countQuestion: resp.data?.countQuestion || 0,
      wordInUser: resp.data?.wordInUser || [],
      countVocabPhraseReview: resp.data?.countVocabPhraseReview || 0,
      countWord: resp.data?.countWord || 0,
    }
  }

  getLessonPercentageById = (topicId: string): ResultPercentageTopic => {
    const item = this.data[topicId]
    if (!item) {
      return DEFAULT_DATA
    }
    return item
  }

  setLessonPercentageById = async ({ topicId, userId, language }: Props) => {
    if (!userId || !topicId || !language) {
      return
    }
    this.data = { ...this.data, [topicId]: { ...DEFAULT_DATA, fetching: true } }
    const newData = await this.percentageOfTopic({
      topicId,
      userId,
      language,
    })
    this.data = { ...this.data, [topicId]: newData }
  }
}
