import { useState } from 'react'
import { View } from 'react-native'

import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { audioManager } from '#components/utils/audio/audioManager'
import { tw } from '#components/utils/tw'
import { gql } from '#graphql/urql'
import { S } from '#store'
import type { SearchWordbankItem } from '#types/topicInUser'

type ListItemProps = {
  item: SearchWordbankItem
  index: number
  total: number
  isTrue: boolean
  onPlay: () => void
}

export const SItem = ({
  item,
  index,
  total,
  isTrue,
  onPlay,
}: ListItemProps) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false)

  const handlePlayTTS = async () => {
    onPlay()
    try {
      setIsPlaying(true)
      audioManager.stop()

      const tts = await gql.textToSpeech(
        { text: item.word },
        { requestPolicy: 'network-only' },
      )
      const base64Audio = tts.data?.textToSpeech

      if (base64Audio) {
        const base64Uri = `data:audio/mp3;base64,${base64Audio}`
        await audioManager.add(base64Uri)
        await audioManager.play(S.shared.currentUser?.speed || 1)
        const duration = audioManager.getDuration() || 0
        setTimeout(
          () => {
            setIsPlaying(false)
          },
          (await duration) * 1000,
        )
      } else {
        setIsPlaying(false)
      }
    } catch (error) {
      console.error('Error playing TTS audio:', error)
      setIsPlaying(false)
    }
  }

  return (
    <View
      style={tw.style(
        'flex-row bg-background-light-white p-4.5 gap-2',
        index === total - 1 ? 'rounded-b-5' : '',
      )}
    >
      <SystemIcon
        color={isPlaying && isTrue ? tw.color('primary-400') : tw.color('icon')}
        variant={isPlaying && isTrue ? 'Bold' : 'Linear'}
        type='SAX'
        name='VolumeHigh'
        onPress={handlePlayTTS}
      />
      <View style={tw.style('flex-1')}>
        <Text specialType='Title'>{item?.word || ''}</Text>
        <Text specialType='Note'>{item.translatedWords || ''}</Text>
      </View>
    </View>
  )
}
