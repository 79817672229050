import type { FC } from 'react'
import { View } from 'react-native'

import { images } from '#assets'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

import { Gif } from '../../components/base/Gif/Gif'

type ListHeaderProps = {
  countPhrase: number
  countVocabulary: number
  countPhraseUnknown: number
  countVocabularyUnKnown: number
  isPhrase: boolean
}

export const ListHeader: FC<ListHeaderProps> = ({
  countPhrase,
  countVocabulary,
  countPhraseUnknown,
  countVocabularyUnKnown,
  isPhrase,
}) => (
  <View style={tw`mb-4`}>
    <View style={tw`flex-col justify-center items-center mb-4`}>
      <Text specialType='Headline1' color={tw.color('success-400')}>
        Excellent work!!
      </Text>
      <Text style={tw`mb-6`} specialType='paragraph1'>
        You have just learned{' '}
        {isPhrase
          ? countPhraseUnknown - countPhrase
          : countVocabularyUnKnown - countVocabulary}{' '}
        {isPhrase ? 'phrases' : 'words'}
      </Text>
      <Gif source={images.congratulation} width={50} height={50} />
    </View>
    <View style={tw`w-full flex-row justify-between gap-x-8`}>
      <View
        style={tw`flex-1 bg-white rounded-2 flex-col overflow-hidden border border-success-400  ml-10`}
      >
        <View style={tw`flex-1 bg-success-400 justify-center py-3`}>
          <Text
            textAlign='center'
            specialType='paragraph1'
            color={tw.color('text-4')}
          >
            Learn
          </Text>
        </View>
        <View style={tw`flex-1 justify-center py-3`}>
          <Text
            specialType='Headline4'
            textAlign='center'
            color={tw.color('text-1')}
          >
            {String(
              isPhrase
                ? countPhraseUnknown - countPhrase
                : countVocabularyUnKnown - countVocabulary,
            ).padStart(2, '0')}
          </Text>
        </View>
      </View>
      <View
        style={tw`flex-1 bg-white rounded-2 flex-col overflow-hidden border border-secondary2-400  mr-10`}
      >
        <View style={tw`flex-1 bg-secondary2-400 justify-center py-3`}>
          <Text
            textAlign='center'
            specialType='paragraph1'
            color={tw.color('text-4')}
          >
            Learned
          </Text>
        </View>
        <View style={tw`flex-1 justify-center py-3`}>
          <Text
            specialType='Headline4'
            textAlign='center'
            color={tw.color('text-1')}
          >
            {String(isPhrase ? countPhrase : countVocabulary).padStart(2, '0')}
          </Text>
        </View>
      </View>
    </View>
  </View>
)
