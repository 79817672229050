import type { Socket } from 'socket.io-client'

import { socketInstance } from '#screens/Chat/socket'

export class SocketStore {
  socket?: Socket

  initSocket = () => {
    this.socket = socketInstance()
  }
}
