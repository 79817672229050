import { Spinner } from 'native-base'
import { TouchableOpacity, View } from 'react-native'

import { FlatList } from '#components/base/FlatList'
import { MasterCard, VisaCard } from '#components/base/IconSvg/SVG'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

import type { CardSavedType } from './type'

type ExistingCardPaymentType = {
  cardSaveData: CardSavedType[]
  selectedCard: string
  setSelectedCard: (item: CardSavedType) => void
  disabled: boolean
  newCard: () => void
}
type ItemCardSavedProps = {
  selectedCard: boolean
  setSelectedCard: (item: CardSavedType) => void
  disabled: boolean
  item: CardSavedType
}
const IconComponent = {
  visa: VisaCard,
  mastercard: MasterCard,
}

const ItemCardSaved = ({
  selectedCard,
  setSelectedCard,
  disabled,
  item,
}: ItemCardSavedProps) => {
  const Icon = IconComponent[item?.card?.brand ?? 'visa']
  return (
    <TouchableOpacity
      style={tw.style(
        'w-19/20 h-13 p-3 border border-gray-300 rounded-2xl mx-2 bg-transparent justify-between items-center mt-2 flex-row',
        selectedCard && 'border-primary-400',
      )}
      onPress={() => {
        setSelectedCard(item)
      }}
      disabled={disabled}
    >
      <Icon />
      <Text style={tw`text-base`}>**** **** **** {item.card?.last4}</Text>
      <View style={tw`w-1/8 items-end`}>
        {selectedCard && (
          <SystemIcon
            name='TickCircle'
            type='SAX'
            color={tw.color('primary-400')}
            size={25}
            variant='Bold'
          />
        )}
      </View>
    </TouchableOpacity>
  )
}

export const ExistingCardPayment = (props: ExistingCardPaymentType) => {
  const { cardSaveData, disabled, selectedCard, setSelectedCard, newCard } =
    props
  return (
    <View>
      <View style={tw.style('pb-3')}>
        <Text specialType='Title' color={tw.color('text-1')}>
          Existing card
        </Text>
      </View>
      {!cardSaveData?.length && <Spinner />}
      {cardSaveData?.length > 0 && (
        <View
          style={tw`${cardSaveData?.length === 1 ? 'h-20' : cardSaveData?.length === 2 ? 'h-35' : 'h-40'}`}
        >
          <FlatList
            data={cardSaveData}
            keyExtractor={item => item.id}
            renderItem={({ item }) => (
              <ItemCardSaved
                selectedCard={item.id === selectedCard}
                setSelectedCard={setSelectedCard}
                disabled={disabled}
                item={item}
              />
            )}
            style={tw.style('mb-4')}
            showsHorizontalScrollIndicator={false}
          />
        </View>
      )}

      <TouchableOpacity
        style={tw`self-center items-center flex-row mb-2`}
        onPress={newCard}
      >
        <SystemIcon type='SVG' name='addNewCard' size={30} />
        <Text color={'#3B89EB'} style={tw`text-base ml-2`}>
          Add new card
        </Text>
      </TouchableOpacity>
    </View>
  )
}
