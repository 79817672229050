import type { KeyboardType } from 'react-native'
import { View } from 'react-native'

import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

import { FormInputPayment } from './FormInputPayment'

type Props = {
  onChangeText: (t: string) => void
  name: string
  mt?: string
  placeholder: string
  keyboardType?: KeyboardType
  value: string
}
export const InputPayment = (props: Props) => {
  const {
    onChangeText,
    name,
    mt,
    placeholder,
    keyboardType = 'default',
    value,
  } = props
  return (
    <>
      <View style={tw.style(`pb-3 ${mt}`)}>
        <Text specialType='Title' color={tw.color('text-1')}>
          {name}
        </Text>
      </View>
      <FormInputPayment
        autoCapitalize='none'
        value={value}
        keyboardType={keyboardType}
        onChangeText={onChangeText}
        placeholder={placeholder}
      />
    </>
  )
}
