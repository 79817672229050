import type { FC } from 'react'
import { Image, View } from 'react-native'

import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import type { SearchStreakItem } from '#types/streak'

type StreakItemProps = {
  item: SearchStreakItem
  dayStreak: number
  onPress?: () => void
}

export const StreakItem: FC<StreakItemProps> = ({
  item,
  dayStreak,
  onPress,
}) => (
  <View
    // onPress={() => onPress?.(item)}
    style={tw`flex-row items-center py-4`}
  >
    <Image
      source={{
        uri:
          dayStreak >= item.numberStreak
            ? item.media?.url
            : item.thumbnail?.url,
      }}
      style={tw`w-14 h-14 rounded-full mr-4`}
      resizeMode='contain'
    />
    <View style={tw`flex-1`}>
      <Text specialType='paragraph1' style={tw`font-bold`}>
        {item.numberStreak + ' day streak'}
      </Text>
    </View>
  </View>
)
