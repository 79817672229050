export const Streak = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_28_10830)'>
      <path
        d='M13.786 8.40653C15.9304 5.91564 13.6065 4 13.6065 4C15.1027 6.3593 11.0301 5.24545 10.037 7.01257C8.88392 9.06451 9.19533 11.937 8.11429 12.2134C7.03324 12.4897 7.16883 10.9604 7.16883 10.9604C4.61304 16.0362 8.11429 18.2789 8.11429 18.2789C9.03448 18.9905 10.297 19.4304 11.6903 19.4304C13.6682 19.4304 15.3814 18.5449 16.2259 17.2525C19.8478 12.7491 15.9453 7.13759 15.9453 7.13759C16.8786 11.1399 11.6417 10.8974 13.786 8.40653Z'
        fill='#627996'
      />
      <path
        d='M12.8148 15.4261C10.3833 14.9984 10.4768 10.9209 10.4768 10.9209C10.3899 11.7405 10.1963 12.83 10.056 13.6468C9.96251 14.176 9.68196 15.1244 9.06382 15.2851C8.44568 15.4459 8.066 14.8565 7.93508 14.3452C7.91661 14.273 7.90225 14.1999 7.89206 14.1262C7.72405 14.5388 7.63765 14.9803 7.6377 15.4261C7.6377 17.5354 9.54917 19.2461 11.9067 19.2461C14.2643 19.2461 16.1757 17.5354 16.1757 15.4261C16.1753 14.9687 16.0844 14.516 15.9083 14.0942C15.9083 14.0942 15.1293 15.8322 12.8148 15.4261Z'
        fill='#AAB8C9'
      />
    </g>
    <defs>
      <clipPath id='clip0_28_10830'>
        <rect width='12' height='16' fill='white' transform='translate(6 4)' />
      </clipPath>
    </defs>
  </svg>
)
