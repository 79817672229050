import type { TwConfig } from 'twrnc'

export const twConfig: TwConfig = {
  theme: {
    extend: {
      fontWeight: {
        normal: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
        extrabold: 800,
        black: 900,
      },
      colors: {
        primary: {
          50: '#E6E6E6',
          75: '#969696',
          100: '#6B6B6B',
          200: '#8FD7ED',
          300: '#1AB8E5',
          400: '#3B89EB',
          500: '#000',
        },
        secondary1: {
          50: '#EAFEF7',
          75: '#ABFADD',
          100: '#88F7CE',
          200: '#54F4B9',
          300: '#31F2AB',
          400: '#22A978',
          500: '#1E9468',
        },
        secondary2: {
          50: '#F5F2FD',
          75: '#D8C9F6',
          100: '#C7B2F3',
          200: '#AF91EE',
          300: '#9F7BEA',
          400: '#6F56A4',
          500: '#614B8F',
        },
        error: {
          50: '#FFEBEA',
          75: '#FFAFAA',
          100: '#FF8D87',
          200: '#FF5C53',
          300: '#FF3B30',
          400: '#B32922',
          500: '#9C241D',
        },
        success: {
          50: '#EBF9EE',
          75: '#ACE8BB',
          100: '#89DF9F',
          200: '#57D175',
          300: '#34C759',
          400: '#248B3E',
          500: '#207936',
        },
        warning: {
          50: '#FFFAE6',
          75: '#FFEA96',
          100: '#FFE16B',
          200: '#FFD52B',
          300: '#FFCC00',
          400: '#B38F00',
          500: '#9C7C00',
        },
        neutral: {
          75: '#FBFCFD',
          100: '#F0F4F8',
          200: '#D9E2EC',
          300: '#AAB8C9',
          400: '#627996',
          500: '#0E1F34',
        },
        gray: {
          5: '#F0F1F2',
          100: '#F3F2F2',
          200: '#E1E2E4',
          500: '#808080',
          600: '#404040',
          700: '#3A3A3F',
          800: '#26262B',
          900: '#141417',
        },
        text: {
          1: '#15191D',
          2: '#87919A',
          3: '#ABB3BA',
          4: '#F4F4F5',
        },
        icon: '#292D32',
        separation: '#D9D9D9',
        line: {
          1: '#F0F0F0',
          2: '#E9E9EB',
        },
        body: {
          light: '#0F0F0F',
          dark: '#f5faff',
        },
        background: {
          dark: { 2: '#EFF3FD', 1: '#F3F4F8', white: '#FFF' },
          light: { 2: '#EFF3FD', 1: '#F3F4F8', white: '#FFF' },
          // light: '#F7F9FC',
        },
      },
    },
  },
}
