import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Flex, Typography } from 'antd'
import type { ColumnType } from 'antd/es/table'
import { useEffect, useState } from 'react'

import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { PaginatedTable } from '#admin/components/widgets/PaginatedTable'
import { SearchButton } from '#admin/components/widgets/SearchButton'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import { useCountQuestion, useSearchQuestion } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import { navigate } from '#navigator/helpers'
import { questionListenGameLabel } from '#types/listenGame'
import type { SearchQuestionItem } from '#types/question'

type Props = { id: string }

export const Listening = ({ id }: Props) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchKeyword, setSearchKeyword] = useState('')
  const { openModal } = useOverlay()

  const handleAdd = () => {
    navigate('Admin', {
      screen: 'AdminListenGame',
      params: { topicId: id, id: '' },
    })
  }

  const handleEditData = (questionId: string) => {
    navigate('Admin', {
      screen: 'AdminListenGame',
      params: { id: questionId, topicId: id },
    })
  }
  const [d, refetch] = useSearchQuestion({
    variables: {
      filter: searchKeyword
        ? {
            text_iLike: `%${searchKeyword}%`,
            topicId: id,
            wordId: '',
          }
        : { topicId: id, wordId: '' },
      order: ['text_asc'],
      page: { limit: pageSize, offset: (currentPage - 1) * pageSize },
    },
  })

  const [c, refetchCount] = useCountQuestion({
    variables: {
      filter: searchKeyword
        ? {
            text_iLike: `%${searchKeyword}%`,
            topicId: id,
            wordId: '',
          }
        : { topicId: id, wordId: '' },
    },
  })

  const reload = () => {
    refetch({ requestPolicy: 'network-only' })
    refetchCount({ requestPolicy: 'network-only' })

    if (c.data?.countQuestion !== undefined) {
      const remainingItemsCount = c.data.countQuestion - 1
      let newPageCount = Math.ceil(remainingItemsCount / pageSize)
      if (newPageCount < 1) {
        newPageCount = 1
      }
      if (currentPage > newPageCount) {
        setCurrentPage(newPageCount)
      }
    }
  }

  useEffect(() => {
    reload()
  }, [])

  const actionsColumn = {
    title: 'Actions',
    key: 'actions',
    width: 100,
    render: (text, record) => (
      <Flex style={tw`justify-between`}>
        <Button
          icon={<EditOutlined />}
          onClick={e => {
            e.stopPropagation()
            handleEditData(record?.id)
          }}
          className='p-0 bg-transparent'
        />
        <Button
          icon={<DeleteOutlined />}
          onClick={e => {
            e.stopPropagation()
            openModal(ModalLayout, {
              onConfirm: async () => {
                const res = await gql.deleteQuestion({ id: record.id })
                if (res.data) {
                  reload()
                  toastSuccess({ message: 'Delete listen Success' })
                } else {
                  toastError({ message: 'Delete listen failed' })
                }
              },
              selectedAi: record,
              title: 'Confirm delete',
              content: 'Are you sure you want to delete this listen?',
              containerProps: {
                closeOnOverlayClick: false,
              },
            })
          }}
          className='p-0 bg-transparent'
          danger={true}
        />
      </Flex>
    ),
  }
  const searchQuestion = d.data?.searchQuestion ?? []
  const columns: ColumnType<SearchQuestionItem>[] = [
    {
      title: 'Question',
      dataIndex: 'text',
      key: 'text',
      render: (_, record) => <Typography>{record.text}</Typography>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => (
        <Typography>{questionListenGameLabel[record.type]}</Typography>
      ),
    },
    actionsColumn,
  ]

  return (
    <Flex style={tw`flex-1 flex-col p-4 h-full overflow-y-hidden`}>
      <SearchButton
        title='Add'
        handleAddData={handleAdd}
        keyword={(k: string) => {
          setSearchKeyword(k)
          setCurrentPage(1)
        }}
      />
      <PaginatedTable<SearchQuestionItem>
        dataSource={searchQuestion}
        columns={columns}
        loading={d.fetching}
        total={c.data?.countQuestion ? c.data?.countQuestion : 0}
        currentPage={currentPage}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        scrollHeight={'50vh'}
      />
    </Flex>
  )
}
