import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useRef } from 'react'
import { View } from 'react-native'

import { tw } from '#components/utils/tw'
import { GameContainer } from '#components/widgets/Games/GameContainer'
import { logCustomEvent } from '#config/firebaseConfig'
import type { AppStackScreenProps } from '#navigator/types'
import { S } from '#store'
import type { LanguageType } from '#types/language'

import { OverView } from './OverView'
import { ReviewLevelTest } from './ReviewLevelTest'
import { useRandomLevelTest } from './useRandomLevelTest'

export const LevelTest = observer(({}: AppStackScreenProps<'LevelTest'>) => {
  const languageLearn =
    (S.shared.currentUser?.languageLearn as LanguageType) ?? 'English'
  const currentLevel = useMemo(
    () => S.shared.currentUser?.level?.current || '',
    [],
  )
  const countAnswer = useRef<number>(0)
  const startTime = useRef<number>(0)
  const totalTime = useRef<number>(0)

  const updateLevelUser = (level: string) => {
    if (S.shared.currentUser) {
      const user = S.shared.currentUser
      S.shared.currentUser = {
        ...user,
        level: { isShow: level !== 'A1', current: level },
      }
    }
  }
  const {
    answers,
    currentIndex,
    question,
    loading,
    totalCounts,
    onChangeData,
    isPopup,
    handleCloseReview,
    typeReview,
    item,
    nextItem,
  } = useRandomLevelTest({
    language: languageLearn,
    updateLevelUser,
    lv: currentLevel,
  })

  useEffect(() => {
    if (!loading) {
      if (currentIndex === 0) {
        totalTime.current = Date.now()
      }
      startTime.current = Date.now()
    }
  }, [loading, currentIndex])

  useEffect(() => {
    if (currentIndex === 0) {
      S.shared.setPointLevelTest(0)
    }
  }, [currentIndex])

  const onChangeAnswer = async (isTrue: boolean) => {
    countAnswer.current = isTrue ? countAnswer.current + 1 : countAnswer.current
    if (isTrue) {
      S.shared.setPointLevelTest(S.shared.pointLevelTest + 1)
    }
    const endTime = Date.now()
    const a = answers.filter(i => i.isCorrect)
    const timer = (endTime - startTime.current) / 60000 // convert ms to m
    logCustomEvent('level_test_result', {
      question: question?.text || '',
      answer_results: isTrue,
      game_type: question?.type || '',
      text: a.length > 0 ? a[0]?.text || '' : '',
      type: item?.type || '',
      answer_time_per_minute: timer,
    })
    logCustomEvent('level_test_game_type_result', {
      question: question?.text || '',
      answer_results: isTrue,
      game_type: question?.type || '',
      text: a.length > 0 ? a[0]?.text || '' : '',
      type: item?.type || '',
      answer_time_per_minute: timer,
    })
    if (currentIndex === totalCounts - 1) {
      const time = (endTime - totalTime.current) / 60000 // convert ms to m
      logCustomEvent('level_test_session_time', {
        count_answer: countAnswer.current,
        total_time_per_section: time,
      })
      if (countAnswer.current === totalCounts) {
        logCustomEvent('session_level_test_result', {
          count_answer: countAnswer.current,
          total_time_per_section: time,
        })
      }
    }
  }

  const renderContent = () => {
    switch (isPopup) {
      case 'OverView':
        return <OverView handleStartTest={handleCloseReview} />

      case 'Review':
        return (
          <ReviewLevelTest
            handleClose={handleCloseReview}
            type={typeReview}
            level={S.shared.currentUser?.level.current}
          />
        )

      default:
        return (
          <GameContainer
            isShowPopupClose
            currentIndex={currentIndex}
            total={totalCounts}
            question={question}
            answers={answers}
            loading={loading}
            onChangeIndex={() => onChangeData(S.shared.pointLevelTest)}
            onChangeAnswer={onChangeAnswer}
            type={item?.type === 'listen' ? 'Listen' : 'Test'}
            nextType={nextItem?.type === 'listen' ? 'Listen' : 'Test'}
            topic={item?.topic}
            isShowReview={false}
          />
        )
    }
  }

  return <View style={tw`flex-1`}>{renderContent()}</View>
})
