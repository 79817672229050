import type { FC } from 'react'
import { memo, useEffect, useRef, useState } from 'react'
import { Animated, View } from 'react-native'

import { sounds } from '#assets'
import { Image } from '#components/base/Image'
import { Text } from '#components/base/Text'
import { audioManager } from '#components/utils/audio/audioManager'
import { useAudioManager } from '#components/utils/audio/useAudioManager'
import { tw } from '#components/utils/tw'
import { useDimensions } from '#components/utils/useDimensions'

type Props = {
  text?: string
  imageUrl?: string
  isCorrect: boolean
  audioUrl: string | null
  onChangeIndex: () => void
  onCloseView: () => void
  isLast: boolean
  isShowAnimation?: boolean
}

export const ReviewAnswer: FC<Props> = memo(
  ({
    text,
    imageUrl,
    isCorrect = true,
    audioUrl,
    onChangeIndex,
    onCloseView,
    isLast,
    isShowAnimation = true,
  }) => {
    const { play, stop } = useAudioManager({
      url: audioUrl ?? '',
    })

    const { screenWidth } = useDimensions({
      padding: 16,
    })
    const backgroundScale = useRef(new Animated.Value(0)).current
    const backgroundScaleOpacity = useRef(new Animated.Value(0)).current
    const imageScale = useRef(new Animated.Value(0)).current
    const imageScaleOpacity = useRef(new Animated.Value(1)).current
    const bgPrimaryValue = useRef(new Animated.Value(0)).current
    const bgSecondaryValue = useRef(new Animated.Value(0)).current
    const xValue = useRef(new Animated.Value(0)).current
    const checkValue1 = useRef(new Animated.Value(0)).current
    const checkValue2 = useRef(new Animated.Value(0)).current
    const endValue = useRef(new Animated.Value(1)).current
    const [changeBackground, setChangeBackground] = useState<boolean>(false)

    const onPlayAudio = () => {
      if (audioUrl) {
        play(1)
      }
    }

    const onPauseAudio = () => {
      stop()
    }

    const playSound = async (url: string) => {
      await audioManager.add(url)
      await audioManager.play(1)
    }

    useEffect(() => {
      const startAnimation = () => {
        Animated.parallel([
          animateTiming(backgroundScale, 1, 250),
          animateTiming(backgroundScaleOpacity, 1, 500),
        ]).start(() => {
          Animated.sequence([
            animateTiming(bgPrimaryValue, 1, 100),
            animateTiming(bgPrimaryValue, 0.8, 100),
            animateTiming(bgPrimaryValue, 1, 100),
            animateTiming(bgSecondaryValue, 1, 75),
            animateTiming(bgSecondaryValue, 0.8, 75),
            animateTiming(bgSecondaryValue, 1, 75),
            isCorrect
              ? (playSound(sounds.check_sound),
                Animated.sequence([
                  animateTiming(checkValue1, 1, 100, false),
                  animateTiming(checkValue2, 1, 150, false),
                ]))
              : (playSound(sounds.x_sound),
                Animated.sequence([
                  animateTiming(xValue, 1, 78),
                  animateTiming(xValue, 0.8, 78),
                  animateTiming(xValue, 1, 78),
                ])),
            Animated.delay(200),

            animateTiming(endValue, 0, 1000),
          ]).start(() => {
            setChangeBackground(true)

            if (isShowAnimation) {
              onPlayAudio()
              Animated.sequence([
                animateTiming(imageScale, 1, 500),
                animateTiming(backgroundScale, 1, 500),
                animateTiming(imageScale, 1, 500),
              ]).start(() => {
                runCompletionSequence()
              })
            } else {
              runCompletionSequence()
            }
          })
        })
      }

      const runCompletionSequence = () => {
        const timeOut = setTimeout(
          () => {
            if (!isLast) {
              onChangeIndex()
            }
            Animated.sequence([
              animateTiming(imageScaleOpacity, 0, 500),
              animateTiming(backgroundScaleOpacity, 0, 250),
            ]).start(() => {
              onPauseAudio()
              if (isLast) {
                onChangeIndex()
              } else {
                onCloseView()
              }
              clearTimeout(timeOut)
            })
          },
          isShowAnimation ? 1000 : 0,
        )
      }

      startAnimation()
    }, [])

    const animateTiming = (
      animatedValue: Animated.Value,
      toValue: number,
      duration: number,
      useNativeDriver: boolean = true,
    ) =>
      Animated.timing(animatedValue, {
        toValue,
        duration,
        useNativeDriver,
      })

    const widthInterpolation1 = checkValue1.interpolate({
      inputRange: [0, 1],
      outputRange: ['0%', '100%'],
    })

    const widthInterpolation2 = checkValue2.interpolate({
      inputRange: [0, 1],
      outputRange: ['0%', '100%'],
    })

    return (
      <View
        style={tw.style(
          'absolute inset-0 bg-background-light-1 justify-center items-center',
        )}
      >
        <Animated.View
          style={[
            tw.style('absolute inset-0 justify-center items-center'),
            {
              backgroundColor: tw.color(
                changeBackground
                  ? 'background-light'
                  : isCorrect
                    ? 'success-50'
                    : 'error-50',
              ),
              transform: [{ scale: backgroundScale }],
              opacity: backgroundScaleOpacity,
            },
          ]}
        >
          <Animated.View
            style={[
              tw`flex-1 justify-center items-center`,
              { transform: [{ scale: endValue }], opacity: endValue },
            ]}
          >
            <Animated.View
              style={[
                tw.style('w-50 h-50 justify-center items-center rounded-full', {
                  backgroundColor: isCorrect
                    ? tw.color('success-75')
                    : tw.color('error-100'),
                  transform: [{ scale: bgPrimaryValue }],
                  opacity: bgPrimaryValue,
                }),
              ]}
            >
              <Animated.View
                style={[
                  tw.style(
                    'w-40 h-40 justify-center items-center rounded-full',
                  ),
                  {
                    backgroundColor: isCorrect
                      ? tw.color('success-300')
                      : tw.color('error-300'),
                    transform: [{ scale: bgSecondaryValue }],
                    opacity: bgSecondaryValue,
                  },
                ]}
              />
            </Animated.View>
            <Animated.View
              style={[
                tw`w-24 h-24 justify-center items-center absolute`,
                { transform: [{ scale: xValue }] },
              ]}
            >
              <View
                style={tw.style('flex-1 w-18 h-18 absolute', {
                  transform: [{ rotate: '45deg' }],
                })}
              >
                <View
                  style={tw.style(
                    'bg-white w-2.5 h-18 position-1 -right-7.75 rounded-full',
                  )}
                />
                <View
                  style={tw.style(
                    'bg-white w-18 h-2.5 position-2 -top-10.25 rounded-full',
                  )}
                />
              </View>
            </Animated.View>
            <View style={tw`flex-row flex-1 absolute`}>
              <View
                style={[
                  tw`w-10 h-2.5 justify-center items-center overflow-hidden`,
                  { transform: [{ rotate: '45deg' }], top: 12.5, right: -16.5 },
                ]}
              >
                <Animated.View
                  style={[
                    tw`absolute left-0 h-full bg-white rounded-full `,
                    { width: widthInterpolation1 },
                  ]}
                />
              </View>
              <View
                style={[
                  tw`w-20 h-2.5 justify-center items-center overflow-hidden`,
                  {
                    transform: [{ rotate: '-45deg' }],
                    top: 0,
                    right: 7.5,
                  },
                ]}
              >
                <Animated.View
                  style={[
                    tw`absolute left-0 h-full bg-white rounded-full`,
                    { width: widthInterpolation2 },
                  ]}
                />
              </View>
            </View>
          </Animated.View>
        </Animated.View>
        <Animated.View
          style={[
            tw.style('absolute flex-1 inset-0 justify-center items-center'),
            {
              transform: [{ scale: imageScale }],
              opacity: imageScaleOpacity,
            },
          ]}
        >
          {imageUrl && (
            <View
              style={tw.style(
                'border-4 rounded-2xl mb-3 items-center border-white justify-center',
                {
                  width: screenWidth,
                  height: screenWidth,
                  overflow: 'hidden',
                },
              )}
            >
              <Image
                source={{ uri: imageUrl }}
                style={tw.style('border-4 rounded-2xl border-white', {
                  width: screenWidth,
                  height: screenWidth,
                })}
              />
            </View>
          )}
          <View style={tw`px-4 items-center justify-center`}>
            <Text
              textAlign='center'
              specialType={imageUrl ? 'Headline3' : 'Headline2'}
            >
              {text}
            </Text>
          </View>
        </Animated.View>
      </View>
    )
  },
  () => true,
)
