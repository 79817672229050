import type { PropsWithChildren } from 'react'
import { createContext, useState } from 'react'

export type DarkMode = 'device' | 'dark' | 'light'
export const defaultDarkMode: DarkMode = 'device'

export const DarkModeContext = createContext<DarkMode>(defaultDarkMode)
export const SetDarkModeContext = createContext<(mode: DarkMode) => void>(
  () => {
    throw new Error('Missing dark mode provider')
  },
)

export type DarkModeProviderProps = PropsWithChildren<{
  value?: DarkMode
}>
export const DarkModeProvider = ({
  value,
  children,
}: DarkModeProviderProps) => {
  // const [mode, setMode] = useState<DarkMode>(defaultDarkMode)
  const [mode, setMode] = useState<DarkMode>('light')
  return (
    <DarkModeContext.Provider value={value || mode}>
      <SetDarkModeContext.Provider value={setMode}>
        {children}
      </SetDarkModeContext.Provider>
    </DarkModeContext.Provider>
  )
}
