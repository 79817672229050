const SAMPLE_RATE = 16000 // in Hz, typical for speech recognition
const BIT_DEPTH = 16 // bits per sample
const CHANNELS = 1 // mono audio

export const calculateAudioDuration = base64String => {
  if (!base64String) {
    return 0
  }

  try {
    const binaryString = atob(base64String)
    const byteLength = binaryString.length

    const bytesPerSample = BIT_DEPTH / 8
    const durationSeconds =
      byteLength / (SAMPLE_RATE * CHANNELS * bytesPerSample)

    return Math.round(durationSeconds * 100) / 100
  } catch (error) {
    console.error('Error decoding base64 string:', error)
    return 0
  }
}

export const getWordCount = (text: string): number =>
  text.trim().length > 0 ? text.trim().split(/\s+/).length : 0
