import type { FC } from 'react'

import type {
  AnswerGame,
  AnswerItemProps,
  HintProps,
  LayoutState,
} from '#types/games'
import type { QuesTionType } from '#types/question'

import { AnswerItem } from './Answer/AnswerItem'
import { SelectImageAnswer } from './Answer/SelectImageAnswer'

type GameAnswerItemProps = {
  item: AnswerGame
  index: number
  type: QuesTionType
  onPressAnswer?: () => void
  layoutStates: LayoutState
  hint: HintProps
}

const answerComponent: Record<
  Exclude<QuesTionType, 'rearrangement' | 'sameOrDifferent'>,
  FC<AnswerItemProps>
> = {
  selectImage: SelectImageAnswer,
  hearAudio: AnswerItem,
  hearVideo: AnswerItem,
  correctTranslation: AnswerItem,
  fillTheBlank: AnswerItem,
  completeTheDialogue: AnswerItem,
  multipleChoice: AnswerItem,
  yesNo: AnswerItem,
  correctPhrase: AnswerItem,
}

export const GameAnswerItem: FC<GameAnswerItemProps> = ({
  item,
  type,
  onPressAnswer,
  layoutStates,
  hint,
  index,
  // word,
}) => {
  const Component = answerComponent[type] || AnswerItem
  return (
    <Component
      item={item}
      onPressAnswer={onPressAnswer}
      layoutStates={layoutStates}
      hint={hint}
      index={index}
    />
  )
}
