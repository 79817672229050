import AsyncStorage from '@react-native-async-storage/async-storage'
import { Platform } from 'react-native'

import { setToken } from '#apis'
import type { DarkMode } from '#components/dark/DarkModeProvider'
import { setUrqlHeaders } from '#graphql/urql'
import type { CurrentUser } from '#types/user'

import type { Persisted } from '.'

export class SharedStore {
  static persisted: Persisted<SharedStore> = {
    authToken: 'string',
    darkMode: 'string',
    isTipsLearned: 'boolean',
    isTipsTopicSpeak: 'boolean',
    isShowBottomSheet: 'boolean',
    autoCorrectCount: 'number',
    speakingDeafaultTask: 'string',
    currentTopicId: 'string',
  }

  private _authToken = ''
  private _autoCorrectCount = 0

  get authToken() {
    return this._authToken
  }

  set authToken(t: string) {
    this._authToken = t
    setUrqlHeaders(this)
    setToken(t)
  }

  darkMode?: DarkMode
  currentUser?: CurrentUser

  get autoCorrectCount() {
    return this._autoCorrectCount.valueOf()
  }

  set autoCorrectCount(number: number) {
    this._autoCorrectCount = number
    Platform.OS === 'web'
      ? localStorage.setItem('autoCorrectCount', number.toString())
      : AsyncStorage.setItem('autoCorrectCount', number.toString())
  }

  point: number = 0
  setPoint = (p: number) => {
    this.point = p
  }

  checkUserSubscribed: boolean = false
  setCheckUserSubscribed = (c: boolean) => {
    this.checkUserSubscribed = c
  }

  pointLevelTest: number = 0
  setPointLevelTest = (p: number) => {
    this.pointLevelTest = p
  }

  wrongNext: number = 0
  setWrongNext = (p: number) => {
    this.wrongNext = p
  }

  checkWrongNext = (isTrue: boolean) => {
    if (isTrue) {
      this.wrongNext = 0
    } else {
      this.wrongNext++
    }
  }

  avatar: string = ''
  setAvatar = (p: string) => {
    this.avatar = p
  }

  isTipsLearned: boolean = false
  isTipsTopicSpeak: boolean = false
  isShowBottomSheet: boolean = false
  isChatSuggestShow: boolean = false
  speakingDeafaultTask: string = ''
  currentTopicId: string = ''

  currentPlayingAudio: HTMLAudioElement | null = null
}
