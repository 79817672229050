import { observer } from 'mobx-react-lite'
import { Fragment, useEffect } from 'react'
import { Platform, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { Button } from '#components/base/Button/Button'
import { Image } from '#components/base/Image'
import { ScrollView } from '#components/base/ScrollView'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { Divider } from '#components/widgets/Divider'
import { Header } from '#components/widgets/Header'
import { useSearchTopic } from '#graphql/codegen'
import { navigate } from '#navigator/helpers'
import type { AppStackScreenProps } from '#navigator/types'
import { S } from '#store'

import { RolePlay } from './RolePlay'
import { Skeleton } from './Skeleton'
import { TaskList } from './TaskList'

export const ScenarioSpeak = observer(
  ({ route }: AppStackScreenProps<'ScenarioSpeak'>) => {
    const insets = useSafeAreaInsets()
    const { topicId } = route.params

    const [topicData] = useSearchTopic({
      variables: { filter: { id: topicId } },
      requestPolicy: 'network-only',
    })

    useEffect(() => {
      S.shared.isChatSuggestShow = false
    }, [])

    const handleStart = () => {
      navigate('App', { screen: 'Chat', params: { topicId } })
    }

    const handleWordsPhrases = () => {
      navigate('App', { screen: 'SuggestedSpeak', params: { topicId } })
    }

    const topic = topicData.data?.searchTopic[0]

    return (
      <View
        style={tw.style('flex-1 flex-col', {
          paddingBottom: Platform.OS === 'ios' ? insets.bottom / 2 : 0,
        })}
      >
        <Header title='Role - play speaking' />
        <ScrollView
          style={tw`flex-1 flex-col p-4`}
          contentContainerStyle={tw`flex-grow-1`}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        >
          {topicData.fetching ? (
            <Skeleton.Thumbnail />
          ) : (
            <Image
              resizeMode='cover'
              source={{ uri: topic?.media?.url }}
              style={tw.style('mb-4 flex-1 rounded-2xl', {
                aspectRatio: 16 / 10,
              })}
            />
          )}
          <View style={tw`flex flex-col gap-y-2`}>
            <Text specialType='Headline3'>Scenario</Text>
            {topicData.fetching ? (
              <Skeleton.Paragraph />
            ) : (
              <Text
                specialType='paragraph1'
                style={tw`text-[17px] leading-snug`}
              >
                {topic?.scenario}
              </Text>
            )}
          </View>
          <Divider style={tw`my-6 flex-grow-0`} />
          <RolePlay
            loading={topicData.fetching}
            AIRole={topic?.aiTutorRole || 'Unknown'}
            userRole={topic?.userRole || 'Unknown'}
          />
          <Divider style={tw`my-6 flex-grow-0`} />
          <TaskList
            loading={topicData.fetching}
            topicId={topicId}
            tasksFromTopic={topic?.task?.data || []}
          />
          <View style={tw`mt-8 flex-col gap-y-2`}>
            {topicData.fetching ? (
              <Skeleton.Button />
            ) : (
              <Fragment>
                <Button onPress={handleStart}>Start speaking</Button>
                <View style={tw`w-full border border-primary-400 rounded-full`}>
                  <Button tone='secondary' onPress={handleWordsPhrases}>
                    Words & phrases
                  </Button>
                </View>
              </Fragment>
            )}
          </View>
        </ScrollView>
      </View>
    )
  },
)
