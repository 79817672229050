import '#polyfill'
import 'react-native-reanimated'
import 'react-native-gesture-handler'

import { AppRegistry, Platform } from 'react-native'

import { App } from '#screens/App'

const app = 'mychatapp2'
AppRegistry.registerComponent(app, () => App)

if (Platform.OS === 'web') {
  const rootTag = document.getElementById('root')
  AppRegistry.runApplication(app, { rootTag })
}
