import type { FC } from 'react'
import { View } from 'react-native'

import { Image } from '#components/base/Image'
import { tw } from '#components/utils/tw'
import { useDimensions } from '#components/utils/useDimensions'
import type { GameQuestionProps } from '#types/games'

export const CorrectPhraseQuestion: FC<GameQuestionProps> = ({ question }) => {
  const { screenWidth } = useDimensions({
    padding: 16,
  })
  return (
    <View style={tw`flex-1 flex-col items-center overflow-hidden`}>
      <Image
        source={{
          uri: question.media?.url || '',
        }}
        style={tw.style('rounded-2xl', {
          height: screenWidth * 0.5625,
          width: screenWidth,
        })}
        resizeMode='cover'
      />
    </View>
  )
}
