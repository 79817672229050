import type React from 'react'
import { Platform, View } from 'react-native'
import { Gesture, GestureDetector } from 'react-native-gesture-handler'
import Animated, {
  interpolate,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated'

import { Image } from '#components/base/Image'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { useDimensions } from '#components/utils/useDimensions'
import type { SwipeType } from '#types/games'
import type { SearchWordItem } from '#types/word'

export type ItemWord = SearchWordItem & { index: number }

type CardProps = {
  data: ItemWord
  onSwipeLeft: () => void
  onSwipeRight: () => void
  onStartSwipe?: (swipeType: SwipeType) => void
  total: number
  isShowTotal?: boolean
}

const PADDING = 16
export const Card: React.FC<CardProps> = ({
  data,
  onSwipeLeft,
  onSwipeRight,
  onStartSwipe,
  total,
  isShowTotal = true,
}) => {
  const { swipeThreshold, maxHeight, maxWidth, screenWidth } = useDimensions({
    padding: PADDING,
  })

  const translateX = useSharedValue(0)
  const cardOpacity = useSharedValue(1)
  const panGesture = Gesture.Pan()
    .onUpdate(event => {
      if (Platform.OS !== 'web') {
        ;('worklet')
      }
      translateX.value = event.translationX
      if (translateX.value < -10 && onStartSwipe) {
        runOnJS(onStartSwipe)('left')
      } else if (translateX.value > 10 && onStartSwipe) {
        runOnJS(onStartSwipe)('right')
      } else {
        if (onStartSwipe) {
          runOnJS(onStartSwipe)('none')
        }
      }
    })
    .onEnd(() => {
      if (Platform.OS !== 'web') {
        ;('worklet')
      }
      if (translateX.value < -swipeThreshold) {
        cardOpacity.value = withSpring(0, {}, () => runOnJS(onSwipeLeft)())
      } else if (translateX.value > swipeThreshold) {
        cardOpacity.value = withSpring(0, {}, () => runOnJS(onSwipeRight)())
      } else {
        translateX.value = 0
        if (onStartSwipe) {
          runOnJS(onStartSwipe)('none')
        }
      }
    })

  const animatedStyle = useAnimatedStyle(
    () => ({
      transform: [
        { translateX: translateX.value },
        Platform.OS === 'web'
          ? {
              rotate: `${interpolate(
                translateX.value,
                [-screenWidth / 2, 0, screenWidth / 2],
                [-50, 0, 50],
              )}deg`,
            }
          : {
              rotateZ: `${interpolate(
                translateX.value,
                [-screenWidth / 2, 0, screenWidth / 2],
                [-50, 0, 50],
              )}deg`,
            },
      ],
      opacity: cardOpacity.value,
    }),
    [translateX, cardOpacity],
  )

  const imageHeight = maxWidth + 8

  return (
    <GestureDetector gesture={panGesture}>
      <Animated.View
        style={[
          tw.style('bg-white rounded-3xl py-3 items-center justify-center'),
          {
            width: maxWidth + 24,
            height: maxHeight + 241,
          },
          animatedStyle,
        ]}
      >
        {(data.isVocabulary || data?.isThumbnail) && (
          <View
            style={tw.style(
              'items-center justify-center overflow-hidden rounded-2xl',
              {
                width: maxWidth,
                height: imageHeight,
              },
            )}
          >
            <Image
              source={{ uri: data.media?.url }}
              style={tw.style(
                'rounded-2xl',
                `w-[${maxWidth}px] h-[${maxHeight}px]`,
              )}
            />
          </View>
        )}
        <View style={tw`flex-col flex-1 items-center justify-center m-4`}>
          <Text
            specialType='Headline2'
            textAlign='center'
            numberOfLines={data?.isThumbnail ? 3 : 6}
          >
            {data.vocabAndPhrase}
          </Text>
          {(!data.isVocabulary || data?.isTranslate) && (
            <Text
              specialType='paragraph1'
              numberOfLines={data?.isThumbnail ? 3 : 6}
              textAlign='center'
            >
              {data.translate?.text ?? ''}
            </Text>
          )}
        </View>

        {isShowTotal && (
          <Text
            specialType='Subtitle'
            textAlign='center'
            color={tw.color('text-2')}
          >
            {`${data.index} / ${total}`}
          </Text>
        )}
      </Animated.View>
    </GestureDetector>
  )
}
