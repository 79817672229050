import type { PropsWithChildren } from 'react'
import { useEffect, useState } from 'react'
import type {
  Client,
  CombinedError,
  OperationContext,
  UseQueryResponse,
} from 'urql'
import {
  cacheExchange,
  createClient,
  fetchExchange,
  mapExchange,
  Provider,
} from 'urql'

import { fetchWithTimeout } from '##/shared/fetchWithTimeout'
import type { Undefined } from '##/shared/ts'
import { rnServerOrigin } from '#config'
import type { SharedStore } from '#store/SharedStore'

import { operations } from './codegen'

const create = () =>
  createClient({
    url: `${rnServerOrigin}/api/graphql`,
    fetch: fetchWithTimeout,
    fetchOptions: () => ({
      headers,
    }),
    exchanges: [
      cacheExchange,
      mapExchange({
        onError: err => errorHandler?.(err),
      }),
      fetchExchange,
    ],
  })

let client = create()
let set: Undefined<(c: Client) => void>
export const resetUrqlProvider = () => {
  client = create()
  gql = operations(client)
  set?.(client)
}

/**
 * Singleton, should be only one rendered globally
 */
export const UrqlProvider = ({ children }: PropsWithChildren) => {
  const [c, s] = useState(client)
  set = s
  useEffect(
    () => () => {
      set = undefined
    },
    [],
  )
  return <Provider value={c}>{children}</Provider>
}

let headers: Undefined<HeadersInit> = undefined
export const setUrqlHeaders = (s: Pick<SharedStore, 'authToken'>) => {
  if (!s.authToken) {
    headers = undefined
    return
  }
  headers = {}
  if (s.authToken) {
    headers.Authorization = `Bearer ${s.authToken}`
  }
}

let errorHandler: Undefined<(err: CombinedError) => void>
export const setUrqlErrorHandler = (h: (err: CombinedError) => void) => {
  errorHandler = h
}

export let gql = operations(client)

export const urqlExtraQuery = (r: UseQueryResponse) => {
  const [
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      data,
      ...extra
    },
    refetch,
  ] = r
  return {
    ...extra,
    refetch: (opts?: Partial<OperationContext>) =>
      refetch({ requestPolicy: 'network-only', ...opts }),
  }
}
