import { useEffect, useState } from 'react'
import type { Style } from 'twrnc'

import { Select, SelectItem } from '#components/form/Select'
import { tw } from '#components/utils/tw'

type Option<T> = {
  label: string
  value: T
}

type DropdownProps<T extends string> = {
  options: Option<T>[]
  onSelect: (value: T) => void
  defaultValue?: T
  style?: Style
}

export const Dropdown = <T extends string>({
  options,
  onSelect,
  defaultValue,
  style,
}: DropdownProps<T>) => {
  const [selectedValue, setSelectedValue] = useState<T | undefined>(
    defaultValue ? defaultValue : undefined,
  )
  useEffect(() => {
    if (defaultValue) {
      onSelect(defaultValue)
    }
  }, [defaultValue])

  const handleChange = (value: string) => {
    setSelectedValue(value as T)
    onSelect(value as T)
  }

  return (
    <Select
      style={tw.style('h-13.4', style)}
      onValueChange={handleChange}
      selectedValue={selectedValue ? selectedValue : undefined}
    >
      {options.map(({ label, value }, index) => (
        <SelectItem key={index} label={label} value={value} />
      ))}
    </Select>
  )
}
