interface GetNameFromEmailProps {
  name: string
  email: string
}

export const getUserName = ({ email, name }: GetNameFromEmailProps) => {
  if (!name) {
    return email.split('@')[0]
  }
  return name.split('@')[0]
}
