import { shuffleArray } from '#components/utils/random'
import type { InputWord } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import type {
  EasyGameType,
  GameType,
  GenerateHardGameProps,
  GenerateProps,
  HardGameType,
  QuestionGame,
  WordItem,
} from '#types/games'
import {
  EasyGame,
  easyGameLabel,
  HardGame,
  hardGameLabel,
  phraseGameLabel,
  wordGameLabel,
} from '#types/games'
import type { LanguageType } from '#types/language'
import type { ReviewItem, ReviewProps } from '#types/review'
import type { SearchTopicItem } from '#types/topic'
import type { SearchWordInUser } from '#types/wordInUser'

import { easyGameFunc, hardGameFunc } from './gameFunc'
import { getItemInList } from './getItemInList'

type Props = { language: LanguageType; userId: string }

type ResultProps = { data: ReviewItem[]; topic: SearchTopicItem[] }

export const generateGameReview = async ({
  language,
  userId,
}: Props): Promise<ResultProps> => {
  const resp = await gql.searchVocabPhraseReview(
    {
      filter: {
        userId,
        topic_some: { languageTopic: language },
      },
      order: ['milestone_asc', 'nextDay_asc'],
      page: { offset: 0, limit: 8 },
    },
    {
      requestPolicy: 'network-only',
    },
  )
  const data: SearchWordInUser[] = resp.data?.searchVocabPhraseReview || []

  if (!data.length) {
    return { data: [], topic: [] }
  }
  const generateHardGame: InputWord[] = []
  let type: GameType | null = null
  const easyType: EasyGameType | null = null

  const list: ReviewProps[] = data.map(i => {
    const gameType = getItemInList(
      i.word?.isVocabulary
        ? wordGameLabel.filter(j => j.value !== type)
        : phraseGameLabel.filter(j => j.value !== type),
    )
    const newType: GameType = gameType?.value || EasyGame.hearAudio
    type = newType
    if (hardGameLabel.some(j => j.value === gameType?.value)) {
      generateHardGame.push({
        text: i.word?.vocabAndPhrase || '',
        language,
        isPhrase: !i.word?.isVocabulary || false,
        id: i.id,
        type: newType,
      })
    }

    const easyGameData: EasyGameType[] = [
      EasyGame.rearrangement,
      EasyGame.correctPhrase,
    ]
    const gameEasyType = getItemInList(
      i.word?.isVocabulary
        ? easyGameLabel.filter(
            j => j.value !== easyType && !easyGameData.includes(j.value),
          )
        : easyGameLabel.filter(j => j.value !== easyType),
    )
    const hardGameData: HardGameType[] = [HardGame.completeTheDialogue]
    const gameHardType = getItemInList(
      i.word?.isVocabulary
        ? hardGameLabel.filter(
            j => j.value !== easyType && !hardGameData.includes(j.value),
          )
        : hardGameLabel,
    )
    const hardType = gameHardType?.value || HardGame.fillTheBlank
    generateHardGame.push({
      text: i.word?.vocabAndPhrase || '',
      language,
      isPhrase: !i.word?.isVocabulary || false,
      id: i.id,
      type: hardType,
    })
    return {
      ...i,
      gameType: newType,
      easyType: gameEasyType?.value || EasyGame.hearAudio,
      hardType,
    }
  })

  const topicIds: string[] = [...new Set(list.map(obj => obj.topicId))]
  const respData = await gql.generateReview({
    filterTopic: { id_in: topicIds },
    filterWord: { topicId_in: topicIds },
    filterResourceInWord: {
      word_some: { topicId_in: topicIds },
    },
    filterTranslate: { Word_some: { topicId_in: topicIds } },
    dataHardGame: generateHardGame,
  })

  const hardGame = respData.data?.hardGame || []
  const resourceInWord = respData.data?.resourceInWord || []
  const translate = respData.data?.translate || []
  const word = respData.data?.word || []
  const wordsData: WordItem[] = word.map(w => {
    const m = resourceInWord.filter(media => media.wordId === w.id)
    const t = translate.filter(media => media.wQAId === w.id)
    return { ...w, media: m, translation: t }
  })

  const questionGame: QuestionGame = {
    text: '',
    media: null,
    type: null,
    translation: '',
    index: 0,
  }
  // const results: ReviewItem[] = []
  const results: ReviewItem[] = await Promise.all(
    list.map(async (item, idx) => {
      let newItem: ReviewItem = {
        ...item,
        total: 0,
        count: 0,
        game: { question: questionGame, answers: [] },
        easy: { question: questionGame, answers: [] },
        hard: { question: questionGame, answers: [] },
      }
      const translation = translate.filter(
        i =>
          i.wQAId === item.wordId &&
          item.word?.isVocabulary === i.Word?.isVocabulary,
      )
      const media = resourceInWord.filter(
        i =>
          i.wordId === item.wordId &&
          item.word?.isVocabulary === i.word?.isVocabulary,
      )
      let dataHardGame = hardGame.filter(
        i => i.id === item.id && i.type === item.gameType,
      )
      const itemParams = {
        ...item,
        media,
        text: item.word?.vocabAndPhrase || '',
        translation,
      }
      let paramsHard: GenerateHardGameProps = {
        item: itemParams,
        data: getItemInList(dataHardGame) || null,
      }

      let paramsEasy: GenerateProps = {
        language,
        isPhrase: !item.word?.isVocabulary || false,
        item: itemParams,
        data: wordsData.filter(
          i => i.id !== item.id && item.word?.isVocabulary === i.isVocabulary,
        ),
      }

      if (hardGameLabel.some(j => j.value === item.gameType)) {
        const resultHard = await hardGameFunc[item.gameType](paramsHard)
        newItem = { ...newItem, game: resultHard }
      } else {
        const resultEasy = await easyGameFunc[item.gameType](paramsEasy)
        newItem = { ...newItem, game: resultEasy }
      }
      dataHardGame = hardGame.filter(
        i => i.id === item.id && i.type === item.hardType,
      )
      paramsHard = { ...paramsHard, data: getItemInList(dataHardGame) || null }
      const resultHard = await hardGameFunc[item.hardType](paramsHard)
      paramsEasy = {
        ...paramsEasy,
        data: wordsData.filter(
          i => i.id !== item.id && item.word?.isVocabulary === i.isVocabulary,
        ),
      }
      const resultEasy = await easyGameFunc[item.easyType](paramsEasy)
      return {
        ...newItem,
        easy: resultEasy,
        hard: resultHard,
      }
    }),
  )

  const topic: SearchTopicItem[] = respData.data?.topic || []
  return { data: shuffleArray(results), topic }
}
