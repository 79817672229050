import { DeleteOutlined } from '@ant-design/icons'
import { Button, Flex, Form, Input, Typography } from 'antd'
import type { FC } from 'react'
import { useEffect, useState } from 'react'

import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { Label } from '#admin/components/widgets/Label'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { Upload } from '#admin/components/widgets/Upload'
import { tw } from '#components/utils/tw'
import { gql } from '#graphql/urql'
import type { ResponseFile } from '#types/media'
import type { SearchStreakItem } from '#types/streak'

type Pr = {
  closeModal: () => void
  item?: SearchStreakItem
  reload: () => void
}

const { TextArea } = Input

export const MilestoneModal: FC<Pr> = ({ closeModal, item, reload }) => {
  const [loading, setLoading] = useState(false)
  const [numberStreak, setNumberStreak] = useState<string>(
    item ? item.numberStreak.toString() : '',
  )
  const [media, setMedia] = useState<{
    url: string
    mediaId: string
  }>({
    url: item?.media?.url || '',
    mediaId: item?.mediaId ?? '',
  })

  const [thumbnail, setThumbnail] = useState<{
    url: string
    thumbnailId: string
  }>({
    url: item?.thumbnail?.url || '',
    thumbnailId: item?.thumbnailId ?? '',
  })

  const [info, setInfo] = useState<string[]>(item?.infor?.content || [])

  const handleSubmit = async () => {
    try {
      if (
        loading ||
        !numberStreak ||
        !media.mediaId ||
        !thumbnail.thumbnailId ||
        !info.length ||
        (info.length > 0 && info.includes(''))
      ) {
        return
      }
      setLoading(true)
      const params = {
        numberStreak: +numberStreak,
        mediaId: media.mediaId,
        thumbnailId: thumbnail.thumbnailId,
        infor: { content: info },
      }
      let message: string = ''
      if (item?.id) {
        const resp = await gql.updateStreak({
          id: item.id,
          data: params,
        })
        message =
          resp.error || !resp.data
            ? resp.error?.message || 'Update failed!'
            : ''
      } else {
        const resp = await gql.createStreak({
          data: params,
        })
        message =
          resp.error || !resp.data
            ? resp.error?.message || 'Create failed!'
            : ''
      }

      if (message) {
        toastError({ title: 'Error', message })
        return
      }

      const successMessage = item?.id
        ? 'Updated successfully.'
        : 'Created successfully.'
      toastSuccess({ message: successMessage })
      reload()
      closeModal()
    } catch {
      toastError({
        message: item ? 'Update failed!.' : 'Create failed!',
      })
    } finally {
      setLoading(false)
    }
  }
  const handleModalCancel = () => {
    closeModal()
  }

  const onChangeMedia = (data: ResponseFile) => {
    setMedia({ url: data.url, mediaId: data.id })
  }

  const onChangeThumbnail = (data: ResponseFile) => {
    setThumbnail({ url: data.url, thumbnailId: data.id })
  }

  const handleAddInfo = () => {
    setInfo(s => [...s, ''])
  }

  const onChangeDataInfo = ({
    value,
    index,
  }: {
    value: string
    index: number
  }) => {
    setInfo(pre => pre.map((i, idx: number) => (idx === index ? value : i)))
  }

  const onDeleteInfo = (idx: number) => {
    setInfo(s => s.filter((_, index: number) => index !== idx))
  }

  return (
    <ModalLayout
      title={item ? 'Edit' : 'Add'}
      closeModal={handleModalCancel}
      onConfirm={handleSubmit}
      disableOk={
        loading ||
        !numberStreak ||
        !media.mediaId ||
        !thumbnail.thumbnailId ||
        !info.length ||
        (info.length > 0 && info.includes(''))
      }
      autoClose={false}
      isEnterEvent={false}
    >
      <Flex style={tw`flex-1/2`}>
        <Form layout='vertical' style={tw`w-120 bg-white rounded-5`}>
          <Form.Item label={<Label label='Number streak' required />}>
            <Input
              placeholder='Enter your number streak'
              value={numberStreak}
              onChange={e => {
                const regex = /^\d+$/gm
                if (regex.test(e.target.value) || !e.target.value) {
                  setNumberStreak(e.target.value)
                }
              }}
            />
          </Form.Item>
          <Flex style={tw`items-center justify-center flex-1`}>
            <Flex vertical style={tw`flex-1 items-center justify-center`}>
              <Form.Item style={tw`text-center w-full`}>
                <Upload
                  resourceType='image_game'
                  type='image'
                  display='pictureCard'
                  url={media.url}
                  onChange={onChangeMedia}
                  onDeleted={() => setMedia({ url: '', mediaId: '' })}
                  setLoadingUpload={setLoading}
                  required
                  label='Badge active'
                />
              </Form.Item>
            </Flex>
            <Flex vertical style={tw`flex-1 items-center justify-center`}>
              <Form.Item style={tw`text-center w-full`}>
                <Upload
                  resourceType='image_game'
                  type='image'
                  display='pictureCard'
                  url={thumbnail.url}
                  onChange={onChangeThumbnail}
                  onDeleted={() => setThumbnail({ url: '', thumbnailId: '' })}
                  setLoadingUpload={setLoading}
                  required
                  label='Badge inactive'
                />
              </Form.Item>
            </Flex>
          </Flex>
          <Flex style={tw`items-center mt-4`}>
            <Typography.Title level={5} style={tw`m-0 flex-1`}>
              Slogan
            </Typography.Title>
            <Button onClick={handleAddInfo} className='p-0'>
              Add slogan
            </Button>
          </Flex>
          <Flex vertical gap={8}>
            {info.map((i, index) => (
              <Info
                key={`${index}`}
                item={i}
                index={index}
                onChangeData={onChangeDataInfo}
                onDelete={onDeleteInfo}
              />
            ))}
          </Flex>
        </Form>
      </Flex>
    </ModalLayout>
  )
}

type InfoProps = {
  item: string
  index: number
  onChangeData: (data: { value: string; index: number }) => void
  onDelete: (index: number) => void
}

const Info: FC<InfoProps> = ({ item, index, onChangeData, onDelete }) => {
  const [name, setName] = useState<string>(item)
  useEffect(() => {
    if (item !== name) {
      setName(item)
    }
  }, [item])

  return (
    <Flex vertical gap={8}>
      <Typography>{`Slogan ${index + 1}:`}</Typography>
      <Flex style={tw`items-center`} gap={8}>
        <TextArea
          value={name}
          onChange={e => {
            setName(e.target.value)
            onChangeData({ value: e.target.value, index })
          }}
        />
        <Button
          icon={<DeleteOutlined />}
          onClick={() => onDelete(index)}
          className='p-0 bg-transparent'
          danger={true}
        />
      </Flex>
    </Flex>
  )
}
