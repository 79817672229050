/* eslint-disable func-style */
/* eslint-disable arrow-body-style */
/* eslint-disable prefer-arrow/prefer-arrow-functions */

import { Skeleton as BaseSkeleton } from '#components/base/Skeleton'
import { tw } from '#components/utils/tw'

function ImageSkeleton() {
  return <BaseSkeleton style={tw.style('w-22 h-22 rounded-full mb-4')} />
}

function TitleSkeleton() {
  return <BaseSkeleton style={tw`w-40 h-6 mb-2`} />
}

function SubtitleSkeleton() {
  return <BaseSkeleton style={tw`w-3/4 h-4 mb-6`} />
}

export const Skeleton = {
  Image: ImageSkeleton,
  Title: TitleSkeleton,
  Subtitle: SubtitleSkeleton,
}
