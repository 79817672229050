export const audioExts = ['.mp3', '.ogg', '.wav']

export const isValidAudioUrl = (url: string): boolean =>
  audioExts.some(ext => url.endsWith(ext))

export const imageExts = [
  '.png',
  '.jpg',
  '.jpeg',
  '.gif',
  '.svg',
  //
]

export const isValidImageUrl = (url: string): boolean =>
  imageExts.some(ext => url.endsWith(ext))

export const videoExts = [
  '.mp4',
  '.mov',
  '.mkv',
  '.avi',
  '.m4v',
  '.flv',
  '.webm',
  //
]

export const isValidVideoUrl = (url: string): boolean =>
  videoExts.some(ext => url.endsWith(ext))

export const isValidUrl = (url: string): boolean =>
  isValidAudioUrl(url) || isValidImageUrl(url) || isValidVideoUrl(url)

export const checkFileType = (pathOrUrl: string) => {
  const ext = pathOrUrl.split('.').pop() ?? ''
  return (
    (imageExts.includes('.' + ext)
      ? 'image'
      : videoExts.includes('.' + ext)
        ? 'video'
        : audioExts.includes('.' + ext)
          ? 'audio'
          : '') +
    '/' +
    ext
  )
}

export type FileType = 'audio' | 'image' | 'video'

export const checkType = (pathOrUrl: string, type: FileType) => {
  const ext = pathOrUrl.split('.').pop() ?? ''
  return (
    (type === 'image' && imageExts.includes('.' + ext)) ||
    (type === 'video' && videoExts.includes('.' + ext)) ||
    (type === 'audio' && audioExts.includes('.' + ext))
  )
}
