import { DeleteOutlined } from '@ant-design/icons'
import { Button, Flex, Form, Input, Typography } from 'antd'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { ulid } from 'ulidx'

import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { tw } from '#components/utils/tw'
import type { UpdateTopic } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import type { Task, TaskItem } from '#types/chat'
import type { SearchTopicItem } from '#types/topic'

type Prop = {
  topic: SearchTopicItem
  data: TaskItem[]
  item?: TaskItem
  onChangeData: (data: TaskItem[]) => void
  closeModal: () => void
}

export const TaskSpeaking: FC<Prop> = ({
  topic,
  closeModal,
  onChangeData,
  item,
  data,
}) => {
  const [name, setName] = useState<string>(item?.name ?? '')
  const [subTask, setSubTask] = useState<Task[]>(item?.subTask ?? [])

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (item?.id) {
      setName(item.name)
      setSubTask(item.subTask)
    }
  }, [item?.name, item?.subTask])

  const handleModalOk = async () => {
    try {
      if (loading || !name || subTask.some(sub => sub.name === '')) {
        return
      }
      setLoading(true)
      const newData = item?.id
        ? data.map(i => (i.id === item.id ? { ...i, name, subTask } : i))
        : [...data, { name, id: ulid(), description: '', subTask }]
      const params: UpdateTopic = {
        task: { data: newData },
      }
      const resp = await gql.updateTopic({
        id: topic.id,
        data: params,
      })
      if (resp.error || !resp.data) {
        toastError({ title: 'Error', message: resp.error?.message })
        setLoading(false)
        return
      }
      onChangeData(newData)
      toastSuccess({
        message: item?.id
          ? 'Successfully updated task speaking'
          : 'Successfully created task speaking',
      })
      setLoading(false)
      closeModal()
    } catch (error) {
      toastError({ message: 'Failed updated info' })
      setLoading(false)
    }
  }

  const handleModalCancel = () => {
    closeModal()
  }

  // const handleKeyPress = (event: React.KeyboardEvent) => {
  //   if (event.key === 'Enter') {
  //     handleModalOk()
  //   }
  // }

  const handleAddSubTask = () => {
    setSubTask(s => [...s, { id: ulid(), description: '', name: '' }])
  }

  const onChangeDataSubTask = (itemTask: Task) => {
    setSubTask(pre => pre.map(i => (i.id === itemTask.id ? itemTask : i)))
  }

  const onDeleteSubTask = (taskId: string) => {
    setSubTask(s => s.filter(i => i.id !== taskId))
  }

  return (
    <ModalLayout
      title={item?.id ? 'Edit task speaking' : 'Add task speaking'}
      closeModal={handleModalCancel}
      onConfirm={handleModalOk}
      disableOk={loading || !name || subTask.some(sub => sub.name === '')}
      autoClose={false}
      isEnterEvent={false}
    >
      <Form layout='vertical' style={tw`w-full pt-2`}>
        <Form.Item label='Task'>
          <Input
            value={name}
            onChange={e => setName(e.target.value)}
            className='w-full'
          />
        </Form.Item>
        <Flex style={tw`items-center`}>
          <Typography.Title level={5} style={tw`m-0 flex-1`}>
            Sub task
          </Typography.Title>
          <Button onClick={handleAddSubTask} className='p-0'>
            Add sub task
          </Button>
        </Flex>
        <Flex vertical gap={8}>
          {subTask.map((i, index) => (
            <SubTask
              key={i.id}
              item={i}
              index={index}
              onChangeData={onChangeDataSubTask}
              onDelete={onDeleteSubTask}
            />
          ))}
        </Flex>
      </Form>
    </ModalLayout>
  )
}

type SubTaskProps = {
  item: Task
  index: number
  onChangeData: (data: Task) => void
  onDelete: (taskId: string) => void
}

const SubTask: FC<SubTaskProps> = ({ item, index, onChangeData, onDelete }) => {
  const [name, setName] = useState<string>(item.name)
  useEffect(() => {
    if (item.name !== name) {
      setName(item.name)
    }
  }, [item?.name])

  return (
    <Flex vertical gap={8}>
      <Typography>{`Sub task ${index + 1}:`}</Typography>
      <Flex style={tw`items-center`} gap={8}>
        <Input
          value={name}
          onChange={e => {
            setName(e.target.value)
            onChangeData({ ...item, name: e.target.value })
          }}
        />
        <Button
          icon={<DeleteOutlined />}
          onClick={() => onDelete(item.id)}
          className='p-0 bg-transparent'
          danger={true}
        />
      </Flex>
    </Flex>
  )
}
