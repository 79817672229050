import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Flex } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useState } from 'react'

import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { PaginatedTable } from '#admin/components/widgets/PaginatedTable'
import { SearchButton } from '#admin/components/widgets/SearchButton'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import { useCountStreakDaily, useSearchStreakDaily } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import type { SearchStreakDailyItem } from '#types/streakDaily'

import { DailyModal } from './DailyModal'

export const Daily = () => {
  const { openModal } = useOverlay()

  const [searchKeyword, setSearchKeyword] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState(10)

  const [splash, refetch] = useSearchStreakDaily({
    variables: {
      filter: searchKeyword ? { name_iLike: `%${searchKeyword}%` } : {},
      order: ['name_asc'],
      page: { limit: pageSize, offset: (currentPage - 1) * pageSize },
    },
  })

  const [count, refetchCount] = useCountStreakDaily({
    variables: {
      filter: searchKeyword ? { name_iLike: `%${searchKeyword}%` } : {},
    },
  })

  const reload = () => {
    refetch({ requestPolicy: 'network-only' })
    refetchCount({ requestPolicy: 'network-only' })

    if (count.data?.countStreakDaily) {
      const remainingItemsCount = count.data.countStreakDaily - 1
      let newPageCount = Math.ceil(remainingItemsCount / pageSize)
      if (newPageCount < 1) {
        newPageCount = 1
      }
      if (currentPage > newPageCount) {
        setCurrentPage(newPageCount)
      }
    }
  }

  const handleAdd = () => {
    openModal(DailyModal, {
      containerProps: {
        closeOnOverlayClick: false,
      },
      onComplete: reload(),
      reload,
    })
  }

  const actionsColumn = {
    title: 'Actions',
    key: 'actions',
    width: 100,
    render: (text, record) => (
      <Flex style={tw`justify-between`}>
        <Button
          icon={<EditOutlined />}
          onClick={e => {
            e.stopPropagation()
            openModal(DailyModal, {
              item: record,
              containerProps: {
                closeOnOverlayClick: false,
              },
              reload,
            })
          }}
          className='p-0 bg-transparent'
        />
        <Button
          icon={<DeleteOutlined />}
          onClick={e => {
            e.stopPropagation()
            openModal(ModalLayout, {
              onConfirm: async () => {
                const res = await gql.deleteStreakDaily({ id: record.id })
                if (res.data) {
                  reload()
                  toastSuccess({ message: 'Delete successfully' })
                } else {
                  toastError({ message: 'Delete failed' })
                }
              },
              title: 'Confirm delete',
              content: 'Are you sure you want to delete?',
              containerProps: {
                closeOnOverlayClick: false,
              },
            })
          }}
          className='p-0 bg-transparent'
          danger={true}
        />
      </Flex>
    ),
  }

  const columns: ColumnsType<SearchStreakDailyItem> = [
    {
      title: 'Slogan',
      dataIndex: 'name',
      key: 'name',
    },
    actionsColumn,
  ]

  return (
    <Flex style={tw`flex-1 flex-col p-4 px-[10%]`}>
      <SearchButton
        title='Add'
        handleAddData={handleAdd}
        keyword={(k: string) => {
          setSearchKeyword(k)
          setCurrentPage(1)
        }}
      />
      <PaginatedTable<SearchStreakDailyItem>
        dataSource={splash.data?.searchStreakDaily || []}
        columns={columns}
        loading={splash.fetching}
        total={count.data?.countStreakDaily || 0}
        currentPage={currentPage}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        scrollHeight={'60vh'}
      />
    </Flex>
  )
}
