import type { FC } from 'react'
import { useWindowDimensions, View } from 'react-native'

import { Button } from '#components/base/Button/Button'
import { NotFound as NotFound404 } from '#components/base/IconSvg/SVG'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

type INotFoundProps = {
  onPress?: () => void
}

export const NotFound: FC<INotFoundProps> = ({ onPress }) => {
  const { width } = useWindowDimensions()
  return (
    <View style={tw.style('flex-1 flex-col p-4')}>
      <View style={tw`flex-1 flex-col items-center justify-center`}>
        <NotFound404 width={width} height={(width - 16) * 0.5625} />
        <View style={tw`mt-6 p-6 gap-1`}>
          <Text
            textAlign='center'
            color={tw.color('primary-400')}
            specialType='Headline4'
          >
            Content not available
          </Text>
          <Text
            textAlign='center'
            specialType='paragraph2'
            color={tw.color('text-2')}
          >
            This section is updating. Choose another question to continue
          </Text>
        </View>
      </View>
      <Button onPress={onPress}>Continue</Button>
    </View>
  )
}
