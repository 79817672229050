import { toastError } from '#components/utils/Toast'
import { navigate } from '#navigator/helpers'
import { PercentageTopic } from '#types/games'
import type { ResultPercentageTopic } from '#types/topic'

type Props = ResultPercentageTopic & { topicId: string }

export const redirectLearn = (props: Props) => {
  const {
    percentGame,
    countVocabularyUnKnown,
    countVocabulary,
    percentListening,
    countWord,
    count,
    topicId,
  } = props
  if (!countWord) {
    toastError({
      message: 'The data for the game section is currently empty',
    })
  } else if (!count || countWord > count) {
    navigate('App', { screen: 'KnownOrUnknown', params: { id: topicId } })
  } else if (percentGame < PercentageTopic.Game) {
    const isPhrase = countVocabularyUnKnown === countVocabulary
    navigate('App', { screen: 'Games', params: { topicId, isPhrase } })
  } else if (percentListening < PercentageTopic.Listen) {
    navigate('App', { screen: 'ListenGame', params: { topicId } })
  } else {
    navigate('App', { screen: 'ReviewListening', params: { topicId } })
  }
}
