import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { TouchableOpacity, View } from 'react-native'

import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import { S } from '#store'
import type { SearchTopicInUserItem } from '#types/topicInUser'

import { LessonList } from './LessonList'

type LessonProps = {
  lesson: SearchTopicInUserItem | null
  onChangeLesson?: (item: SearchTopicInUserItem) => void
  currentTopicInUser: SearchTopicInUserItem[]
  fetching: boolean
}

export const Lesson: FC<LessonProps> = observer(
  ({ lesson, onChangeLesson, currentTopicInUser, fetching }) => {
    const { openModal } = useOverlay()

    const onPressLesson = () => {
      openModal(LessonList, {
        userId: S.shared.currentUser?.id || '',
        onChangeLesson,
        currentTopicInUser,
        fetching,
      })
    }
    return (
      <TouchableOpacity
        style={tw`flex-row items-center py-3 px-4 bg-background-light-white gap-1 rounded-2xl`}
        onPress={onPressLesson}
      >
        <View
          style={tw`flex-row items-center justify-center p-2 rounded-full bg-primary-400`}
        >
          <SystemIcon
            type='SAX'
            name='Notepad2'
            size={16}
            color={tw.color('background-light-white')}
          />
        </View>
        <View style={tw`flex-1 flex-row items-center gap-1`}>
          <Text color={tw.color('text-2')} specialType='paragraph2'>
            Lesson:
          </Text>
          <Text specialType='Title' numberOfLines={1}>
            {lesson?.topic?.name || ''}
          </Text>
        </View>
        <SystemIcon type='SAX' name='ArrowRight2' />
      </TouchableOpacity>
    )
  },
)
