import type React from 'react'
import type { ScrollViewProps } from 'react-native'
import { ScrollView as RNScrollView } from 'react-native'
import type { Style } from 'twrnc'

import { tw } from '#components/utils/tw'

type CustomScrollViewProps = Omit<
  ScrollViewProps,
  'style' | 'contentContainerStyle'
> & {
  children: React.ReactNode
  style?: Style
  contentContainerStyle?: Style
}

export const ScrollView = ({
  children,
  style,
  contentContainerStyle,
  ...props
}: CustomScrollViewProps) => (
  <RNScrollView
    showsHorizontalScrollIndicator={false}
    showsVerticalScrollIndicator={false}
    {...props}
    style={tw.style('bg-transparent', style)}
    contentContainerStyle={tw.style('flex-grow', contentContainerStyle)}
  >
    {children}
  </RNScrollView>
)
