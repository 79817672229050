import { useEffect, useState } from 'react'

import { audioManager } from './audioManager'

export type AudioManagerProps = {
  url: string
}

export type ResultAudioManager = {
  isPlay: boolean
  play: (rate: number) => void
  stop: () => void
  pause: () => void
  setIsPlay: (s: boolean) => void
}

export const useAudioManager = ({
  url,
}: AudioManagerProps): ResultAudioManager => {
  const [isPlay, setIsPlay] = useState<boolean>(false)

  useEffect(
    () => () => {
      audioManager.stop()
      setIsPlay(false)
      url = ''
    },
    [url],
  )

  const setup = async () => {
    await audioManager.add(url)
  }

  const handlePlayAudio = async (rate: number) => {
    if (!url) {
      return
    }
    await setup()
    await audioManager.play(rate)
    setIsPlay(true)
  }

  const handleStop = () => {
    audioManager.stop()
    setIsPlay(false)
  }

  const handlePause = () => {
    audioManager.pause()
    setIsPlay(false)
  }

  return {
    isPlay,
    play: handlePlayAudio,
    stop: handleStop,
    pause: handlePause,
    setIsPlay,
  }
}
