import type React from 'react'
import { useState } from 'react'
import { TouchableOpacity, useWindowDimensions, View } from 'react-native'
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field'
import type { CodeFieldOverridableComponent } from 'react-native-confirmation-code-field/esm/CodeField'

import { Button } from '#components/base/Button/Button'
import { Countdown } from '#components/base/CountDown'
import { tw } from '#components/utils/tw'
import { gql } from '#graphql/urql'

import { IconSvg } from '../base/IconSvg'
import { Text } from '../base/Text'

type Pr = {
  modalId: string
  closeModal: () => void
  handleConfirm: (value: string, idForgotPassword: string) => Promise<boolean>
  cellCount?: number
  idForgotPassword?: string
  codeField?: Omit<
    CodeFieldOverridableComponent,
    'value' | 'onChangeText' | 'cellCount'
  >
}

export const OTPDialog: React.FC<Pr> = ({
  handleConfirm,
  closeModal,
  cellCount = 6,
  idForgotPassword,
}) => {
  const [value, setValue] = useState('')
  const ref = useBlurOnFulfill({ value, cellCount })
  const [showResend, setShowResend] = useState<boolean>()
  const [isCheckOtp, setIsCheckOtp] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  })
  const { width } = useWindowDimensions()

  const checkOtp = async () => {
    setIsLoading(true)

    if (idForgotPassword) {
      const r = await handleConfirm(value, idForgotPassword)

      if (r) {
        setIsLoading(false)
        closeModal()
      } else {
        setIsCheckOtp(true)
        setIsLoading(false)
      }
    }
  }

  const handleCountdownEnd = () => {
    setShowResend(true)
  }

  const handleResendCode = () => {
    setShowResend(false)

    if (idForgotPassword) {
      onResendTicket(idForgotPassword)
    }
  }

  const onResendTicket = async (id: string) => {
    try {
      await gql.resendTicket({ id })
    } catch (e) {}
  }

  const handleChangeText = (text: string) => {
    setValue(text)
    if (value.length === 0) {
      setIsCheckOtp(false)
    }
  }

  return (
    <View
      style={tw.style(
        'bg-background-light-1 p-4 rounded-2xl justify-center items-center m-4',
        // Platform.OS === 'web' ?  : 'w-80 ',
        { width: width > 540 ? 508 : width - 32 },
      )}
    >
      <View style={tw`w-full flex-row justify-end items-center`}>
        <TouchableOpacity onPress={closeModal}>
          <IconSvg name='x' />
        </TouchableOpacity>
      </View>

      <View style={tw`gap-6`}>
        <View style={tw`gap-1 px-4`}>
          <Text specialType='Headline3' textAlign='center'>
            Verify Code
          </Text>
          <Text
            specialType='Subtitle'
            textAlign='center'
            color={tw.color('text-2')}
          >
            Enter the 6-digit code sent to your email
          </Text>
        </View>

        <View style={tw`items-center justify-center gap-4`}>
          <CodeField
            ref={ref}
            keyboardType='number-pad'
            textContentType='oneTimeCode'
            autoComplete='sms-otp'
            autoFocus
            renderCell={({ index, symbol, isFocused }) => (
              <View
                key={index}
                style={tw.style(
                  'w-[44px] h-[44px] leading-[40px] text-xl border text-center rounded-xl mx-1.5 justify-center items-center',
                  isCheckOtp ? 'border-red-300' : 'border-primary-400',
                )}
                onLayout={getCellOnLayoutHandler(index)}
              >
                <Text specialType='Headline4' style={tw`text-xl text-center`}>
                  {symbol || (isFocused ? <Cursor /> : null)}
                </Text>
              </View>
            )}
            {...props}
            value={value}
            onChangeText={handleChangeText}
            cellCount={cellCount}
          />

          {isCheckOtp && (
            <Text
              specialType='Note'
              textAlign='center'
              color={tw.color('error-300')}
            >
              Verification code is not valid
            </Text>
          )}

          <View style={tw`flex-row items-center justify-center`}>
            <Text
              specialType='Note'
              textAlign='center'
              color={tw.color('text-2')}
              style={tw.style({
                lineHeight: 20,
              })}
            >
              Haven't received the code?{'  '}
            </Text>

            {showResend ? (
              <Button
                onPress={handleResendCode}
                tone='plain'
                size='plain'
                fontWeight='extrabold'
                titleColor={tw.color('primary-400')}
                style={tw.style({
                  height: 20, // Make Button height equal to the Text height
                  lineHeight: 20, // Consistent lineHeight
                })}
              >
                Resend
              </Button>
            ) : (
              <Countdown initialSeconds={90} onEnd={handleCountdownEnd} />
            )}
          </View>
        </View>
      </View>
      <View style={tw`w-full mt-6`}>
        <Button
          loading={isLoading}
          onPress={checkOtp}
          disabled={!(value.length === cellCount)}
        >
          Confirm
        </Button>
      </View>
    </View>
  )
}
