import { View } from 'react-native'
import type { SharedValue } from 'react-native-reanimated'

import { Image } from '#components/base/Image'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import type { SearchSplashItem } from '#types/splash'

type Props = {
  item: SearchSplashItem
  index: number
  scrollX: SharedValue<number>
  containerWidth: number
  width: number
  height: number
}

export const SlideItem = ({ item, containerWidth, width, height }: Props) => (
  <View
    style={[
      tw.style('flex justify-center items-center overflow-hidden rounded-2xl', {
        width: containerWidth,
      }),
    ]}
  >
    <Image
      style={tw.style('rounded-2xl', { width, height })}
      source={{ uri: item.thumbnail?.url }}
    />
    <View style={tw`px-6 mt-4 flex w-full flex-col gap-y-3`}>
      <View style={tw`flex flex-col gap-y-0.5`}>
        {item.title.split('<br>').map(text => (
          <Text
            key={text}
            specialType='Headline1'
            style={tw`leading-10`}
            color={tw.color('text-4')}
          >
            {text.trim()}
          </Text>
        ))}
      </View>
      <Text
        specialType='paragraph1'
        style={tw`leading-6`}
        color={tw.color('text-4')}
      >
        {item.subTitle}
      </Text>
    </View>
  </View>
)
