import type React from 'react'
import { useEffect } from 'react'
import { View } from 'react-native'

import { SystemIcon } from '#components/base/SystemIcon'
import { tw } from '#components/utils/tw'

import { Text } from '../base/Text'

type Condition = {
  test: (password: string) => boolean
  message: string
}
type Props = {
  password: string
  onAllConditionsMet?: (isValid: boolean) => void
}
const conditions: Condition[] = [
  {
    test: password => password.length >= 6,
    message: 'A minimum of 6 characters',
  },
  {
    test: password => /[A-Z]/.test(password),
    message: '1 upper case letter',
  },
  {
    test: password => /\d/.test(password),
    message: '1 or more numbers',
  },
  {
    test: password => /[!@#$%^&*(),.?":{}|<>]/.test(password),
    message: '1 or more special characters',
  },
]

export const WarningPassword: React.FC<Props> = ({
  password,
  onAllConditionsMet,
}) => {
  useEffect(() => {
    const isValidPassword = conditions.every(condition =>
      condition.test(password),
    )
    if (onAllConditionsMet) {
      onAllConditionsMet(isValidPassword)
    }
  }, [password, onAllConditionsMet])
  return (
    <View style={tw`gap-2`}>
      <Text specialType='paragraph1' color={tw.color('text-2')}>
        Password must contain the following:
      </Text>
      <View style={tw`gap-2`}>
        {conditions.map((condition, index) => {
          const isValid = condition.test(password)
          const showIcon = password.length > 0
          return (
            <View key={index} style={tw`flex-row items-center gap-2`}>
              {showIcon ? (
                <SystemIcon
                  type='SAX'
                  name={isValid ? 'TickCircle' : 'CloseCircle'}
                  variant='Bold'
                  color={tw.color(isValid ? 'success-300' : 'error-300')}
                  size={24}
                />
              ) : (
                <View
                  style={tw.style(
                    'w-6 h-6 rounded-full justify-center items-center bg-neutral-200',
                  )}
                />
              )}
              <Text specialType='paragraph2' color={tw.color('text-2')}>
                {condition.message}
              </Text>
            </View>
          )
        })}
      </View>
    </View>
  )
}
